"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WOMALL_SEARCH_KEY = exports.TRANSACTIONS_SEARCH_KEY = exports.ORDER_SEARCH_KEY = exports.MYLIKE_SEARCH_KEY = exports.GOOD_SEARCH_KEY = void 0;
exports.add = add;
exports.clear = clear;
exports.default = void 0;
exports.get = get;
exports.remove = remove;
var GOOD_SEARCH_KEY = 'good_search_key'; // 商品搜索key

exports.GOOD_SEARCH_KEY = GOOD_SEARCH_KEY;
var WOMALL_SEARCH_KEY = 'womall_search_key'; // 自营搜索key

exports.WOMALL_SEARCH_KEY = WOMALL_SEARCH_KEY;
var ORDER_SEARCH_KEY = 'order_search_key'; // 订单搜索key

exports.ORDER_SEARCH_KEY = ORDER_SEARCH_KEY;
var TRANSACTIONS_SEARCH_KEY = 'transactions_search_key'; // 交易流水搜索key

exports.TRANSACTIONS_SEARCH_KEY = TRANSACTIONS_SEARCH_KEY;
var MYLIKE_SEARCH_KEY = 'mylike_search_key'; // 我的收藏搜索key
// 添加单个缓存

exports.MYLIKE_SEARCH_KEY = MYLIKE_SEARCH_KEY;

function add(key, value) {
  if (!window.localStorage) {
    alert('Browser does not support localstorage');
    return false;
  } else {
    var storage = window.localStorage;
    storage.setItem(key, value);
  }
} // 获取单个缓存


function get(key) {
  if (!window.localStorage) {
    alert('Browser does not support localstorage');
    return false;
  } else {
    var storage = window.localStorage;
    return storage.getItem(key);
  }
} // 清除单个缓存


function remove(key) {
  if (!window.localStorage) {
    alert('Browser does not support localstorage');
    return false;
  } else {
    var storage = window.localStorage;
    storage.removeItem(key);
  }
} // 清除所有缓存


function clear() {
  if (!window.localStorage) {
    alert('Browser does not support localstorage');
    return false;
  } else {
    var storage = window.localStorage;
    storage.clear();
  }
}

var _default = {
  add: add,
  remove: remove,
  get: get,
  clear: clear,
  GOOD_SEARCH_KEY: GOOD_SEARCH_KEY,
  ORDER_SEARCH_KEY: ORDER_SEARCH_KEY,
  TRANSACTIONS_SEARCH_KEY: TRANSACTIONS_SEARCH_KEY,
  MYLIKE_SEARCH_KEY: MYLIKE_SEARCH_KEY
};
exports.default = _default;