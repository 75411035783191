"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.regexp.replace");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Withdrawal",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "money van-ellipsis"
  }, [_vm._v("₦" + _vm._s(_vm.commissionBalance))]), _vm._v(" "), _c("div", {
    staticClass: "money-tip"
  }, [_vm._v("Current commission balance")])]), _vm._v(" "), _c("van-form", {
    ref: "form",
    attrs: {
      "validate-first": ""
    },
    on: {
      submit: _vm.onSubmit
    }
  }, [_c("div", {
    staticClass: "input-title"
  }, [_vm._v("Please enter withdrawal amount")]), _vm._v(" "), _c("van-field", {
    staticClass: "amount",
    attrs: {
      formatter: _vm.formatterAmount,
      type: "number",
      label: "₦",
      name: "amount",
      placeholder: "0.00",
      rules: [{
        required: true,
        message: "Please enter amount"
      }, {
        validator: _vm.validAmount,
        message: "Minimum withdrawal amount:".concat(_vm.userCommissionInfo.canWithdrawLimit || 0, "NGN")
      }]
    },
    model: {
      value: _vm.formData.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "amount", $$v);
      },
      expression: "formData.amount"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "input-title"
  }, [_vm._v("Please enter your registered email address")]), _vm._v(" "), _c("van-field", {
    attrs: {
      name: "email",
      placeholder: "Email",
      rules: [{
        required: true,
        message: "Please enter email address"
      }, {
        validator: _vm.validEmail,
        message: "Please enter the correct email address"
      }]
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("van-button", {
          staticStyle: {
            "border-radius": "6px",
            width: "80px"
          },
          attrs: {
            disabled: _vm.counter ? true : false,
            color: "#EA3340",
            size: "small",
            type: "primary",
            plain: "",
            "native-type": "button"
          },
          on: {
            click: _vm.getCode
          }
        }, [_vm._v(_vm._s(_vm.counter ? _vm.counter : "Get Code"))])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formData.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "email", $$v);
      },
      expression: "formData.email"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "input-title"
  }, [_vm._v("Please enter your email code")]), _vm._v(" "), _c("van-field", {
    attrs: {
      name: "verifyCode",
      type: "digit",
      placeholder: "Code",
      rules: [{
        required: true,
        message: "Please enter email code"
      }, {
        pattern: /^\d{6}$/,
        message: "Email Code must be 6 digits"
      }]
    },
    model: {
      value: _vm.formData.verifyCode,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "verifyCode", $$v);
      },
      expression: "formData.verifyCode"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "input-title"
  }, [_vm._v("Please enter receiving bank account")]), _vm._v(" "), _c("van-field", {
    attrs: {
      readonly: "",
      clickable: "",
      "right-icon": "arrow"
    },
    on: {
      click: _vm.handleSelectAccount
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_vm.formData.selectAccountItem ? _c("div", {
          staticClass: "account-item"
        }, [_c("div", [_vm._v(_vm._s(_vm.formData.selectAccountItem.withdrawName))]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.formData.selectAccountItem.bankName))]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.formData.selectAccountItem.account.replace(/\D/g, "").replace(/....(?!$)/g, "$& ")))])]) : _c("div", {
          staticStyle: {
            color: "#C8C9CF"
          }
        }, [_vm._v("Select receiving bank account")])];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "16px"
    }
  }, [_c("van-button", {
    attrs: {
      round: "",
      block: "",
      type: "info",
      "native-type": "submit"
    }
  }, [_vm._v("OK")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "footer"
  }, [_c("van-cell", {
    attrs: {
      title: "Withdrawal record",
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/withdrawalRecord");
      }
    }
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Withdrawal rules",
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/withdrawalRules");
      }
    }
  })], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;