"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.function.name");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticStyle: {
      paddingTop: "46px"
    }
  }, [_c("van-nav-bar", {
    attrs: {
      title: _vm.title,
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("van-notice-bar", {
    attrs: {
      wrapable: "",
      "left-icon": "info-o",
      mode: "closeable"
    }
  }, [_vm._v("Please fill in the real guest information, not the warehouse staff information.")]), _vm._v(" "), _c("van-form", {
    on: {
      submit: _vm.onSave
    }
  }, [_c("van-field", {
    attrs: {
      name: "name",
      label: "Consignee:",
      rules: [{
        required: true,
        message: "Please fill in the consignee's name"
      }]
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      type: "tel",
      name: "phone",
      rules: [{
        required: true,
        message: "Please fill phone"
      }, {
        validator: _vm.validatorTel,
        message: "Please enter the correct number"
      }]
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c("div", {
          staticClass: "phone"
        }, [_c("span", [_vm._v("Phone:")]), _vm._v(" "), _c("van-dropdown-menu", [_c("van-dropdown-item", {
          attrs: {
            options: _vm.options
          },
          model: {
            value: _vm.countryCode,
            callback: function callback($$v) {
              _vm.countryCode = $$v;
            },
            expression: "countryCode"
          }
        })], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      name: "defaultAddress",
      label: "Set to default receiver"
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c("van-switch", {
          attrs: {
            size: "20"
          },
          model: {
            value: _vm.defaultAddress,
            callback: function callback($$v) {
              _vm.defaultAddress = $$v;
            },
            expression: "defaultAddress"
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "16px"
    }
  }, [_c("van-button", {
    attrs: {
      round: "",
      block: "",
      type: "danger",
      "native-type": "submit"
    }
  }, [_vm._v("Submit")]), _vm._v(" "), _vm.id ? _c("van-button", {
    attrs: {
      round: "",
      block: "",
      type: "default"
    },
    on: {
      click: _vm.onDelete
    }
  }, [_vm._v("Delete")]) : _vm._e()], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;