"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadUrlList = void 0;
// 上传图片到阿里云的路线
var uploadUrlList = ['https://womata.oss-accelerate.aliyuncs.com', // 英国路线
'https://womata-usa.oss-accelerate.aliyuncs.com', // 美国路线
'https://womata-gr.oss-accelerate.aliyuncs.com' // 德国路线
];
exports.uploadUrlList = uploadUrlList;