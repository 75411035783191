"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("core-js/modules/es6.function.name");

require("vant/es/checkbox/style");

var _checkbox = _interopRequireDefault(require("vant/es/checkbox"));

var _DeliveredItem = _interopRequireDefault(require("./DeliveredItem"));

var _ForwardingItem = _interopRequireDefault(require("./ForwardingItem"));

var _PurchaseItem = _interopRequireDefault(require("./PurchaseItem"));

var _components;

var _default2 = {
  components: (_components = {}, (0, _defineProperty2.default)(_components, _checkbox.default.name, _checkbox.default), (0, _defineProperty2.default)(_components, "DeliveredItem", _DeliveredItem.default), (0, _defineProperty2.default)(_components, "ForwardingItem", _ForwardingItem.default), (0, _defineProperty2.default)(_components, "PurchaseItem", _PurchaseItem.default), _components),
  props: {
    // 是否有包裹编号
    isCoding: {
      type: Boolean,
      default: null
    },
    activeTab: {
      type: Number,
      default: -1
    },
    invoiceStatus: {
      type: Number,
      default: 1
    },
    list: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    bgColor: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  methods: {}
};
exports.default = _default2;