"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addWithdrawAccount = addWithdrawAccount;
exports.bindSale = bindSale;
exports.commissionWithdrawal = commissionWithdrawal;
exports.createShareLink = createShareLink;
exports.editWithdrawAccount = editWithdrawAccount;
exports.geCommissionGroupList = geCommissionGroupList;
exports.getCommissionInfo = getCommissionInfo;
exports.getFansCommission = getFansCommission;
exports.getOrderCommissionList = getOrderCommissionList;
exports.getWithdrawAccountList = getWithdrawAccountList;
exports.getWithdrawRecordList = getWithdrawRecordList;

var _request = _interopRequireDefault(require("@/utils/request"));

// 用户佣金
function getCommissionInfo() {
  return (0, _request.default)({
    url: 'sale_service/orderCommissionInfo/totalCommission',
    method: 'get'
  });
} // 我的收益-我的粉丝


function getFansCommission() {
  return (0, _request.default)({
    url: 'sale_service/orderCommissionInfo/fansCommission',
    method: 'get'
  });
} // 我的佣金订单


function getOrderCommissionList(params) {
  return (0, _request.default)({
    url: 'sale_service/orderCommissionInfo/orderSaleInfo',
    method: 'get',
    params: params
  });
} // 佣金分组列表


function geCommissionGroupList(params) {
  return (0, _request.default)({
    url: 'spu-service/other/commissionGroupPmsSpu',
    method: 'get',
    params: params
  });
} // 佣金提现


function commissionWithdrawal(data) {
  return (0, _request.default)({
    url: 'sale_service/baseWithdrawRecord/withdraw',
    method: 'post',
    data: data
  });
} // 佣金提现记录


function getWithdrawRecordList(params) {
  return (0, _request.default)({
    url: 'sale_service/baseWithdrawRecord/app',
    method: 'get',
    params: params
  });
} // 获取佣金银行账户


function getWithdrawAccountList() {
  return (0, _request.default)({
    url: 'sale_service/baseWithdrawAccount/withdrawAccountList',
    method: 'get'
  });
} // 添加佣金银行账户


function addWithdrawAccount(data) {
  return (0, _request.default)({
    url: 'sale_service/baseWithdrawAccount/addWithdrawAccount',
    method: 'post',
    data: data
  });
} // 修改佣金银行账户


function editWithdrawAccount(data) {
  return (0, _request.default)({
    url: 'sale_service/baseWithdrawAccount/editWithdrawAccount',
    method: 'put',
    data: data
  });
} // 创建绑定url


function createShareLink(data) {
  return (0, _request.default)({
    url: 'sale_service/baseSaleRelation/createShareLink',
    method: 'post',
    data: data
  });
} // 绑定销售


function bindSale(data) {
  return (0, _request.default)({
    url: 'sale_service/baseSaleRelation/bindSale',
    method: 'post',
    data: data
  });
}