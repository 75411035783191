"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("core-js/modules/es6.function.name");

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

var _default = {
  name: 'TheLocalRecommendation',
  components: (0, _defineProperty2.default)({}, _image.default.name, _image.default),
  props: {
    recommedationList: {
      type: Array,
      required: true,
      default: null
    },
    titleStr: {
      type: String,
      default: ''
    },
    bgImageUrl: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      upRowGoodList: [],
      // 上一行
      downRowGoodList: [] // 下一行

    };
  },
  watch: {
    recommedationList: function recommedationList(newQuestion, oldQuestion) {
      this.getRowList();
    }
  },
  mounted: function mounted() {
    this.getRowList();
  },
  methods: {
    getRowList: function getRowList() {
      var _length = this.recommedationList.length;

      var _count = _length >= 8 ? Math.ceil(_length / 2) : _length;

      this.upRowGoodList = this.recommedationList.slice(0, _count);
      this.downRowGoodList = this.recommedationList.slice(_count, _length);
    },
    toGoodDetail: function toGoodDetail(item) {
      console.log("🚀 ~ file: TheLocalRecommendation.vue ~ line 100 ~ toGoodDetail ~ item", item);
      if (item === null) return;
      var _arguments = {
        "url": item['spuId'],
        "module": item['module']
      };
      this.$router.push({
        path: "/GoodsContent",
        query: _arguments
      });
    }
  }
};
exports.default = _default;