"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Withdrawal rules",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("Withdrawal rules")]), _vm._v(" "), _c("van-divider", {
    style: {
      borderColor: "#D1D1D1",
      margin: "16px 0 0"
    }
  })], 1), _vm._v(" "), _vm._m(0)])], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("main", [_c("div", {
    staticStyle: {
      marginBottom: "10px"
    }
  }, [_vm._v("Please read the following rules carefully：")]), _vm._v(" "), _c("div", [_vm._v("1)  After withdrawal, the amount will be transferred to the designatedaccount. Please "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("fill in the correct account information")]), _vm._v(" carefully.")]), _vm._v(" "), _c("div", [_vm._v("2)  ln order to protect the user's account amount, withdrawal must usethe "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("login account")]), _vm._v(" to submit information.")]), _vm._v(" "), _c("div", [_vm._v("3)  After submitting the account information, you need to "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("wait 3-5working days")]), _vm._v(" before the amount can reach the designated account.Please wait patiently.")]), _vm._v(" "), _c("div", [_vm._v("4)  After the withdrawal is completed, the withdrawal status of thewithdrawal record will be changed to "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("Finished")]), _vm._v(". You can view yourcommission arrival information in the transaction record.")]), _vm._v(" "), _c("div", [_vm._v("5)  You can only withdraw cash twice a month.")]), _vm._v(" "), _c("div", [_vm._v("6)  The amount of each withdrawal shall be more than ₦2000.")]), _vm._v(" "), _c("div", [_vm._v("7)  The amount of each withdrawal cannot exceed ₦20000.")]), _vm._v(" "), _c("div", [_vm._v("8)  After submitting the withdrawal, the commission amount will befrozen. "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("Please do not submit it again")]), _vm._v(".")]), _vm._v(" "), _c("div", [_vm._v("9)  After submitting the withdrawal, the commission amount will befrozen and cannot be used for other consumption.")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;