"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.function.name");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "delivery-details-view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Delivery details",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "wrap"
  }, [_c("div", {
    staticClass: "box",
    on: {
      click: function click($event) {
        return _vm.$router.push("/consignee");
      }
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/order/consignee.png"),
      size: "16"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "consignee"
  }, [_vm._v("Consignee")]), _vm._v(" "), _c("div", {
    staticClass: "theme-color"
  }, [_vm._v("change")])], 1), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_vm.selectedConsigneeItem ? [_c("div", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.selectedConsigneeItem.name))]), _vm._v(" "), _c("div", {
    staticClass: "phone"
  }, [_vm._v(_vm._s(_vm.selectedConsigneeItem.tel))])] : [_c("div", {
    staticClass: "name"
  }, [_vm._v("Add name and phone number of the consignee")])], _vm._v(" "), _c("van-icon", {
    attrs: {
      name: "arrow",
      color: "#666666",
      size: "16"
    }
  })], 2), _vm._v(" "), !_vm.selectedConsigneeItem ? _c("div", {
    staticStyle: {
      marginLeft: "22px",
      fontSize: "9px",
      color: "#666"
    }
  }, [_vm._v("*If you buy goods for the guest,please fill in the guest's information")]) : _vm._e()]), _vm._v(" "), _vm.sensitiveLineList.length ? _c("div", {
    staticClass: "box",
    on: {
      click: function click($event) {
        _vm.actionShow = true;
      }
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/order/logistics.png"),
      size: "16"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "consignee"
  }, [_vm._v("Logistics")]), _vm._v(" "), _c("div", {
    staticClass: "theme-color"
  }, [_vm._v("change")])], 1), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "name"
  }, [_c("div", {
    staticClass: "line"
  }, [_vm._v(_vm._s(_vm.sensitiveLineList[_vm.selectLineIndex].line)), _c("van-icon", {
    attrs: {
      name: _vm.sensitiveLineList[_vm.selectLineIndex].type === 2 ? require("@/assets/images/order/express_type_air.png") : require("@/assets/images/order/express_type_ship.png"),
      size: "20"
    }
  })], 1), _vm._v(" "), _c("div", [_vm._v("Estimated time：" + _vm._s(_vm.sensitiveLineList[_vm.selectLineIndex].round))])]), _vm._v(" "), _c("van-icon", {
    attrs: {
      name: "arrow",
      color: "#666666",
      size: "16"
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _vm.warehouseList.length ? _c("div", {
    staticClass: "box",
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/houseAddress",
          query: {
            warehouseList: _vm.warehouseList,
            clickReturn: 1
          }
        });
      }
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/order/warehouse.png"),
      size: "16"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "consignee"
  }, [_vm._v("Warehouse")]), _vm._v(" "), _c("div", {
    staticClass: "theme-color"
  }, [_vm._v("change")])], 1), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.warehouseList[_vm.selectWarehouseIndex].name))]), _vm._v(" "), _c("van-icon", {
    attrs: {
      name: "arrow",
      color: "#666666",
      size: "16"
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "box",
    class: {
      animate__animated: !_vm.isRemoveCartons && _vm.errTip,
      animate__shakeX: !_vm.isRemoveCartons && _vm.errTip
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/removeCartons",
          query: {
            isRemoveCartons: _vm.isRemoveCartons
          }
        });
      }
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/order/cartons.png"),
      size: "16"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "consignee"
  }, [_vm._v("Remove cartons or not")]), _vm._v(" "), _c("div", {
    staticClass: "theme-color"
  }, [_vm._v("change")])], 1), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_vm.isRemoveCartons ? [_c("div", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.isRemoveCartons === 1 ? "Yes" : "No"))])] : [_c("div", {
    staticClass: "name"
  }, [_vm._v("Please select")])], _vm._v(" "), _c("van-icon", {
    attrs: {
      name: "arrow",
      color: "#666666",
      size: "16"
    }
  })], 2)]), _vm._v(" "), _c("div", {
    staticClass: "box"
  }, [_vm.orderWithWeightList.length ? [_c("div", {
    staticClass: "title"
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/order/copy.png"),
      size: "16"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "consignee"
  }, [_vm._v("Orders")])], 1), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "photo"
  }, _vm._l(_vm.orderWithWeightList, function (item, index) {
    return _c("van-image", {
      key: index,
      attrs: {
        width: "1.4rem",
        height: "1.4rem",
        radius: "10",
        src: item.type === 3 ? require("@/assets/images/order/pack.png") : item.mainImage
      }
    });
  }), 1), _vm._v(" "), _c("div", {
    staticClass: "total"
  }, [_vm._v("Total：" + _vm._s(_vm.orderWithWeightList.length))])]), _vm._v(" "), _c("div", {
    staticClass: "estimateWeight"
  }, [_c("div", [_vm._v("Total estimated weight:")]), _vm._v(" "), _c("div", {
    staticStyle: {
      fontSize: "14px",
      marginLeft: "10px"
    }
  }, [_c("span", {
    staticClass: "theme-color"
  }, [_vm._v(_vm._s(Number(_vm.estimateWeight).toFixed(2)))]), _vm._v("KG")])])] : _vm._e(), _vm._v(" "), _vm.orderNoWeightList.length ? [_c("div", {
    staticClass: "title",
    class: {
      pad: _vm.orderWithWeightList.length ? true : false
    }
  }, [!_vm.orderWithWeightList.length ? _c("van-icon", {
    attrs: {
      name: require("@/assets/images/order/copy.png"),
      size: "16"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.orderWithWeightList.length ? _c("div", {
    staticClass: "consignee"
  }, [_vm._v("Orders")]) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "photo"
  }, _vm._l(_vm.orderNoWeightList, function (item, index) {
    return _c("van-image", {
      key: index,
      attrs: {
        width: "1.4rem",
        height: "1.4rem",
        radius: "10",
        src: item.type === 3 ? require("@/assets/images/order/pack.png") : item.mainImage
      }
    });
  }), 1), _vm._v(" "), _c("div", {
    staticClass: "total"
  }, [_vm._v("Total：" + _vm._s(_vm.orderNoWeightList.length))])]), _vm._v(" "), _vm._m(0)] : _vm._e()], 2), _vm._v(" "), _c("div", {
    staticClass: "box"
  }, [_c("div", {
    staticClass: "tips-weight"
  }, [_vm._v("The weight is an estimate and the logistics company shall prevail")]), _vm._v(" "), _vm.logisticList.length ? _c("div", {
    staticClass: "logistic"
  }, [_c("div", {
    staticClass: "logistic-title"
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/order/merge_order_tips.png"),
      size: "24"
    }
  }), _vm._v(" "), _vm._m(1)], 1), _vm._v(" "), _c("div", {
    staticClass: "logistic-content"
  }, [_c("van-checkbox-group", {
    model: {
      value: _vm.mergeLogisticIdList,
      callback: function callback($$v) {
        _vm.mergeLogisticIdList = $$v;
      },
      expression: "mergeLogisticIdList"
    }
  }, _vm._l(_vm.logisticList, function (item) {
    return _c("van-checkbox", {
      key: item.logisticsId,
      attrs: {
        name: item.logisticsId,
        shape: "",
        "icon-size": "18",
        "checked-color": "#EA3340",
        "label-disabled": ""
      }
    }, [_c("div", {
      on: {
        click: function click($event) {
          return _vm.handleClickDeliveryItem(item.logisticsId);
        }
      }
    }, [_c("div", {
      staticClass: "time"
    }, [_vm._v("Creat Time：" + _vm._s(_vm._f("parseTime")(item.createTime)))]), _vm._v(" "), _c("div", [_vm._v("Delivery ID：" + _vm._s(item.logisticsId))]), _vm._v(" "), _c("div", {
      staticClass: "logistic-content-pic"
    }, [_c("div", {
      staticClass: "photo"
    }, _vm._l(item.orderList, function (ele, index) {
      return _c("van-image", {
        key: index,
        attrs: {
          width: "1.4rem",
          height: "1.4rem",
          radius: "10",
          src: ele.images.toString() || require("@/assets/images/order/pack.png")
        }
      });
    }), 1), _vm._v(" "), _c("van-icon", {
      attrs: {
        name: "arrow",
        color: "#666666",
        size: "16"
      }
    })], 1)])]);
  }), 1)], 1)]) : _vm._e()])]), _vm._v(" "), _c("footer", [_c("van-notice-bar", {
    attrs: {
      mode: "closeable",
      color: "#EA3340",
      background: "#FFE2E2",
      "left-icon": "info-o",
      text: "Excluding international freigh"
    }
  }), _vm._v(" "), _c("van-button", {
    attrs: {
      color: "#EA3340",
      block: ""
    },
    on: {
      click: _vm.createDelivery
    }
  }, [_vm._v("Delivery")])], 1), _vm._v(" "), _c("van-action-sheet", {
    attrs: {
      title: "Select Logistics Company"
    },
    model: {
      value: _vm.actionShow,
      callback: function callback($$v) {
        _vm.actionShow = $$v;
      },
      expression: "actionShow"
    }
  }, [_c("div", {
    staticClass: "action-content"
  }, _vm._l(_vm.sensitiveLineList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "box",
      on: {
        click: function click($event) {
          return _vm.selectLine(index);
        }
      }
    }, [_c("van-icon", {
      attrs: {
        name: item.img,
        size: "40"
      }
    }), _vm._v(" "), _c("div", [_c("div", {
      staticClass: "line"
    }, [_vm._v(_vm._s(item.line)), _c("van-icon", {
      attrs: {
        name: item.type === 2 ? require("@/assets/images/order/express_type_air.png") : require("@/assets/images/order/express_type_ship.png"),
        size: "20"
      }
    })], 1), _vm._v(" "), _c("div", [_vm._v("Estimated time：" + _vm._s(item.round))])]), _vm._v(" "), _c("van-icon", {
      attrs: {
        "class-prefix": "iconfont ".concat(item.type === 2 ? "icon-kongyun1" : "icon-webcangdanhaiyun"),
        color: "#888888",
        size: "40"
      }
    })], 1);
  }), 0)]), _vm._v(" "), _c("van-popup", {
    staticClass: "deliveryDetailsItemPopup-view",
    style: {
      height: "80%"
    },
    attrs: {
      round: "",
      "safe-area-inset-bottom": "",
      "close-on-popstate": "",
      position: "bottom"
    },
    model: {
      value: _vm.deliveryItemShow,
      callback: function callback($$v) {
        _vm.deliveryItemShow = $$v;
      },
      expression: "deliveryItemShow"
    }
  }, [_c("div", {
    staticClass: "fixed-box"
  }, [_c("header", [_c("div", [_c("div", {
    staticStyle: {
      color: "#969799"
    }
  }, [_vm._v("Create Time：" + _vm._s(_vm._f("parseTime")(_vm.deliveryItem.creatTime)))]), _vm._v(" "), _c("div", [_vm._v("Delivery ID：" + _vm._s(_vm.logisticsId))])]), _vm._v(" "), _c("van-icon", {
    attrs: {
      name: "cross",
      size: "16"
    },
    on: {
      click: function click($event) {
        _vm.deliveryItemShow = false;
      }
    }
  })], 1), _vm._v(" "), _c("van-divider")], 1), _vm._v(" "), _c("DeliveryDetailsItem", {
    staticClass: "content-box",
    attrs: {
      "logistics-id": _vm.logisticsId,
      closeable: true,
      "delivery-item": _vm.deliveryItem
    },
    on: {
      show: function show($event) {
        _vm.deliveryItemShow = false;
      }
    }
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "estimateWeight"
  }, [_c("div", [_vm._v("No weight yet")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticStyle: {
      marginLeft: "10px",
      fontSize: "10px"
    }
  }, [_vm._v("You have another order to be shipped and you can ship them together to save shipping costs.\n            "), _c("br"), _vm._v("If necessary, please select it.")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;