"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.replace");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "good-list-page"
  }, [_c("div", {
    staticClass: "page-up"
  }, [_c("div", {
    staticClass: "nav-bar-box"
  }, [_c("div", {
    staticClass: "r-space-between nav-bar padding-row"
  }, [_c("div", {
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c("van-icon", {
    attrs: {
      name: "arrow-left",
      size: "16"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      width: "80vw"
    }
  }, [_c("van-search", {
    attrs: {
      shape: "round",
      disabled: "",
      placeholder: _vm.content ? _vm.content : ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.replace({
          path: "/search",
          query: {
            fromPage: "ProductListPage",
            moduleType: _vm.moduleType,
            content: _vm.content,
            searchKey: _vm.searchKey
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "left-icon",
      fn: function fn() {
        return [_c("span", {
          staticClass: "iconfont"
        }, [_vm._v("")])];
      },
      proxy: true
    }])
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "header-row"
  }, _vm._l(_vm.headerList, function (headerItem, index) {
    return _c("div", {
      key: index,
      class: headerItem.isSelected ? "header-active" : "header-unactive",
      on: {
        click: function click($event) {
          return _vm.changeItem(headerItem);
        }
      }
    }, [_vm._v("\n        " + _vm._s(headerItem.name) + " "), _c("van-icon", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: headerItem.desc,
        expression: "headerItem.desc"
      }],
      attrs: {
        name: "arrow-down"
      }
    }), _vm._v(" "), _c("van-icon", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !headerItem.desc,
        expression: "!headerItem.desc"
      }],
      attrs: {
        name: "arrow-up"
      }
    })], 1);
  }), 0)]), _vm._v(" "), _c("div", {
    staticClass: "page-sub"
  }, [_c("TheGoodListCom", {
    key: "".concat(_vm.screen) + "".concat(_vm.sort),
    attrs: {
      "group-id": _vm.groupId,
      "module-type": _vm.moduleType,
      screen: _vm.screen,
      sort: _vm.sort,
      "category-id1": _vm.categoryId1,
      "category-id2": _vm.categoryId2,
      content: _vm.content
    }
  })], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;