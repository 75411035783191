"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "help-page"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Help",
      "left-arrow": "",
      "safe-area-inset-top": ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "help-view"
  }, [_c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _vm._m(0), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "40px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "service-item"
  }, [_c("h3", [_vm._v("Purchasing services")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "f-14"
  }, [_vm._v("\n        You can select the products from various Chinese ecommerce platforms on the Womata the products link of 1688, Try our one-click shopping Agent service, for details, please read:\n      ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "theme-color",
    on: {
      click: function click($event) {
        _vm.$router.push("/user_help_purchase");
      }
    }
  }, [_vm._v("\n        Womata Shopping Agent Guide  "), _c("span", {
    staticClass: "iconfont",
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("")])]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "theme-color",
    on: {
      click: function click($event) {
        _vm.$router.push("/purchaseNotice");
      }
    }
  }, [_vm._v(" \n        Womata Purchase Notice  "), _c("span", {
    staticClass: "iconfont",
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("")])])]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "service-item"
  }, [_c("h3", [_vm._v("\n        Delivery services\n      ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "f-14"
  }, [_vm._v("\n        Ship a parcel aboard? Your friends and families want to ship a parcel to you? Don't worry, Womata will help you forward the parcel all around the world\n      ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "f-14"
  }, [_vm._v("\n        If you have already prepared the product and the parcel you want to forward, please read:\n      ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "theme-color",
    on: {
      click: function click($event) {
        _vm.$router.push("/user_help_forwarding");
      }
    }
  }, [_vm._v("\n        Womata Parcel Forwarding Guide "), _c("span", {
    staticClass: "iconfont",
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("")])])])])], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "padding-row-13"
  }, [_c("div", {
    staticClass: "padding-row-13"
  }, [_c("h2", [_vm._v("Hello! My New Friend~")]), _vm._v(" "), _c("h3", [_vm._v("Welcome to the Womata guide for new users")])])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;