"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", _vm._l(_vm.list, function (element, i) {
    return _c("div", {
      key: i,
      staticClass: "wrap"
    }, [_c("div", {
      staticClass: "card"
    }, [_c("div", {
      staticClass: "top-box",
      class: element.coupon.goodsRangeType === 1 ? "" : element.coupon.goodsRangeType === 2 ? "bg-womall" : "bg-1688"
    }, [_c("div", {
      staticClass: "top-box-left"
    }, [_c("div", {
      staticClass: "title"
    }, [_vm._v(_vm._s(element.coupon.titleEn || "WOMATA"))]), _vm._v(" "), _vm.timeShow ? _c("div", {
      staticClass: "time"
    }, [_vm._v("Validity period:" + _vm._s(_vm._f("parseTime")(element.createTime)) + " - " + _vm._s(_vm._f("parseTime")(element.expiresTime)))]) : _vm._e()]), _vm._v(" "), [element.coupon.type === 1 ? _c("div", {
      staticClass: "quota"
    }, [_vm._v(_vm._s(_vm.defaultCurrency.unit) + " " + _vm._s(_vm._f("CNYToCurrencyComma")(element.coupon.subtract)) + " "), _c("span", [_vm._v("off")])]) : element.coupon.type === 2 ? _c("div", {
      staticClass: "quota"
    }, [_vm._v(_vm._s(100 - element.coupon.discount) + "% "), _c("span", [_vm._v("off")])]) : element.coupon.type === 3 ? _c("div", {
      staticClass: "quota"
    }, [_vm._v(_vm._s(_vm.defaultCurrency.unit) + " " + _vm._s(_vm._f("CNYToCurrencyComma")(element.coupon.voucher)) + " "), _c("span", [_vm._v("off")])]) : _vm._e()]], 2), _vm._v(" "), _c("div", {
      staticClass: "bottom-box"
    }, [[element.coupon.type === 1 ? _c("div", {
      staticClass: "info"
    }, [_c("div", {
      staticClass: "round"
    }), _vm._v("Amount over " + _vm._s(_vm._f("CNYToCurrencyUnit")(element.coupon.reach)))]) : element.coupon.type === 2 ? _c("div", {
      staticClass: "info"
    }, [_c("div", {
      staticClass: "round"
    }), _vm._v(_vm._s(100 - element.coupon.discount) + "% off for items")]) : element.coupon.type === 3 ? _c("div", {
      staticClass: "info"
    }, [_c("div", {
      staticClass: "round"
    }), _vm._v("Special discount for particular goods")]) : _vm._e()], _vm._v(" "), _c("div", {
      staticClass: "use"
    }, [_vm._t("btn", null, {
      coupon: element.coupon
    }), _vm._v(" "), _c("van-icon", {
      attrs: {
        name: !element.switch ? require("@/assets/images/coupon/coupon_down.png") : require("@/assets/images/coupon/coupon_up.png"),
        size: "16"
      },
      on: {
        click: function click($event) {
          return _vm.$set(element, "switch", !element.switch);
        }
      }
    })], 2)], 2), _vm._v(" "), _vm.isDueSoon && element.isDueSoon ? _c("div", {
      staticClass: "tip"
    }, [_vm._v("Due soon")]) : _vm._e()]), _vm._v(" "), element.switch ? _c("div", {
      staticClass: "rules"
    }, [_c("div", [_vm._v("Use rules:")]), _vm._v(" "), _c("div", [_vm._v("1. The order cannot be refunded if using coupons, unless the goods are out of stock;")]), _vm._v(" "), _c("div", [_vm._v("2. If the goods are out of stock, the order refund amount will be refunded based on the actually paid amount, excluding the discount amount of the coupon;")]), _vm._v(" "), _c("div", [_vm._v("3. It is not allowed to be superimposed with other coupons;")]), _vm._v(" "), _c("div", [_vm._v("4. The final power of interpretation belongs to womata.")])]) : _vm._e()]);
  }), 0);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;