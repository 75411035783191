"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("van-nav-bar", {
    attrs: {
      title: "My Account",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("header", [_c("div", {
    on: {
      click: function click($event) {
        return _vm.$router.push("/changeUserName");
      }
    }
  }, [_vm._v(_vm._s(_vm.user.username) + " "), _c("van-icon", {
    attrs: {
      name: "edit",
      color: "#F8F8F8"
    }
  })], 1), _vm._v(" "), _c("HeadImgUpload", {
    attrs: {
      "oss-key": "womataH5/headImage/",
      "head-image": _vm.user.headImage
    },
    on: {
      fileImg: _vm.fileImg
    }
  }, [_vm.user.headImage ? _c("div", [_c("van-image", {
    staticStyle: {
      height: "80px",
      width: "80px"
    },
    attrs: {
      round: "",
      src: _vm.user.headImage,
      fit: "cover"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("div", {
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.copyUserCode(_vm.user.userCode);
      }
    }
  }, [_vm._v("ID: " + _vm._s(_vm.user.userCode) + " "), _c("van-icon", {
    attrs: {
      name: "share-o",
      color: "#F8F8F8"
    }
  })], 1)], 1), _vm._v(" "), _c("main", [_c("van-cell", {
    attrs: {
      title: "Phone number",
      "is-link": "",
      value: _vm.regMobile(_vm.user.phone)
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/changePhone");
      }
    }
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Email",
      value: _vm.regEmail(_vm.user.email)
    }
  })], 1), _vm._v(" "), _c("footer", [_c("van-cell", {
    attrs: {
      title: "Change login password",
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/forgot");
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: "lock"
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Logout",
      "is-link": ""
    },
    on: {
      click: _vm.handleLoginOut
    }
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;