"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/dropdown-item/style");

var _dropdownItem = _interopRequireDefault(require("vant/es/dropdown-item"));

require("vant/es/dropdown-menu/style");

var _dropdownMenu = _interopRequireDefault(require("vant/es/dropdown-menu"));

require("vant/es/switch/style");

var _switch = _interopRequireDefault(require("vant/es/switch"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/field/style");

var _field = _interopRequireDefault(require("vant/es/field"));

require("vant/es/form/style");

var _form = _interopRequireDefault(require("vant/es/form"));

require("vant/es/notice-bar/style");

var _noticeBar = _interopRequireDefault(require("vant/es/notice-bar"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _consignee = require("api/consignee");

var _components;

var _default = {
  name: "EditConsignee",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _noticeBar.default.name, _noticeBar.default), (0, _defineProperty2.default)(_components, _form.default.name, _form.default), (0, _defineProperty2.default)(_components, _field.default.name, _field.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _switch.default.name, _switch.default), (0, _defineProperty2.default)(_components, _dropdownMenu.default.name, _dropdownMenu.default), (0, _defineProperty2.default)(_components, _dropdownItem.default.name, _dropdownItem.default), _components),
  data: function data() {
    return {
      options: [{
        text: "+234",
        value: '+234'
      }, {
        text: "+86",
        value: '+86'
      }],
      title: 'Add New Consignee',
      id: '',
      name: '',
      countryCode: '+234',
      phone: '',
      defaultAddress: false
    };
  },
  created: function created() {
    var _this = this;

    this.title = this.$route.query.title || 'Add New Consignee';

    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      (0, _consignee.getConsigneeList)({
        id: this.$route.query.id
      }).then(function (res) {
        res.data.forEach(function (item) {
          _this.name = res.data[0].name;
          _this.phone = res.data[0].phone;
          _this.countryCode = res.data[0].countryCode || "+234";
          _this.defaultAddress = Boolean(res.data[0].defaultAddress);
        });
      });
    }
  },
  methods: {
    validatorTel: function validatorTel(val) {
      return /^(([0]\d{10})|([1-9]\d{9})|(1[3-9]\d{9}))$/.test(val);
    },
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    },
    onSave: function onSave(values) {
      var _this2 = this;

      console.log(values);

      if (this.id) {
        (0, _consignee.editConsignee)({
          id: this.id,
          name: values.name,
          phone: values.phone.length === 11 && values.phone.slice(0, 1) === "0" ? values.phone.substr(1) : values.phone,
          countryCode: this.countryCode,
          defaultAddress: Number(values.defaultAddress)
        }).then(function (res) {
          // console.log("onSubmit -> res", res.data)
          if (res.data + '' === 'undefined') {
            _toast.default.fail("".concat(res.message));

            return;
          }

          _toast.default.success('Submit successfully');

          _this2.$router.replace({
            path: '/consignee'
          });
        });
      } else {
        (0, _consignee.addConsignee)({
          name: values.name,
          phone: values.phone.length === 11 && values.phone.slice(0, 1) === "0" ? values.phone.substr(1) : values.phone,
          countryCode: this.countryCode,
          defaultAddress: Number(values.defaultAddress)
        }).then(function (res) {
          // console.log("onSubmit -> res", res.data)
          if (res.data + '' === 'undefined') {
            _toast.default.fail("".concat(res.message));

            return;
          }

          _toast.default.success('Submit successfully');

          _this2.$router.replace({
            path: '/consignee'
          });
        });
      }
    },
    onDelete: function onDelete() {
      var _this3 = this;

      (0, _consignee.deleteConsignee)(this.id).then(function (res) {
        // console.log("onSubmit -> res", res.data)
        if (res.data + '' === 'undefined') {
          _toast.default.fail("".concat(res.message));
        } else {
          _toast.default.success('Delete successfully');

          _this3.$router.replace({
            path: '/consignee'
          });
        }
      });
    }
  }
};
exports.default = _default;