"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("van-popup", {
    staticStyle: {
      height: "54%",
      padding: "16px 16px 44px"
    },
    attrs: {
      round: "",
      position: "bottom",
      "close-on-popstate": "",
      "safe-area-inset-bottom": ""
    },
    model: {
      value: _vm.show_,
      callback: function callback($$v) {
        _vm.show_ = $$v;
      },
      expression: "show_"
    }
  }, [_c("van-icon", {
    attrs: {
      name: "cross",
      size: "22"
    },
    on: {
      click: function click($event) {
        _vm.show_ = false;
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "title"
  }, [_vm._v("Freight fee for reference only")]), _vm._v(" "), _c("div", {
    staticClass: "weight"
  }, [_c("div", [_vm._v("Estimated weight：")]), _vm._v(" "), _c("div", [_c("span", {
    staticClass: "theme-color"
  }, [_vm._v(_vm._s(_vm.selectWeight))]), _vm._v("KG")])]), _vm._v(" "), _c("div", [_c("van-row", {
    staticClass: "table-title-bg"
  }, [_c("van-col", {
    attrs: {
      span: "7"
    }
  }, [_vm._v("Logistics")]), _vm._v(" "), _c("van-col", {
    attrs: {
      span: "5"
    }
  }, [_vm._v("Goods")]), _vm._v(" "), _c("van-col", {
    attrs: {
      span: "6"
    }
  }, [_vm._v("Freight fee")]), _vm._v(" "), _c("van-col", {
    attrs: {
      span: "6"
    }
  }, [_vm._v("Clearance fee")])], 1), _vm._v(" "), _vm._l(_vm.sensitiveLineList, function (item, index) {
    return _c("van-row", {
      key: index,
      staticClass: "table-row",
      attrs: {
        type: "flex",
        align: "center"
      }
    }, [_c("div", {
      class: {
        filter: item.startPlace === "GZ" && item.type === 2 && _vm.sensitive && !item.sensitive
      },
      staticStyle: {
        width: "100%"
      }
    }, [_c("van-col", {
      attrs: {
        span: "5"
      }
    }, [_vm._v(_vm._s(item.line))]), _vm._v(" "), _c("van-col", {
      staticClass: "table-col-color",
      attrs: {
        span: "2"
      }
    }, [_c("van-icon", {
      attrs: {
        name: item.type === 2 ? require("@/assets/images/order/express_type_air.png") : require("@/assets/images/order/express_type_ship.png"),
        size: "14"
      }
    })], 1), _vm._v(" "), _c("van-col", {
      staticClass: "table-col-color",
      attrs: {
        span: "5"
      }
    }, [_vm._v(_vm._s(item.ahPriceJson.sensitive === 1 ? "Specical" : "Normal"))]), _vm._v(" "), _c("van-col", {
      staticClass: "table-col-color",
      attrs: {
        span: "6"
      }
    }, [_vm._v(_vm._s(item.ahPriceJson.freightFee))]), _vm._v(" "), _c("van-col", {
      staticClass: "table-col-color",
      attrs: {
        span: "6"
      }
    }, [_vm._v(_vm._s(item.ahPriceJson.clearanceFee))])], 1), _vm._v(" "), item.startPlace === "GZ" && item.type === 2 && _vm.sensitive && !item.sensitive ? _c("div", {
      staticClass: "tips"
    }, [_vm._v("* The goods you selected includes special goods, which cannot go through GZ airport.")]) : _vm._e()]);
  })], 2), _vm._v(" "), _c("footer", [_c("div", [_c("van-checkbox", {
    attrs: {
      shape: "",
      "icon-size": "18",
      "checked-color": "#EA3340"
    },
    model: {
      value: _vm.checked,
      callback: function callback($$v) {
        _vm.checked = $$v;
      },
      expression: "checked"
    }
  }), _vm._v("I have understood international freight.")], 1), _vm._v(" "), _c("van-button", {
    attrs: {
      color: "#EA3340",
      disabled: !_vm.checked
    },
    on: {
      click: _vm.sendDelivery
    }
  }, [_vm._v("Next")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;