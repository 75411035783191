"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.regexp.replace");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Withdrawal",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.replace({
          name: "MyGain"
        });
      }
    }
  }), _vm._v(" "), _c("main", [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/my_gain/withdrawal_success.png"),
      size: "84",
      color: "#EA3340"
    }
  }), _vm._v(" "), _c("div", [_vm._v("Success!")]), _vm._v(" "), _c("div", [_vm._v("Please wait for 3 to 5 working days.")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;