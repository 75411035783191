"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: _vm.navBarTitle,
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("van-notice-bar", {
    attrs: {
      wrapable: "",
      "left-icon": "info-o"
    }
  }, [_vm._v("Please fill in the real account information")]), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("van-form", {
    ref: "form",
    attrs: {
      "validate-first": ""
    },
    on: {
      submit: _vm.onSubmit
    }
  }, [_c("div", {
    staticClass: "input-title"
  }, [_vm._v("Please enter the name of payee")]), _vm._v(" "), _c("van-field", {
    attrs: {
      name: "withdrawName",
      placeholder: "Please enter the name of payee",
      rules: [{
        required: true,
        message: "Please enter the name of payee"
      }]
    },
    model: {
      value: _vm.formData.withdrawName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "withdrawName", $$v);
      },
      expression: "formData.withdrawName"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "input-title"
  }, [_vm._v("Please enter the name of receiving bank")]), _vm._v(" "), _c("van-field", {
    attrs: {
      name: "bankName",
      placeholder: "Please enter the name of receiving bank",
      rules: [{
        required: true,
        message: "Please enter the name of receiving bank"
      }]
    },
    model: {
      value: _vm.formData.bankName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "bankName", $$v);
      },
      expression: "formData.bankName"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "input-title"
  }, [_vm._v("Please enter the receiving bank account number")]), _vm._v(" "), _c("van-field", {
    attrs: {
      formatter: _vm.formatter,
      type: "digit",
      name: "account",
      placeholder: "Account number",
      rules: [{
        required: true,
        message: "Please enter account number"
      }]
    },
    model: {
      value: _vm.formData.account,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "account", $$v);
      },
      expression: "formData.account"
    }
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      center: "",
      title: "Set as default receiving account"
    },
    scopedSlots: _vm._u([{
      key: "right-icon",
      fn: function fn() {
        return [_c("van-switch", {
          attrs: {
            "active-value": 1,
            "inactive-value": 2,
            size: "20"
          },
          model: {
            value: _vm.formData.isDefault,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "isDefault", $$v);
            },
            expression: "formData.isDefault"
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "16px"
    }
  }, [_c("van-button", {
    attrs: {
      round: "",
      block: "",
      type: "info",
      "native-type": "submit"
    }
  }, [_vm._v("Submit")])], 1)], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;