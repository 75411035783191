"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/dialog/style");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

require("vant/es/divider/style");

var _divider = _interopRequireDefault(require("vant/es/divider"));

require("core-js/modules/es6.function.name");

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

var _order = require("api/order");

var _components;

var _default2 = {
  name: "LocalGoodsRefundList",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _divider.default.name, _divider.default), (0, _defineProperty2.default)(_components, _image.default.name, _image.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _dialog.default.Component.name, _dialog.default.Component), _components),
  filters: {
    getStatusName: function getStatusName(status) {
      var statusString = "";

      switch (status) {
        case 1:
          statusString = 'Pending';
          break;

        case 2:
          statusString = 'Refunding';
          break;

        case 3:
          statusString = 'Finished';
          break;

        case 4:
          statusString = 'Refused';
          break;

        default:
          break;
      }

      return statusString;
    }
  },
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  methods: {
    // 取消售后
    cancel: function cancel(id) {
      var _this = this;

      (0, _order.cancelLocalGoodsRefund)(id).then(function (res) {
        _toast.default.success('successfully');

        _this.$emit("refreshList");
      });
    },
    // 点击支付
    handlePay: function handlePay(id, refundStationFee) {
      this.$emit("openPayPassword", {
        refundId: id,
        refundStationFee: refundStationFee
      });
    },
    // 地址弹框
    addressDialog: function addressDialog(item) {
      this.$emit("openAddress", item);
    }
  }
};
exports.default = _default2;