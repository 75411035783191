"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Select Consignee",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("van-notice-bar", {
    attrs: {
      wrapable: "",
      "left-icon": "info-o",
      mode: "closeable"
    }
  }, [_vm._v("Please fill in the real guest information, not the warehouse staff information.")]), _vm._v(" "), _c("van-contact-list", {
    attrs: {
      list: _vm.contactList,
      "add-text": "Add New Consignee",
      "default-tag-text": "default"
    },
    on: {
      add: _vm.onAdd,
      edit: _vm.onEdit,
      select: _vm.onSelect
    },
    model: {
      value: _vm.chosenContactId,
      callback: function callback($$v) {
        _vm.chosenContactId = $$v;
      },
      expression: "chosenContactId"
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;