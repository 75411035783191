"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("vant/es/dialog/style");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/notice-bar/style");

var _noticeBar = _interopRequireDefault(require("vant/es/notice-bar"));

require("core-js/modules/es6.function.name");

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

var _order = require("api/order");

var _api = require("api");

var _components;

var _default2 = {
  name: "DeliveredItem",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _noticeBar.default.name, _noticeBar.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), _components),
  props: {
    order: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  },
  created: function created() {// console.log(this.$attrs['delivered-status']);
  },
  methods: {
    paymentOrder: function paymentOrder(logisticsId) {
      var _this = this;

      (0, _api.getSkycargoExchangeRateList)({
        fromCurrency: "USD",
        toCurrency: "NGN"
      }).then(function (res) {
        _this.$EventBus.$emit('openPayPassword', {
          totalPriceNGN: Number(_this.order["customsClearanceFee"]) + (_this.order["logisticsFee"] ? Number(res.data.exchangeRate) * Number(_this.order["logisticsFee"]) : null),
          logisticsId: logisticsId
        });
      });
    },
    // 物流轨迹
    logisticsTrack: function logisticsTrack(logisticWaybillList) {
      this.$router.push({
        path: '/logisticsTrack',
        query: {
          waybill: logisticWaybillList.toString()
        }
      });
    },
    // 退回
    returnDelivered: function returnDelivered(logisticsId) {
      var _this2 = this;

      _dialog.default.confirm({
        title: 'Would you like to return the forwarding order?',
        theme: 'round-button'
      }).then(function () {
        (0, _order.returnInvoice)(logisticsId).then(function (res) {
          (0, _toast.default)("Return Success");

          _this2.$EventBus.$emit('orderListRefresh');
        });
      }).catch(function () {// on cancel
      });
    }
  }
};
exports.default = _default2;