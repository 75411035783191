"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("core-js/modules/es6.function.name");

require("vant/es/card/style");

var _card = _interopRequireDefault(require("vant/es/card"));

var _utils = require("@/utils");

var _components;

var _default2 = {
  name: "GoodCardItem",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _card.default.name, _card.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), _components),
  props: {
    ele: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    removeShow: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  methods: {
    toGoodDetailPage: function toGoodDetailPage() {
      if (this.removeShow) return;
      var _arguments = {
        "url": this.ele.module === 1 ? this.ele.spuId : (0, _utils.alibabaGoodsLink)(this.ele.spuId),
        "module": this.ele.module
      };
      this.$router.push({
        path: "/GoodsContent",
        query: _arguments
      });
    }
  }
};
exports.default = _default2;