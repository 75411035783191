"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/switch/style");

var _switch = _interopRequireDefault(require("vant/es/switch"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/cell/style");

var _cell = _interopRequireDefault(require("vant/es/cell"));

require("vant/es/field/style");

var _field = _interopRequireDefault(require("vant/es/field"));

require("vant/es/form/style");

var _form = _interopRequireDefault(require("vant/es/form"));

require("vant/es/notice-bar/style");

var _noticeBar = _interopRequireDefault(require("vant/es/notice-bar"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _cmmission = require("api/cmmission");

var _components;

var _default = {
  name: "EditAccount",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _noticeBar.default.name, _noticeBar.default), (0, _defineProperty2.default)(_components, _form.default.name, _form.default), (0, _defineProperty2.default)(_components, _field.default.name, _field.default), (0, _defineProperty2.default)(_components, _cell.default.name, _cell.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _switch.default.name, _switch.default), _components),
  data: function data() {
    return {
      formData: {
        withdrawName: "",
        bankName: "",
        account: "",
        isDefault: 2
      },
      navBarTitle: ""
    };
  },
  watch: {
    'formData.account': {
      handler: function handler(val) {
        if (val) {
          // 格式化成银行卡格式
          this.$set(this.formData, "account", val.replace(/\D/g, "").replace(/....(?!$)/g, "$& "));
        }
      }
    }
  },
  created: function created() {
    var _this$$route$query;

    // 判断是添加还是编辑
    if ((_this$$route$query = this.$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.selectAccountItem) {
      var _this$$route$query2, _this$$route$query2$s;

      if (!((_this$$route$query2 = this.$route.query) !== null && _this$$route$query2 !== void 0 && (_this$$route$query2$s = _this$$route$query2.selectAccountItem) !== null && _this$$route$query2$s !== void 0 && _this$$route$query2$s.id)) return this.$router.go(-1);
      this.navBarTitle = "Edit Account";
      this.formData = {
        id: this.$route.query.selectAccountItem.id,
        withdrawName: this.$route.query.selectAccountItem.withdrawName,
        bankName: this.$route.query.selectAccountItem.bankName,
        account: this.$route.query.selectAccountItem.account,
        isDefault: this.$route.query.selectAccountItem.isDefault
      };
    } else {
      this.navBarTitle = "Add New Account";
    }
  },
  methods: {
    formatter: function formatter(val) {
      return val.substr(0, 16);
    },
    // 提交
    onSubmit: function onSubmit(values) {
      var _this = this;

      console.log('submit', values);

      if (this.formData.id) {
        (0, _cmmission.editWithdrawAccount)({
          id: this.formData.id,
          withdrawName: values.withdrawName,
          bankName: values.bankName,
          account: values.account.replace(/\s*/g, ""),
          isDefault: this.formData.isDefault
        }).then(function (res) {
          _this.$router.replace({
            name: "SelectAccount",
            params: {
              editSuccess: 1
            }
          });
        });
      } else {
        (0, _cmmission.addWithdrawAccount)({
          withdrawName: values.withdrawName,
          bankName: values.bankName,
          account: values.account.replace(/\s*/g, ""),
          isDefault: this.formData.isDefault
        }).then(function (res) {
          _this.$router.replace({
            name: "SelectAccount",
            params: {
              editSuccess: 1
            }
          });
        });
      }
    }
  }
};
exports.default = _default;