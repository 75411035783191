"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/dialog/style");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

require("vant/es/form/style");

var _form = _interopRequireDefault(require("vant/es/form"));

require("vant/es/field/style");

var _field = _interopRequireDefault(require("vant/es/field"));

require("vant/es/notice-bar/style");

var _noticeBar = _interopRequireDefault(require("vant/es/notice-bar"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/divider/style");

var _divider = _interopRequireDefault(require("vant/es/divider"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _Upload = _interopRequireDefault(require("@/components/Upload"));

var _api = require("api");

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "BankRechargeTwo",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _divider.default.name, _divider.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _noticeBar.default.name, _noticeBar.default), (0, _defineProperty2.default)(_components, _field.default.name, _field.default), (0, _defineProperty2.default)(_components, _form.default.name, _form.default), (0, _defineProperty2.default)(_components, _dialog.default.Component.name, _dialog.default.Component), (0, _defineProperty2.default)(_components, "Upload", _Upload.default), _components),
  data: function data() {
    return {
      shadow: true,
      dialogShow: false,
      name: "",
      account: "",
      amount: "",
      remark: "",
      screenshots: []
    };
  },
  created: function created() {},
  methods: {
    formatter: function formatter(val) {
      return val && Number(val);
    },
    handleShadow: function handleShadow() {
      this.shadow = false;
      this.dialogShow = true;
    },
    addFileImg: function addFileImg(files) {
      this.screenshots.push({
        url: files.fileUrl,
        lastModified: files.lastModified,
        status: 'done'
      });
      console.log(files);
    },
    delImg: function delImg(lastModified) {
      this.screenshots = this.screenshots.filter(function (element) {
        return element.lastModified !== lastModified;
      });
    },
    // 表单验证无误
    submit: function submit(values) {
      var _this = this;

      console.log(values);
      if (!this.screenshots.length) return _toast.default.fail('screenshots is required');
      (0, _api.addArtificialRecharge)(_objectSpread(_objectSpread({}, values), {}, {
        screenshots: JSON.stringify(this.screenshots.map(function (item) {
          return item.url;
        }))
      })).then(function (res) {
        console.log(res);

        _this.$router.push("/bankRechargeThree");
      });
    },
    // 表单验证错误
    onFailed: function onFailed(errorInfo) {
      console.log("failed", errorInfo);
    },
    onClickLeft: function onClickLeft() {
      this.$router.push("/recharge");
    }
  }
};
exports.default = _default;