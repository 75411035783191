"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: "RecommendationListCom",
  props: {
    recommedationList: {
      type: Array,
      required: true,
      default: null
    }
  },
  methods: {
    toPage: function toPage(item) {
      var _arguments = {
        "groupId": item['otherGroupId'],
        "hideHotSale": true,
        "moduleType": 2 // 搜索自营和1688

      };
      this.$router.push({
        path: "/ProductListPage",
        query: _arguments
      });
    }
  }
};
exports.default = _default;