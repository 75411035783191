"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/sticky/style");

var _sticky = _interopRequireDefault(require("vant/es/sticky"));

require("vant/es/empty/style");

var _empty = _interopRequireDefault(require("vant/es/empty"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/list/style");

var _list = _interopRequireDefault(require("vant/es/list"));

require("vant/es/pull-refresh/style");

var _pullRefresh = _interopRequireDefault(require("vant/es/pull-refresh"));

require("vant/es/search/style");

var _search = _interopRequireDefault(require("vant/es/search"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _goods = require("@/api/goods.js");

var _auth = require("@/utils/auth");

var _TheGoodListCom = _interopRequireDefault(require("@/views/home/components/TheGoodListCom.vue"));

var _components;

var _default = {
  name: "Local",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _search.default.name, _search.default), (0, _defineProperty2.default)(_components, _pullRefresh.default.name, _pullRefresh.default), (0, _defineProperty2.default)(_components, _list.default.name, _list.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _empty.default.name, _empty.default), (0, _defineProperty2.default)(_components, _sticky.default.name, _sticky.default), (0, _defineProperty2.default)(_components, "TheGoodListCom", _TheGoodListCom.default), _components),
  data: function data() {
    return {
      keyword: "",
      // 海外仓分类
      localClassifyList: [],
      activeClassify: "0",
      isLogin: false
    };
  },
  created: function created() {
    var _this = this;

    this.isLogin = Boolean((0, _auth.getToken)());
    (0, _goods.getOneLevelCategory)({
      supplyType: 2,
      pageSize: 25
    }).then(function (res) {
      var _res$data;

      _this.localClassifyList = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.data) || [];

      _this.localClassifyList.unshift({
        categoryId: "0",
        categoryImage: require('@/assets/images/home/local_all.png'),
        englishTitle: "All"
      });
    });
  },
  methods: {
    // 选中分类
    handleClassify: function handleClassify(categoryId) {
      this.activeClassify = categoryId;
    }
  }
};
exports.default = _default;