"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addMyLike = addMyLike;
exports.aliTranslate = aliTranslate;
exports.cancelMyLike = cancelMyLike;
exports.checkMyLike = checkMyLike;
exports.getGoodsCommission = getGoodsCommission;
exports.getGoodsContent = getGoodsContent;
exports.getGroupList = getGroupList;
exports.getHomeSwiperImages = getHomeSwiperImages;
exports.getOneLevelCategory = getOneLevelCategory;
exports.getPmsSpu = getPmsSpu;
exports.getSpuActivity = getSpuActivity;
exports.getSpuCategory = getSpuCategory;
exports.getSpuInfoById = getSpuInfoById;
exports.getWarehouseSpuInfoById = getWarehouseSpuInfoById;
exports.saveProductInfo = saveProductInfo;
exports.searchGoodsFromImg = searchGoodsFromImg;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

var _request = _interopRequireDefault(require("@/utils/request"));

var _settings = _interopRequireDefault(require("@/settings"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// 获取1688商品详情
function getGoodsContent(data) {
  return (0, _request.default)({
    url: 'goods-service/thirdGoods/getByUrl',
    method: 'get',
    params: data
  });
} // 自营 根据spuId查询spu信息


function getSpuInfoById(data) {
  return (0, _request.default)({
    url: 'spu-service/spu/byId',
    method: 'get',
    params: _objectSpread(_objectSpread({}, data), {}, {
      version: _settings.default.Version
    })
  });
} // 自营海外仓 根据spuId查询spu信息


function getWarehouseSpuInfoById(data) {
  return (0, _request.default)({
    url: 'spu-service/spu/getSkuByWhId',
    method: 'get',
    params: data
  });
} // 获取商品是否有佣金


function getGoodsCommission(data) {
  return (0, _request.default)({
    url: 'sale_service/orderCommissionInfo/getCommission',
    method: 'get',
    params: data
  });
} // /保存1688商品


function saveProductInfo(data) {
  return (0, _request.default)({
    url: 'wms-service/unauth/spu/save',
    method: 'post',
    data: data
  });
} // 阿里翻译


function aliTranslate(data) {
  return (0, _request.default)({
    url: 'oss-service/translate/ali',
    method: 'post',
    data: data
  });
} // 获取首页轮播图


function getHomeSwiperImages() {
  return (0, _request.default)({
    url: 'base_service/advertising',
    method: 'get'
  });
} // 获取商品分组


function getGroupList(data) {
  return (0, _request.default)({
    url: 'spu-service/other/app',
    method: 'get',
    params: data
  });
} // 查询商品分组下的所有spu


function getPmsSpu(data) {
  return (0, _request.default)({
    url: 'spu-service/other/pmsSpu',
    method: 'get',
    params: data
  });
} // 自营商品首页分类


function getOneLevelCategory(data) {
  return (0, _request.default)({
    url: 'spu-service/category/app',
    method: 'get',
    params: data
  });
} // 自营分类页面 一级


function getSpuCategory(data) {
  return (0, _request.default)({
    url: 'spu-service/category',
    method: 'get',
    params: data
  });
} // 以图找货


function searchGoodsFromImg(data) {
  return (0, _request.default)({
    url: 'alibaba-service/findable/goods',
    method: 'post',
    data: data
  });
} // 获取闪购商品


function getSpuActivity(data) {
  return (0, _request.default)({
    url: 'spu-service/activity/spu',
    method: 'get',
    params: data
  });
} // /检测是否已经收藏


function checkMyLike(data) {
  return (0, _request.default)({
    url: 'spu-service/spu/user/check/like',
    method: 'get',
    params: data
  });
} // /取消收藏


function cancelMyLike(data) {
  return (0, _request.default)({
    url: 'spu-service/spu/user/unlike',
    method: 'post',
    data: data
  });
} // /加入收藏


function addMyLike(data) {
  return (0, _request.default)({
    url: 'spu-service/spu/user/like',
    method: 'post',
    data: data
  });
}