"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/dialog/style");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/cell/style");

var _cell = _interopRequireDefault(require("vant/es/cell"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _vuex = require("vuex");

var _utils = require("utils");

var _user = require("api/user");

var _HeadImgUpload = _interopRequireDefault(require("@/components/HeadImgUpload"));

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "UserInfo",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _image.default.name, _image.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, "HeadImgUpload", _HeadImgUpload.default), (0, _defineProperty2.default)(_components, _cell.default.name, _cell.default), _components),
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    user: function user(state) {
      return state.user['user'];
    }
  })),
  created: function created() {},
  methods: _objectSpread(_objectSpread(_objectSpread({
    regMobile: _utils.regMobile,
    regEmail: _utils.regEmail
  }, (0, _vuex.mapActions)({
    loginOut: 'user/loginOut',
    setUserData: 'user/SetUserData'
  })), (0, _vuex.mapMutations)({
    setUserData: 'user/SetUserData'
  })), {}, {
    fileImg: function fileImg(headImage) {
      var _this = this;

      (0, _user.editUser)({
        headImage: headImage
      }).then(function (res) {
        _toast.default.success('Upload avatar successfully');

        var user = _objectSpread(_objectSpread({}, _this.user), {}, {
          headImage: headImage
        });

        _this.setUserData(user);
      });
    },
    copyUserCode: function copyUserCode(userCode) {
      (0, _utils.copyTextToClipboard)(userCode);

      _toast.default.success('Copy success');
    },
    // 退出登录提示
    handleLoginOut: function handleLoginOut() {
      var _this2 = this;

      _dialog.default.confirm({
        title: 'Logout?',
        theme: 'round-button'
      }).then(function () {
        _this2.loginOut();
      }).catch(function () {// on cancel
      });
    },
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    }
  })
};
exports.default = _default;