"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/tag/style");

var _tag = _interopRequireDefault(require("vant/es/tag"));

require("vant/es/cell/style");

var _cell = _interopRequireDefault(require("vant/es/cell"));

require("vant/es/radio-group/style");

var _radioGroup = _interopRequireDefault(require("vant/es/radio-group"));

require("vant/es/radio/style");

var _radio = _interopRequireDefault(require("vant/es/radio"));

require("vant/es/cell-group/style");

var _cellGroup = _interopRequireDefault(require("vant/es/cell-group"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _vuex = require("vuex");

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// createNamespacedHelpers
var _default = {
  name: "Wallet",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _cellGroup.default.name, _cellGroup.default), (0, _defineProperty2.default)(_components, _radio.default.name, _radio.default), (0, _defineProperty2.default)(_components, _radioGroup.default.name, _radioGroup.default), (0, _defineProperty2.default)(_components, _cell.default.name, _cell.default), (0, _defineProperty2.default)(_components, _tag.default.name, _tag.default), _components),
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    defaultCurrency: function defaultCurrency(state) {
      return state.exchangeRate['defaultCurrency'];
    },
    exchangeRateList: function exchangeRateList(state) {
      return state.exchangeRate['exchangeRateList'];
    }
  })),
  created: function created() {},
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)({
    setDefaultCurrency: 'exchangeRate/setDefaultCurrency'
  })), {}, {
    handleCurrency: function handleCurrency(currency) {
      this.setDefaultCurrency(currency);
      this.$router.go(-1);
    },
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    }
  })
};
exports.default = _default;