"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

require("core-js/modules/es6.regexp.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/pull-refresh/style");

var _pullRefresh = _interopRequireDefault(require("vant/es/pull-refresh"));

require("vant/es/empty/style");

var _empty = _interopRequireDefault(require("vant/es/empty"));

require("vant/es/list/style");

var _list = _interopRequireDefault(require("vant/es/list"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/notice-bar/style");

var _noticeBar = _interopRequireDefault(require("vant/es/notice-bar"));

require("vant/es/tabs/style");

var _tabs = _interopRequireDefault(require("vant/es/tabs"));

require("vant/es/tab/style");

var _tab = _interopRequireDefault(require("vant/es/tab"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _coupon = require("api/coupon");

var _CouponItem = _interopRequireDefault(require("components/CouponItem"));

var _components;

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var _default = {
  name: "MyCoupon",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _tab.default.name, _tab.default), (0, _defineProperty2.default)(_components, _tabs.default.name, _tabs.default), (0, _defineProperty2.default)(_components, _noticeBar.default.name, _noticeBar.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _list.default.name, _list.default), (0, _defineProperty2.default)(_components, _empty.default.name, _empty.default), (0, _defineProperty2.default)(_components, _pullRefresh.default.name, _pullRefresh.default), (0, _defineProperty2.default)(_components, "CouponItem", _CouponItem.default), _components),
  data: function data() {
    return {
      notice: true,
      active: 1,
      loading: false,
      finished: false,
      refreshing: false,
      tabList: [{
        title: 'Latest',
        name: 1,
        data: []
      }, {
        title: 'Due soon',
        name: 2,
        data: []
      }],
      pageNum: 1
    };
  },
  created: function created() {
    this.getListData();
  },
  methods: {
    getListData: function getListData() {
      var _this = this;

      (0, _coupon.getCouponCode)({
        status: 1,
        queryDueSoon: this.active === 2 ? 1 : null,
        pageNum: this.pageNum,
        pageSize: 10
      }).then(function (res) {
        _this.refreshing = false;
        _this.loading = false;

        if (res.data.length > 0) {
          if (_this.pageNum === 1) {
            var _iterator = _createForOfIteratorHelper(_this.tabList),
                _step;

            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var item = _step.value;

                if (item.name === _this.active) {
                  item.data = [];
                  item.data = res.data;
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
          } else {
            var _iterator2 = _createForOfIteratorHelper(_this.tabList),
                _step2;

            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var _item = _step2.value;

                if (_item.name === _this.active) {
                  _item.data = _item.data.concat(res.data);
                }
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
        } else {
          if (_this.pageNum === 1) {
            var _iterator3 = _createForOfIteratorHelper(_this.tabList),
                _step3;

            try {
              for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                var _item2 = _step3.value;

                if (_item2.name === _this.active) {
                  _item2.data = [];
                }
              }
            } catch (err) {
              _iterator3.e(err);
            } finally {
              _iterator3.f();
            }
          }

          _this.finished = true;
        }
      });
    },
    // 去使用
    useNow: function useNow(coupon) {
      if (coupon.goodsUsableType === 1) {
        // 全部可用
        switch (coupon.goodsRangeType) {
          case 2:
            this.$router.push('/selfSupport');
            break;

          default:
            this.$router.push('/');
            break;
        }
      } else {
        // 部分可用
        switch (coupon.goodsRangeType) {
          case 2:
            this.$router.push({
              path: '/designatCouponPage',
              query: {
                id: coupon.id
              }
            });
            break;

          default:
            this.$router.push('/');
            break;
        }
      }
    },
    // 加载更多
    onLoad: function onLoad() {
      this.pageNum++;
      this.getListData();
    },
    // 切换tab
    onClick: function onClick() {
      this.tabList = this.$options.data().tabList;
      this.pageNum = 1;
      this.finished = false;
      this.getListData();
    },
    // 下拉刷新
    onRefresh: function onRefresh() {
      this.tabList = this.$options.data().tabList;
      this.finished = false;
      this.pageNum = 1;
      this.getListData();
    },
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    }
  }
};
exports.default = _default;