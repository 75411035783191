"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLocation = getLocation;
exports.setLocation = setLocation;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var position = {
  latitude: null,
  longitude: null
};

function getLocation(params) {
  return position;
}

function setLocation(params) {
  if ("geolocation" in navigator) {
    var toast = _toast.default.loading({
      duration: 5000,
      forbidClick: true
    });

    navigator.geolocation.getCurrentPosition(function (pos) {
      toast.clear();
      console.log("pos", pos);
      var crd = pos.coords;
      console.log('Your current position is:');
      console.log('Latitude : ' + crd.latitude);
      console.log('Longitude: ' + crd.longitude);
      console.log('More or less ' + crd.accuracy + ' meters.');
      position = {
        latitude: crd.latitude,
        longitude: crd.longitude
      };
    }, function (err) {
      toast.clear();
      console.warn('ERROR(' + err.code + '): ' + err.message); // Dialog.confirm({
      //   message: 'App needs to obtain your location permission to get the pickup point near you',
      //   theme: 'round-button'
      // })
      //   .then(() => {
      //     location.reload();
      //   })
      //   .catch(() => {
      //   });
    }, {
      enableHighAccuracy: true,
      // 是否精确
      timeout: 5000,
      // 允许最长时间
      maximumAge: 0 // 多久更新一次

    });
  } else {
    console.warn("不支持定位服务");
  }
}