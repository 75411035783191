"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "customerCare-view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Customer Care",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "bg"
  }, [_c("div", [_vm._v("Welcome to womata")]), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "10px 0"
    }
  }, [_vm._v("Womata is a cross-border platform to help you buy goods in China. You can choose your favorite goods in womata and we will send them to your country.")]), _vm._v(" "), _vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "tool"
  }, [_c("div", {
    staticClass: "box",
    on: {
      click: _vm.handleClickLink
    }
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/account/whatsapp_icon.png"),
      size: "48"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "box-name"
  }, [_vm._v("Whatsapp")])], 1)])])]), _vm._v(" "), _c("div", {
    staticClass: "card",
    staticStyle: {
      padding: "12px"
    }
  }, [_c("div", [_vm._v("Feedback & suggestions：")]), _vm._v(" "), _c("div", {
    staticClass: "mail",
    on: {
      click: function click($event) {
        return _vm.copy("service@womata.com");
      }
    }
  }, [_c("a", {
    attrs: {
      href: "mailto:service@womata.com"
    }
  }, [_vm._v("service@womata.com")])])])], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_vm._v("If you don't understand anything in the process of using, "), _c("span", {
    staticClass: "theme-color",
    staticStyle: {
      textDecoration: "underline"
    }
  }, [_vm._v("please contact us in the following ways:")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;