"use strict";

module.exports = {
  title: 'Womata',
  vconsole: true,

  /**
   * @type {String}
   * @description Version
   */
  Version: "4.3.6",

  /**
   * 1688图片地址
   */
  imgAliUrl: 'https://cbu01.alicdn.com/',

  /**
   * 1688移动端
   */
  alibabaMobileLink: 'https://m.1688.com',

  /**
   * 国内图片地址,以图找货的图片域名会被改为这个
   */
  chinaImgUrl: 'https://gz-rep.oss-cn-hangzhou.aliyuncs.com'
};