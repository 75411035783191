"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "shipping-fee-page"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Shipping Fee",
      "left-arrow": "",
      "safe-area-inset-top": ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "shipping-list"
  }, [_vm._l(_vm.shippingFeeList, function (shippingFeeItem, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "fee-item"
    }, [_c("div", {
      staticClass: "up-half padding-row-13"
    }, [_c("van-image", {
      attrs: {
        width: "12vw",
        height: "12vw",
        "lazy-load": "",
        fit: "cover",
        src: shippingFeeItem.img
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "line-title"
    }, [_c("span", {
      staticStyle: {
        "font-size": "13px",
        "font-weight": "bold"
      }
    }, [_vm._v("\n              " + _vm._s(shippingFeeItem.line) + "\n            ")]), _vm._v(" "), _c("div"), _vm._v(" "), _c("br"), _vm._v(" "), _c("span", {
      staticClass: "sub-title theme-color tag-background-color"
    }, [_vm._v("\n              " + _vm._s(shippingFeeItem.subTitleEn) + "\n            ")])]), _vm._v(" "), shippingFeeItem.expressType === 1 ? _c("span", {
      staticClass: "iconfont theme-color",
      staticStyle: {
        "font-size": "26px"
      }
    }, [_vm._v("")]) : _vm._e(), _vm._v(" "), shippingFeeItem.expressType === 2 ? _c("span", {
      staticClass: "iconfont theme-color",
      staticStyle: {
        "font-size": "26px"
      }
    }, [_vm._v("")]) : _vm._e()], 1), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "18px"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "van-hairline--top"
    }), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "18px"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "sub-half"
    }, [_c("div", {
      staticClass: "sub-half-row padding-row-13"
    }, [_c("div", {
      staticClass: "c-center-center"
    }, [_c("div", {
      staticClass: "sub-half-title"
    }, [_vm._v("\n                Freight fee\n              ")]), _vm._v(" "), _c("div", {
      staticClass: "sub-half-content"
    }, [_vm._v("\n                " + _vm._s(shippingFeeItem.ahPriceJson.freightFee) + "\n              ")])]), _vm._v(" "), _c("div", {
      staticClass: "c-center-center"
    }, [_c("div", {
      staticClass: "sub-half-title"
    }, [_vm._v("Clearance fee")]), _vm._v(" "), _c("div", {
      staticClass: "sub-half-content"
    }, [_vm._v("\n                " + _vm._s(shippingFeeItem.ahPriceJson.clearanceFee) + "\n              ")])]), _vm._v(" "), _c("div", {
      staticClass: "c-center-center"
    }, [_c("div", {
      staticClass: "sub-half-title"
    }, [_vm._v("Averange Time")]), _vm._v(" "), _c("div", {
      staticClass: "sub-half-content"
    }, [_vm._v("\n                " + _vm._s(shippingFeeItem.round) + "\n              ")])])]), _vm._v(" "), shippingFeeItem.content != null && !["", " "].includes(shippingFeeItem.content) ? _c("div", {
      staticClass: "remark theme-color"
    }, [_c("van-icon", {
      attrs: {
        name: "warning"
      }
    }), _vm._v(" "), _c("div", [_vm._v("\n              " + _vm._s(shippingFeeItem.content) + "\n            ")])], 1) : _vm._e()])])]);
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "33px"
    }
  }), _vm._v(" "), _vm._m(0), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "33px"
    }
  })], 2)], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "bottom-text c-center-center"
  }, [_c("div", [_vm._v("\n        For more logistics routes, please contact your agent.\n      ")]), _vm._v(" "), _c("div", [_vm._v("\n        You can inquire about logistics at the following website:\n      ")]), _vm._v(" "), _c("div", [_c("a", {
    staticStyle: {
      color: "#000"
    },
    attrs: {
      href: " https://www.skycargoltd.com",
      target: "_blank",
      rel: "noopener noreferrer"
    }
  }, [_vm._v(" https://www.skycargoltd.com")])])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;