"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("van-nav-bar", {
    attrs: {
      title: "My Wallet",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("header", [_c("div", [_vm._v("Current balance")]), _vm._v(" "), _c("div", {
    staticClass: "money"
  }, [_vm._v("₦ "), _c("span", {
    staticStyle: {
      fontSize: "28px"
    }
  }, [_vm._v(_vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.user.balance).toFixed(2))))])])]), _vm._v(" "), _c("main", [_c("van-cell", {
    attrs: {
      title: "Bnak transfer recharge record",
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/bankRechargeRecord");
      }
    }
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Transactions",
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/transactions");
      }
    }
  })], 1), _vm._v(" "), _c("footer", [_c("van-cell", {
    attrs: {
      title: "Change payment password",
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/paymentPassword");
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: "lock"
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Display currency",
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/currency");
      }
    }
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;