"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.function.name");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "houseaddress-list-view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: _vm.navBarTitle,
      "safe-area-inset-top": "",
      "left-arrow": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("van-tabs", {
    attrs: {
      animated: "",
      swipeable: ""
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("van-tab", {
    attrs: {
      title: "For local orders",
      disabled: _vm.formType === 2
    }
  }, _vm._l(_vm.packageStoreList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "card local"
    }, [_c("van-cell", {
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v("\n            " + _vm._s(item.nameEn) + "\n          ")];
        },
        proxy: true
      }], null, true)
    }), _vm._v(" "), _c("van-cell", {
      attrs: {
        value: item.address
      }
    })], 1);
  }), 0), _vm._v(" "), _c("van-tab", {
    attrs: {
      title: "For global orders"
    }
  }, _vm._l(_vm.warehouseList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "card",
      on: {
        click: function click($event) {
          return _vm.handleClick(item.id);
        }
      }
    }, [_c("header", [_c("div", {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.name))]), _vm._v(" "), _c("van-button", {
      attrs: {
        color: "#EA3340",
        round: "",
        plain: "",
        size: "mini"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.copy(item);
        }
      }
    }, [_vm._v("copy all")])], 1), _vm._v(" "), _c("div", {
      staticClass: "bg-border"
    }), _vm._v(" "), _c("main", [_c("div", [_vm._v("Adress:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(item.address))])]), _vm._v(" "), _c("footer", [_vm._v("Transport: " + _vm._s(item.memo))])]);
  }), 0)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;