"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_vm._l(_vm.orderList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "box"
    }, [_c("div", {
      staticClass: "title"
    }, [_c("van-icon", {
      attrs: {
        size: "18",
        name: item.type === 1 ? require("@/assets/images/cart/wo_store.png") : item.type === 2 ? require("@/assets/images/cart/common_store.png") : ""
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.sellerCompanyName))])], 1), _vm._v(" "), _c("div", {
      staticClass: "content"
    }, [item.goodsList.length === 1 ? _vm._l(item.goodsList, function (childItem, childIndex) {
      return _c("van-card", {
        key: childIndex,
        attrs: {
          thumb: childItem.imageUrl
        },
        scopedSlots: _vm._u([{
          key: "title",
          fn: function fn() {
            return [_c("div", {
              staticClass: "van-multi-ellipsis--l2 my-title-tag"
            }, [item.supplyType === 2 ? _c("span", {
              staticClass: "my-tag"
            }, [_vm._v("Goods in Nigeria")]) : _vm._e(), _vm._v(_vm._s(childItem.goodsNameEn))])];
          },
          proxy: true
        }, {
          key: "price",
          fn: function fn() {
            return [_vm._v("\n            " + _vm._s(_vm._f("CNYToCurrencyUnit")(childItem.unitPrice))), _c("span", {
              staticClass: "piece"
            }, [_vm._v(" /Piece")])];
          },
          proxy: true
        }, {
          key: "tags",
          fn: function fn() {
            return [_c("div", {
              staticStyle: {
                display: "flex",
                marginTop: "14px"
              }
            }, [_c("van-tag", {
              attrs: {
                round: "",
                color: "#f0f0f0",
                "text-color": "#727272"
              }
            }, [_vm._v(_vm._s(_vm._f("getSkuInfosEn")(childItem.skuInfosEn && JSON.parse(childItem.skuInfosEn))))])], 1)];
          },
          proxy: true
        }, {
          key: "num",
          fn: function fn() {
            return [_vm._v("\n            x" + _vm._s(childItem.quantity) + "\n          ")];
          },
          proxy: true
        }], null, true)
      });
    }) : [_c("div", {
      staticClass: "wrap"
    }, [_c("div", {
      staticClass: "photo"
    }, _vm._l(item.goodsList, function (childItem, childIndex) {
      return _c("van-image", {
        key: childIndex,
        attrs: {
          width: "88px",
          height: "88px",
          radius: "10",
          src: childItem.imageUrl
        }
      });
    }), 1), _vm._v(" "), _c("div", {
      staticClass: "total",
      on: {
        click: function click($event) {
          return _vm.openSubGood(item.supplyType, item.goodsList);
        }
      }
    }, [_vm._v("sub：X" + _vm._s(item.goodsList.length)), _c("van-icon", {
      attrs: {
        size: "10",
        name: "arrow"
      }
    })], 1)])]], 2), _vm._v(" "), _c("div", {
      staticClass: "bottom"
    }, [_c("div", {
      staticClass: "other"
    }, [_c("div", [_vm._v("Local Freight:"), _c("span", {
      staticClass: "theme-color"
    }, [_vm._v(" " + _vm._s(_vm._f("CNYToCurrencyUnit")(item.freightPrice)))])]), _vm._v(" "), _c("div", [_vm._v("Service Price:"), _c("span", {
      staticClass: "theme-color"
    }, [_vm._v(" " + _vm._s(_vm._f("CNYToCurrencyUnit")(item.servicePrice)))])])]), _vm._v(" "), _c("div", {
      staticClass: "total"
    }, [_vm._v("Subtotal:"), _c("span", {
      staticClass: "theme-color"
    }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(item.goodsPrice)))])])])]);
  }), _vm._v(" "), _vm.show ? _c("van-popup", {
    style: {
      height: "70%"
    },
    attrs: {
      round: "",
      "close-on-popstate": "",
      "safe-area-inset-bottom": "",
      position: "bottom"
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c("header", [_c("div", {
    staticClass: "title"
  }, [_vm._v("Commodity list")]), _vm._v(" "), _c("div", [_vm._v("sub: "), _c("van-icon", {
    attrs: {
      name: "cross",
      size: "12"
    }
  }), _vm._v(_vm._s(_vm.order.subGoodsList.length))], 1), _vm._v(" "), _c("van-icon", {
    attrs: {
      name: "close",
      size: "16"
    },
    on: {
      click: function click($event) {
        _vm.show = false;
      }
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, _vm._l(_vm.order.subGoodsList, function (childItem, childIndex) {
    return _c("van-card", {
      key: childIndex,
      attrs: {
        thumb: childItem.imageUrl
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c("div", {
            staticClass: "van-multi-ellipsis--l2 my-title-tag"
          }, [_vm.order.supplyType === 2 ? _c("span", {
            staticClass: "my-tag"
          }, [_vm._v("Goods in Nigeria")]) : _vm._e(), _vm._v(_vm._s(childItem.goodsNameEn))])];
        },
        proxy: true
      }, {
        key: "price",
        fn: function fn() {
          return [_vm._v("\n          " + _vm._s(_vm._f("CNYToCurrencyUnit")(childItem.unitPrice))), _c("span", {
            staticClass: "piece"
          }, [_vm._v(" /Piece")])];
        },
        proxy: true
      }, {
        key: "tags",
        fn: function fn() {
          return [_c("div", {
            staticStyle: {
              display: "flex",
              marginTop: "14px"
            }
          }, [_c("van-tag", {
            attrs: {
              round: "",
              color: "#f0f0f0",
              "text-color": "#727272"
            }
          }, [_vm._v(_vm._s(_vm._f("getSkuInfosEn")(childItem.skuInfosEn && JSON.parse(childItem.skuInfosEn))))])], 1)];
        },
        proxy: true
      }, {
        key: "num",
        fn: function fn() {
          return [_vm._v("\n          x" + _vm._s(childItem.quantity) + "\n        ")];
        },
        proxy: true
      }], null, true)
    });
  }), 1)]) : _vm._e()], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;