"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _vm._m(0);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "purchase-notice"
  }, [_c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _c("h2", [_vm._v("\n    Purchase notice:\n  ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "f-14"
  }, [_vm._v("\n    1.Womata provides purchasing services for designated third-party platform products, but does not assume the risk of third-party platform products. Womata only provides you with the service of purchasing the goods on the designated third-party platform, and does not bear the risks of infringement, quality and other related responsibilities of the goods themselves.\n  ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "f-14"
  }, [_vm._v("\n    2.Shopping is divided into two stages: purchasing and shipping.\n  ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "f-12 padding-left"
  }, [_vm._v("\n    Stage 1: You need to pay for the goods and the logistics costs and platform service fees of the goods in China first, and we will purchase for you. The purchased goods will be sent to Temporary storage in Womata transit warehouse. After the goods are put into the warehouse, they can be temporarily stored for 30 days for free. You can buy goods cumulatively and combine them for international delivery, which saves money.\n  ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "f-12 padding-left"
  }, [_vm._v("\n    Stage 2: Choose the goods you want to ship and submit the application for delivery. We will merge and pack the goods for cross-border transportation, the international logistics costs are paid to the logistics company when the goods are picked up in Nigeria.\n  ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "f-14"
  }, [_vm._v("\n    3.Purchasing goods are carried by third-party logistics providers, which are restricted by customs policies and may have logistics risks, which need to be assessed and borne by yourselves.\n  ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "f-12"
  }, [_vm._v("\n    As a service platform, Womata integrates many third-party international logistics providers to provide you with parcel delivery services for purchasing goods, but it does not bear the risks caused by customs policies and uncontrollable factors in cross-border logistics, such as fines, damage, lost items and delivery delays during peak periods, etc. Womata will assist you in risk estimation and reduce the probability of loss by providing timely risk reminders and continuous improvement of logistics insurance and other measures; Womata preferred mall provides all commodity logistics compensation guarantees without risk pressure.\n  ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "80px"
    }
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;