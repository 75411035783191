"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/cell/style");

var _cell = _interopRequireDefault(require("vant/es/cell"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _api = require("api");

var _components;

var _default = {
  name: "AfterService",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _cell.default.name, _cell.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), _components),
  data: function data() {
    return {
      APP_AFTER_CONFIG: {
        APP_RETURN_REFUND: 0,
        // APP-海外仓退款-退货退款
        APP_REFUND_ONLY: 0,
        // APP-海外仓退款-仅退款
        APP_EXCHANGE_GOODS: 0 // APP-海外仓退款-换货

      }
    };
  },
  created: function created() {
    var _this$$route$query,
        _this = this;

    if (!((_this$$route$query = this.$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.id)) this.$router.go(-1);
    (0, _api.getConfigList)("1597118458149994496").then(function (res) {
      console.log(res.data);
      res.data.forEach(function (element) {
        switch (element.sysValue) {
          case "APP_RETURN_REFUND":
            _this.APP_AFTER_CONFIG.APP_RETURN_REFUND = element.status;
            break;

          case "APP_REFUND_ONLY":
            _this.APP_AFTER_CONFIG.APP_REFUND_ONLY = element.status;
            break;

          case "APP_EXCHANGE_GOODS":
            _this.APP_AFTER_CONFIG.APP_EXCHANGE_GOODS = element.status;
            break;

          default:
            break;
        }
      });
    });
  },
  methods: {}
};
exports.default = _default;