"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.function.name");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticStyle: {
      padding: "0 10px"
    }
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Display Currency",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("main", [_c("van-radio-group", {
    model: {
      value: _vm.defaultCurrency.name,
      callback: function callback($$v) {
        _vm.$set(_vm.defaultCurrency, "name", $$v);
      },
      expression: "defaultCurrency.name"
    }
  }, [_c("van-cell-group", [_vm._l(_vm.exchangeRateList, function (item) {
    return [item.fromCurrency === "CNY" ? _c("van-cell", {
      key: item.id,
      attrs: {
        clickable: ""
      },
      on: {
        click: function click($event) {
          return _vm.handleCurrency({
            name: item.toCurrency,
            unit: item.toCurrencyUnit
          });
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c("span", [_vm._v(_vm._s(item.toCurrency))]), _vm._v(" "), _c("span", {
            staticStyle: {
              color: "#a3a3a3"
            }
          }, [_vm._v(_vm._s(item.toCurrencyUnit))])];
        },
        proxy: true
      }, {
        key: "right-icon",
        fn: function fn() {
          return [_c("van-radio", {
            attrs: {
              name: item.toCurrency
            },
            scopedSlots: _vm._u([{
              key: "icon",
              fn: function fn(props) {
                return [_c("van-icon", {
                  attrs: {
                    name: props.checked ? "success" : ""
                  }
                })];
              }
            }], null, true)
          })];
        },
        proxy: true
      }], null, true)
    }) : _vm._e()];
  })], 2)], 1)], 1), _vm._v(" "), _c("footer", [_c("div", [_vm._v("Exchange rate:")]), _vm._v(" "), _c("div", {
    staticClass: "exchange-rate"
  }, [_vm._l(_vm.exchangeRateList, function (item) {
    return [item.fromCurrency !== "NGN" && item.toCurrency === "NGN" ? _c("van-tag", {
      key: item.id,
      attrs: {
        round: "",
        color: "#ffcbcb",
        "text-color": "#7a7a7a"
      }
    }, [_vm._v("1" + _vm._s(item.fromCurrency) + " = " + _vm._s(item.exchangeRate) + " " + _vm._s(item.toCurrency))]) : _vm._e()];
  })], 2)]), _vm._v(" "), _c("div", {
    staticStyle: {
      fontSize: "10px",
      color: "#a7a7a7"
    }
  }, [_vm._v("* The specific exchange rate shall be subject to the settlement.")])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;