"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/tag/style");

var _tag = _interopRequireDefault(require("vant/es/tag"));

require("vant/es/card/style");

var _card = _interopRequireDefault(require("vant/es/card"));

require("vant/es/empty/style");

var _empty = _interopRequireDefault(require("vant/es/empty"));

require("vant/es/list/style");

var _list = _interopRequireDefault(require("vant/es/list"));

require("vant/es/tab/style");

var _tab = _interopRequireDefault(require("vant/es/tab"));

require("vant/es/tabs/style");

var _tabs = _interopRequireDefault(require("vant/es/tabs"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _cmmission = require("api/cmmission");

var _utils = require("@/utils");

var _components;

var _default = {
  name: "AllCommissioinOrders",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _image.default.name, _image.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _tabs.default.name, _tabs.default), (0, _defineProperty2.default)(_components, _tab.default.name, _tab.default), (0, _defineProperty2.default)(_components, _list.default.name, _list.default), (0, _defineProperty2.default)(_components, _empty.default.name, _empty.default), (0, _defineProperty2.default)(_components, _card.default.name, _card.default), (0, _defineProperty2.default)(_components, _tag.default.name, _tag.default), _components),
  filters: {
    getCommissionStatus: function getCommissionStatus(status) {
      var statusString = "";

      switch (status) {
        case 2:
          statusString = 'Upcoming';
          break;

        case 3:
          statusString = 'Gained';
          break;

        case 4:
          statusString = 'Closed';
          break;

        default:
          break;
      }

      return statusString;
    }
  },
  data: function data() {
    return {
      activeTab: 0,
      loading: false,
      finished: false,
      tabList: [{
        id: 0,
        title: "All"
      }, {
        id: 2,
        title: "Upcoming"
      }, {
        id: 3,
        title: "Gained"
      }, {
        id: 4,
        title: "Closed"
      }],
      list: [],
      userCommissionInfo: {},
      orderCount: 0,
      commissionSum: 0,
      pageNum: 1
    };
  },
  created: function created() {
    var _this = this;

    (0, _cmmission.getCommissionInfo)().then(function (res) {
      _this.userCommissionInfo = res.data;
    });
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this2 = this;

      (0, _cmmission.getOrderCommissionList)({
        commissionStatus: this.activeTab,
        pageNum: this.pageNum,
        pageSize: 10
      }).then(function (res) {
        var _res$data, _res$data2, _res$data3, _res$data3$data;

        _this2.loading = false;
        _this2.orderCount = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.orderIdCount) || 0;
        _this2.commissionSum = ((_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.commissionSum) || 0;

        if (((_res$data3 = res.data) === null || _res$data3 === void 0 ? void 0 : (_res$data3$data = _res$data3.data) === null || _res$data3$data === void 0 ? void 0 : _res$data3$data.length) > 0) {
          if (_this2.pageNum === 1) {
            _this2.list = res.data.data;
          } else {
            _this2.list = _this2.list.concat(res.data.data);
          }
        } else {
          if (_this2.pageNum === 1) _this2.list = [];
          _this2.finished = true;
        }
      });
    },
    // 跳转商品详情
    toGoodDetailPage: function toGoodDetailPage(childItem) {
      var _arguments = {
        "url": childItem.goodsSource === 0 ? childItem.spu : childItem.goodsSource === 2 ? (0, _utils.alibabaGoodsLink)(childItem.spu) : "",
        "module": childItem.goodsSource === 0 ? 1 : childItem.goodsSource === 2 ? 2 : ""
      };
      this.$router.push({
        path: "/GoodsContent",
        query: _arguments
      });
    },
    changeTab: function changeTab() {
      this.pageNum = 1;
      this.list = [];
      this.finished = false;
      this.fetchData();
    },
    onLoad: function onLoad() {
      this.pageNum++;
      this.fetchData();
    }
  }
};
exports.default = _default;