"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));

var _router = _interopRequireDefault(require("@/router"));

function getRoutesList(routes, pre) {
  return routes.reduce(function (array, route) {
    var path = "".concat(pre).concat(route.path);

    if (route.path !== '*') {
      array.push(path);
    }

    if (route.children) {
      array.push.apply(array, (0, _toConsumableArray2.default)(getRoutesList(route.children, "".concat(path, "/"))));
    }

    return array;
  }, []);
} // getRoutesList(router.options.routes, 'https://zigamiklic.com');


function getRoutesXML() {
  var list = getRoutesList(_router.default.options.routes, 'https://m.womata.ng').map(function (route) {
    return "<url>\n      <loc>".concat(route, "</loc>\n      <changefreq>daily</changefreq>\n      <priority>0.80</priority>\n    </url>");
  }).join('\r\n');
  return "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n  <urlset xmlns=\"http://www.sitemaps.org/schemas/sitemap/0.9\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd\">\n    ".concat(list, "\n  </urlset>");
}

console.log(getRoutesXML());