"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("h3", [_vm._v("Greater benefits")]), _vm._v(" "), _vm.list.length ? _c("van-list", {
    attrs: {
      finished: _vm.finished,
      "finished-text": "No More ...",
      "immediate-check": false
    },
    on: {
      load: _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm._l(_vm.list, function (ele, i) {
    return [_c("div", {
      key: i,
      staticClass: "good-card"
    }, [_c("van-card", {
      attrs: {
        thumb: ele.images
      },
      on: {
        "click-thumb": function clickThumb($event) {
          return _vm.toGoodDetailPage(ele);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c("div", {
            staticClass: "van-multi-ellipsis--l3 my-title-tag"
          }, [_c("span", [_vm._v(_vm._s(ele.spuName))])])];
        },
        proxy: true
      }, {
        key: "price",
        fn: function fn() {
          return [_c("div", {
            staticClass: "price"
          }, [_vm._v("You may gain "), _c("span", {
            staticClass: "commissionPrice"
          }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(ele.commissionPrice)))])])];
        },
        proxy: true
      }, {
        key: "num",
        fn: function fn() {
          return [_c("div", {
            staticClass: "Share",
            on: {
              click: function click($event) {
                return _vm.toGoodDetailPage(ele);
              }
            }
          }, [_vm._v("Share")])];
        },
        proxy: true
      }], null, true)
    })], 1)];
  })], 2) : _c("van-empty", {
    attrs: {
      description: "No more data!"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/no_data.png")
          }
        })];
      },
      proxy: true
    }])
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;