"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.function.name");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "home-view"
  }, [_c("div", {
    staticClass: "nav-bar-box"
  }, [_c("div", {
    staticClass: "r-space-between nav-bar padding-row"
  }, [_c("div", {
    on: {
      click: function click($event) {
        return _vm.$router.push("/currency");
      }
    }
  }, [_c("van-icon", {
    staticClass: "f-14",
    attrs: {
      name: "arrow-down"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "f-12"
  }, [_vm._v(" " + _vm._s(_vm.defaultCurrency.name))])], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      width: "80vw"
    }
  }, [_c("van-search", {
    attrs: {
      shape: "round",
      disabled: "",
      placeholder: "Please search products/paste your link"
    },
    on: {
      click: function click($event) {
        return _vm.toSearchPage();
      }
    },
    scopedSlots: _vm._u([{
      key: "left-icon",
      fn: function fn() {
        return [_c("span", {
          staticClass: "iconfont"
        }, [_vm._v("")])];
      },
      proxy: true
    }])
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "bg-color",
    staticStyle: {
      height: "6vh"
    }
  }), _vm._v(" "), _c("van-pull-refresh", {
    on: {
      refresh: _vm.initData
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_c("div", {
    staticClass: "bg-color",
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "padding-row bg-color"
  }, [_c("the-home-swiper", {
    key: _vm.tempTime
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "bg-color",
    staticStyle: {
      height: "30px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "padding-row bg-color entry-list"
  }, [_c("div", {
    staticClass: "r-space-between"
  }, _vm._l(_vm.entryListUp, function (entryItem, index) {
    return _c("div", {
      key: index,
      staticClass: "entry-item"
    }, [_c("div", {
      staticClass: "c-center-center",
      on: {
        click: function click($event) {
          return _vm.$router.push(entryItem.path);
        }
      }
    }, [_c("img", {
      staticStyle: {
        width: "8.2vw"
      },
      attrs: {
        src: entryItem.asset
      }
    }), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "14px"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "f-12",
      staticStyle: {
        color: "rgba(0, 0, 0, 0.4)"
      }
    }, [_vm._v(_vm._s(entryItem.text))])])]);
  }), 0), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "28px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "r-space-between"
  }, _vm._l(_vm.entryListDown, function (entryItem, index) {
    return _c("div", {
      key: index,
      staticClass: "entry-item"
    }, [_c("div", {
      staticClass: "c-center-center",
      on: {
        click: function click($event) {
          return _vm.$router.push(entryItem.path);
        }
      }
    }, [_c("img", {
      staticStyle: {
        width: "8.2vw"
      },
      attrs: {
        src: entryItem.asset
      }
    }), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "14px"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "f-12",
      staticStyle: {
        color: "rgba(0, 0, 0, 0.4)"
      }
    }, [_vm._v(_vm._s(entryItem.text))])])]);
  }), 0), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "15px"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "8px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "r-space-between"
  }, [_c("div", {
    staticClass: "banner-sub r-center-center"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/images/home/home_shop.png"),
      alt: "",
      srcset: ""
    }
  }), _vm._v(" "), _c("span", [_vm._v("Purchasing products")])]), _vm._v(" "), _c("div", {
    staticClass: "banner-sub r-center-center"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/images/home/home_mall.png"),
      alt: "",
      srcset: ""
    }
  }), _vm._v(" "), _c("span", [_vm._v("Private warehouse")])]), _vm._v(" "), _c("div", {
    staticClass: "banner-sub r-center-center"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/images/home/home_clock.png"),
      alt: "",
      srcset: ""
    }
  }), _vm._v(" "), _c("span", [_vm._v("International transport")])])]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "8px"
    }
  }), _vm._v(" "), _vm.token && _vm.couponList.length ? _c("div", {
    staticClass: "coupon"
  }, [_c("div", {
    staticClass: "coupon-content"
  }, [_c("div", {
    staticClass: "coupon-content-top"
  }, [_c("div", [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/home/coupon_center.png"),
      size: "22"
    }
  }), _c("span", [_vm._v("Discount")])], 1), _vm._v(" "), _c("div", {
    on: {
      click: function click($event) {
        return _vm.$router.push("/couponCenter");
      }
    }
  }, [_vm._v("More discount >>>")])]), _vm._v(" "), _c("div", {
    staticClass: "coupon-content-bottom"
  }, _vm._l(_vm.couponList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "coupon-card"
    }, [[item.type === 1 ? _c("div", {
      staticClass: "coupon-card-left theme-color"
    }, [_c("span", {
      staticClass: "van-ellipsis"
    }, [_vm._v(_vm._s(_vm.defaultCurrency.unit) + _vm._s(_vm._f("CNYToCurrencyComma")(item.subtract)) + " off")])]) : item.type === 2 ? _c("div", {
      staticClass: "coupon-card-left theme-color"
    }, [_c("span", {
      staticClass: "van-ellipsis"
    }, [_vm._v(_vm._s(100 - item.discount) + "% off")])]) : item.type === 3 ? _c("div", {
      staticClass: "coupon-card-left theme-color"
    }, [_c("span", {
      staticClass: "van-ellipsis"
    }, [_vm._v(_vm._s(_vm.defaultCurrency.unit) + _vm._s(_vm._f("CNYToCurrencyComma")(item.voucher)) + " off")])]) : _vm._e()], _vm._v(" "), _c("div", {
      staticClass: "coupon-card-right"
    }, [item.isReceived ? _c("span", {
      staticClass: "theme-color"
    }, [_vm._v("Use")]) : _c("span", {
      staticClass: "theme-color",
      on: {
        click: function click($event) {
          return _vm.obtainCoupon(item.id);
        }
      }
    }, [_vm._v("Get")])])], 2);
  }), 0)]), _vm._v(" "), _c("div", {
    staticClass: "coupon-footer"
  }, [_vm._v("Get coupons before shopping")])]) : _vm._e(), _vm._v(" "), _vm.couponList.length ? _c("div", {
    staticStyle: {
      height: "8px"
    }
  }) : _vm._e(), _vm._v(" "), _vm.localRecommedationMap && _vm.localRecommedationList.length ? _c("TheLocalRecommendation", {
    staticClass: "page-background-color",
    staticStyle: {
      "padding-bottom": "20px"
    },
    attrs: {
      "recommedation-list": _vm.localRecommedationList,
      "title-str": _vm.localRecommedationMap["commodityTitle"],
      "bg-image-url": _vm.localRecommedationMap["otherImage"]
    }
  }) : _vm._e(), _vm._v(" "), _vm.flashSaleList != null && _vm.flashSaleList.length > 0 ? _c("div", {
    staticClass: "padding-row page-background-color",
    staticStyle: {
      "padding-bottom": "20px"
    }
  }, [_c("FlashSaleCard", {
    attrs: {
      "flash-sale-list": _vm.flashSaleList,
      "begin-time": _vm.beginTime,
      "end-time": _vm.endTime,
      "activity-id": _vm.activityId
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "padding-row page-background-color"
  }, [_c("div", {
    staticClass: "bg-color",
    staticStyle: {
      "border-radius": "6px"
    }
  }, [_c("div", {
    staticStyle: {
      height: "15px"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "f-15 padding-row",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("Recommedation")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "15px"
    }
  }), _vm._v(" "), _c("RecommendationListCom", {
    attrs: {
      "recommedation-list": _vm.recommedationList
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "page-background-color",
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "page-background-color"
  }, [_c("img", {
    staticClass: "banner",
    attrs: {
      src: require("../../assets/images/home/home_banner2.png")
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/selfSupport");
      }
    }
  })]), _vm._v(" "), _c("van-tabs", {
    attrs: {
      "offset-top": "45px",
      swipeable: "",
      animated: "",
      sticky: "",
      ellipsis: false,
      "line-height": "0px"
    },
    model: {
      value: _vm.activeGroud,
      callback: function callback($$v) {
        _vm.activeGroud = $$v;
      },
      expression: "activeGroud"
    }
  }, _vm._l(_vm.tabTitleList, function (tabItem, index) {
    return _c("van-tab", {
      key: index,
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c("div", {
            staticClass: "r-space-between"
          }, [_c("div", {
            staticClass: "tab-title"
          }, [_c("div", {
            class: _vm.activeGroud == index ? "active-commodity-title" : "unactive-commodity-title"
          }, [_vm._v("\n                " + _vm._s(tabItem.commodityTitle) + "\n              ")]), _vm._v(" "), _c("div", {
            class: _vm.activeGroud == index ? "active-sub-title" : "unactive-sub-title"
          }, [_vm._v("\n                " + _vm._s(tabItem.commoditySubtitle) + "\n              ")])]), _vm._v(" "), index < _vm.tabTitleList.length - 1 ? _c("div", {
            staticStyle: {
              width: "1px",
              height: "22px",
              "background-color": "rgba(221, 221, 221)"
            }
          }) : _vm._e()])];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" "), _c("div", [_c("TheGoodListCom", {
      attrs: {
        "disabled-refresh": true,
        "group-id": tabItem.otherGroupId
      }
    })], 1)]);
  }), 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "page-background-color",
    staticStyle: {
      height: "80px"
    }
  }), _vm._v(" "), _c("FooterTabbar"), _vm._v(" "), _c("van-dialog", {
    attrs: {
      "message-align": "center",
      theme: "round-button",
      "confirm-button-text": "Get it",
      "close-on-click-overlay": ""
    },
    on: {
      confirm: function confirm($event) {
        return _vm.obtainCoupon(_vm.couponList[0].id);
      }
    },
    model: {
      value: _vm.couponTipShow,
      callback: function callback($$v) {
        _vm.couponTipShow = $$v;
      },
      expression: "couponTipShow"
    }
  }, [_c("img", {
    attrs: {
      src: require("../../assets/images/home/home_discount.png"),
      width: "100%"
    }
  }), _vm._v(" "), _vm.couponList.length ? _c("div", {
    staticClass: "wrap"
  }, [_c("div", {
    staticClass: "miss"
  }, [_vm._v("Don't miss this discount!")]), _vm._v(" "), _vm.couponList[0].type === 1 ? _c("div", {
    staticClass: "discount"
  }, [_vm._v(_vm._s(_vm.defaultCurrency.unit) + _vm._s(_vm._f("CNYToCurrencyComma")(_vm.couponList[0].subtract)) + " off on purchase of " + _vm._s(_vm.defaultCurrency.unit) + _vm._s(_vm._f("CNYToCurrencyComma")(_vm.couponList[0].reach)) + " or above")]) : _vm.couponList[0].type === 2 ? _c("div", {
    staticClass: "discount"
  }, [_vm._v(_vm._s(100 - _vm.couponList[0].discount) + "% off for items")]) : _vm.couponList[0].type === 3 ? _c("div", {
    staticClass: "discount"
  }, [_vm._v(_vm._s(_vm.defaultCurrency.unit) + _vm._s(_vm._f("CNYToCurrencyComma")(_vm.couponList[0].voucher)) + " off for particular goods")]) : _vm._e()]) : _vm._e()])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;