"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/dialog/style");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

require("core-js/modules/es6.function.name");

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

var _order = require("api/order");

var _filter = require("@/filter");

var _utils = require("@/utils");

var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));

var _components;

var _default2 = {
  name: "PurchaseItem",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _image.default.name, _image.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _dialog.default.Component.name, _dialog.default.Component), _components),
  props: {
    // 跳去页面Name
    clickToPageName: {
      type: String,
      default: null
    },
    // 是否显示取消按钮
    cancelBtnShow: {
      type: Boolean,
      default: false
    },
    // 是否显示售后服务按钮
    serviceBtnShow: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: Number,
      default: -1
    },
    order: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      show: false,
      pickupCode: "",
      boxQuantity: ""
    };
  },
  created: function created() {},
  methods: {
    // 点击商品卡片
    handleGoodCard: function handleGoodCard(good) {
      console.log(good);

      if (this.clickToPageName === "OrderDetails") {
        this.$router.push({
          path: '/orderDetails',
          query: {
            order: this.order
          }
        });
      } else if (this.clickToPageName === "GoodsContent") {
        var _arguments = {
          "url": this.order.type === 1 ? good.spu : (0, _utils.alibabaGoodsLink)(good.spu),
          "module": this.order.type || 2,
          "activityId": good.activityId
        };
        this.$router.push({
          path: "/GoodsContent",
          query: _arguments
        });
      }
    },
    cancelOrder: function cancelOrder(id, status) {
      var _this = this;

      _dialog.default.confirm({
        title: 'Cancel this order?',
        theme: 'round-button'
      }).then(function () {
        // 取消订单
        if (status === 0) {
          (0, _order.cancelOrder)({
            orderId: id
          }).then(function (res) {
            (0, _toast.default)("Order cancel successfully");

            _this.$EventBus.$emit('orderListRefresh');

            _this.$router.push({
              name: 'Order',
              params: {
                status: null
              }
            });
          });
        } else if (status === 8) {
          // 拒绝补款
          (0, _order.rejectAdditionalOrder)({
            orderId: id
          }).then(function (res) {
            (0, _toast.default)("Order cancel successfully");

            _this.$EventBus.$emit('orderListRefresh');

            _this.$router.push({
              name: 'Order',
              params: {
                status: null
              }
            });
          });
        }
      }).catch(function () {// on cancel
      });
    },
    // 退单-App待入库订单
    refundWarehousedOrder: function refundWarehousedOrder(id) {
      var _this2 = this;

      _dialog.default.confirm({
        title: 'Cancel this order?',
        theme: 'round-button'
      }).then(function () {
        (0, _order.refundWarehousedOrder)({
          orderId: id
        }).then(function (res) {
          (0, _toast.default)("Order cancel successfully");

          _this2.$EventBus.$emit('orderListRefresh');

          _this2.$router.push({
            name: 'Order',
            params: {
              status: null
            }
          });
        });
      }).catch(function () {// on cancel
      });
    },
    // 查看提货码
    viewCode: function viewCode(id) {
      var _this3 = this;

      (0, _order.getPickCodeList)({
        orderId: id
      }).then(function (res) {
        var _res$data, _res$data$pickupCode;

        if ((_res$data = res.data) !== null && _res$data !== void 0 && (_res$data$pickupCode = _res$data.pickupCode) !== null && _res$data$pickupCode !== void 0 && _res$data$pickupCode.length) {
          _this3.show = true;
          _this3.pickupCode = res.data.pickupCode[0];
          _this3.boxQuantity = res.data.boxQuantity;

          _this3.$nextTick(function () {
            _this3.$refs.qrcodeContainer.innerHTML = '';
            new _qrcodejs.default(_this3.$refs.qrcodeContainer, {
              text: res.data.pickupCode[0],
              width: 140,
              height: 140,
              colorDark: '#000000',
              colorLight: '#ffffff',
              correctLevel: _qrcodejs.default.CorrectLevel.H
            });
          });
        }
      });
    },
    paymentOrder: function paymentOrder(id, _type) {
      var _this4 = this;

      // 1.订单付款
      if (_type === 1) {
        if (Number(this.order.commissionOffsetPrice)) {
          _dialog.default.confirm({
            message: 'The Commission is not enough to pay the order. Do you want to use the wallet to pay it?',
            theme: 'round-button'
          }).then(function () {
            _this4.$EventBus.$emit(_this4.$route.name === 'OrderDetails' ? 'openPayPassword2' : 'openPayPassword', {
              totalPriceNGN: (Number((0, _filter.fromCurrencyByToCurrency)(_this4.order.totalPrice, "CNY", "NGN")) + Number(_this4.order.billAmount)).toFixed(2),
              commissionOffsetPrice: _this4.order.commissionOffsetPrice,
              orderIds: [id]
            });
          }).catch(function () {// on cancel
          });
        } else {
          this.$EventBus.$emit(this.$route.name === 'OrderDetails' ? 'openPayPassword2' : 'openPayPassword', {
            totalPriceNGN: (Number((0, _filter.fromCurrencyByToCurrency)(this.order.totalPrice, "CNY", "NGN")) + Number(this.order.billAmount)).toFixed(2),
            commissionOffsetPrice: this.order.commissionOffsetPrice,
            orderIds: [id]
          });
        }
      } else {
        // 2.订单补款
        this.$EventBus.$emit(this.$route.name === 'OrderDetails' ? 'openPayPassword2' : 'openPayPassword', {
          totalPriceNGN: (0, _filter.fromCurrencyByToCurrency)(this.order.orderPremium.amount, "CNY", "NGN"),
          orderPremiumId: id
        });
      }
    }
  }
};
exports.default = _default2;