"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("van-nav-bar", {
    attrs: {
      title: "Transactions Details",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("main", [_c("div", {
    staticClass: "top"
  }, [_c("van-icon", {
    attrs: {
      name: _vm.detailsData.transaction.type === 0 ? require("@/assets/images/account/recharge2.png") : _vm.detailsData.transaction.type === 1 ? require("@/assets/images/account/consume.png") : _vm.detailsData.transaction.type === 2 ? require("@/assets/images/account/refund2.png") : _vm.detailsData.transaction.type === 3 ? require("@/assets/images/account/withdrawal.png") : _vm.detailsData.transaction.type === 4 ? require("@/assets/images/account/deduction.png") : "",
      size: "36"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm._f("getTransactionType")(_vm.detailsData.transaction.type)) + "-" + _vm._s(_vm.detailsData.transaction.remark))]), _vm._v(" "), _c("div", {
    staticClass: "money",
    style: "color: ".concat(_vm.detailsData.transaction.type === 0 ? "#FC7A22" : _vm.detailsData.transaction.type === 1 ? "#EA3340" : _vm.detailsData.transaction.type === 2 ? "#009933" : _vm.detailsData.transaction.type === 3 ? "#D392FF" : _vm.detailsData.transaction.type === 4 ? "#be9c54" : "", ";")
  }, [_vm._v(_vm._s(_vm.detailsData.transaction.direction === 1 ? "+" : "-") + "₦" + _vm._s(_vm._f("numberToCurrencyNo")((Number(_vm.detailsData.transaction.amount) * Number(_vm.detailsData.transaction.exchangeRate)).toFixed(2))))])], 1), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Wallet balance",
      value: "₦" + _vm.detailsData.balance
    }
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Transaction status",
      value: _vm._f("getTransactionStatus")(_vm.detailsData.transaction.state)
    }
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Transaction type",
      value: _vm._f("getTransactionType")(_vm.detailsData.transaction.type)
    }
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Transaction mode"
    }
  }, [[_vm._v("\n        " + _vm._s(_vm._f("getBalanceType")(_vm.detailsData.balanceType)) + " " + _vm._s(_vm._f("getTransactionWay")(_vm.detailsData.transaction.mode)) + "\n      ")]], 2), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Serial No.",
      value: _vm.detailsData.transactionId
    }
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Create time",
      value: _vm._f("parseTime")(_vm.detailsData.transaction.transactionTime)
    }
  })], 1), _vm._v(" "), _c("footer", [_vm.detailsData.transaction.orderId || _vm.detailsData.transaction.logisticId ? _c("van-cell", {
    attrs: {
      "is-link": "",
      to: {
        path: _vm.detailsData.transaction.orderId ? "/orderDetails" : "/deliveryDetails",
        query: {
          id: _vm.detailsData.transaction.orderId || null,
          logisticsId: _vm.detailsData.transaction.logisticId || null
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("\n        " + _vm._s(_vm.detailsData.transaction.orderId ? "Order details" : "Delivery order details") + "\n      ")];
      },
      proxy: true
    }], null, false, 2809112188)
  }, [_vm._v(" "), [_vm._v("\n        " + _vm._s(_vm.detailsData.transaction.orderId || _vm.detailsData.transaction.logisticId) + "\n      ")]], 2) : _vm._e(), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Remark",
      value: _vm.detailsData.transaction.remarkDetail || ""
    }
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;