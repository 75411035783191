"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticStyle: {
      padding: "0 10px"
    }
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Recharge",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _vm.rachargeList.length ? _c("main", [_c("div", [_c("div", [_vm._v("Recharge amount")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("getCurrencyToUnit")(_vm.rachargeList[_vm.defaultMode]["toCurrency"])) + " " + _vm._s(_vm.rachargeList[_vm.defaultMode]["amount"]))])]), _vm._v(" "), _c("div", {
    staticStyle: {
      borderBottom: "1px solid #e4e4e4"
    }
  }, [_c("div", [_vm._v("Transactioin fee")]), _vm._v(" "), _c("div", [Number(_vm.rachargeList[_vm.defaultMode].transactionFee) ? _c("span", {
    staticClass: "tip",
    on: {
      click: function click($event) {
        _vm.dialogShow = true;
      }
    }
  }, [_vm._v("How to calculate ?")]) : _vm._e(), _vm._v(" " + _vm._s(_vm._f("getCurrencyToUnit")(_vm.rachargeList[_vm.defaultMode]["fromCurrency"])) + " " + _vm._s(_vm.rachargeList[_vm.defaultMode]["transactionFee"]))])]), _vm._v(" "), _c("div", [_c("div", [_vm._v("Total")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "green"
    }
  }, [_vm._v(_vm._s(_vm._f("getCurrencyToUnit")(_vm.rachargeList[_vm.defaultMode]["fromCurrency"])) + " " + _vm._s(_vm.rachargeList[_vm.defaultMode]["payAmount"]))])])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticStyle: {
      paddingLeft: "20px",
      color: "#787878"
    }
  }, [_vm._v("Select a way to Recharge")]), _vm._v(" "), _c("div", {
    staticClass: "select-racharge"
  }, [_c("van-radio-group", {
    model: {
      value: _vm.defaultMode,
      callback: function callback($$v) {
        _vm.defaultMode = $$v;
      },
      expression: "defaultMode"
    }
  }, [_c("van-cell-group", [_vm._l(_vm.rachargeList, function (item, index) {
    return [_c("van-cell", {
      key: index,
      attrs: {
        icon: item.mode === 1 ? require("@/assets/images/account/paystack.jpg") : item.mode === 2 ? require("@/assets/images/account/flutterwave.png") : item.mode === 3 ? require("@/assets/images/account/alipay.png") : "",
        clickable: ""
      },
      on: {
        click: function click($event) {
          _vm.defaultMode = index;
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c("span", [_vm._v(_vm._s(item.modeNameEn))])];
        },
        proxy: true
      }, {
        key: "right-icon",
        fn: function fn() {
          return [_c("van-radio", {
            attrs: {
              name: index
            },
            scopedSlots: _vm._u([{
              key: "icon",
              fn: function fn(props) {
                return [_c("van-icon", {
                  attrs: {
                    name: props.checked ? "success" : ""
                  }
                })];
              }
            }], null, true)
          })];
        },
        proxy: true
      }], null, true)
    })];
  })], 2)], 1)], 1), _vm._v(" "), _vm.rachargeList.length ? _c("footer", {
    staticClass: "van-contact-list__bottom"
  }, [_c("van-button", {
    staticClass: "van-contact-list__add",
    attrs: {
      round: "",
      block: "",
      type: "info",
      color: "#EA3340"
    },
    on: {
      click: _vm.toPay
    }
  }, [_vm._v("Go to pay " + _vm._s(_vm._f("getCurrencyToUnit")(_vm.rachargeList[_vm.defaultMode]["fromCurrency"])) + _vm._s(_vm.rachargeList[_vm.defaultMode]["payAmount"]))])], 1) : _vm._e(), _vm._v(" "), _vm.rachargeList.length ? _c("van-dialog", {
    attrs: {
      title: "How to calculate the transaction fee from ".concat(_vm.rachargeList[_vm.defaultMode].modeNameEn, "?"),
      "close-on-click-overlay": "",
      "confirm-button-text": "Got it"
    },
    model: {
      value: _vm.dialogShow,
      callback: function callback($$v) {
        _vm.dialogShow = $$v;
      },
      expression: "dialogShow"
    }
  }, [_vm.rachargeList[_vm.defaultMode].mode === 1 ? [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("●  "), _c("span", [_vm._v("Final Amount")])]), _vm._v(" "), _c("div", {
    staticClass: "formula"
  }, [_vm._v("Final Amount = ({Price} + {Flat fee}) / (1 - {Decimal Fee})")]), _vm._v(" "), _c("p", [_c("span", [_vm._v("1. Final Amount -")]), _vm._v(" This is the amount you ultimately need to pay, including the transaction fee charged by paystack.")]), _vm._v(" "), _c("p", [_c("span", [_vm._v("2. Price -")]), _vm._v(" This is how much you will receive after we deduct our transaction fees.")]), _vm._v(" "), _c("p", [_c("span", [_vm._v("3. Decimal Fee -")]), _vm._v(" This is our percentage transaction fee converted to decimals. To calculate this simply divide the fee that applies to you by 100. So if the transaction fee for your country is 1.50%, your decimal fee is 0.0150.")]), _vm._v(" "), _c("p", [_c("span", [_vm._v("4. Flat fee -")]), _vm._v(" This is the flat fee we charge based on our pricing.")])]), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("●"), _c("span", [_vm._v("For Local Transactions")])]), _vm._v(" "), _c("div", {
    staticClass: "formula weight"
  }, [_vm._v("1.5% + NGN 100")]), _vm._v(" "), _c("p", [_vm._v("1. ₦100 fee waived for transactions under ₦2500.")]), _vm._v(" "), _c("p", [_vm._v("2. Local transactions fees are capped at ₦2000, meaning that's the absolute maximum you'll ever pay in fees per transaction.")])])] : _vm.rachargeList[_vm.defaultMode].mode === 2 ? [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("●"), _c("span", [_vm._v("For Local Transactions")])]), _vm._v(" "), _c("div", {
    staticClass: "formula weight"
  }, [_vm._v("1.4%")]), _vm._v(" "), _c("p", [_vm._v("1. For local Naira payments, the processing fees are capped at a maximum of NGN 2,000.")]), _vm._v(" "), _c("p", [_vm._v("2. Minimum charge of 35 KES.")])])] : _vm._e()], 2) : _vm._e()], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;