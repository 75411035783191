"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/pull-refresh/style");

var _pullRefresh = _interopRequireDefault(require("vant/es/pull-refresh"));

require("vant/es/list/style");

var _list = _interopRequireDefault(require("vant/es/list"));

require("vant/es/empty/style");

var _empty = _interopRequireDefault(require("vant/es/empty"));

require("core-js/modules/es6.function.name");

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

var _order = require("api/order");

var _TabList = _interopRequireDefault(require("./TabList"));

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default2 = {
  name: "TabRefresh",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _empty.default.name, _empty.default), (0, _defineProperty2.default)(_components, _list.default.name, _list.default), (0, _defineProperty2.default)(_components, _pullRefresh.default.name, _pullRefresh.default), (0, _defineProperty2.default)(_components, "TabList", _TabList.default), _components),
  props: {
    activeTab: {
      type: Number,
      default: -1
    },
    actionsIndex: {
      type: Number,
      default: 0
    },
    invoiceStatus: {
      type: Number,
      default: 1
    },
    deliveredStatus: {
      type: Number,
      default: 2
    },
    types: {
      type: String,
      default: null
    },
    orderIds: {
      type: Array,
      default: function _default() {
        return null;
      }
    },
    checkedAll: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      data: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageNum: 1
    };
  },
  created: function created() {
    this.getListData();
  },
  methods: {
    getListData: function getListData() {
      var _this = this;

      if (this.activeTab === 3) {
        if (this.invoiceStatus === 1) {
          // 海外仓
          (0, _order.getOrderList)({
            types: 1,
            status: 3,
            queryPage: 2,
            queryPremium: 2,
            queryRefund: 2,
            queryRefundOrder: 2,
            supplyType: 2,
            pageNum: this.pageNum,
            pageSize: 10
          }).then(function (res) {
            _this.refreshing = false;
            _this.loading = false;

            _this.initData(res.data);
          });
        } else {
          // 发货单
          (0, _order.getInvoiceList)({
            status: this.deliveredStatus,
            pageNum: this.pageNum,
            pageSize: 10
          }).then(function (res) {
            _this.refreshing = false;
            _this.loading = false;

            _this.initData(res.data);
          });
        }
      } else {
        // My warehouse
        if (this.activeTab === 2) {
          if (this.invoiceStatus === 1) {
            // Unpaid
            (0, _order.getOrderList)({
              types: 3,
              status: this.activeTab === -1 ? null : this.activeTab,
              currency: "CNY",
              queryRefundOrder: 2,
              queryRefund: 2,
              queryPremium: 2,
              payStatusList: "0",
              queryPage: 2,
              pageNum: this.pageNum,
              pageSize: 10
            }).then(function (res) {
              var _res$data;

              // 先判断是否有转运费未支付
              if ((_res$data = res.data) !== null && _res$data !== void 0 && _res$data.data.length) {
                _this.refreshing = false;
                _this.loading = false;

                _this.initData(res.data);
              } else {
                if (_this.pageNum === 1) {
                  _this.$EventBus.$emit('orderUnpaid');

                  (0, _order.getOrderList)({
                    types: _this.types,
                    status: _this.activeTab === -1 ? null : _this.activeTab,
                    payStatusList: "1, 2, 3",
                    currency: "CNY",
                    queryRefundOrder: 2,
                    queryRefund: 2,
                    queryPremium: 2,
                    queryPage: 2,
                    pageNum: _this.pageNum,
                    pageSize: 10
                  }).then(function (res) {
                    _this.refreshing = false;
                    _this.loading = false;

                    if (_this.checkedAll) {
                      res.data.data.forEach(function (item) {
                        _this.$set(item, "checked", true);
                      });
                    }

                    _this.initData(res.data);
                  });
                } else {
                  _this.initData(res.data);
                }
              }
            });
          } else {
            // Deliverable
            (0, _order.getOrderList)({
              types: this.types,
              status: this.activeTab === -1 ? null : this.activeTab,
              payStatusList: "1, 2, 3",
              currency: "CNY",
              queryRefundOrder: 2,
              queryRefund: 2,
              queryPremium: 2,
              queryPage: 2,
              pageNum: this.pageNum,
              pageSize: 10
            }).then(function (res) {
              _this.refreshing = false;
              _this.loading = false;

              if (_this.checkedAll) {
                res.data.data.forEach(function (item) {
                  _this.$set(item, "checked", true);
                });
              }

              _this.initData(res.data);
            });
          }
        } else {
          (0, _order.getOrderList)({
            types: this.activeTab === 1 && this.invoiceStatus === 1 ? "0,1,2" : this.types,
            status: this.activeTab === -1 ? null : this.activeTab,
            currency: "CNY",
            queryPage: 2,
            queryPremium: [-1, 8].includes(this.activeTab) ? 2 : null,
            queryRefund: 2,
            queryRefundOrder: 2,
            supplyType: this.activeTab === 9 ? 2 : null,
            // 待提货需要返回可查海外仓筛选
            ids: this.orderIds,
            // 待提货需要返回可查海外仓筛选
            pageNum: this.pageNum,
            pageSize: 10
          }).then(function (res) {
            _this.refreshing = false;
            _this.loading = false;

            _this.initData(res.data);
          });
        }
      }
    },
    initData: function initData(list) {
      var _list$data, _list$lists;

      // 待提货需要返回可查海外仓筛选
      if (this.activeTab === 9 && this.pageNum === 1) {
        var _list$siteGroup, _list$siteGroup2;

        var packageStoreWareList = [].concat((list === null || list === void 0 ? void 0 : (_list$siteGroup = list.siteGroup) === null || _list$siteGroup === void 0 ? void 0 : _list$siteGroup.overseaWarehouse) || [], (list === null || list === void 0 ? void 0 : (_list$siteGroup2 = list.siteGroup) === null || _list$siteGroup2 === void 0 ? void 0 : _list$siteGroup2.station) || []);
        this.$emit('getPackageStoreWareList', packageStoreWareList);
      }

      this.$emit('getTotalCount', list.totalCount);
      this.$emit('getTotalWeight', list.totalWeight);

      if ((list === null || list === void 0 ? void 0 : (_list$data = list.data) === null || _list$data === void 0 ? void 0 : _list$data.length) > 0 || (list === null || list === void 0 ? void 0 : (_list$lists = list.lists) === null || _list$lists === void 0 ? void 0 : _list$lists.length) > 0) {
        if (this.pageNum === 1) {
          this.data = [];
          this.data = this.deepObj(list.data) || list.lists;
        } else {
          this.data = this.data.concat(this.deepObj(list.data) || list.lists);
        }

        this.$emit("dataList", this.data);
      } else {
        if (this.pageNum === 1) {
          this.data = [];
          this.$emit("dataList", this.data);
        }

        this.finished = true;
      }
    },
    // 组装为一个退款数组
    deepObj: function deepObj(list) {
      var _this2 = this;

      list === null || list === void 0 ? void 0 : list.forEach(function (tmp) {
        var _tmp$refunds, _tmp$orderDetails, _tmp$localGoodsRefund, _tmp$orderDetails2;

        if (tmp !== null && tmp !== void 0 && (_tmp$refunds = tmp.refunds) !== null && _tmp$refunds !== void 0 && _tmp$refunds.length && tmp !== null && tmp !== void 0 && (_tmp$orderDetails = tmp.orderDetails) !== null && _tmp$orderDetails !== void 0 && _tmp$orderDetails.length) {
          // 代购
          tmp.myRefunds = tmp.refunds.map(function (item) {
            _this2.$set(item, "myRefundDetails", item.refundDetails.map(function (item) {
              return _objectSpread(_objectSpread(_objectSpread({}, item), tmp.orderDetails.filter(function (ele) {
                return ele.orderDetailId === item.orderDetailId;
              })[0]), {}, {
                quantity2: item.quantity
              });
            }));

            return item;
          });
        } else if (tmp !== null && tmp !== void 0 && (_tmp$localGoodsRefund = tmp.localGoodsRefunds) !== null && _tmp$localGoodsRefund !== void 0 && _tmp$localGoodsRefund.length && tmp !== null && tmp !== void 0 && (_tmp$orderDetails2 = tmp.orderDetails) !== null && _tmp$orderDetails2 !== void 0 && _tmp$orderDetails2.length) {
          // 海外仓
          tmp.myRefunds = tmp.localGoodsRefunds.map(function (item) {
            _this2.$set(item, "status", item.status - 1);

            _this2.$set(item, "myRefundDetails", item.localGoodsRefundDetails.map(function (item) {
              return _objectSpread(_objectSpread(_objectSpread({}, item), tmp.orderDetails.filter(function (ele) {
                return ele.orderDetailId === item.orderDetailId;
              })[0]), {}, {
                quantity2: item.quantity
              });
            }));

            return item;
          });
        }
      });
      return list;
    },
    // 加载更多
    onLoad: function onLoad() {
      this.pageNum++;
      this.getListData();
    },
    // 下拉刷新
    onRefresh: function onRefresh() {
      this.data = [];
      this.finished = false;
      this.pageNum = 1;
      this.getListData();
    }
  }
};
exports.default = _default2;