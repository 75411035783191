"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _order = require("@/api/order.js");

var _DeliveryDetailsItem = _interopRequireDefault(require("./DeliveryDetailsItem"));

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "DeliveryDetails",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, "DeliveryDetailsItem", _DeliveryDetailsItem.default), _components),
  data: function data() {
    return {
      deliveryItem: {}
    };
  },
  created: function created() {
    var _this = this;

    (0, _order.getInvoiceDetails)({
      logisticId: this.$route.query.logisticsId,
      currency: "CNY"
    }).then(function (res) {
      _this.deliveryItem = res.data;

      _this.$set(_this.deliveryItem, "orderList", _this.deepObj(_this.deliveryItem.orderList));

      _this.$set(_this.deliveryItem, "logisticsDetail", _this.deepObj2(_this.deliveryItem.logisticsDetail));
    });
  },
  methods: {
    // 组装为一个退款数组
    deepObj: function deepObj(list) {
      var _this2 = this;

      if (!(list !== null && list !== void 0 && list.length)) return;
      list === null || list === void 0 ? void 0 : list.forEach(function (tmp) {
        var _tmp$refunds, _tmp$orderDetails, _tmp$localGoodsRefund, _tmp$orderDetails2;

        if (tmp !== null && tmp !== void 0 && (_tmp$refunds = tmp.refunds) !== null && _tmp$refunds !== void 0 && _tmp$refunds.length && tmp !== null && tmp !== void 0 && (_tmp$orderDetails = tmp.orderDetails) !== null && _tmp$orderDetails !== void 0 && _tmp$orderDetails.length) {
          // 代购
          tmp.myRefunds = tmp.refunds.map(function (cur) {
            _this2.$set(cur, "myRefundDetails", cur.refundDetails.map(function (item) {
              return _objectSpread(_objectSpread(_objectSpread({}, item), tmp.orderDetails.filter(function (ele) {
                return ele.orderDetailId === item.orderDetailId;
              })[0]), {}, {
                quantity2: item.quantity
              });
            }));

            return cur;
          });
        } else if (tmp !== null && tmp !== void 0 && (_tmp$localGoodsRefund = tmp.localGoodsRefunds) !== null && _tmp$localGoodsRefund !== void 0 && _tmp$localGoodsRefund.length && tmp !== null && tmp !== void 0 && (_tmp$orderDetails2 = tmp.orderDetails) !== null && _tmp$orderDetails2 !== void 0 && _tmp$orderDetails2.length) {
          // 海外仓
          tmp.myRefunds = tmp.localGoodsRefunds.map(function (cur) {
            _this2.$set(cur, "status", cur.status - 1);

            _this2.$set(cur, "myRefundDetails", cur.localGoodsRefundDetails.map(function (item) {
              return _objectSpread(_objectSpread(_objectSpread({}, item), tmp.orderDetails.filter(function (ele) {
                return ele.orderDetailId === item.orderDetailId;
              })[0]), {}, {
                quantity2: item.quantity
              });
            }));

            return cur;
          });
        }
      });
      return list;
    },
    deepObj2: function deepObj2(list) {
      var _this3 = this;

      if (!(list !== null && list !== void 0 && list.length)) return;
      return list.map(function (e) {
        var _e$order, _e$order$refunds, _e$order2, _e$order2$orderDetail, _e$order3, _e$order3$localGoodsR, _e$order4, _e$order4$orderDetail;

        if ((_e$order = e.order) !== null && _e$order !== void 0 && (_e$order$refunds = _e$order.refunds) !== null && _e$order$refunds !== void 0 && _e$order$refunds.length && (_e$order2 = e.order) !== null && _e$order2 !== void 0 && (_e$order2$orderDetail = _e$order2.orderDetails) !== null && _e$order2$orderDetail !== void 0 && _e$order2$orderDetail.length) {
          // 代购
          e.order.myRefunds = e.order.refunds.map(function (cur) {
            _this3.$set(cur, "myRefundDetails", cur.refundDetails.map(function (item) {
              return _objectSpread(_objectSpread(_objectSpread({}, item), e.order.orderDetails.filter(function (ele) {
                return ele.orderDetailId === item.orderDetailId;
              })[0]), {}, {
                quantity2: item.quantity
              });
            }));

            return cur;
          });
        } else if ((_e$order3 = e.order) !== null && _e$order3 !== void 0 && (_e$order3$localGoodsR = _e$order3.localGoodsRefunds) !== null && _e$order3$localGoodsR !== void 0 && _e$order3$localGoodsR.length && (_e$order4 = e.order) !== null && _e$order4 !== void 0 && (_e$order4$orderDetail = _e$order4.orderDetails) !== null && _e$order4$orderDetail !== void 0 && _e$order4$orderDetail.length) {
          // 海外仓
          e.order.myRefunds = e.order.localGoodsRefunds.map(function (cur) {
            _this3.$set(cur, "status", cur.status - 1);

            _this3.$set(cur, "myRefundDetails", cur.localGoodsRefundDetails.map(function (item) {
              return _objectSpread(_objectSpread(_objectSpread({}, item), e.order.orderDetails.filter(function (ele) {
                return ele.orderDetailId === item.orderDetailId;
              })[0]), {}, {
                quantity2: item.quantity
              });
            }));

            return cur;
          });
        }

        return _objectSpread({}, e);
      });
    }
  }
};
exports.default = _default;