"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "local-recommendation"
  }, [_c("div", {
    staticClass: "content",
    style: {
      backgroundImage: "url(".concat(_vm.bgImageUrl, ")")
    }
  }, [_c("div", {
    staticStyle: {
      height: "16px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "r-space-between padding-row",
    staticStyle: {
      color: "white"
    }
  }, [_c("div", {
    staticClass: "f-15"
  }, [_vm._v(_vm._s(_vm.titleStr))]), _vm._v(" "), _c("div", {
    staticClass: "view-more theme-background-color",
    on: {
      click: function click($event) {
        return _vm.$router.push("/local");
      }
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("view more")]), _vm._v(" "), _c("span", {
    staticClass: "iconfont",
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("")])])]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "16px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "r-start list-box padding-left"
  }, _vm._l(_vm.upRowGoodList, function (goodItem, index) {
    return _c("div", {
      key: index,
      staticClass: "good-item",
      on: {
        click: function click($event) {
          return _vm.toGoodDetail(goodItem);
        }
      }
    }, [_c("van-image", {
      staticStyle: {
        width: "25vw",
        height: "25vw"
      },
      attrs: {
        fit: "cover",
        "lazy-load": "",
        src: goodItem["images"]
      }
    }), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "4px"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "f-12 spu-name van-ellipsis"
    }, [_vm._v("\n          " + _vm._s(goodItem["spuName"]) + "\n        ")]), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "4px"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "f-12 theme-color van-ellipsis spu-name"
    }, [_vm._v("\n          " + _vm._s(_vm._f("CNYToCurrencyUnit")(goodItem["offerPrice"])) + "\n        ")]), _vm._v(" "), _c("div", {
      staticClass: "van-ellipsis discount-price"
    }, [_vm._v("\n          " + _vm._s(_vm._f("CNYToCurrencyUnit")(+goodItem["showPrice"] ? goodItem["showPrice"] : "")) + "\n        ")]), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "2px"
      }
    })], 1);
  }), 0), _vm._v(" "), _vm.downRowGoodList.length > 0 ? _c("div", {
    staticStyle: {
      height: "16px"
    }
  }) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "r-start list-box padding-left"
  }, _vm._l(_vm.downRowGoodList, function (goodItem, index) {
    return _c("div", {
      key: index,
      staticClass: "good-item",
      on: {
        click: function click($event) {
          return _vm.toGoodDetail(goodItem);
        }
      }
    }, [_c("van-image", {
      staticStyle: {
        width: "25vw",
        height: "25vw"
      },
      attrs: {
        fit: "cover",
        "lazy-load": "",
        src: goodItem["images"]
      }
    }), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "4px"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "f-12 spu-name van-ellipsis"
    }, [_vm._v("\n          " + _vm._s(goodItem["spuName"]) + "\n        ")]), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "4px"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "f-12 theme-color van-ellipsis spu-name"
    }, [_vm._v("\n          " + _vm._s(_vm._f("CNYToCurrencyUnit")(goodItem["offerPrice"])) + "\n        ")]), _vm._v(" "), _c("div", {
      staticClass: "van-ellipsis discount-price"
    }, [_vm._v("\n          " + _vm._s(_vm._f("CNYToCurrencyUnit")(+goodItem["showPrice"] ? goodItem["showPrice"] : "")) + "\n        ")]), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "2px"
      }
    })], 1);
  }), 0), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "11px"
    }
  })])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;