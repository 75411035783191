"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("core-js/modules/es6.regexp.replace");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/contact-list/style");

var _contactList = _interopRequireDefault(require("vant/es/contact-list"));

require("vant/es/notice-bar/style");

var _noticeBar = _interopRequireDefault(require("vant/es/notice-bar"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _vuex = require("vuex");

var _consignee = require("api/consignee");

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "Consignee",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _noticeBar.default.name, _noticeBar.default), (0, _defineProperty2.default)(_components, _contactList.default.name, _contactList.default), _components),
  data: function data() {
    return {
      chosenContactId: "",
      contactList: []
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    selectedConsigneeItem: function selectedConsigneeItem(state) {
      return state.consignee['consigneeItem'];
    }
  })),
  created: function created() {
    var _this = this;

    (0, _consignee.getConsigneeList)().then(function (res) {
      _this.contactList = res.data.map(function (item) {
        if (_this.selectedConsigneeItem && item.id === _this.selectedConsigneeItem.id) {
          _this.chosenContactId = item.id;
        }

        return {
          id: item.id,
          name: item.name,
          tel: item.countryCode + item.phone,
          isDefault: Boolean(item.defaultAddress)
        };
      });
    });
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)({
    selectConsignee: 'consignee/selectConsignee'
  })), {}, {
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    },
    onAdd: function onAdd() {
      this.$router.push({
        path: '/editConsignee',
        query: {
          title: "Add New Consignee"
        }
      });
    },
    onEdit: function onEdit(contact) {
      this.$router.push({
        path: '/editConsignee',
        query: {
          title: "Modify Consignee",
          id: contact.id
        }
      });
    },
    onSelect: function onSelect(contact) {
      if (!/^(([0]\d{10})|([1-9]\d{9})|(1[3-9]\d{9}))$/.test(contact.tel.replace(/\+234|\+86/, ""))) {
        return _toast.default.fail("Your moblie phone number is incorrect. Please modify it and submit it.");
      }

      this.$router.go(-1);
      this.selectConsignee(contact);
    }
  })
};
exports.default = _default;