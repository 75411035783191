"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/notice-bar/style");

var _noticeBar = _interopRequireDefault(require("vant/es/notice-bar"));

require("vant/es/divider/style");

var _divider = _interopRequireDefault(require("vant/es/divider"));

require("vant/es/popup/style");

var _popup = _interopRequireDefault(require("vant/es/popup"));

require("vant/es/stepper/style");

var _stepper = _interopRequireDefault(require("vant/es/stepper"));

require("vant/es/tag/style");

var _tag = _interopRequireDefault(require("vant/es/tag"));

require("vant/es/card/style");

var _card = _interopRequireDefault(require("vant/es/card"));

require("vant/es/checkbox-group/style");

var _checkboxGroup = _interopRequireDefault(require("vant/es/checkbox-group"));

require("vant/es/checkbox/style");

var _checkbox = _interopRequireDefault(require("vant/es/checkbox"));

require("vant/es/empty/style");

var _empty = _interopRequireDefault(require("vant/es/empty"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/submit-bar/style");

var _submitBar = _interopRequireDefault(require("vant/es/submit-bar"));

require("vant/es/pull-refresh/style");

var _pullRefresh = _interopRequireDefault(require("vant/es/pull-refresh"));

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

require("vant/es/cell/style");

var _cell = _interopRequireDefault(require("vant/es/cell"));

require("vant/es/list/style");

var _list = _interopRequireDefault(require("vant/es/list"));

require("core-js/modules/es6.function.name");

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

var _FooterTabbar = _interopRequireDefault(require("components/FooterTabbar"));

var _CouponList = _interopRequireDefault(require("components/CouponList"));

var _vuex = require("vuex");

var _cart = require("@/api/cart.js");

var _coupon = require("@/api/coupon.js");

var _utils = require("@/utils");

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: 'Cart',
  components: (_components = {}, (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _list.default.name, _list.default), (0, _defineProperty2.default)(_components, _cell.default.name, _cell.default), (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _pullRefresh.default.name, _pullRefresh.default), (0, _defineProperty2.default)(_components, _submitBar.default.name, _submitBar.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _empty.default.name, _empty.default), (0, _defineProperty2.default)(_components, _checkbox.default.name, _checkbox.default), (0, _defineProperty2.default)(_components, _checkboxGroup.default.name, _checkboxGroup.default), (0, _defineProperty2.default)(_components, _card.default.name, _card.default), (0, _defineProperty2.default)(_components, _tag.default.name, _tag.default), (0, _defineProperty2.default)(_components, _stepper.default.name, _stepper.default), (0, _defineProperty2.default)(_components, _popup.default.name, _popup.default), (0, _defineProperty2.default)(_components, _divider.default.name, _divider.default), (0, _defineProperty2.default)(_components, _noticeBar.default.name, _noticeBar.default), (0, _defineProperty2.default)(_components, "CouponList", _CouponList.default), (0, _defineProperty2.default)(_components, "FooterTabbar", _FooterTabbar.default), _components),
  data: function data() {
    return {
      couponTipObj: null,
      showCouponPop: false,
      deleteShow: false,
      cartList: [],
      couponList: [],
      selectCouponIndex: "",
      pickupWarehouse: null,
      pageNum: 1,
      loading: false,
      finished: false,
      refreshing: false
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)({
    defaultCurrency: function defaultCurrency(state) {
      return state.exchangeRate['defaultCurrency'];
    }
  })), {}, {
    // 获取checkout数量和小计
    getCheckTotal: function getCheckTotal() {
      var _this = this;

      return this.cartList.reduce(function (total, currentValue) {
        var _currentValue$checked;

        _this.$set(currentValue, 'subTotal', 0);

        total += (currentValue === null || currentValue === void 0 ? void 0 : (_currentValue$checked = currentValue.checkedArr) === null || _currentValue$checked === void 0 ? void 0 : _currentValue$checked.reduce(function (count, value) {
          currentValue.subTotal += Number(value.unitPrice) * value.quantity;
          count += value.quantity;
          return count;
        }, 0)) || 0;
        return total;
      }, 0);
    },
    // 获取总计金额
    getTotalAmout: function getTotalAmout() {
      if (String(this.selectCouponIndex)) {
        var amountTotal = this.cartList.reduce(function (total, currentValue) {
          total += Number(currentValue.subTotal);
          return total;
        }, 0) - Number(this.couponList[this.selectCouponIndex].discountedPrice);
        return amountTotal < 0 ? 0 : amountTotal;
      } else {
        var _amountTotal = this.cartList.reduce(function (total, currentValue) {
          total += Number(currentValue.subTotal);
          return total;
        }, 0);

        return _amountTotal < 0 ? 0 : _amountTotal;
      }
    }
  }),
  created: function created() {
    var _this2 = this;

    this.$EventBus.$on('cartPageRefresh', function (_) {
      _this2.getListData();
    });
    this.getListData();
  },
  deactivated: function deactivated() {
    if (this.pickupWarehouse && this.pickupWarehouse.id) this.$EventBus.$emit('selectPickup', this.pickupWarehouse);
  },
  destroyed: function destroyed() {
    this.$EventBus.$off('cartPageRefresh');
  },
  methods: {
    // 全选和不全选
    toggleAllCheckbox: function toggleAllCheckbox(refName, check) {
      console.log(refName, check);
      this.$refs[refName][0].toggleAll(check);
    },
    // 校验是否全选 
    changeCheckbox: function changeCheckbox(index) {
      var _this$cartList$index, _this$cartList$index$, _this$cartList$index2, _this$cartList$index3;

      if (((_this$cartList$index = this.cartList[index]) === null || _this$cartList$index === void 0 ? void 0 : (_this$cartList$index$ = _this$cartList$index.checkedArr) === null || _this$cartList$index$ === void 0 ? void 0 : _this$cartList$index$.length) === ((_this$cartList$index2 = this.cartList[index]) === null || _this$cartList$index2 === void 0 ? void 0 : (_this$cartList$index3 = _this$cartList$index2.cartDetails) === null || _this$cartList$index3 === void 0 ? void 0 : _this$cartList$index3.length)) {
        this.$set(this.cartList[index], 'checkedAll', true);
      } else {
        this.$set(this.cartList[index], 'checkedAll', false);
      }
    },
    // 编辑商品数量
    handleRevise: function handleRevise(index) {
      var _this3 = this;

      this.cartList[index].reviseShow = this.$set(this.cartList[index], 'reviseShow', !this.cartList[index].reviseShow);

      if (!this.cartList[index].reviseShow) {
        (0, _cart.editCart)(this.cartList[index].id, this.cartList[index].cartDetails.map(function (item) {
          return {
            id: item.id,
            quantity: item.quantity
          };
        })).then(function (res) {}).catch(function (_) {
          _this3.onRefresh();
        });
      }
    },
    // 删除商品
    handleDelete: function handleDelete(all, index, childIndex) {
      var _this4 = this;

      if (all === "all") {
        var cartIds = this.cartList.filter(function (item) {
          return item.checkedAll;
        }).map(function (item) {
          return item.id;
        });
        if (!cartIds.length) return _toast.default.fail("Please select first and click 'Delete'");
        (0, _cart.delCart)({
          cartIds: cartIds
        }).then(function (res) {
          cartIds.forEach(function (item) {
            _this4.cartList = _this4.cartList.filter(function (element) {
              return element.id !== item;
            });
          });
        });
      } else {
        var cartDetailIds = [all];
        (0, _cart.delCart)({
          cartDetailIds: cartDetailIds
        }).then(function (res) {
          _this4.cartList[index].cartDetails.splice(childIndex, 1);
        });
      }
    },
    onLoad: function onLoad() {
      // console.log("onLoad -> onLoad")
      this.pageNum++;
      this.getListData();
    },
    onRefresh: function onRefresh() {
      this.pageNum = 1;
      this.getListData();
    },
    getListData: function getListData() {
      var _this5 = this;

      // this.loading = false
      // this.finished = false
      (0, _cart.getCartList)({
        pageNum: this.pageNum
      }).then(function (res) {
        _this5.loading = false;
        _this5.refreshing = false;
        console.log('getStoreInfo -> val', res);

        if (res.data.length) {
          if (_this5.pageNum === 1) {
            _this5.cartList = [];
            _this5.cartList = res.data;
          } else {
            _this5.cartList = _this5.cartList.concat(res.data);
          }
        } else {
          if (_this5.pageNum === 1) _this5.cartList = [];
          _this5.finished = true;
        }
      });
      (0, _coupon.getCouponCode)({
        status: 1,
        pageNum: 1,
        pageSize: 1
      }).then(function (res) {
        _this5.couponTipObj = res.data && res.data[0] || null;
      });
    },
    // 查看可用优惠券
    openCoupon: function openCoupon() {
      var _this6 = this;

      if (!this.getCheckTotal) return _toast.default.fail("Please select first");
      (0, _coupon.getCouponCodeCart)({
        cartDetailIds: this.cartList.map(function (item) {
          return item.checkedArr;
        }).flat().filter(Boolean).map(function (item) {
          return item.id;
        }),
        pageNum: 1,
        pageSize: 50
      }).then(function (res) {
        _this6.couponList = res.data;

        for (var key in res.data) {
          var _res$data$key;

          if (((_res$data$key = res.data[key]) === null || _res$data$key === void 0 ? void 0 : _res$data$key.isAvailable) === true) {
            _this6.$set(_this6.couponList[key], 'checked', true);

            _this6.selectCouponIndex = +key;
            return;
          }
        }

        _this6.selectCouponIndex = '';
      });
      this.showCouponPop = true;
    },
    // 选中哪个优惠券
    selectCoupon: function selectCoupon(index) {
      var _this7 = this;

      this.couponList.forEach(function (item, i) {
        if (index === i) {
          _this7.$set(item, 'checked', !item.checked);

          _this7.selectCouponIndex = item.checked ? index : '';
        } else {
          _this7.$set(item, 'checked', false);
        }
      });
    },
    // 提交
    onSubmit: function onSubmit() {
      if (!this.getCheckTotal) return _toast.default.fail("Please select first");
      this.$router.push({
        path: '/checkout',
        query: {
          cartDetailIds: JSON.stringify(this.cartList.map(function (item) {
            return item.checkedArr;
          }).flat().filter(Boolean).map(function (item) {
            return item.id;
          }))
        }
      });
    },
    // 跳转商品详情
    toGoodDetailPage: function toGoodDetailPage(type, childItem, pickupWarehouse) {
      var _arguments = {
        "url": type === 1 ? childItem.spuId : (0, _utils.alibabaGoodsLink)(childItem.spuId),
        "module": type,
        "activityId": childItem.activityId
      };
      if (pickupWarehouse) this.pickupWarehouse = pickupWarehouse;
      this.$router.push({
        path: "/GoodsContent",
        query: _arguments
      });
    }
  }
};
exports.default = _default;