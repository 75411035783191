"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "user_help_forwarding_page"
  }, [_c("div", {
    staticClass: "page-box"
  }, [_c("div", {
    staticClass: "translate-icon"
  }, [_c("img", {
    attrs: {
      src: require("../../static/asset/icon/translate_icon.png"),
      width: "30px",
      height: "30px"
    },
    on: {
      click: function click($event) {
        _vm.isEn = !_vm.isEn;
      }
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "40px"
    }
  }), _vm._v(" "), _vm._l(_vm.forwardingGuide, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "col-center"
    }, [_c("div", {
      staticClass: "title1"
    }, [_vm._v("\n        Step" + _vm._s(index + 1) + " " + _vm._s(_vm.isEn ? item.entitle : item.title) + "\n      ")]), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "10px"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "img-box"
    }, [_c("img", {
      attrs: {
        src: item.image,
        width: "50%"
      }
    })]), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "10px"
      }
    }), _vm._v(" "), _c("span", {
      staticStyle: {
        "font-size": "0.4rem"
      },
      domProps: {
        innerHTML: _vm._s(_vm.isEn ? item.encontent : item.content)
      }
    }), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "40px"
      }
    })]);
  })], 2)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;