"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.match");

require("core-js/modules/web.dom.iterable");

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/search/style");

var _search = _interopRequireDefault(require("vant/es/search"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _localStorage = require("@/utils/local-storage.js");

var _vuex = require("vuex");

var _HeadImgUpload = _interopRequireDefault(require("@/components/HeadImgUpload.vue"));

var _upload = require("@/api/upload.js");

var _settings = require("@/settings");

var _auth = require("@/utils/auth");

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "Search",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _search.default.name, _search.default), (0, _defineProperty2.default)(_components, "HeadImgUpload", _HeadImgUpload.default), _components),
  data: function data() {
    return {
      value: "",
      // 输入内容
      localStorage: '',
      // localStorage key
      placeholder: "Please enter order ID",
      // 提示文案
      fromPage: null,
      // 从哪个页面来,
      cacheList: [],
      // 缓存数组
      isLogin: false
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    user: function user(state) {
      return state.user['user'];
    }
  })),
  created: function created() {
    var fromPage = this.$route.query.fromPage; // this.user

    try {
      var token = (0, _auth.getToken)();
      this.isLogin = Boolean(token);
      var userCode = this.user.userCode;
      this.localStorage = userCode + '_' || "comon_key_";
    } catch (error) {
      console.log("🚀 ~ file: Search.vue ~ line 82 ~ created ~ error", error);
    }

    this.fromPage = fromPage;
    this.getLocalStorageKey(fromPage);
    this.getCacheList();
  },
  methods: {
    getLocalStorageKey: function getLocalStorageKey(fromPage) {
      switch (fromPage) {
        case 'Home':
          // App 首页
          this.localStorage += _localStorage.GOOD_SEARCH_KEY;
          this.placeholder = "Please search";
          break;

        case 'Order':
          // 订单列表
          this.localStorage += _localStorage.ORDER_SEARCH_KEY;
          this.placeholder = "Please enter order ID";
          this.isLogin = false;
          break;

        case 'Transactions':
          // 交易流水
          this.localStorage += _localStorage.TRANSACTIONS_SEARCH_KEY;
          this.placeholder = "Please enter order ID";
          this.isLogin = false;
          break;

        case 'ProductListPage':
          // 商品列表
          {
            this.placeholder = "Please search";
            var _this$$route$query = this.$route.query,
                content = _this$$route$query.content,
                searchKey = _this$$route$query.searchKey;
            this.localStorage = searchKey;
            this.value = content || '';
          }
          break;

        case 'SelfSupport':
          // 自营首页
          this.localStorage += _localStorage.WOMALL_SEARCH_KEY;
          this.placeholder = "Search products from womall";
          break;

        case 'MyLike':
          // 我的收藏
          this.localStorage += _localStorage.MYLIKE_SEARCH_KEY;
          this.placeholder = "Please search";
          this.isLogin = false;
          break;

        case 'DesignatCouponPagee':
          // 指定优惠商品列表
          this.localStorage += _localStorage.WOMALL_SEARCH_KEY;
          this.placeholder = "Search products from ...";
          this.isLogin = false;
          break;

        case 'Local':
          // 指定优惠商品列表
          this.localStorage += _localStorage.WOMALL_SEARCH_KEY;
          this.placeholder = "Search products from ...";
          this.isLogin = false;
          break;

        case 'TopSale':
          // 热销
          this.localStorage += _localStorage.GOOD_SEARCH_KEY;
          this.placeholder = "Search products from ...";
          this.isLogin = false;
          break;

        case 'NewArrival':
          // 新品
          this.localStorage += _localStorage.GOOD_SEARCH_KEY;
          this.placeholder = "Search search";
          this.isLogin = false;
          break;

        default:
          break;
      }
    },
    // 获取缓存
    getCacheList: function getCacheList() {
      var listString = (0, _localStorage.get)(this.localStorage);

      try {
        this.cacheList = JSON.parse(listString) || [];
      } catch (e) {
        this.cacheList = [];
        console.log("🚀 ~ file: Search.vue ~ line 74 ~ getCacheList ~ e", e);
      }
    },
    onClickRight: function onClickRight(cacheItem) {
      // 点击历史数据
      if (cacheItem) {
        // 从数组中删除
        var index = this.cacheList.indexOf(cacheItem);

        if (index > -1) {
          this.cacheList.splice(index, 1);
        }

        this.value = cacheItem;
      }

      if (this.value === '') {
        this.$toast(this.placeholder);
        return;
      } // 存入缓存


      this.cacheList.unshift(this.value);
      (0, _localStorage.add)(this.localStorage, JSON.stringify(this.cacheList));
      this.switchPage();
    },
    // 选择正确的的页面
    switchPage: function switchPage() {
      if (this.fromPage === 'Home' || this.fromPage === 'NewArrival') {
        if (this.value.includes('http://') || this.value.includes('https://')) {
          // 输入的是url或者1688口令，打开1688商品详情
          // module 商品来源：1-自营，2-1688
          this.$router.push({
            name: 'GoodsContent',
            query: {
              module: 2,
              url: this.value
            }
          });
        } else {
          // 输入的是文字，打开商品列表
          // moduleType 搜索的模块 1自营，2 1688和自营
          this.$router.push({
            name: 'ProductListPage',
            query: {
              moduleType: "2",
              content: this.value,
              searchKey: this.localStorage
            }
          });
        }
      } else if (this.fromPage === 'ProductListPage') {
        // 商品列表
        var moduleType = this.$route.query.moduleType;
        this.$router.replace({
          name: 'ProductListPage',
          query: {
            moduleType: moduleType,
            content: this.value,
            searchKey: this.localStorage
          }
        });
      } else if (this.fromPage === 'SelfSupport') {
        // 自营首页
        var _moduleType = this.$route.query.moduleType;
        this.$router.replace({
          name: 'ProductListPage',
          query: {
            moduleType: _moduleType,
            content: this.value,
            searchKey: this.localStorage
          }
        });
      } else if (this.fromPage === 'Order') {
        //  訂單
        this.$router.replace({
          name: 'QueryOrder',
          params: {
            id: this.value
          }
        });
      } else if (this.fromPage === 'Transactions') {
        //  交易流水頁
        var balanceType = this.$route.query.balanceType;
        this.$router.replace({
          name: 'Transactions',
          params: {
            id: this.value
          },
          query: {
            balanceType: balanceType
          }
        });
      } else if (this.fromPage === 'MyLike') {
        //  我的收藏
        this.$router.replace({
          name: 'MyLike',
          params: {
            keyword: this.value
          }
        });
      } else if (this.fromPage === 'DesignatCouponPage') {
        //  指定优惠商品列表
        // moduleType 搜索的模块 1自营，2 1688和自营
        this.$router.push({
          name: 'ProductListPage',
          query: {
            moduleType: "1",
            content: this.value,
            searchKey: this.localStorage
          }
        });
      } else if (this.fromPage === 'Local') {
        //  海外仓
        // moduleType 搜索的模块 1自营，2 1688和自营
        this.$router.push({
          name: 'ProductListPage',
          query: {
            moduleType: "1",
            content: this.value,
            searchKey: this.localStorage
          }
        });
      } else if (this.fromPage === 'TopSale') {
        //  海外仓
        // moduleType 搜索的模块 1自营，2 1688和自营
        this.$router.push({
          name: 'ProductListPage',
          query: {
            moduleType: "1",
            content: this.value,
            searchKey: this.localStorage
          }
        });
      }
    },
    // 清除缓存
    doRemove: function doRemove() {
      (0, _localStorage.remove)(this.localStorage);
      this.getCacheList();
    },
    // 上传图片
    getfileImg: function getfileImg(imgUrl) {
      var _this = this;

      this.$toast.loading({
        message: 'Loading...',
        duration: 0,
        forbidClick: true
      });
      var uploadImgUrlList = (0, _toConsumableArray2.default)(_upload.uploadUrlList); //   映射为国内url

      uploadImgUrlList.forEach(function (element) {
        if (imgUrl.match(element)) {
          imgUrl = imgUrl.replace(element, _settings.chinaImgUrl);
          return;
        }
      });

      if (imgUrl) {
        setTimeout(function () {
          _this.$router.push({
            path: "/ProductListFromImg",
            query: {
              imgUrl: imgUrl
            }
          });

          _this.$toast.clear();
        }, 9000);
      } else {
        this.$toast.clear();
      }
    }
  }
};
exports.default = _default;