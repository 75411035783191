"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "recommend-list"
  }, _vm._l(_vm.recommedationList, function (item, index) {
    return _c("div", {
      key: index,
      on: {
        click: function click($event) {
          return _vm.toPage(item);
        }
      }
    }, [_c("div", {
      staticClass: "recommend-item"
    }, [_c("div", {
      staticClass: "bg-img"
    }, [_c("img", {
      attrs: {
        src: item.otherImage
      }
    })]), _vm._v(" "), _c("div", {
      staticClass: "text-content"
    }, [_c("div", {
      staticStyle: {
        height: "9px"
      }
    }), _vm._v(" "), !["", " "].includes(item.commodityTitle) ? _c("div", {
      staticClass: "commodity-title"
    }, [_c("div", {
      staticClass: "up-title van-ellipsis"
    }, [_vm._v("\n            " + _vm._s(item.commodityTitle) + "\n          ")]), _vm._v(" "), _c("img", {
      attrs: {
        src: require("../../../assets/images/home/red_title.png")
      }
    })]) : _vm._e(), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "15px"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "sub-title van-ellipsis"
    }, [_vm._v("\n          " + _vm._s(item.commoditySubtitle) + "\n        ")])])])]);
  }), 0);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;