"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.function.name");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    class: _vm.notice ? "isNotice view" : "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "My coupon",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    },
    scopedSlots: _vm._u([{
      key: "right",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: "tosend",
            size: "18"
          },
          on: {
            click: function click($event) {
              return _vm.$router.push("/couponHistory");
            }
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("van-notice-bar", {
    attrs: {
      mode: "closeable",
      wrapable: "",
      "left-icon": "info-o",
      background: "#FFE2E2",
      color: "#EA3340"
    },
    on: {
      close: function close($event) {
        _vm.notice = false;
      }
    }
  }, [_vm._v("The discount fluctuates according to the exchange rate.")]), _vm._v(" "), _c("van-tabs", {
    attrs: {
      animated: ""
    },
    on: {
      click: _vm.onClick
    },
    model: {
      value: _vm.active,
      callback: function callback($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, _vm._l(_vm.tabList, function (item, index) {
    return _c("van-tab", {
      key: index,
      attrs: {
        title: item.title,
        name: item.name
      }
    }, [_c("van-pull-refresh", {
      staticStyle: {
        "min-height": "100vh"
      },
      attrs: {
        "pulling-text": "Pull down refresh",
        "loosing-text": "Release to refresh",
        "loading-text": "Refreshing",
        "success-text": "Refresh completed"
      },
      on: {
        refresh: _vm.onRefresh
      },
      model: {
        value: _vm.refreshing,
        callback: function callback($$v) {
          _vm.refreshing = $$v;
        },
        expression: "refreshing"
      }
    }, [item.data.length ? _c("van-list", {
      attrs: {
        finished: _vm.finished,
        "finished-text": "No More ...",
        "immediate-check": false
      },
      on: {
        load: _vm.onLoad
      },
      model: {
        value: _vm.loading,
        callback: function callback($$v) {
          _vm.loading = $$v;
        },
        expression: "loading"
      }
    }, [_c("CouponItem", {
      attrs: {
        list: item.data,
        "is-due-soon": true,
        "time-show": true
      },
      scopedSlots: _vm._u([{
        key: "btn",
        fn: function fn(props) {
          return [_c("div", {
            on: {
              click: function click($event) {
                return _vm.useNow(props.coupon);
              }
            }
          }, [_vm._v("use now")])];
        }
      }], null, true)
    })], 1) : _c("van-empty", {
      staticStyle: {
        "min-height": "calc(100vh - 5rem)"
      },
      attrs: {
        description: "No more data!"
      },
      scopedSlots: _vm._u([{
        key: "image",
        fn: function fn() {
          return [_c("van-icon", {
            attrs: {
              name: require("@/assets/images/no_data.png")
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1)], 1);
  }), 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;