"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

var _router = _interopRequireDefault(require("@/router"));

var _app = require("firebase/app");

var _analytics = require("firebase/analytics");

var SET_DOWNLOAD_URL = 'setDownloadUrl'; // 设置app下载链接

if (process.env.NODE_ENV === "production") {
  var firebaseConfig = {
    apiKey: 'AIzaSyBXPwoea97w-DtwIS1x_c8eFTlGmsQ_Fd8',
    authDomain: 'womata-shop.firebaseapp.com',
    projectId: 'womata-shop',
    storageBucket: 'womata-shop.appspot.com',
    messagingSenderId: '419901522180',
    appId: '1:419901522180:web:fb100e01e5a772ba2d5051',
    measurementId: 'G-SX7TML2WLB'
  }; // Initialize Firebase

  var app = (0, _app.initializeApp)(firebaseConfig); // Initialize Analytics and get a reference to the service

  var analytics = (0, _analytics.getAnalytics)(app);
}

var _default = {
  namespaced: true,
  state: {
    // 唤起App指令
    schemeUrl: "womata://",
    // app下载地址
    downloadUrl: ""
  },
  mutations: (0, _defineProperty2.default)({}, SET_DOWNLOAD_URL, function (state, downloadUrl) {
    state.downloadUrl = downloadUrl;
  }),
  actions: {
    setDownloadUrl: function setDownloadUrl(state, data) {
      console.log("setDownloadUrl -> data", data);
      state.commit(SET_DOWNLOAD_URL, data);
    },
    firebaseLogEvent: function firebaseLogEvent(state, logEventName) {
      if (process.env.NODE_ENV !== "production") return;
      var _router$currentRoute$ = _router.default.currentRoute.query,
          openType = _router$currentRoute$.openType,
          userCode = _router$currentRoute$.userCode;
      (0, _analytics.logEvent)(analytics, logEventName, {
        userCode: openType === "binding" ? userCode : null
      }); // 只有带有分销的url才记录userCode
    }
  }
};
exports.default = _default;