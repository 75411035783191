"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/cell/style");

var _cell = _interopRequireDefault(require("vant/es/cell"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _user = require("api/user");

var _components;

var _default = {
  name: "TransactionsDetails",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _cell.default.name, _cell.default), _components),
  filters: {
    // /交易流水 交易类型
    getTransactionType: function getTransactionType(type) {
      var typeString = "";

      switch (type) {
        case 0:
          typeString = 'Recharge';
          break;

        case 1:
          typeString = 'Consume';
          break;

        case 2:
          typeString = 'Refund';
          break;

        case 3:
          typeString = 'Withdrawal';
          break;

        case 4:
          typeString = 'Deduction';
          break;

        default:
          break;
      }

      return typeString;
    },
    // 获取钱包类型 balanceType    钱包类型（1：余额，2：佣金余额）
    getBalanceType: function getBalanceType(type) {
      var typeString = '';

      switch (type) {
        case 0:
          typeString = '';
          break;

        case 1:
          typeString = 'Wallet';
          break;

        case 2:
          typeString = 'Commission';
          break;

        default:
          break;
      }

      return typeString;
    },
    // mode 交易方式（0：零钱，1：paystack，2：flutterwave，3：支付宝）
    getTransactionWay: function getTransactionWay(type) {
      var typeString = '';

      switch (type) {
        case 0:
          typeString = 'Balance';
          break;

        case 1:
          typeString = 'Paystack';
          break;

        case 2:
          typeString = 'Flutterwave';
          break;

        case 3:
          typeString = 'Alipay';
          break;

        default:
          break;
      }

      return typeString;
    },
    // 交易状态（0：待交易，1：交易成功，2：交易失败，3：转入退款，4：已撤销）
    getTransactionStatus: function getTransactionStatus(type) {
      var typeString = '';

      switch (type) {
        case 0:
          typeString = 'To be traded';
          break;

        case 1:
          typeString = 'Successful trade';
          break;

        case 2:
          typeString = 'The deal failed';
          break;

        case 3:
          typeString = 'Transfer in refund';
          break;

        case 4:
          typeString = 'Rescinded';
          break;

        default:
          break;
      }

      return typeString;
    }
  },
  data: function data() {
    return {
      detailsData: {
        balanceType: '',
        transaction: {}
      }
    };
  },
  created: function created() {
    var _this = this;

    if (this.$route.query.id) {
      (0, _user.getWalletDetail)({
        id: this.$route.query.id
      }).then(function (res) {
        console.log(res);
        _this.detailsData = res.data[0];
      });
    }
  },
  methods: {
    // 后退
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    }
  }
};
exports.default = _default;