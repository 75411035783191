"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("core-js/modules/es6.function.name");

require("vant/es/count-down/style");

var _countDown = _interopRequireDefault(require("vant/es/count-down"));

var _dayjs = _interopRequireDefault(require("dayjs"));

var _default = {
  name: "CountDown",
  components: (0, _defineProperty2.default)({}, _countDown.default.name, _countDown.default),
  filters: {
    addZero: function addZero(value) {
      if (!value) return '00';
      return (value < 10 ? "0" : "") + value;
    }
  },
  props: {
    // 开始时间
    beginTime: {
      type: String,
      required: true,
      default: ''
    },
    // 结束时间
    endTime: {
      type: String,
      required: true,
      default: ''
    },
    bgColor: {
      type: String,
      default: '#000'
    },
    color: {
      type: String,
      default: '#fff'
    }
  },
  data: function data() {
    return {
      time: null,
      type: null
    };
  },
  computed: {
    myStyle: function myStyle() {
      return {
        "--bgColor": this.bgColor,
        "--color": this.color
      };
    }
  },
  mounted: function mounted() {
    this.judgeTime();
  },
  methods: {
    // 判断时间
    judgeTime: function judgeTime() {
      var _nowDate = Date.now(); // 此刻(国际时间)


      console.log(this.beginTime, this.endTime);

      if (this.beginTime === '' || this.endTime === '') {
        return;
      }

      var now = (0, _dayjs.default)(_nowDate).utcOffset(1); // 此刻尼日利亚时间

      var begin = (0, _dayjs.default)(this.beginTime).utcOffset(1);
      var end = (0, _dayjs.default)(this.endTime).utcOffset(1); // 开始前 三天

      var _beforeThreeDay = begin.subtract(3, "day");

      if (now.isBefore(_beforeThreeDay)) {
        // 活动开始前的前三天 ，显示未开始
        this.type = 0;
      } else if (now.isAfter(_beforeThreeDay) && now.isBefore(begin)) {
        // 在活动开始前三天内 显示离开始还有XX XX XX 天
        this.type = 1;
        this.time = begin.diff(_nowDate);
        console.log('离开始还有XX XX XX 天 _durationCount------------|||||$', this.time);
      } else if (now.isAfter(begin) && now.isBefore(end)) {
        // 在活动时间范围内 显示离结束还有XX XX XX 天
        this.type = 2;
        this.time = end.diff(now, 'millisecond', true);
        console.log('显示离结束还有XX XX XX 天 _durationCount------------|||||$', this.time);
      } else if (now.isAfter(end)) {
        // 在活动时间范围之后，显示已结束
        console.log('在活动时间范围之后，显示已结束');
        this.type = 3;
      } else {
        console.log(' //未判断时间的范围');
      }
    },
    switchType: function switchType(type) {
      var text = '';

      switch (type) {
        case 0:
          text = "Upcoming";
          break;

        case 1:
          text = "Begins in";
          break;

        case 2:
          text = "Ends in";
          break;

        case 3:
          text = "Activity Ended";
          break;

        default:
          break;
      }

      return text;
    }
  }
};
exports.default = _default;