"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("vant/es/dialog/style");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/checkbox/style");

var _checkbox = _interopRequireDefault(require("vant/es/checkbox"));

require("vant/es/action-sheet/style");

var _actionSheet = _interopRequireDefault(require("vant/es/action-sheet"));

require("vant/es/notice-bar/style");

var _noticeBar = _interopRequireDefault(require("vant/es/notice-bar"));

require("vant/es/tabs/style");

var _tabs = _interopRequireDefault(require("vant/es/tabs"));

require("vant/es/tab/style");

var _tab = _interopRequireDefault(require("vant/es/tab"));

require("vant/es/search/style");

var _search = _interopRequireDefault(require("vant/es/search"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _filter = require("@/filter");

var _vuex = require("vuex");

var _order = require("@/api/order.js");

var _FreightFeePopupItem = _interopRequireDefault(require("./components/FreightFeePopupItem"));

var _TabRefresh = _interopRequireDefault(require("./components/TabRefresh"));

var _PayPassword = _interopRequireDefault(require("components/PayPassword"));

var _components;

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "Order",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _search.default.name, _search.default), (0, _defineProperty2.default)(_components, _tab.default.name, _tab.default), (0, _defineProperty2.default)(_components, _tabs.default.name, _tabs.default), (0, _defineProperty2.default)(_components, _noticeBar.default.name, _noticeBar.default), (0, _defineProperty2.default)(_components, _actionSheet.default.name, _actionSheet.default), (0, _defineProperty2.default)(_components, _checkbox.default.name, _checkbox.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, "FreightFeePopupItem", _FreightFeePopupItem.default), (0, _defineProperty2.default)(_components, "TabRefresh", _TabRefresh.default), (0, _defineProperty2.default)(_components, "PayPassword", _PayPassword.default), _components),
  data: function data() {
    return {
      order: {
        orderIds: "",
        commissionOffsetPrice: "",
        totalPriceNGN: "",
        logisticsId: "",
        selectOrderIdList: []
      },
      addressTipShow: true,
      // 发货是否全选
      checkedAll: true,
      // 密码输入框
      showKeyboard: false,
      // 搜索框
      orderId: "",
      // 右上角筛选按钮
      actionFilterShow: false,
      // 右上角筛选index
      actionsIndex: 0,
      // tab
      activeTab: -1,
      // tabChildren
      activeChildren: 1,
      grandchildrenArr: [],
      // 发货单的状态
      deliveredStatus: 1,
      // 是否显示tabChildren
      showUnpaid: true,
      types: null,
      // 查询条件
      orderIds: null,
      // 查询条件
      // 数据
      dataList: [],
      // 发货路线弹框
      freightFeeShow: false,
      // 发货路线
      sensitiveLineList: [],
      sensitive: null,
      tabList: [{
        title: 'All',
        name: -1
      }, {
        title: 'Payment Pending',
        name: 0
      }, {
        title: 'Warehouse Pending',
        name: 1,
        children: [{
          id: 1,
          types: "0,1,2",
          title: "Purchase"
        }, {
          id: 2,
          types: "3",
          title: "Forwarding"
        }]
      }, {
        title: 'Global Warehouse',
        name: 2,
        children: [{
          id: 1,
          title: "Unpaid"
        }, {
          id: 2,
          title: "Deliverable"
        }]
      }, {
        title: 'Delivered Reviews',
        name: 3,
        children: [{
          id: 1,
          title: "Local orders"
        }, {
          id: 2,
          title: "Global orders",
          children: [{
            id: 1,
            icon: "iconfont icon-a-zu2140",
            title: "Need to pay"
          }, {
            id: 2,
            icon: "iconfont icon-a-zu1283",
            title: "Picking"
          }, {
            id: 3,
            icon: "iconfont icon-a-zu1279",
            title: "Shipped"
          }]
        }]
      }, {
        title: 'Additional Payment',
        name: 8
      }, {
        title: 'Pending Pickup',
        name: 9
      }, {
        title: 'Picked Up',
        name: 4
      }],
      totalCount: null,
      totalWeight: null,
      // 待提货需要返回可查海外仓筛选
      packageStoreWareList: [],
      roundIndex: 0,
      selectDeliveryOrderIdList: [],
      exceptDeliveryOrderIdList: []
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)({
    user: function user(state) {
      return state.user['user'];
    },
    defaultCurrency: function defaultCurrency(state) {
      return state.exchangeRate['defaultCurrency'];
    }
  })), {}, {
    actionsList: function actionsList() {
      var _this = this;

      if (this.activeTab === 9) {
        // 待提货需要返回可查海外仓筛选
        return [{
          name: "All(".concat(this.packageStoreWareList.reduce(function (total, cur) {
            total += cur.orderCount;
            return total;
          }, 0), ")"),
          color: this.actionsIndex === 0 ? '#EA3340' : '',
          orderIds: null
        }].concat(this.packageStoreWareList.map(function (ele, i) {
          return {
            name: "".concat(ele.nameEn, "(").concat(ele.orderCount, ")"),
            color: _this.actionsIndex === i + 1 ? '#EA3340' : '',
            orderIds: ele.orderIds
          };
        }));
      } else {
        return [{
          name: 'All',
          color: this.actionsIndex === 0 ? '#EA3340' : '',
          types: null
        }, {
          name: 'Purchase',
          color: this.actionsIndex === 1 ? '#EA3340' : '',
          types: "0,1,2"
        }, {
          name: 'Forwarding',
          color: this.actionsIndex === 2 ? '#EA3340' : '',
          types: "3"
        }];
      }
    },
    // 批量付款的总金额
    selectTotalAmount: function selectTotalAmount() {
      if (this.activeTab === 2) {
        // Global Warehouse
        return (0, _filter.fromCurrencyByToCurrency)(this.dataList.reduce(function (total, currentValue) {
          if (currentValue.checked) total += Number(currentValue.subtotal);
          return total;
        }, 0).toFixed(2), "CNY", "NGN");
      } else if (this.activeTab === 0) {
        // Payment Pending
        var totalStationFee = 0;
        var totalPrice = (0, _filter.fromCurrencyByToCurrency)(this.dataList.reduce(function (total, currentValue) {
          if (currentValue.checked) {
            total += Number(currentValue.totalPrice);
            totalStationFee += Number(currentValue.billAmount);
          }

          return total;
        }, 0).toFixed(2), "CNY", "NGN");
        return (Number(totalStationFee) + Number(totalPrice)).toFixed(2);
      }

      return "0.00";
    },
    // Global Warehouse批量发货的总重量
    myWarehouseSelectTotalEstimateWeight: function myWarehouseSelectTotalEstimateWeight() {
      if (this.checkedAll) {
        return (Number(this.totalWeight) - this.dataList.reduce(function (total, currentValue) {
          if (!currentValue.checked) total += Number(currentValue.estimateWeight);
          return total;
        }, 0)).toFixed(1);
      } else {
        return this.dataList.reduce(function (total, currentValue) {
          if (currentValue.checked) total += Number(currentValue.estimateWeight);
          return total;
        }, 0).toFixed(1);
      }
    },
    // Global Warehouse批量发货的总数量
    myWarehouseSelectCount: function myWarehouseSelectCount() {
      if (this.checkedAll) {
        return Number(this.totalCount) - this.dataList.reduce(function (total, currentValue) {
          if (!currentValue.checked) total += 1;
          return total;
        }, 0);
      } else {
        return this.dataList.reduce(function (total, currentValue) {
          if (currentValue.checked) total += 1;
          return total;
        }, 0);
      }
    }
  }),
  watch: {
    $route: {
      handler: function handler(val, oldVal) {
        if (Number(val.query.fromPlatform) === 1) {
          this.activeTab = Number(val.query.status);
          this.activeChildren = Number(val.query.activeChildren) || 1;
          this.deliveredStatus = 1;

          if (Number(val.query.activeChildren)) {
            var _iterator = _createForOfIteratorHelper(this.tabList),
                _step;

            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var _item$children;

                var item = _step.value;

                if (item.name === Number(val.query.status) && item !== null && item !== void 0 && (_item$children = item.children) !== null && _item$children !== void 0 && _item$children.length) {
                  var _iterator2 = _createForOfIteratorHelper(item.children),
                      _step2;

                  try {
                    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                      var _ele$children;

                      var ele = _step2.value;

                      if (ele.id === Number(val.query.activeChildren) && ele !== null && ele !== void 0 && (_ele$children = ele.children) !== null && _ele$children !== void 0 && _ele$children.length) {
                        this.grandchildrenArr = ele.children;
                        return;
                      }
                    }
                  } catch (err) {
                    _iterator2.e(err);
                  } finally {
                    _iterator2.f();
                  }
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
          } else {
            this.grandchildrenArr = [];
          }

          this.showUnpaid = true;
          this.roundIndex += 1;
        } else if (val.params.status !== undefined) {
          this.activeTab = Number(val.params.status);
          this.activeChildren = Number(val.params.activeChildren) || 1;
          this.deliveredStatus = 1;

          if (Number(val.params.activeChildren)) {
            var _iterator3 = _createForOfIteratorHelper(this.tabList),
                _step3;

            try {
              for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                var _item$children2;

                var _item = _step3.value;

                if (_item.name === Number(val.params.status) && _item !== null && _item !== void 0 && (_item$children2 = _item.children) !== null && _item$children2 !== void 0 && _item$children2.length) {
                  var _iterator4 = _createForOfIteratorHelper(_item.children),
                      _step4;

                  try {
                    for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
                      var _ele$children2;

                      var _ele = _step4.value;

                      if (_ele.id === Number(val.params.activeChildren) && _ele !== null && _ele !== void 0 && (_ele$children2 = _ele.children) !== null && _ele$children2 !== void 0 && _ele$children2.length) {
                        this.grandchildrenArr = _ele.children;
                        return;
                      }
                    }
                  } catch (err) {
                    _iterator4.e(err);
                  } finally {
                    _iterator4.f();
                  }
                }
              }
            } catch (err) {
              _iterator3.e(err);
            } finally {
              _iterator3.f();
            }
          } else {
            this.grandchildrenArr = [];
          }

          this.showUnpaid = true;
          this.roundIndex += 1;
        }
      },
      immediate: true
    }
  },
  created: function created() {
    var _this2 = this;

    this.$EventBus.$on('openPayPassword', function (order) {
      console.log(order);
      _this2.showKeyboard = true;
      _this2.order = order;
    });
    this.$EventBus.$on('orderListRefresh', function (_) {
      _this2.roundIndex += 1;
    });
    this.$EventBus.$on('orderUnpaid', function (_) {
      _this2.showUnpaid = false;
      _this2.activeChildren = 2;
    });
  },
  destroyed: function destroyed() {
    this.$EventBus.$off('openPayPassword');
    this.$EventBus.$off('orderListRefresh');
    this.$EventBus.$off('orderUnpaid');
  },
  // beforeRouteLeave(to, from, next) {
  //   this.$EventBus.$off('openPayPassword');
  //   this.$EventBus.$off('orderListRefresh');
  //   this.$EventBus.$off('orderUnpaid');
  //   next();
  // },
  methods: _objectSpread(_objectSpread({
    numberToCurrencyNo: _filter.numberToCurrencyNo
  }, (0, _vuex.mapActions)({
    getInfo: 'user/getInfo'
  })), {}, {
    getPackageStoreWareList: function getPackageStoreWareList(data) {
      this.packageStoreWareList = data;
    },
    getTotalCount: function getTotalCount(totalCount) {
      this.totalCount = totalCount;
    },
    getTotalWeight: function getTotalWeight(totalWeight) {
      this.totalWeight = totalWeight;
    },
    onSelectAction: function onSelectAction(item, index) {
      console.log(item, index);

      if (this.activeTab === 9) {
        this.orderIds = item.orderIds;
      } else {
        this.types = item.types;
      }

      this.actionsIndex = index;
      this.roundIndex += 1;
    },
    // 支付密码pop
    showPayPassword: function showPayPassword(show) {
      this.showKeyboard = show;
    },
    // 密码六位数回调
    payPasswordDown: function payPasswordDown(payPassword) {
      var _this$order,
          _this$order$orderIds,
          _this3 = this,
          _this$order2,
          _this$order2$selectOr,
          _this$order3;

      console.log(payPassword);
      console.log(this.order);

      if ((_this$order = this.order) !== null && _this$order !== void 0 && (_this$order$orderIds = _this$order.orderIds) !== null && _this$order$orderIds !== void 0 && _this$order$orderIds.length) {
        // 待支付订单
        (0, _order.payOrder)({
          orderIds: this.order.orderIds,
          commissionOffsetPrice: this.order.commissionOffsetPrice ? Number(this.order.commissionOffsetPrice) < Number(this.user.commissionBalance) ? this.order.commissionOffsetPrice : this.user.commissionBalance : null,
          payPassword: payPassword
        }).then(function (res) {
          _this3.$EventBus.$emit('clearPayPassword');

          _this3.showKeyboard = false;

          _this3.$router.push({
            path: "/PaySuccess",
            query: {
              status: 1
            }
          });
        }).catch(function (e) {
          _this3.$EventBus.$emit('clearPayPassword');
        });
      } else if ((_this$order2 = this.order) !== null && _this$order2 !== void 0 && (_this$order2$selectOr = _this$order2.selectOrderIdList) !== null && _this$order2$selectOr !== void 0 && _this$order2$selectOr.length) {
        // 转运费
        (0, _order.payTransportOrder)({
          selectOrderIdList: this.order.selectOrderIdList,
          payPassword: payPassword
        }).then(function (res) {
          _this3.getInfo();

          _this3.$EventBus.$emit('clearPayPassword');

          _this3.showKeyboard = false;
          _this3.roundIndex += 1;
        }).catch(function (e) {
          _this3.$EventBus.$emit('clearPayPassword');
        });
      } else if ((_this$order3 = this.order) !== null && _this$order3 !== void 0 && _this$order3.orderPremiumId) {
        console.log("补款");
        (0, _order.payAdditionalOrder)({
          orderPremiumId: this.order.orderPremiumId,
          payPassword: payPassword
        }).then(function (res) {
          _this3.getInfo();

          _this3.$EventBus.$emit('clearPayPassword');

          _this3.showKeyboard = false;
          _this3.roundIndex += 1;
        }).catch(function (e) {
          _this3.$EventBus.$emit('clearPayPassword');
        });
      } else {
        console.log("发货单支付");
        console.log(this.order.logisticsId);
        (0, _order.payInvoice)({
          logisticId: this.order.logisticsId,
          payPassword: payPassword
        }).then(function (res) {
          _this3.getInfo();

          _this3.$EventBus.$emit('clearPayPassword');

          _this3.activeChildren = 2;
          _this3.showKeyboard = false;
          _this3.roundIndex += 1;
        }).catch(function (e) {
          console.log("eeeeeeeeeeeeeeee", e);

          _this3.$EventBus.$emit('clearPayPassword');
        });
      }
    },
    // list数据
    getDataList: function getDataList(list) {
      this.dataList = list;
    },
    // 批量支付
    paySelectOrder: function paySelectOrder() {
      var _this$dataList,
          _this4 = this;

      if (!((_this$dataList = this.dataList) !== null && _this$dataList !== void 0 && _this$dataList.length) || !this.dataList.some(function (item) {
        return item.checked === true;
      })) {
        return (0, _toast.default)("Please choice the order before");
      } // Payment Pending 


      if (this.activeTab === 0) {
        this.order = {
          totalPriceNGN: this.selectTotalAmount,
          commissionOffsetPrice: this.dataList.reduce(function (total, currentValue) {
            if (currentValue.checked) total += Number(currentValue.commissionOffsetPrice);
            return total;
          }, 0).toFixed(2),
          orderIds: this.dataList.filter(function (item) {
            return item.checked;
          }).map(function (item) {
            return item.id;
          })
        };
        this.showKeyboard = true;
      } else if (this.activeTab === 2) {
        // Global Warehouse 转运费
        _dialog.default.confirm({
          message: 'This fee is only for warehouse storage, does not include the international freight fee.',
          theme: 'round-button'
        }).then(function () {
          _this4.order = {
            totalPriceNGN: (0, _filter.fromCurrencyByToCurrency)(_this4.dataList.reduce(function (total, currentValue) {
              if (currentValue.checked) total += Number(currentValue.subtotal);
              return total;
            }, 0).toFixed(2), "CNY", "NGN"),
            selectOrderIdList: _this4.dataList.filter(function (item) {
              return item.checked;
            }).map(function (item) {
              return item.id;
            })
          };
          _this4.showKeyboard = true;
        }).catch(function () {// on cancel
        });
      }
    },
    // myWarehouse全选发货订单
    handleCheckbox: function handleCheckbox() {
      var _this5 = this;

      this.dataList.forEach(function (item) {
        _this5.$set(item, "checked", _this5.checkedAll);
      });
    },
    // 订单发货线路
    goDelivery: function goDelivery() {
      var _this6 = this;

      if (this.checkedAll) {
        this.exceptDeliveryOrderIdList = this.dataList.filter(function (item) {
          return !item.checked;
        }).map(function (item) {
          return item.id;
        });
        this.selectDeliveryOrderIdList = [];
      } else {
        this.selectDeliveryOrderIdList = this.dataList.filter(function (item) {
          return item.checked;
        }).map(function (item) {
          return item.id;
        });
        this.exceptDeliveryOrderIdList = [];
      }

      (0, _order.getShippingLineList)({
        selectOrderIdList: this.selectDeliveryOrderIdList,
        exceptOrderIdList: this.exceptDeliveryOrderIdList
      }).then(function (res) {
        _this6.sensitive = res.data.sensitive;
        _this6.sensitiveLineList = res.data.lines;
      });
      this.freightFeeShow = true;
    },
    // 切换tab
    changeTab: function changeTab() {
      this.packageStoreWareList = [];
      this.grandchildrenArr = [];
      this.showUnpaid = true;
      this.actionsIndex = 0;
      this.types = null;
      this.orderIds = null;
      this.checkedAll = true;
      this.activeChildren = 1;
      this.deliveredStatus = 1;
      this.totalCount = null;
      this.roundIndex += 1;
    }
  })
};
exports.default = _default;