"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("core-js/modules/es6.function.name");

require("vant/es/stepper/style");

var _stepper = _interopRequireDefault(require("vant/es/stepper"));

var _default = {
  name: "TheSkuItem",
  components: (0, _defineProperty2.default)({}, _stepper.default.name, _stepper.default),
  props: {
    skuName: {
      type: String,
      required: true
    },
    groupName: {
      type: String,
      default: ""
    },

    /**
     * {"sku":"粉色碎花;90cm","color":"red","price":5.5,"count":4773,"selectedCount":0,"skuImageUrl":"img/ibank/O1CN012hJcfe1XA07YVde4p_!!2204148322882-0-cib.jpg","skuId":"4578989665882","specId":"dd4c68c27eb4c1bfe72dc5624626cd28"}
     */
    skuDataItem: {
      type: Object,
      required: true
    },
    step: {
      type: Number,
      default: 1
    }
  },
  methods: {
    onChange: function onChange(skuDataItem) {
      console.log("🚀 ~ file: TheSkuItem.vue ~ line 57 ~ onChange ~ skuDataItem", skuDataItem);
      if (skuDataItem == null) return;
      this.$emit('skuStepperOnChange', {
        skuDataItem: skuDataItem,
        groupName: this.groupName
      });
    }
  }
};
exports.default = _default;