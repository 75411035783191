"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticStyle: {
      padding: "0 10px"
    }
  }, [_c("van-nav-bar", {
    attrs: {
      title: "After Service",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go("-1");
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "title"
  }, [_vm._v("Select after service type")]), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_vm.APP_AFTER_CONFIG.APP_RETURN_REFUND === 1 ? _c("van-cell", {
    attrs: {
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/exchange",
          query: {
            id: _vm.$route.query.id,
            afterType: 1
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/after_service/return_refund.png"),
            size: "20"
          }
        }), _vm._v(" "), _c("span", [_vm._v("Return&Refund")])];
      },
      proxy: true
    }], null, false, 3024646757)
  }) : _vm._e(), _vm._v(" "), _vm.APP_AFTER_CONFIG.APP_REFUND_ONLY === 1 ? _c("van-cell", {
    attrs: {
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/exchange",
          query: {
            id: _vm.$route.query.id,
            afterType: 2
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/after_service/refund.png"),
            size: "20"
          }
        }), _vm._v(" "), _c("span", [_vm._v("Refund")])];
      },
      proxy: true
    }], null, false, 2135248508)
  }) : _vm._e(), _vm._v(" "), _vm.APP_AFTER_CONFIG.APP_EXCHANGE_GOODS === 1 ? _c("van-cell", {
    attrs: {
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/exchange",
          query: {
            id: _vm.$route.query.id,
            afterType: 3
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/after_service/exchange.png"),
            size: "20"
          }
        }), _vm._v(" "), _c("span", [_vm._v("Exchange")])];
      },
      proxy: true
    }], null, false, 1151451228)
  }) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("van-cell", {
    attrs: {
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/aboutAfterService");
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/after_service/about.png"),
            size: "20"
          }
        }), _vm._v(" "), _c("span", [_vm._v("About after service")])];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/customerCare");
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/after_service/contact.png"),
            size: "20"
          }
        }), _vm._v(" "), _c("span", [_vm._v("Contact us")])];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("van-cell", {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("div", {
          staticClass: "tip"
        }, [_vm._v("△ You can return or exchange the goods "), _c("span", [_vm._v("only after womata's approval.")]), _vm._v(" Please wait for approval.")])];
      },
      proxy: true
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;