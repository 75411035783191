"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/divider/style");

var _divider = _interopRequireDefault(require("vant/es/divider"));

require("vant/es/list/style");

var _list = _interopRequireDefault(require("vant/es/list"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _cmmission = require("api/cmmission");

var _components;

var _default = {
  name: "WithdrawalRecord",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _list.default.name, _list.default), (0, _defineProperty2.default)(_components, _divider.default.name, _divider.default), _components),
  filters: {
    getApprovalStatusString: function getApprovalStatusString(status) {
      var approvalStatusString = "";

      switch (status) {
        case 1:
          approvalStatusString = "Pending";
          break;

        case 2:
          approvalStatusString = "Finished";
          break;

        case 3:
          approvalStatusString = "Refused";
          break;

        default:
          break;
      }

      return approvalStatusString;
    }
  },
  data: function data() {
    return {
      withdrawalRecordList: [],
      loading: false,
      finished: false,
      pageNum: 1
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      (0, _cmmission.getWithdrawRecordList)({
        pageNum: this.pageNum,
        pageSize: 20
      }).then(function (res) {
        var _res$data, _res$data$data;

        _this.loading = false;

        if (((_res$data = res.data) === null || _res$data === void 0 ? void 0 : (_res$data$data = _res$data.data) === null || _res$data$data === void 0 ? void 0 : _res$data$data.length) > 0) {
          if (_this.pageNum === 1) {
            _this.withdrawalRecordList = res.data.data;
          } else {
            _this.withdrawalRecordList = _this.withdrawalRecordList.concat(res.data.data);
          }
        } else {
          if (_this.pageNum === 1) {
            _this.withdrawalRecordList = [];
          }

          _this.finished = true;
        }
      });
    },
    onLoad: function onLoad() {
      this.pageNum++;
      this.fetchData();
    }
  }
};
exports.default = _default;