"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Flash Sale",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "count-down"
  }, [_c("CountDown", {
    key: _vm.beginTime,
    attrs: {
      "begin-time": _vm.beginTime,
      "end-time": _vm.endTime
    }
  })], 1), _vm._v(" "), _c("van-pull-refresh", {
    attrs: {
      "pulling-text": "Pull down refresh",
      "loosing-text": "Release to refresh",
      "loading-text": "Refreshing",
      "success-text": "Refresh completed"
    },
    on: {
      refresh: _vm.onRefresh
    },
    model: {
      value: _vm.refreshing,
      callback: function callback($$v) {
        _vm.refreshing = $$v;
      },
      expression: "refreshing"
    }
  }, [_vm.list.length ? _c("van-list", {
    attrs: {
      finished: _vm.finished,
      "finished-text": "No More ...",
      "immediate-check": false
    },
    on: {
      load: _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (item, index) {
    return _c("van-card", {
      key: index,
      attrs: {
        thumb: item.images
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c("div", {
            staticClass: "van-multi-ellipsis--l3 my-title-tag"
          }, [item.supplyType === 2 ? _c("span", {
            staticClass: "my-tag"
          }, [_vm._v("Goods in Nigeria")]) : _vm._e(), _vm._v(_vm._s(item.spuName))])];
        },
        proxy: true
      }, {
        key: "price",
        fn: function fn() {
          return [_c("div", [_c("span", {
            staticClass: "offer-price theme-color"
          }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(item.discountPrice)))]), _c("span", {
            staticClass: "show-price"
          }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(item.offerPrice)))])]), _vm._v(" "), _c("van-button", {
            attrs: {
              round: "",
              color: "#EA3340",
              size: "mini"
            },
            on: {
              click: function click($event) {
                return _vm.toGoodDetailPage(item);
              }
            }
          }, [_vm._v("Buy now")])];
        },
        proxy: true
      }], null, true)
    });
  }), 1) : _c("van-empty", {
    attrs: {
      description: "No more data!"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/no_data.png")
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;