"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/dialog/style");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/radio-group/style");

var _radioGroup = _interopRequireDefault(require("vant/es/radio-group"));

require("vant/es/radio/style");

var _radio = _interopRequireDefault(require("vant/es/radio"));

require("vant/es/cell-group/style");

var _cellGroup = _interopRequireDefault(require("vant/es/cell-group"));

require("vant/es/cell/style");

var _cell = _interopRequireDefault(require("vant/es/cell"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/field/style");

var _field = _interopRequireDefault(require("vant/es/field"));

require("vant/es/form/style");

var _form = _interopRequireDefault(require("vant/es/form"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _vuex = require("vuex");

var _api = require("api");

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "SelectRecharge",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _form.default.name, _form.default), (0, _defineProperty2.default)(_components, _field.default.name, _field.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _cell.default.name, _cell.default), (0, _defineProperty2.default)(_components, _cellGroup.default.name, _cellGroup.default), (0, _defineProperty2.default)(_components, _radio.default.name, _radio.default), (0, _defineProperty2.default)(_components, _radioGroup.default.name, _radioGroup.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _dialog.default.Component.name, _dialog.default.Component), _components),
  filters: {
    getCurrencyToUnit: function getCurrencyToUnit(currency) {
      var unitString = '';

      switch (currency) {
        case 'CNY':
          unitString = '¥';
          break;

        case 'NGN':
          unitString = '₦';
          break;

        case 'USD':
          unitString = '$';
          break;

        default:
          break;
      }

      return unitString;
    }
  },
  data: function data() {
    return {
      dialogShow: false,
      defaultMode: 0,
      rachargeList: []
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    user: function user(state) {
      return state.user['user'];
    }
  })),
  created: function created() {
    var _this = this;

    if (this.$route.query.amount) {
      (0, _api.getPayInfos)({
        amount: this.$route.query.amount
      }).then(function (res) {
        _this.rachargeList = res.data;
      });
    }
  },
  methods: {
    toPay: function toPay() {
      var _this$rachargeList$th, _this$rachargeList$th2, _window$location;

      (0, _api.getPay)({
        terminal: 2,
        mode: (_this$rachargeList$th = this.rachargeList[this.defaultMode]) === null || _this$rachargeList$th === void 0 ? void 0 : _this$rachargeList$th.mode,
        type: 0,
        amount: (_this$rachargeList$th2 = this.rachargeList[this.defaultMode]) === null || _this$rachargeList$th2 === void 0 ? void 0 : _this$rachargeList$th2.amount,
        email: this.user.email,
        redirectUrl: ((_window$location = window.location) === null || _window$location === void 0 ? void 0 : _window$location.origin) + "/account" // 成功返回地址

      }).then(function (res) {
        console.log(res.data);
        window.location.replace(res.data);
      });
    },
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    }
  }
};
exports.default = _default;