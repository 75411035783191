"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _components;

var _default = {
  name: "RemoveCartons",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), _components),
  data: function data() {
    return {
      isRemoveCartons: null
    };
  },
  created: function created() {
    var _this$$route$query;

    this.isRemoveCartons = Number((_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.isRemoveCartons) || null;
  },
  destroyed: function destroyed() {
    this.$EventBus.$emit('getIsRemoveCartons', this.isRemoveCartons);
  },
  methods: {
    toReturn: function toReturn() {
      if (this.isRemoveCartons) {
        this.$router.go(-1);
      } else {
        _toast.default.fail("Please select");
      }
    },
    selectIsRemoveCartons: function selectIsRemoveCartons(isRemoveCartons) {
      this.isRemoveCartons = isRemoveCartons;
      this.$router.go(-1);
    }
  }
};
exports.default = _default;