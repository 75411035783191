"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "transactions-view"
  }, [_c("van-nav-bar", {
    attrs: {
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-search", {
          attrs: {
            shape: "round",
            disabled: "",
            "clear-trigger": "always",
            placeholder: "Please enter order ID"
          },
          on: {
            click: function click($event) {
              _vm.$router.push({
                path: "/search",
                query: {
                  fromPage: "Transactions",
                  balanceType: Number(_vm.$route.query.balanceType) === 2 ? 2 : null
                }
              });
            },
            clear: _vm.onClick
          },
          model: {
            value: _vm.orderId,
            callback: function callback($$v) {
              _vm.orderId = $$v;
            },
            expression: "orderId"
          }
        })];
      },
      proxy: true
    }, {
      key: "right",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/account/date.png"),
            size: "16"
          },
          on: {
            click: _vm.openDate
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("van-tabs", {
    class: {
      "page-background-color": true,
      isShowTabs: Number(_vm.$route.query.balanceType) === 2
    },
    attrs: {
      animated: "",
      swipeable: Number(_vm.$route.query.balanceType) !== 2
    },
    on: {
      click: _vm.onClick
    },
    model: {
      value: _vm.active,
      callback: function callback($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, _vm._l(_vm.tabList, function (item, index) {
    return _c("van-tab", {
      key: index,
      attrs: {
        title: item.title,
        name: item.name
      }
    }, [_c("van-pull-refresh", {
      staticStyle: {
        "min-height": "calc(100vh - 3.6rem)"
      },
      attrs: {
        "pulling-text": "Pull down refresh",
        "loosing-text": "Release to refresh",
        "loading-text": "Refreshing",
        "success-text": "Refresh completed"
      },
      on: {
        refresh: _vm.onRefresh
      },
      model: {
        value: _vm.refreshing,
        callback: function callback($$v) {
          _vm.refreshing = $$v;
        },
        expression: "refreshing"
      }
    }, [item.data.length ? _c("van-list", {
      attrs: {
        finished: _vm.finished,
        "finished-text": "No More ...",
        "immediate-check": false
      },
      on: {
        load: _vm.onLoad
      },
      model: {
        value: _vm.loading,
        callback: function callback($$v) {
          _vm.loading = $$v;
        },
        expression: "loading"
      }
    }, _vm._l(item.data, function (element) {
      return _c("div", {
        key: element.id,
        staticClass: "card",
        on: {
          click: function click($event) {
            return _vm.$router.push({
              path: "/transactionsDetails",
              query: {
                id: element.id
              }
            });
          }
        }
      }, [_c("van-icon", {
        attrs: {
          name: element.transaction.type === 0 ? require("@/assets/images/account/recharge2.png") : element.transaction.type === 1 ? require("@/assets/images/account/consume.png") : element.transaction.type === 2 ? require("@/assets/images/account/refund2.png") : element.transaction.type === 3 ? require("@/assets/images/account/withdrawal.png") : element.transaction.type === 4 ? require("@/assets/images/account/deduction.png") : "",
          size: "24"
        }
      }), _vm._v(" "), _c("div", {
        staticClass: "info"
      }, [_c("div", {
        staticClass: "info-title"
      }, [_vm._v(_vm._s(_vm._f("getTransactionType")(element.transaction.type)))]), _vm._v(" "), _c("div", [_vm._v(_vm._s(element.transaction.remark))]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("parseTime")(element.transaction.transactionTime)))])]), _vm._v(" "), _c("div", {
        staticClass: "money",
        style: "color: ".concat(element.transaction.type === 0 ? "#FC7A22" : element.transaction.type === 1 ? "#EA3340" : element.transaction.type === 2 ? "#009933" : element.transaction.type === 3 ? "#D392FF" : element.transaction.type === 4 ? "#be9c54" : "", ";")
      }, [_vm._v(_vm._s(element.transaction.direction === 1 ? "+" : "-") + _vm._s(_vm._f("numberToCurrencyNo")((Number(element.transaction.amount) * Number(element.transaction.exchangeRate)).toFixed(2))) + "₦")])], 1);
    }), 0) : _c("van-empty", {
      staticStyle: {
        "min-height": "calc(100vh - 3.6rem)"
      },
      attrs: {
        description: "No more data!"
      },
      scopedSlots: _vm._u([{
        key: "image",
        fn: function fn() {
          return [_c("van-icon", {
            attrs: {
              name: require("@/assets/images/no_data.png")
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1)], 1);
  }), 1), _vm._v(" "), _c("van-popup", {
    model: {
      value: _vm.showDate,
      callback: function callback($$v) {
        _vm.showDate = $$v;
      },
      expression: "showDate"
    }
  }, [_c("van-datetime-picker", {
    attrs: {
      type: "year-month",
      title: "Select Date",
      "min-date": _vm.minDate,
      "max-date": _vm.maxDate
    },
    on: {
      cancel: function cancel($event) {
        _vm.showDate = false;
        _vm.currentDate = "";
      },
      confirm: _vm.confirmDate
    },
    model: {
      value: _vm.currentDate,
      callback: function callback($$v) {
        _vm.currentDate = $$v;
      },
      expression: "currentDate"
    }
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;