"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/swipe-item/style");

var _swipeItem = _interopRequireDefault(require("vant/es/swipe-item"));

require("vant/es/swipe/style");

var _swipe = _interopRequireDefault(require("vant/es/swipe"));

require("core-js/modules/es6.function.name");

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

var _components;

var _default = {
  name: "UserHelpPurchase",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _swipe.default.name, _swipe.default), (0, _defineProperty2.default)(_components, _swipeItem.default.name, _swipeItem.default), _components),
  data: function data() {
    return {};
  },
  methods: {
    onFinish: function onFinish() {
      this.$router.back();
    }
  }
};
exports.default = _default;