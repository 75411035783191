"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("van-popup", {
    staticStyle: {
      height: "80%",
      padding: "16px 16px 0"
    },
    attrs: {
      round: "",
      position: "bottom",
      "close-on-click-overlay": false,
      "close-on-popstate": "",
      "safe-area-inset-bottom": ""
    },
    model: {
      value: _vm.showPayPassword_,
      callback: function callback($$v) {
        _vm.showPayPassword_ = $$v;
      },
      expression: "showPayPassword_"
    }
  }, [_c("van-icon", {
    attrs: {
      name: "cross",
      size: "22"
    },
    on: {
      click: _vm.clickClose
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "title"
  }, [_vm._v("Total Amount")]), _vm._v(" "), _c("div", {
    staticClass: "amount"
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.totalAmount).toFixed(2))))]), _vm._v(" "), _c("div", {
    staticClass: "enter"
  }, [_vm._v("Please enter the payment password")]), _vm._v(" "), _c("van-password-input", {
    attrs: {
      value: _vm.payPassword,
      focused: _vm.showPayPassword_
    },
    on: {
      focus: function focus($event) {
        return _vm.$emit("showPayPassword", true);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "tip"
  }, [_c("span", {
    on: {
      click: function click($event) {
        return _vm.$router.push("/paymentPassword");
      }
    }
  }, [_vm._v("Forget payment password ?")])]), _vm._v(" "), _c("van-number-keyboard", {
    attrs: {
      "safe-area-inset-bottom": "",
      "extra-key": "",
      show: _vm.showPayPassword_
    },
    model: {
      value: _vm.payPassword,
      callback: function callback($$v) {
        _vm.payPassword = $$v;
      },
      expression: "payPassword"
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;