"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticStyle: {
      background: "#fff"
    }
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Terms of service",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _vm._m(0)], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("main", [_c("div", [_c("h3", [_vm._v("1. Confirmation and acceptance of terms of service")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_vm._v("The ownership and operation of the registered user service is\n        owned by WOMATA. The services provided must be strictly\n        implemented in accordance with the service charter, service\n        terms and operating rules issued by the company. It also means\n        that the user and WOMATA have reached the cost agreement and\n        accepted all its terms of service.")])]), _vm._v(" "), _c("div", [_c("h3", [_vm._v("2. Provision of service information")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_vm._v('"WOMATA" through the Internet, uses its own information and\n        service platform to provide users with international purchasing\n        services and international transshipment services. The consignee\n        information provided by users must be detailed, accurate, true\n        and effective, and the personal email address of users must also\n        be true and effective. When the information is changed, it should\n        be submitted to "WOMATA" customer service in time. If the\n        information provided by users is inaccurate and untrue, the\n        website will not be able to provide effective services. And has the\n        right to unilaterally suspend the service right to the user.')])]), _vm._v(" "), _c("div", [_c("h3", [_vm._v("3. User data protection principles")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_vm._v("Respecting users' privacy is a basic principle of WOMATA.\n        Therefore, we will not disclose, change or disclose the user's\n        personal data to a third party and keep it in the registered user\n        service file of \"WOMATA\", unless required by law or in response to\n        the needs of customers.")])]), _vm._v(" "), _c("div", [_c("h3", [_vm._v("4. Rights to terms of service")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_vm._v('"WOMATA" reserves the right to modify or interrupt the service\n        at any time without notice to the user and is not responsible to the\n        user or any third party.')])]), _vm._v(" "), _c("div", [_c("h3", [_vm._v("5. Security maintenance of user's account / password")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_vm._v('Once you register as a user of this app, you will have a member\n        account, which is vour user name. WOMATA has the right to\n        modify your user name when it contains illegal or network\n        sensitive words. You will be responsible for all actions of your user\n        name and password. Therefore, please take good care of your\n        user name and password and do not disclose it to any third party.\n        Otherwise, you will bear all the legal consequences caused by it. If\n        you find that your password may have been leaked, please\n        change it in time. You can enter the "personal information" in your\n        "user center" at any time to modify your password and\n        information, or you can close your old account and open a new\n        one, but you must inform our customer service staff immediatelv.\n        To add the balance from vour old account to vour new account.')])]), _vm._v(" "), _c("div", [_c("h3", [_vm._v("6. User management")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_vm._v("Users will be solely responsible for publishing information\n        content. Users' use of the service is in accordance with all local\n        national and international legal standards applicable to the\n        website service. Users must follow:\n        "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("(1) the release of information must complv with the relevant laws\n          and requlations of China.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("(2) do not use network registered user services to engage in\n          illegal activities.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("(3) no interference or confusion of network services.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v('(4) comply with all network protocols, regulations and\n          procedures for using network registered user services. Users are\n          required to bear legal liability for their actions in the "WOMATA"\n          registered user service. If the user spreads or spreads\n          reactionary, pornographic or other information violating national\n          laws in the "WOMATA" registered user service, we will provide the\n          relevant departments with the system records which may be used\n          as evidence of the user\'s violation of the law. At the same time,\n          delete the information immediately without notifying the user.')])])]), _vm._v(" "), _c("div", [_c("h3", [_vm._v("7. Limited liability")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_vm._v('"WOMATA" hereby reserves the right to pursue compensation\n        for any direct, indirect, accidental, special use of the network\n        member services or illegal use of the information transmitted by\n        the service users to damage or cause damage to the image and\n        reputation of "WOMATA".')])]), _vm._v(" "), _c("div", [_c("h3", [_vm._v("8. Refusal to provide guarantee")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_vm._v("The user clearly agrees that the use of the mail service is at the\n        user's personal risk. \"WOMATA\" expressly states that it does not\n        provide any kind of warranty, whether express or implied, that the\n        user's understanding and acceptance of any information is up to\n        the user himself and bears all risks and responsibilities for system\n        damage or data loss.")])]), _vm._v(" "), _c("div", [_c("h3", [_vm._v('9. Storage and restriction of "WOMATA" registered user service information')]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_vm._v('"WOMATA" has no upper limit on the amount of information\n        transmitted, but this website has the right to determine whether\n        the user\'s behavior conforms to the requirements and spirit of the\n        "WOMATA" registered user\'s service terms. If the user violates the\n        provisions of the service terms, the account number of the\n        registered user\'s service on the network will be interrupted. The\n        copyright of all the contents of this website belongs to\n        "WOMATA". Anyone who wants to reprint the website content\n        must obtain the authorization of "WOMATA". Otherwise, it is\n        regarded as infringement.')])]), _vm._v(" "), _c("div", [_c("h3", [_vm._v("10. Exemption from liability")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "sub"
  }, [_vm._v("(1) We will try our best to make the selling products consistent\n          with the description and patterns of the products published on\n          the website. However, due to the product in the shooting process,\n          will be affected by lighting, color system, equipment differences,\n          as well as the color difference of each computer, resolution and\n          other factors. We can not guarantee that the information in the\n          description of the goods is exactly the same as the actual\n          samples. Especially in the color, can only be based on the actual\n          sample of goods.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v('(2) This website refers to the website designated by the user to\n          purchase the specified goods. International transshipment\n          service, under normal conditions, usually completed within 6-15\n          working days. But no final commitment. If the website is out of\n          stock, price changes, delayed delivery, abnormal goods or other\n          irresistible factors, which result in the failure to complete the\n          purchasing agent normally, we will communicate with the users in\n          a timely manner. "WOMATA" will not be liable for any loss caused\n          to users.')]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("(3) If the package cannot be delivered to the destination due to\n          the user's reason, this website will not bear any responsibility.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("(4) This website does not accept any conditions negotiated\n          between customers and merchants. Members and customers of\n          this website can only enjoy the rights of preferential activities and\n          promotion activities announced by WOMATA on the official\n          website, and no longer enjoy the rights of any preferential\n          activities of other business websites.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("(5) This website does not support the purchase of counterfeit\n          goods, prescription drugs, tobacco, lottery tickets, adult\n          magazines and DVDs.")])])]), _vm._v(" "), _c("div", [_c("h3", [_vm._v("11. Privacy statement")]), _c("br"), _vm._v(" "), _c("h3", [_vm._v("Ⅰ. Scope of application:")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "sub"
  }, [_vm._v("(1) Personal information required when users register with WOMATA.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("(2) When users use WOMATA related services or visit its related\n          web pages, WOMATA automatically receives and records the\n          server on the user's browser, including but not limited to IP\n          address, host country and other data and web page records\n          required by users.")])]), _vm._v(" "), _c("h3", [_vm._v("Ⅱ. Scope of application:")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "sub"
  }, [_vm._v("(1) WOMATA will not sell or lend the user's personal information\n          to anyone except with the user's prior permission.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("(2) WOMATA also does not allow the task third party to collect,\n          edit, sell or disseminate the user's personal information by anv\n          means. If any user is found to be engaged in the above activities,\n          WOMATA has the right to immediately terminate the service\n          agreement with the user, seal up his account and pursue relevant\n          legal liabilities.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("(3) In order to serve users, WOMATA may provide services to\n          users by using their personal information, including but not limited\n          to sending product and service information to users, or sharing\n          information with WOMATA partners so that they can send\n          information about products and services to users.")])]), _vm._v(" "), _c("h3", [_vm._v("Ⅲ. Information disclosure: the user's personal\n        information will be partially or completely disclosed\n        under the following circumstances:")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "sub"
  }, [_vm._v("(1) With the consent of users, it is disclosed to a third party.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("(2) According to the relevant provisions of local laws or the\n          requirements of relevant administrative and judicial institutions,\n          shall be disclosed to a third party or relevant administrative and\n          judicial institutions.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("(3) If users violate the relevant laws or WOMATA policies of\n          China, they need to disclose to a third party.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("(4) Personal information of users that must be shared with third\n          parties in order to provide products and services required by\n          users.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("(5) Other disclosure as WOMATA considers appropriate in\n          accordance with the law or WOMATA policy.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("(6) WOMATA will strictly abide by the confidentiality agreement\n          for the bank account information provided by users when using\n          WOMATA.")])])]), _vm._v(" "), _c("div", [_c("h3", [_vm._v("12. Other")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "sub"
  }, [_vm._v("1. WOMATA solemnly reminds users to pay attention to the\n          clauses in this agreement that exempt WOMATA from liability and\n          restrict users' rights. Users are requested to read carefully and\n          consider risks independently.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("2. The validity, interpretation and dispute settlement of this\n          Agreement shall be governed by the laws of the people's Republic\n          of China. In case of any dispute or dispute between user and\n          WOMATA, friendly negotiation shall be conducted first. If\n          negotiation fails. User agrees to submit the dispute or dispute to\n          the people's court with urisdiction in the place where WOMATA is\n          located.")]), _vm._v(" "), _c("div", {
    staticClass: "sub"
  }, [_vm._v("3. Guangzhou LIANFEI Network Technology Co., Ltd. reserves\n          the right of interpretation and modification of this agreement.")])])])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;