import { render, staticRenderFns } from "./TheGoodDetailPrice.vue?vue&type=template&id=5088966d&scoped=true&"
import script from "./TheGoodDetailPrice.vue?vue&type=script&lang=js&"
export * from "./TheGoodDetailPrice.vue?vue&type=script&lang=js&"
import style0 from "./TheGoodDetailPrice.vue?vue&type=style&index=0&id=5088966d&scoped=true&lang=css&"
import style1 from "./TheGoodDetailPrice.vue?vue&type=style&index=1&id=5088966d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5088966d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/womata-web-app_dYpF/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5088966d')) {
      api.createRecord('5088966d', component.options)
    } else {
      api.reload('5088966d', component.options)
    }
    module.hot.accept("./TheGoodDetailPrice.vue?vue&type=template&id=5088966d&scoped=true&", function () {
      api.rerender('5088966d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/components/TheGoodDetailPrice.vue"
export default component.exports