"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "All Commission Orders",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "main"
  }, [_c("van-tabs", {
    attrs: {
      swipeable: "",
      animated: ""
    },
    on: {
      change: _vm.changeTab
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, _vm._l(_vm.tabList, function (ele) {
    return _c("van-tab", {
      key: ele.id,
      attrs: {
        name: ele.id,
        title: ele.title
      }
    }, [_c("div", {
      staticClass: "totalCount"
    }, [_c("span", [_vm._v("orders:" + _vm._s(_vm.orderCount))]), _vm._v(" "), _c("span", [_vm._v("total:₦" + _vm._s(Number(_vm.commissionSum).toFixed(2)))])]), _vm._v(" "), _vm.list.length ? _c("van-list", {
      attrs: {
        finished: _vm.finished,
        "finished-text": "No More ...",
        "immediate-check": false
      },
      on: {
        load: _vm.onLoad
      },
      model: {
        value: _vm.loading,
        callback: function callback($$v) {
          _vm.loading = $$v;
        },
        expression: "loading"
      }
    }, _vm._l(_vm.list, function (item, index) {
      return _c("div", {
        key: index,
        staticClass: "box"
      }, [_c("div", {
        staticClass: "title"
      }, [_c("van-icon", {
        attrs: {
          size: "14",
          name: require("@/assets/images/cart/common_store.png")
        }
      }), _vm._v(" "), _c("div", {
        staticClass: "name"
      }, [_vm._v(_vm._s(item.sellerCompanyName))]), _vm._v(" "), _c("span", {
        staticClass: "theme-color",
        staticStyle: {
          fontSize: "10px"
        }
      }, [_vm._v(_vm._s(_vm._f("getCommissionStatus")(item.orderDetails[0].commissionStatus)))])], 1), _vm._v(" "), _c("div", {
        staticClass: "content"
      }, _vm._l(item.orderDetails, function (childItem, childIndex) {
        return _c("van-card", {
          key: childIndex,
          attrs: {
            thumb: childItem.mainImage
          },
          on: {
            "click-thumb": function clickThumb($event) {
              return _vm.toGoodDetailPage(childItem);
            }
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function fn() {
              return [_c("div", {
                staticClass: "van-multi-ellipsis--l2"
              }, [_vm._v(_vm._s(childItem.goodsNameEn))])];
            },
            proxy: true
          }, {
            key: "price",
            fn: function fn() {
              return [_vm._v("\n                  " + _vm._s(_vm._f("CNYToCurrencyUnit")(childItem.unitPrice))), _c("span", {
                staticClass: "piece"
              }, [_vm._v(" /Piece")])];
            },
            proxy: true
          }, {
            key: "tags",
            fn: function fn() {
              return [_c("div", {
                staticStyle: {
                  display: "flex",
                  marginTop: "14px",
                  color: "#858587"
                }
              }, [_vm._v("\n                    " + _vm._s(_vm._f("getSkuInfosEn")(childItem.skuInfosEn && JSON.parse(childItem.skuInfosEn))) + "\n                  ")])];
            },
            proxy: true
          }, {
            key: "num",
            fn: function fn() {
              return [_vm._v("\n                  x" + _vm._s(childItem.quantity) + "\n                ")];
            },
            proxy: true
          }, {
            key: "footer",
            fn: function fn() {
              return [_c("div", {
                staticClass: "other"
              }, [_vm._v("\n                    " + _vm._s(_vm._f("parseTime")(item.createTime)) + "\n                  ")]), _vm._v(" "), _c("div", {
                staticClass: "total"
              }, [_vm._v("Commission: "), _c("span", {
                staticClass: "theme-color"
              }, [_vm._v("₦" + _vm._s(Number(childItem.commission).toFixed(2)))])])];
            },
            proxy: true
          }], null, true)
        });
      }), 1)]);
    }), 0) : _c("van-empty", {
      attrs: {
        description: "No more data!"
      },
      scopedSlots: _vm._u([{
        key: "image",
        fn: function fn() {
          return [_c("van-icon", {
            attrs: {
              name: require("@/assets/images/no_data.png")
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;