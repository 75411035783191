"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Rule Description",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "title"
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/my_gain/title.png"),
      size: "12"
    }
  }), _vm._v("How to gain the commission?")], 1), _vm._v(" "), _c("div", {
    staticClass: "photo"
  }, [_c("div", [_c("div", [_vm._v("You")]), _vm._v(" "), _c("van-image", {
    attrs: {
      src: require("@/assets/images/my_gain/rule1.png")
    }
  })], 1), _vm._v(" "), _c("div", [_c("div", [_vm._v("Your friend")]), _vm._v(" "), _c("van-image", {
    attrs: {
      src: require("@/assets/images/my_gain/rule2.png")
    }
  })], 1), _vm._v(" "), _c("div", [_c("div", [_vm._v("You")]), _vm._v(" "), _c("van-image", {
    attrs: {
      src: require("@/assets/images/my_gain/rule3.png")
    }
  })], 1)]), _vm._v(" "), _vm._m(1), _vm._v(" "), _c("div", {
    staticClass: "title"
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/my_gain/title.png"),
      size: "12"
    }
  }), _vm._v("What can commission be used for?")], 1), _vm._v(" "), _c("div", {
    staticClass: "photo photo2"
  }, [_c("div", [_c("div", [_vm._v("Recharge")]), _vm._v(" "), _c("van-image", {
    attrs: {
      src: require("@/assets/images/my_gain/rule4.png")
    }
  })], 1), _vm._v(" "), _c("div", [_c("div", [_vm._v("Recharge By Commisson")]), _vm._v(" "), _c("van-image", {
    attrs: {
      src: require("@/assets/images/my_gain/rule5.png")
    }
  })], 1)]), _vm._v(" "), _vm._m(2), _vm._v(" "), _c("div", {
    staticClass: "title"
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/my_gain/title.png"),
      size: "12"
    }
  }), _vm._v("Why can't I gain the commission?")], 1), _vm._v(" "), _vm._m(3), _vm._v(" "), _vm._m(4), _vm._v(" "), _vm._m(5)])], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "header"
  }, [_vm._v("In order to attract more "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("new")]), _vm._v(" customers to use Womata App, Womata decided to "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("give a sum of money as a commission reward to users")]), _vm._v(" who share womata and make others buy.\n      "), _c("br"), _vm._v("The more you share, the more commission!")]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "tip"
  }, [_c("div", [_vm._v("1、After you "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("share the product link")]), _vm._v(" with your friend, your \n        friend will successfully establish a distribution relationship \n        with you "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("by clicking your link if he is shared for the first \n          time")]), _vm._v(" and does not establish a distribution relationship, \n        otherwise the relationship will fail.")]), _vm._v(" "), _c("div", [_vm._v("2、During the validity period of the distribution relationship \n        with you, "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("after your friend purchases any goods")]), _vm._v(" in womata, you\n        can get a certain proportion of the amount as a "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("commission from\n          Womata")]), _vm._v(" when the goods arrive at thewarehouse.")]), _vm._v(" "), _c("div", [_vm._v("3、Different commodities can gain "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("different commissions")]), _vm._v(", \n        which can be viewed on the "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("commodity page")]), _vm._v(".")]), _vm._v(" "), _c("div", [_vm._v("4、You can check your commission in My Gain page.")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "tip"
  }, [_c("div", [_vm._v("1、You can recharge your commission into your wallet so that it can be used when paying orders.")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "tip"
  }, [_c("div", [_vm._v("1、Order problem:")]), _vm._v(" "), _c("div", [_vm._v("1)   lf your friend's order is fully refunded or using coupons, the corresponding commission will be deducted.")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "tip"
  }, [_c("div", [_vm._v("2、Distribution relationship failure:")]), _vm._v(" "), _c("div", [_vm._v("1)   The validity period of the distribution relationship which established by clicking is 15 days.\n        "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("If it exceeds the validity period")]), _vm._v(", your friend's distribution relationship with you will be terminated\n        and then you can't get the commission. Clickingagain or buying by your friends can extend the validity period.")]), _vm._v(" "), _c("div", [_vm._v("2)   The protection period of the distribution relationship which established by clicking is 7 days. "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("If\n        it exceeds the protection period")]), _vm._v(", your friend will click on other's links to establish a new distribution relationship with him,\n        and then you can't get a commission. Clicking again or buying canextend the validity period.")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "tip"
  }, [_c("div", [_vm._v("3、Others:")]), _vm._v(" "), _c("div", [_vm._v("1)   You can only get the commission by sharing it with your friends instead of buying it yourself.")]), _vm._v(" "), _c("div", [_vm._v("2)   All interpretation rights belong to womata.")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;