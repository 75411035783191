"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "refund-card"
    }, [_c("div", {
      staticClass: "cell"
    }, [_c("div", {
      staticClass: "grey"
    }, [_vm._v("Creat Time: " + _vm._s(_vm._f("parseTime")(item.createTime)))]), _vm._v(" "), _c("div", {
      staticClass: "theme-color"
    }, [_vm._v(_vm._s(_vm._f("getStatusName")(item.status)))])]), _vm._v(" "), _c("div", {
      staticClass: "cell"
    }, [_c("div", [_c("van-icon", {
      attrs: {
        name: require("@/assets/images/after_service/refund_id.png"),
        size: "12"
      }
    }), _c("span", {
      staticStyle: {
        margin: "0 4px"
      }
    }, [_vm._v("Refund Id:")]), _c("span", {
      staticClass: "grey"
    }, [_vm._v(_vm._s(item.id))])], 1), _vm._v(" "), item.innerStatus === 2 ? _c("div", {
      staticClass: "theme-color"
    }, [_vm._v("Need to return the goods")]) : _vm._e()]), _vm._v(" "), _c("van-divider"), _vm._v(" "), _c("div", {
      staticClass: "wrap",
      on: {
        click: function click($event) {
          return _vm.$router.push({
            path: "/refundDetails",
            query: {
              orderId: item.orderId
            }
          });
        }
      }
    }, [_c("van-image", {
      attrs: {
        width: "70",
        height: "70",
        src: item.order && item.order.orderDetails[0].mainImage
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "content"
    }, [_c("div", {
      staticClass: "good-name"
    }, [item.order && item.order.supplyType === 2 ? _c("span", {
      staticClass: "my-tag"
    }, [_vm._v("Goods in Nigeria")]) : _vm._e(), _vm._v(_vm._s(item.order && item.order.orderDetails[0].goodsNameEn))]), _vm._v(" "), _c("div", {
      staticClass: "size"
    }, [_vm._v("Number of applications：" + _vm._s(item.localGoodsRefundDetails && item.localGoodsRefundDetails.reduce(function (total, currentValue) {
      return total += currentValue.quantity;
    }, 0)))])])], 1), _vm._v(" "), item.innerStatus === 10 ? _c("div", {
      staticClass: "refund-station-fee"
    }, [_c("div", [_vm._v("Logistics cost:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("numberToCurrencyNo")(Number(item.refundStationFee).toFixed(2))) + " "), _c("span", {
      staticClass: "theme-color"
    }, [_vm._v("NGN")])])]) : _vm._e(), _vm._v(" "), _c("div", {
      staticClass: "btn"
    }, [item.status === 1 ? _c("van-button", {
      attrs: {
        plain: "",
        hairline: "",
        round: "",
        size: "mini",
        color: "#515151"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.cancel(item.id);
        }
      }
    }, [_vm._v("Cancel")]) : _vm._e(), _vm._v(" "), item.innerStatus === 2 ? _c("van-button", {
      attrs: {
        hairline: "",
        round: "",
        size: "mini",
        color: "#EA3340"
      },
      on: {
        click: function click($event) {
          return _vm.addressDialog(item);
        }
      }
    }, [_vm._v("Return information")]) : _vm._e(), _vm._v(" "), item.innerStatus === 10 && item.isPay === 2 ? _c("van-button", {
      attrs: {
        hairline: "",
        round: "",
        size: "mini",
        color: "#EA3340"
      },
      on: {
        click: function click($event) {
          return _vm.handlePay(item.id, item.refundStationFee);
        }
      }
    }, [_vm._v("Pay now")]) : _vm._e()], 1)], 1);
  }), 0);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;