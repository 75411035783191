"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.regexp.replace");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "user_help_purchase_page"
  }, [_c("div", {
    staticClass: "page-box col_center_center"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.id == 2,
      expression: "$route.query.id == 2"
    }],
    staticClass: "img-box page-two"
  }, [_c("img", {
    attrs: {
      src: require("../../static/asset/images/help_buy/step2/pic_4.png"),
      width: "100%"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "btn-box"
  }, [_c("van-button", {
    staticClass: "btn",
    attrs: {
      plain: "",
      round: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/user_help_page_1",
          query: {
            id: "3.1"
          }
        });
      }
    }
  }, [_vm._v("Onlince")]), _vm._v(" "), _c("div", {
    staticStyle: {
      width: "6px"
    }
  }), _vm._v(" "), _c("van-button", {
    staticClass: "btn",
    attrs: {
      plain: "",
      round: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/user_help_page_1",
          query: {
            id: "3.2"
          }
        });
      }
    }
  }, [_vm._v("Offline")]), _vm._v(" "), _c("div", {
    staticStyle: {
      width: "6px"
    }
  }), _vm._v(" "), _c("van-button", {
    staticClass: "btn",
    attrs: {
      plain: "",
      round: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.$router.replace({
          path: "/user_help_page_1",
          query: {
            id: "3.3"
          }
        });
      }
    }
  }, [_vm._v("Next")])], 1)]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.id == "3.1",
      expression: "$route.query.id == '3.1'"
    }],
    staticClass: "img-box page-three"
  }, [_c("img", {
    attrs: {
      src: require("../../static/asset/images/help_buy/step2/pic_online.png"),
      width: "100%"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "btn-box"
  }, [_c("van-button", {
    staticClass: "btn",
    attrs: {
      plain: "",
      round: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/user_help_page_1",
          query: {
            id: "3.1.1"
          }
        });
      }
    }
  }, [_vm._v("Next")])], 1)]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.id == "3.1.1",
      expression: "$route.query.id == '3.1.1'"
    }],
    staticClass: "img-box page-two"
  }, [_c("img", {
    attrs: {
      src: require("../../static/asset/images/help_buy/step2/pic_online_way.png"),
      width: "100%"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "btn-box"
  }, [_c("van-button", {
    staticClass: "btn",
    attrs: {
      plain: "",
      round: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/user_help_page_1",
          query: {
            id: "3.1.1.1"
          }
        });
      }
    }
  }, [_vm._v("Paystack")]), _vm._v(" "), _c("div", {
    staticStyle: {
      width: "6px"
    }
  }), _vm._v(" "), _c("van-button", {
    staticClass: "btn",
    attrs: {
      plain: "",
      round: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/user_help_page_1",
          query: {
            id: "3.1.1.2"
          }
        });
      }
    }
  }, [_vm._v("Flutterware")]), _vm._v(" "), _c("div", {
    staticStyle: {
      width: "6px"
    }
  }), _vm._v(" "), _c("van-button", {
    staticClass: "btn",
    attrs: {
      plain: "",
      round: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-2);
      }
    }
  }, [_vm._v("Back")])], 1)]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.id == "3.1.1.1",
      expression: "$route.query.id == '3.1.1.1'"
    }],
    staticClass: "img-box page-three"
  }, [_c("img", {
    attrs: {
      src: require("../../static/asset/images/help_buy/step2/pic_paystack_1.png"),
      width: "100%"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "btn-box"
  }, [_c("van-button", {
    staticClass: "btn",
    attrs: {
      plain: "",
      round: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/user_help_page_1",
          query: {
            id: "3.1.1.1.1"
          }
        });
      }
    }
  }, [_vm._v("Next")])], 1)]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.id == "3.1.1.1.1",
      expression: "$route.query.id == '3.1.1.1.1'"
    }],
    staticClass: "img-box page-three"
  }, [_c("img", {
    attrs: {
      src: require("../../static/asset/images/help_buy/step2/pic_paystack_2.png"),
      width: "100%"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "btn-box"
  }, [_c("van-button", {
    staticClass: "btn",
    attrs: {
      plain: "",
      round: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-2);
      }
    }
  }, [_vm._v("Back")])], 1)]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.id == "3.1.1.2",
      expression: "$route.query.id == '3.1.1.2'"
    }],
    staticClass: "img-box page-three"
  }, [_vm._v("\n      " + _vm._s(_vm.$route.query.id) + "\n      "), _c("img", {
    attrs: {
      src: require("../../static/asset/images/help_buy/step2/pic_flutterware.png"),
      width: "100%"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "btn-box"
  }, [_c("van-button", {
    staticClass: "btn",
    attrs: {
      plain: "",
      round: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Back")])], 1)]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.id == "3.2",
      expression: "$route.query.id == '3.2'"
    }],
    staticClass: "img-box page-three"
  }, [_c("img", {
    attrs: {
      src: require("../../static/asset/images/help_buy/step2/pic_offline_1.png"),
      width: "100%"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "btn-box"
  }, [_c("van-button", {
    staticClass: "btn",
    attrs: {
      plain: "",
      round: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/user_help_page_1",
          query: {
            id: "3.2.2"
          }
        });
      }
    }
  }, [_vm._v("Next")])], 1)]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.id == "3.2.2",
      expression: "$route.query.id == '3.2.2'"
    }],
    staticClass: "img-box page-three"
  }, [_c("img", {
    attrs: {
      src: require("../../static/asset/images/help_buy/step2/pic_offline_2.png"),
      width: "100%"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "btn-box"
  }, [_c("van-button", {
    staticClass: "btn",
    attrs: {
      plain: "",
      round: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/user_help_page_1",
          query: {
            id: "3.2.3"
          }
        });
      }
    }
  }, [_vm._v("Next")])], 1)]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.id == "3.2.3",
      expression: "$route.query.id == '3.2.3'"
    }],
    staticClass: "img-box page-three"
  }, [_c("img", {
    attrs: {
      src: require("../../static/asset/images/help_buy/step2/pic_offline_3.png"),
      width: "100%"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "btn-box"
  }, [_c("van-button", {
    staticClass: "btn",
    attrs: {
      plain: "",
      round: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-3);
      }
    }
  }, [_vm._v("Back")])], 1)]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.id == "3.3",
      expression: "$route.query.id == '3.3'"
    }],
    staticClass: "img-box page-three"
  }, [_c("img", {
    attrs: {
      src: require("../../static/asset/images/help_buy/step2/pic_recharge_finish.png"),
      width: "100%"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "btn-box"
  }, [_c("van-button", {
    staticClass: "btn",
    attrs: {
      plain: "",
      round: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.onFinish();
      }
    }
  }, [_vm._v("Finish")])], 1)])])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;