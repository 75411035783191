"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.function.name");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticStyle: {
      padding: "50px 10px 10px"
    }
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Audit Records",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("van-pull-refresh", {
    staticStyle: {
      "min-height": "calc(100vh - 3rem)"
    },
    attrs: {
      "pulling-text": "Pull down refresh",
      "loosing-text": "Release to refresh",
      "loading-text": "Refreshing",
      "success-text": "Refresh completed"
    },
    on: {
      refresh: _vm.onRefresh
    },
    model: {
      value: _vm.refreshing,
      callback: function callback($$v) {
        _vm.refreshing = $$v;
      },
      expression: "refreshing"
    }
  }, [_vm.dataList.length ? _c("van-list", {
    attrs: {
      finished: _vm.finished,
      "finished-text": "No more ...",
      "immediate-check": false
    },
    on: {
      load: _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.dataList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "card"
    }, [_c("div", {
      staticClass: "title"
    }, [_c("van-icon", {
      attrs: {
        name: _vm.user.headImage || require("@/assets/images/wologo_opacity.png"),
        size: "26"
      }
    }), _vm._v(" "), _c("div", [_vm._v(_vm._s(item.name))]), _vm._v(" "), _c("van-icon", {
      attrs: {
        name: "idcard",
        size: "26"
      },
      on: {
        click: function click($event) {
          _vm.imagePreview(JSON.parse(item.screenshots));
        }
      }
    })], 1), _vm._v(" "), _c("div", {
      staticClass: "content"
    }, [_c("div", {
      staticClass: "content-left"
    }, [_c("div", [_vm._v(_vm._s(item.account))]), _vm._v(" "), _c("div", {
      staticClass: "money"
    }, [_vm._v("₦" + _vm._s(Number(item.amount).toFixed(2)))]), _vm._v(" "), _c("div", [_vm._v("CreateTime: " + _vm._s(_vm._f("parseTime")(item.createTime)))])]), _vm._v(" "), _c("div", {
      class: _vm._f("getStatusClass")(item.approvalStatus)
    }, [_vm._v(_vm._s(_vm._f("getStatusText")(item.approvalStatus)))])]), _vm._v(" "), _c("van-collapse", {
      model: {
        value: _vm.activeNames,
        callback: function callback($$v) {
          _vm.activeNames = $$v;
        },
        expression: "activeNames"
      }
    }, [_c("van-collapse-item", {
      attrs: {
        title: "Submit remark",
        name: item.id + "1"
      }
    }, [_vm._v(_vm._s(item.remark))]), _vm._v(" "), _c("van-collapse-item", {
      attrs: {
        title: "Audit remark",
        name: item.id + "2"
      }
    }, [_vm._v(_vm._s(item.approvalRemark))])], 1)], 1);
  }), 0) : [_c("div", {
    staticClass: "van-list__finished-text",
    staticStyle: {
      "min-height": "calc(100vh - 3rem)"
    }
  }, [_vm._v("No more ...")])]], 2)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;