"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticStyle: {
      overflowY: "scroll",
      paddingBottom: "46px"
    }
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Bnak transfer recharge",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("header", [_c("div", {
    staticClass: "photo"
  }, [_c("van-icon", {
    attrs: {
      name: "credit-pay",
      size: "24"
    }
  }), _vm._v(" "), _c("van-divider", {
    style: {
      background: "#fff",
      flex: "1",
      margin: "0 16px"
    }
  }), _vm._v(" "), _c("van-icon", {
    attrs: {
      name: "edit",
      size: "24"
    }
  }), _vm._v(" "), _c("van-divider", {
    style: {
      background: "#fff",
      flex: "1",
      margin: "0 16px"
    }
  }), _vm._v(" "), _c("van-icon", {
    attrs: {
      name: "success",
      size: "24"
    }
  })], 1), _vm._v(" "), _vm._m(0)]), _vm._v(" "), _c("van-empty", {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: "checked",
            size: "110",
            color: "#d12f3a"
          }
        }), _vm._v(" "), _c("div", {
          staticStyle: {
            fontSize: "18px"
          }
        }, [_vm._v("Success!")])];
      },
      proxy: true
    }, {
      key: "description",
      fn: function fn() {
        return [_vm._v("\n      Submitted for review successfully.\n      We will confirm it within 24 hours.\n      Sunday will be delayed by one day.\n    ")];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _vm._v(" "), _c("van-button", {
    staticClass: "bottom-button",
    attrs: {
      round: "",
      type: "danger",
      color: "#d12f3a"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/bankRechargeRecord");
      }
    }
  }, [_vm._v("OK")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "explain"
  }, [_c("div", [_vm._v("Transfer Money to Designated Account")]), _vm._v(" "), _c("div", [_vm._v("Fill the transfer infomation")]), _vm._v(" "), _c("div", [_vm._v("Finish Submit")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;