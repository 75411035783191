"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/field/style");

var _field = _interopRequireDefault(require("vant/es/field"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/form/style");

var _form = _interopRequireDefault(require("vant/es/form"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _vuex = require("vuex");

var _user = require("api/user");

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "ChangePhone",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _form.default.name, _form.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _field.default.name, _field.default), _components),
  data: function data() {
    return {
      phone: ''
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    user: function user(state) {
      return state.user['user'];
    }
  })),
  created: function created() {
    this.phone = this.user.phone;
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapMutations)({
    setUserData: 'user/SetUserData'
  })), {}, {
    onSubmit: function onSubmit(values) {
      var _this = this;

      console.log('submit', values);
      (0, _user.editUser)(values).then(function (res) {
        _toast.default.success('Change successfully');

        var user = _objectSpread(_objectSpread({}, _this.user), values);

        _this.setUserData(user);

        _this.$router.go(-1);
      });
    },
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    }
  })
};
exports.default = _default;