"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CNYToCurrency = CNYToCurrency;
exports.CNYToCurrencyComma = CNYToCurrencyComma;
exports.CNYToCurrencyUnit = CNYToCurrencyUnit;
exports.filterGoodImg = filterGoodImg;
exports.fromCurrencyByToCurrency = fromCurrencyByToCurrency;
exports.getOrderStatusName = getOrderStatusName;
exports.getSkuInfosEn = getSkuInfosEn;
exports.numberToCurrencyNo = numberToCurrencyNo;
exports.parseTime = parseTime;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.math.trunc");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.to-string");

var _typeof2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));

var _store = _interopRequireDefault(require("@/store"));

var _utils = require("@/utils");

var _dayjs = _interopRequireDefault(require("dayjs"));

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

/* 根据类型返回图片链接
*/
function filterGoodImg(imgUrl, modules) {
  return (0, _utils.getGoodImg)(imgUrl, modules);
}
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */


function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }

  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if (typeof time === 'undefined' || time === null || time === 'null' || !time) {
    return '';
  } else if ((0, _typeof2.default)(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = (0, _dayjs.default)(time).utcOffset(1);
  }

  var formatObj = {
    y: (0, _dayjs.default)(date).year(),
    m: (0, _dayjs.default)(date).month() + 1,
    d: (0, _dayjs.default)(date).date(),
    h: (0, _dayjs.default)(date).hour(),
    i: (0, _dayjs.default)(date).minute(),
    s: (0, _dayjs.default)(date).second(),
    a: (0, _dayjs.default)(date).day()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }

    return value || 0;
  });
  return time_str;
} // 数值千分位制


function numberToCurrencyNo(value) {
  if (!value) return 0; // 获取整数部分

  var intPart = Math.trunc(value); // 整数部分处理，增加,

  var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 预定义小数部分

  var floatPart = ''; // 将数值截取为小数部分和整数部分

  var valueArray = value.toString().split('.');

  if (valueArray.length === 2) {
    // 有小数部分
    floatPart = valueArray[1].toString(); // 取得小数部分

    return intPartFormat + '.' + floatPart;
  }

  return intPartFormat + floatPart;
} // 人名币币种汇率转换 1000


function CNYToCurrency(moneyCNY) {
  var _store$state$exchange, _store$state$exchange2;

  if (moneyCNY === '' || moneyCNY === undefined || moneyCNY === null) return '';
  if (moneyCNY === 0 || moneyCNY === '0') return '0.00';

  if (((_store$state$exchange = _store.default.state.exchangeRate) === null || _store$state$exchange === void 0 ? void 0 : (_store$state$exchange2 = _store$state$exchange.defaultCurrency) === null || _store$state$exchange2 === void 0 ? void 0 : _store$state$exchange2.name) === 'CNY') {
    return Number(moneyCNY).toFixed(2);
  } else {
    var _store$state$exchange3;

    var _iterator = _createForOfIteratorHelper((_store$state$exchange3 = _store.default.state.exchangeRate) === null || _store$state$exchange3 === void 0 ? void 0 : _store$state$exchange3.exchangeRateList),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _store$state$exchange4, _store$state$exchange5;

        var item = _step.value;

        if (item.fromCurrency === "CNY" && item.toCurrency === ((_store$state$exchange4 = _store.default.state.exchangeRate) === null || _store$state$exchange4 === void 0 ? void 0 : (_store$state$exchange5 = _store$state$exchange4.defaultCurrency) === null || _store$state$exchange5 === void 0 ? void 0 : _store$state$exchange5.name)) {
          return (Number(moneyCNY) * Number(item.exchangeRate)).toFixed(2);
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
} // 币种汇率转换 1000


function fromCurrencyByToCurrency(money, fromCurrency, ToCurrency, exchangeRateList) {
  if (money === '' || money === undefined || money === null) return '';
  if (!fromCurrency || !ToCurrency) return money;
  if (money === 0 || money === '0') return '0.00';

  if (exchangeRateList) {
    var _iterator2 = _createForOfIteratorHelper(exchangeRateList),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var item = _step2.value;

        if (item.fromCurrency === fromCurrency && item.toCurrency === ToCurrency) {
          return (Number(money) * Number(item.exchangeRate)).toFixed(2);
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  } else {
    var _store$state$exchange6;

    var _iterator3 = _createForOfIteratorHelper((_store$state$exchange6 = _store.default.state.exchangeRate) === null || _store$state$exchange6 === void 0 ? void 0 : _store$state$exchange6.exchangeRateList),
        _step3;

    try {
      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var _item = _step3.value;

        if (_item.fromCurrency === fromCurrency && _item.toCurrency === ToCurrency) {
          return (Number(money) * Number(_item.exchangeRate)).toFixed(2);
        }
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }
  }
} // 币种汇率转换千分位制 1,000


function CNYToCurrencyComma(moneyCNY) {
  var _store$state$exchange7, _store$state$exchange8;

  if (moneyCNY === '' || moneyCNY === undefined || moneyCNY === null) return '';
  if (moneyCNY === 0 || moneyCNY === '0') return '0.00';

  if (((_store$state$exchange7 = _store.default.state.exchangeRate) === null || _store$state$exchange7 === void 0 ? void 0 : (_store$state$exchange8 = _store$state$exchange7.defaultCurrency) === null || _store$state$exchange8 === void 0 ? void 0 : _store$state$exchange8.name) === 'CNY') {
    return numberToCurrencyNo(Number(moneyCNY).toFixed(2));
  } else {
    var _store$state$exchange9;

    var _iterator4 = _createForOfIteratorHelper((_store$state$exchange9 = _store.default.state.exchangeRate) === null || _store$state$exchange9 === void 0 ? void 0 : _store$state$exchange9.exchangeRateList),
        _step4;

    try {
      for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
        var _store$state$exchange10, _store$state$exchange11;

        var item = _step4.value;

        if (item.fromCurrency === "CNY" && item.toCurrency === ((_store$state$exchange10 = _store.default.state.exchangeRate) === null || _store$state$exchange10 === void 0 ? void 0 : (_store$state$exchange11 = _store$state$exchange10.defaultCurrency) === null || _store$state$exchange11 === void 0 ? void 0 : _store$state$exchange11.name)) {
          return numberToCurrencyNo((Number(moneyCNY) * Number(item.exchangeRate)).toFixed(2));
        }
      }
    } catch (err) {
      _iterator4.e(err);
    } finally {
      _iterator4.f();
    }
  }
} // 币种汇率转换 ¥1,000


function CNYToCurrencyUnit(moneyCNY, exchangeRateList) {
  var _store$state, _store$state$exchange12, _store$state$exchange13, _store$state$exchange14, _store$state$exchange15;

  if (moneyCNY === '' || moneyCNY === undefined || moneyCNY === null) return '';
  if (moneyCNY === 0 || moneyCNY === '0') return "".concat((_store$state = _store.default.state) === null || _store$state === void 0 ? void 0 : (_store$state$exchange12 = _store$state.exchangeRate) === null || _store$state$exchange12 === void 0 ? void 0 : (_store$state$exchange13 = _store$state$exchange12.defaultCurrency) === null || _store$state$exchange13 === void 0 ? void 0 : _store$state$exchange13.unit, "0.00");

  if (((_store$state$exchange14 = _store.default.state.exchangeRate) === null || _store$state$exchange14 === void 0 ? void 0 : (_store$state$exchange15 = _store$state$exchange14.defaultCurrency) === null || _store$state$exchange15 === void 0 ? void 0 : _store$state$exchange15.name) === 'CNY') {
    var _store$state$exchange16, _store$state$exchange17;

    return ((_store$state$exchange16 = _store.default.state.exchangeRate) === null || _store$state$exchange16 === void 0 ? void 0 : (_store$state$exchange17 = _store$state$exchange16.defaultCurrency) === null || _store$state$exchange17 === void 0 ? void 0 : _store$state$exchange17.unit) + numberToCurrencyNo(Number(moneyCNY).toFixed(2));
  } else {
    if (exchangeRateList) {
      var _iterator5 = _createForOfIteratorHelper(exchangeRateList),
          _step5;

      try {
        for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
          var _store$state$exchange18, _store$state$exchange19;

          var item = _step5.value;

          if (item.fromCurrency === "CNY" && item.toCurrency === ((_store$state$exchange18 = _store.default.state.exchangeRate) === null || _store$state$exchange18 === void 0 ? void 0 : (_store$state$exchange19 = _store$state$exchange18.defaultCurrency) === null || _store$state$exchange19 === void 0 ? void 0 : _store$state$exchange19.name)) {
            var _store$state$exchange20, _store$state$exchange21;

            return ((_store$state$exchange20 = _store.default.state.exchangeRate) === null || _store$state$exchange20 === void 0 ? void 0 : (_store$state$exchange21 = _store$state$exchange20.defaultCurrency) === null || _store$state$exchange21 === void 0 ? void 0 : _store$state$exchange21.unit) + numberToCurrencyNo((Number(moneyCNY) * Number(item.exchangeRate)).toFixed(2));
          }
        }
      } catch (err) {
        _iterator5.e(err);
      } finally {
        _iterator5.f();
      }
    } else {
      var _store$state$exchange22;

      var _iterator6 = _createForOfIteratorHelper((_store$state$exchange22 = _store.default.state.exchangeRate) === null || _store$state$exchange22 === void 0 ? void 0 : _store$state$exchange22.exchangeRateList),
          _step6;

      try {
        for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
          var _store$state$exchange23, _store$state$exchange24;

          var _item2 = _step6.value;

          if (_item2.fromCurrency === "CNY" && _item2.toCurrency === ((_store$state$exchange23 = _store.default.state.exchangeRate) === null || _store$state$exchange23 === void 0 ? void 0 : (_store$state$exchange24 = _store$state$exchange23.defaultCurrency) === null || _store$state$exchange24 === void 0 ? void 0 : _store$state$exchange24.name)) {
            var _store$state$exchange25, _store$state$exchange26;

            return ((_store$state$exchange25 = _store.default.state.exchangeRate) === null || _store$state$exchange25 === void 0 ? void 0 : (_store$state$exchange26 = _store$state$exchange25.defaultCurrency) === null || _store$state$exchange26 === void 0 ? void 0 : _store$state$exchange26.unit) + numberToCurrencyNo((Number(moneyCNY) * Number(_item2.exchangeRate)).toFixed(2));
          }
        }
      } catch (err) {
        _iterator6.e(err);
      } finally {
        _iterator6.f();
      }
    }
  }
} // 订单状态


function getOrderStatusName(status) {
  var supplyType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  // console.log("supplyType", supplyType);
  var statusString = '';

  switch (status) {
    case 0:
      statusString = 'Payment Pending'; // 待付款

      break;

    case 1:
      statusString = 'Warehouse Pending'; // 待入库

      break;

    case 2:
      statusString = 'My Warehouse'; // 待发货（客户发货）

      break;

    case 3:
      if (supplyType === 1) {
        statusString = 'Delivered Reviews'; // 待收货（已发货）
      } else {
        statusString = 'Picking'; // 海外仓拣货中
      }

      break;

    case 4:
      statusString = 'Picked up'; // 已完成

      break;

    case 5:
      statusString = 'Cancelled'; // 已取消

      break;

    case 6:
      statusString = 'Waiting Pickup'; // 待取货

      break;

    case 7:
      statusString = 'Approval Pending'; // 待审批（出库）

      break;

    case 8:
      statusString = "Additional Payment"; // 待补款

      break;

    case 9:
      statusString = "Pending Pickup"; // 待提货

      break;

    case 100:
      statusString = "Deleted"; // 已取消

      break;

    default:
      statusString = '';
  }

  return statusString;
} // 商品卡片规格


function getSkuInfosEn(arr) {
  if (!arr.length) return '';
  var str = "";
  arr.forEach(function (item) {
    str += "".concat(item.name, ":").concat(item.value, "; ");
  });
  return str;
}