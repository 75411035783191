"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/notify/style");

var _notify = _interopRequireDefault(require("vant/es/notify"));

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/tree-select/style");

var _treeSelect = _interopRequireDefault(require("vant/es/tree-select"));

require("vant/es/checkbox/style");

var _checkbox = _interopRequireDefault(require("vant/es/checkbox"));

require("vant/es/popup/style");

var _popup = _interopRequireDefault(require("vant/es/popup"));

require("vant/es/field/style");

var _field = _interopRequireDefault(require("vant/es/field"));

require("vant/es/form/style");

var _form = _interopRequireDefault(require("vant/es/form"));

require("vant/es/pull-refresh/style");

var _pullRefresh = _interopRequireDefault(require("vant/es/pull-refresh"));

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

require("vant/es/cell/style");

var _cell = _interopRequireDefault(require("vant/es/cell"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("core-js/modules/es6.function.name");

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

var _vuex = require("vuex");

var _warehouse = require("@/api/warehouse.js");

var _api = require("@/api");

var _utils = require("utils");

var _FooterTabbar = _interopRequireDefault(require("components/FooterTabbar"));

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: 'Forwarding',
  components: (_components = {}, (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _cell.default.name, _cell.default), (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _pullRefresh.default.name, _pullRefresh.default), (0, _defineProperty2.default)(_components, _form.default.name, _form.default), (0, _defineProperty2.default)(_components, _field.default.name, _field.default), (0, _defineProperty2.default)(_components, _popup.default.name, _popup.default), (0, _defineProperty2.default)(_components, _checkbox.default.name, _checkbox.default), (0, _defineProperty2.default)(_components, _treeSelect.default.name, _treeSelect.default), (0, _defineProperty2.default)(_components, "FooterTabbar", _FooterTabbar.default), _components),
  data: function data() {
    return {
      expressNo: "",
      title: "",
      categoryNameCh: "",
      categoryNameEn: "",
      show: false,
      categoryTreeList: [],
      activeId: "",
      activeIndex: 0,
      checked: true,
      warehouse: {
        provinceName: "",
        cityName: "",
        districtName: "",
        address: "",
        postCode: ""
      },
      businessMan: {
        userCode: "",
        businessManRealName: "",
        businessManPhone: ""
      },
      refreshing: false
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    user: function user(state) {
      return state.user['user'];
    }
  })),
  created: function created() {
    this.getListData();
  },
  methods: {
    copy: function copy(txt) {
      (0, _utils.copyTextToClipboard)(txt);

      _toast.default.success('Copy success');
    },
    selectItem: function selectItem(item) {
      console.log(item);
      this.title = item.nameEn;
      this.categoryNameCh = item.nameCh;
      this.categoryNameEn = item.nameEn;
      this.show = false;
    },
    onSubmit: function onSubmit(value) {
      var _this = this;

      (0, _api.transportOrder)({
        title: this.title,
        expressNo: this.expressNo,
        categoryNameEn: this.categoryNameEn,
        categoryNameCh: this.categoryNameCh
      }).then(function (res) {
        _this.title = "";
        _this.expressNo = "";
        _this.categoryNameEn = "";
        _this.categoryNameCh = "";
        _this.activeId = "";
        _this.activeIndex = 0;
        (0, _notify.default)({
          type: 'success',
          message: 'Submitted successfully!'
        });
      });
    },
    onRefresh: function onRefresh() {
      this.getListData();
    },
    getListData: function getListData() {
      var _this2 = this;

      (0, _api.getCategoryTreeList)().then(function (res) {
        _this2.categoryTreeList = res.data.map(function (item) {
          var _item$children;

          if (item !== null && item !== void 0 && (_item$children = item.children) !== null && _item$children !== void 0 && _item$children.length) {
            item.children = item.children.map(function (ele) {
              return _objectSpread(_objectSpread({}, ele), {}, {
                id: ele.catId,
                text: ele.nameEn
              });
            });
          } else {
            item.children = [_objectSpread(_objectSpread({}, item), {}, {
              id: item.catId,
              text: item.nameEn
            })];
          }

          return _objectSpread(_objectSpread({}, item), {}, {
            id: item.catId,
            text: item.nameEn
          });
        });
      });
      (0, _warehouse.getWarehouseList)({
        whType: 4
      }).then(function (res) {
        _this2.refreshing = false;

        if (res.data.length) {
          _this2.warehouse = res.data[0];
        }
      });
      (0, _api.getRelationshipList)({
        customerId: this.user.id,
        page: 1,
        pageSize: 10
      }).then(function (res) {
        if (res.data.records.length) {
          _this2.businessMan = res.data.records[0];
        }
      });
    }
  }
};
exports.default = _default;