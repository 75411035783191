"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("div", {
    staticClass: "title"
  }, [_c("div", {
    staticClass: "company-name"
  }, [_vm._t("checkbox"), _vm._v(" "), _c("van-icon", {
    attrs: {
      size: "16",
      name: _vm.order.type === 1 ? require("@/assets/images/cart/wo_store.png") : require("@/assets/images/cart/common_store.png")
    }
  }), _vm._v(_vm._s(_vm.order.sellerCompanyName) + "\n    ")], 2), _vm._v(" "), _c("div", [_vm.order.status === 9 && _vm.order.reservePickupTimeIsExpires ? [_vm._v("\n        Timed out\n      ")] : [_vm._v("\n        " + _vm._s(_vm._f("getOrderStatusName")(_vm.order.status, _vm.order.supplyType)) + "\n      ")]], 2)]), _vm._v(" "), _vm._l(_vm.order.orderDetails, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "wrap",
      on: {
        click: function click($event) {
          return _vm.handleGoodCard(item);
        }
      }
    }, [_c("van-image", {
      attrs: {
        width: "70",
        height: "70",
        src: item.mainImage
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "content"
    }, [_c("div", {
      staticClass: "good-name"
    }, [_vm._v(_vm._s(item.goodsNameEn))]), _vm._v(" "), _c("div", {
      staticClass: "size"
    }, [_vm._v(_vm._s(_vm._f("getSkuInfosEn")(item.skuInfosEn && JSON.parse(item.skuInfosEn))))]), _vm._v(" "), _c("div", {
      staticClass: "info"
    }, [_c("div", {
      staticClass: "piece"
    }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(item.unitPrice, _vm.activeTab === 0 ? null : JSON.parse(_vm.order.exchangeRate || null)))), _c("span", {
      staticClass: "piece"
    }, [_vm._v(" /Piece")])]), _vm._v(" "), _vm.order.refunds && _vm.order.refunds.length ? _c("div", {
      staticStyle: {
        color: "#4EAF52"
      }
    }, [_vm._v("Received:" + _vm._s("".concat(item.stockQuantity, "/").concat(item.quantity)))]) : _c("div", [_vm._v("x" + _vm._s(item.quantity))])])])], 1);
  }), _vm._v(" "), _c("div", {
    staticClass: "orderFee"
  }, [_vm.order.sensitivity === 1 ? _c("van-button", {
    staticStyle: {
      height: "auto",
      lineHeight: "1"
    },
    attrs: {
      plain: "",
      hairline: "",
      round: "",
      size: "mini",
      color: "#EA3340"
    }
  }, [_vm._v("Sensitivity")]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "fee"
  }, [Number(_vm.order.freightPrice) ? _c("div", [_vm._v("Local Freight:"), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v(" " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm.order.freightPrice, _vm.activeTab === 0 ? null : JSON.parse(_vm.order.exchangeRate || null))))])]) : _vm._e(), _vm._v(" "), Number(_vm.order.servicePrice) ? _c("div", [_vm._v("Service Price:"), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v(" " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm.order.servicePrice, _vm.activeTab === 0 ? null : JSON.parse(_vm.order.exchangeRate || null))))])]) : _vm._e()])], 1), _vm._v(" "), _vm.order.couponCodeId ? _c("div", {
    staticClass: "amount"
  }, [_vm._v("Total Savings: "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.order.discountedPrice, _vm.activeTab === 0 ? null : JSON.parse(_vm.order.exchangeRate || null))))])]) : _vm._e(), _vm._v(" "), Number(_vm.order.billAmount) ? _c("div", {
    staticClass: "amount"
  }, [_vm._v("Station fee: "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.order.billAmount).toFixed(2))))])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "amount"
  }, [_vm._v("Subtotal: "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.order.totalPrice, _vm.activeTab === 0 ? null : JSON.parse(_vm.order.exchangeRate || null))))]), _vm.order.couponCodeId ? _c("span", {
    staticClass: "van-card__origin-price",
    staticStyle: {
      fontSize: "10px"
    }
  }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(+_vm.order.goodsPrice + +_vm.order.freightPrice + +_vm.order.servicePrice, _vm.activeTab === 0 ? null : JSON.parse(_vm.order.exchangeRate))))]) : _vm._e()]), _vm._v(" "), _vm.order.status === 8 && _vm.order.orderPremium ? _c("div", {
    staticClass: "amount"
  }, [_vm._v("Additional Payment: "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.order.orderPremium.amount)))])]) : _vm._e(), _vm._v(" "), _vm.order.refundStatus === 1 ? _c("div", {
    staticClass: "amount"
  }, [_c("div", {
    staticClass: "refunding"
  }, [_c("van-icon", {
    attrs: {
      size: "12",
      name: require("@/assets/images/order/refresh.png")
    }
  }), _c("span", [_vm._v("Refunding")])], 1)]) : _vm._e(), _vm._v(" "), _vm.order.refundStatus === 2 ? _c("div", {
    staticClass: "amount"
  }, [_vm._v("Refunded Amount: "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.order.myRefunds[0].refundAmount).toFixed(2))))])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "footer"
  }, [_vm.order.status > 1 && Number(_vm.order.estimateWeight) ? _c("div", {
    staticClass: "weight"
  }, [_vm._v("Estimated weight："), _c("span", [_vm._v(_vm._s(_vm.order.estimateWeight) + "KG")])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "btn"
  }, [_vm.serviceBtnShow && _vm.order.status === 4 && _vm.order.supplyType === 2 && [0, 3, 4].includes(_vm.order.refundStatus) ? _c("van-button", {
    staticClass: "blank",
    attrs: {
      plain: "",
      hairline: "",
      round: "",
      size: "mini",
      color: "#D0D0D0"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/afterService",
          query: {
            id: _vm.order.id
          }
        });
      }
    }
  }, [_vm._v("After service")]) : _vm._e(), _vm._v(" "), _vm.serviceBtnShow && _vm.order.status === 4 && _vm.order.supplyType === 2 && [1, 2].includes(_vm.order.refundStatus) ? _c("van-button", {
    staticClass: "blank",
    attrs: {
      plain: "",
      hairline: "",
      round: "",
      size: "mini",
      color: "#D0D0D0"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/refundDetails",
          query: {
            orderId: _vm.order.id
          }
        });
      }
    }
  }, [_vm._v("View progress")]) : _vm._e(), _vm._v(" "), _vm.cancelBtnShow && [0, 8].includes(_vm.order.status) ? _c("van-button", {
    attrs: {
      plain: "",
      hairline: "",
      round: "",
      size: "mini",
      color: "#515151"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.cancelOrder(_vm.order.id, _vm.order.status);
      }
    }
  }, [_vm._v("Order cancel")]) : _vm.order.type === 0 && _vm.order.status === 1 && _vm.order.payStatus === 3 ? _c("van-button", {
    attrs: {
      plain: "",
      hairline: "",
      round: "",
      size: "mini",
      color: "#515151"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.refundWarehousedOrder(_vm.order.id);
      }
    }
  }, [_vm._v("Order cancel")]) : _vm._e(), _vm._v(" "), _vm.order.supplyType === 1 && !_vm.order.type && _vm.order.thirdId ? _c("van-button", {
    attrs: {
      plain: "",
      hairline: "",
      round: "",
      size: "mini",
      color: "#EEC27F"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/logisticsDetail",
          query: {
            thirdId: _vm.order.thirdId
          }
        });
      }
    }
  }, [_vm._v("Express process")]) : _vm._e(), _vm._v(" "), _vm.order.status === 9 && _vm.order.supplyType === 2 ? _c("van-button", {
    attrs: {
      hairline: "",
      round: "",
      size: "mini",
      color: "#EA3340"
    },
    on: {
      click: function click($event) {
        return _vm.viewCode(_vm.order.id);
      }
    }
  }, [_vm._v("Pickup Voucher")]) : _vm._e()], 1)]), _vm._v(" "), _c("van-dialog", {
    attrs: {
      "get-container": "body",
      theme: "round-button",
      title: "Pickup Voucher",
      "confirm-button-text": "Yes,I see.",
      "confirm-button-color": "#EA3340"
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c("div", {
    staticClass: "pickup-code"
  }, [_c("div", {
    ref: "qrcodeContainer",
    staticClass: "qrcode"
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      fontSize: "14px"
    }
  }, [_vm._v(_vm._s(_vm.pickupCode))]), _vm._v(" "), _c("div", [_vm._v("(" + _vm._s(_vm.boxQuantity) + ")")])])])], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;