"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "the-sku-item"
  }, [_c("div", {
    staticClass: "sku-box"
  }, [_c("div", {
    staticClass: "sku-left"
  }, [_c("div", {
    staticClass: "f-14"
  }, [_vm._v(_vm._s(_vm.skuName))]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "8px"
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "rgba(186,186,186,1)"
    }
  }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.skuDataItem.price)))])]), _vm._v(" "), _c("div", {
    staticClass: "sku-right"
  }, [_c("van-stepper", {
    attrs: {
      step: _vm.step,
      min: "0",
      max: _vm.skuDataItem != null && _vm.skuDataItem.count ? _vm.skuDataItem.count : 0,
      integer: "",
      "input-width": "50px",
      "button-size": "24px"
    },
    on: {
      change: function change($event) {
        return _vm.onChange(_vm.skuDataItem);
      }
    },
    model: {
      value: _vm.skuDataItem.selectedCount,
      callback: function callback($$v) {
        _vm.$set(_vm.skuDataItem, "selectedCount", $$v);
      },
      expression: "skuDataItem.selectedCount"
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "8px"
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "rgba(186,186,186,1)"
    }
  }, [_vm._v("Stock: " + _vm._s(_vm.skuDataItem.count))])], 1)])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;