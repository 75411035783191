"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

require("vant/es/divider/style");

var _divider = _interopRequireDefault(require("vant/es/divider"));

require("vant/es/empty/style");

var _empty = _interopRequireDefault(require("vant/es/empty"));

require("vant/es/pull-refresh/style");

var _pullRefresh = _interopRequireDefault(require("vant/es/pull-refresh"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _myLike = require("api/myLike");

var _utils = require("@/utils");

var _components;

var _default = {
  name: "Browsed",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _pullRefresh.default.name, _pullRefresh.default), (0, _defineProperty2.default)(_components, _empty.default.name, _empty.default), (0, _defineProperty2.default)(_components, _divider.default.name, _divider.default), (0, _defineProperty2.default)(_components, _image.default.name, _image.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), _components),
  data: function data() {
    return {
      list: [],
      refreshing: false
    };
  },
  watch: {
    $route: {
      handler: function handler(val, oldVal) {
        // 因缓存刷新页面
        if (["Account"].includes(oldVal === null || oldVal === void 0 ? void 0 : oldVal.name) && val.name === "Browsed") {
          this.fetchData();
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      (0, _myLike.getMyBrowsed)().then(function (res) {
        _this.refreshing = false;
        _this.list = res.data || [];
      });
    },
    toGoodsContent: function toGoodsContent(ele) {
      var _arguments = {
        "url": ele.module === 1 ? ele.spuId : (0, _utils.alibabaGoodsLink)(ele.spuId),
        "module": ele.module
      };
      this.$router.push({
        path: "/GoodsContent",
        query: _arguments
      });
    },
    onRefresh: function onRefresh() {
      this.fetchData();
    }
  }
};
exports.default = _default;