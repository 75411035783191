"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticStyle: {
      background: "#fff"
    }
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Success",
      "safe-area-inset-top": "",
      fixed: ""
    }
  }), _vm._v(" "), _c("main", [_c("van-icon", {
    attrs: {
      name: "checked",
      size: "84",
      color: "#EA3340"
    }
  }), _vm._v(" "), _c("div", [_vm._v("Success!")]), _vm._v(" "), _c("van-button", {
    attrs: {
      color: "#EA3340",
      round: ""
    },
    on: {
      click: _vm.goPage
    }
  }, [_vm._v("OK(" + _vm._s(_vm.time) + ")")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;