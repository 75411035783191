"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addLocalGoodsRefund = addLocalGoodsRefund;
exports.cancelLocalGoodsRefund = cancelLocalGoodsRefund;
exports.cancelOrder = cancelOrder;
exports.createDispatchDetails = createDispatchDetails;
exports.createOrder = createOrder;
exports.createOrderFromGoods = createOrderFromGoods;
exports.createOrderPreview = createOrderPreview;
exports.getCanMergeLogisticList = getCanMergeLogisticList;
exports.getInvoiceDetails = getInvoiceDetails;
exports.getInvoiceList = getInvoiceList;
exports.getLocalGoodsRefundList = getLocalGoodsRefundList;
exports.getLocalGoodsRetuenAddress = getLocalGoodsRetuenAddress;
exports.getOrderList = getOrderList;
exports.getOrderStatus = getOrderStatus;
exports.getPickCodeList = getPickCodeList;
exports.getRefundReasonList = getRefundReasonList;
exports.getShippingLineList = getShippingLineList;
exports.getStoreList = getStoreList;
exports.payAdditionalOrder = payAdditionalOrder;
exports.payInvoice = payInvoice;
exports.payLocalGoodsRefundStationFee = payLocalGoodsRefundStationFee;
exports.payOrder = payOrder;
exports.payTransportOrder = payTransportOrder;
exports.procOrderCreate = procOrderCreate;
exports.refundWarehousedOrder = refundWarehousedOrder;
exports.rejectAdditionalOrder = rejectAdditionalOrder;
exports.returnInvoice = returnInvoice;
exports.sendOrder = sendOrder;
exports.submitDispatch = submitDispatch;
exports.submitOrderFromGoods = submitOrderFromGoods;
exports.updateOrderPickupTime = updateOrderPickupTime;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

var _request = _interopRequireDefault(require("@/utils/request"));

var _qs = _interopRequireDefault(require("qs"));

var _settings = _interopRequireDefault(require("@/settings"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function procOrderCreate(data) {
  return (0, _request.default)({
    url: 'tms-service/proc-order/create',
    method: 'post',
    // data,
    data: data
  });
}
/**
 * 
 * @param {
	"pageNumber":1,
	"pageSize":10,
	"status":-1, // -1查询 待入库 运单, 入库 0,待发货 1， 已经发货 2 ,取消发货 3 待审核
	"userId": "1235101646707507284"
} 
 */


function getStoreList(data) {
  return (0, _request.default)({
    url: 'tms-service/lf-warehouse-order/store-list',
    method: 'post',
    // data,
    data: data
  });
}
/** 发货
 * 
 * @param {
	"orderIdVOList":[{
		"orderId":"1237665453166317608"
	}],
	"remark": "remark remark...",
	"expressType": 2,
	"addressIds":["1237664019460935767"]
} data 
 */


function sendOrder(data) {
  return (0, _request.default)({
    url: 'tms-service/order/send-order',
    method: 'post',
    // data,
    data: data
  });
}
/**
 * 
 * 查询发货单
 */


function getInvoiceList(data) {
  return (0, _request.default)({
    url: 'wms-service/wms/logistic/app',
    method: 'get',
    params: _objectSpread(_objectSpread({}, data), {}, {
      version: _settings.default.Version
    })
  });
}
/**
 * 
 * 根据发货单id查询订单详情信息
 */


function getInvoiceDetails(data) {
  return (0, _request.default)({
    url: 'wms-service/wms/logistic/getDetailById_v4',
    method: 'get',
    params: data
  });
}
/**
 * 
 * 支付发货单
 */


function payInvoice(data) {
  return (0, _request.default)({
    url: 'wms-service/wms/logistic/payByApp',
    method: 'put',
    data: data
  });
}
/**
 * 
 * 转运订单批量支付
 */


function payTransportOrder(data) {
  return (0, _request.default)({
    url: 'tms-service/order/pay-dispatch-bill-v4',
    method: 'post',
    data: data
  });
}
/**
 * 
 * 发货线路列表
 */


function getShippingLineList(data) {
  return (0, _request.default)({
    url: 'tms-service/order/check-sensitive-v3',
    method: 'post',
    data: data
  });
}
/**
 * 
 * 查询可以合并发货单
 */


function getCanMergeLogisticList(data) {
  return (0, _request.default)({
    url: 'wms-service/wms/logistic/getLogisticByIds',
    method: 'post',
    data: data
  });
}
/**
 * 
 * 发货预览详情
 */


function createDispatchDetails(data) {
  return (0, _request.default)({
    url: 'tms-service/order/dispatch_bill/create',
    method: 'post',
    data: data
  });
}
/**
 * 
 * 提交发货单
 */


function submitDispatch(data) {
  return (0, _request.default)({
    url: 'wms-service/wms/logistic/add-v3',
    method: 'post',
    data: data
  });
}
/**
 * 
 * 退回发货单
 */


function returnInvoice(logisticsId) {
  return (0, _request.default)({
    url: 'wms-service/wms/logistic/appRevoke/' + logisticsId,
    method: 'delete'
  });
}
/**
 * 
 * 查询订单
 */


function getOrderList(data) {
  var params = _objectSpread(_objectSpread({}, data), {}, {
    version: _settings.default.Version
  });

  return (0, _request.default)({
    url: 'order_service/order?' + _qs.default.stringify(params, {
      indices: false,
      arrayFormat: 'repeat',
      skipNulls: true
    }),
    method: 'get'
  });
}
/**
 * 
 * 取消订单
 */


function cancelOrder(data) {
  return (0, _request.default)({
    url: 'order_service/order/cancel',
    method: 'put',
    data: data
  });
}
/**
 * 
 * 退单-App待入库订单
 */


function refundWarehousedOrder(data) {
  return (0, _request.default)({
    url: 'order_service/refund/app',
    method: 'post',
    data: data
  });
}
/**
 * 
 * 订单各种状态数量
 */


function getOrderStatus(data) {
  return (0, _request.default)({
    url: 'order_service/v2/order/count',
    method: 'get',
    params: data
  });
}
/**
 * 
 * 获取提货单码
 */


function getPickCodeList(data) {
  return (0, _request.default)({
    url: 'spu-service/spu/pickInfo',
    method: 'get',
    params: data
  });
}
/**
 * 
 * 创建订单预览（购物车）
 */


function createOrderPreview(data) {
  return (0, _request.default)({
    url: 'order_service/order/create_preview',
    method: 'post',
    data: data
  });
}
/**
 * 
 * 创建订单（购物车）
 */


function createOrder(data) {
  return (0, _request.default)({
    url: 'order_service/order/create',
    method: 'post',
    data: data
  });
}
/**
 * 
 * 创建订单预览（商品詳情）
 */


function createOrderFromGoods(data) {
  return (0, _request.default)({
    url: 'order_service/order/submit_preview',
    method: 'post',
    data: data
  });
}
/**
 * 
 * 提交订单（商品詳情）
 */


function submitOrderFromGoods(data) {
  return (0, _request.default)({
    url: 'order_service/order/submit',
    method: 'post',
    data: data
  });
}
/**
 * 
 * 海外仓订单修改提货时间
 */


function updateOrderPickupTime(data) {
  return (0, _request.default)({
    url: 'order_service/order/receiver_info',
    method: 'put',
    data: data
  });
}
/**
 * 
 * 支付订单
 */


function payOrder(data) {
  return (0, _request.default)({
    url: 'order_service/order/pay',
    method: 'post',
    data: data
  });
}
/**
 * 
 * 拒绝支付补款订单
 */


function rejectAdditionalOrder(data) {
  return (0, _request.default)({
    url: 'order_service/refund',
    method: 'post',
    data: data
  });
}
/**
 * 
 * 支付补款订单
 */


function payAdditionalOrder(data) {
  return (0, _request.default)({
    url: 'order_service/order/pay_premium',
    method: 'post',
    data: data
  });
}
/**
 * 
 * 获取退款原因
 */


function getRefundReasonList(data) {
  return (0, _request.default)({
    url: 'spu-service/refundReason',
    method: 'get',
    params: data
  });
}
/**
 * 
 * 售后服务列表
 */


function getLocalGoodsRefundList(params) {
  return (0, _request.default)({
    url: 'order_service/local_goods_refund',
    method: 'get',
    params: params
  });
}
/**
 * 
 * 申请售后服务
 */


function addLocalGoodsRefund(data) {
  return (0, _request.default)({
    url: 'order_service/local_goods_refund',
    method: 'post',
    data: data
  });
}
/**
 * 
 * 取消售后服务
 */


function cancelLocalGoodsRefund(id) {
  return (0, _request.default)({
    url: 'order_service/local_goods_refund/cancel/' + id,
    method: 'put'
  });
}
/**
 * 
 * 支付售后服务的驿站费
 */


function payLocalGoodsRefundStationFee(data) {
  return (0, _request.default)({
    url: 'order_service/local_goods_refund/app/customerRefundPay',
    method: 'post',
    data: data
  });
}
/**
 * 
 * 获取售后退货地址
 */


function getLocalGoodsRetuenAddress(id) {
  return (0, _request.default)({
    url: 'order_service/local_goods_refund/app/returnInformation/' + id,
    method: 'get'
  });
}