"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "home-view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Womall",
      "safe-area-inset-top": "",
      "left-arrow": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "46px"
    }
  }), _vm._v(" "), _c("van-pull-refresh", {
    on: {
      refresh: _vm.initData
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_c("div", {
    staticClass: "bg-color"
  }, [_c("div", {
    staticStyle: {
      height: "30px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "padding-row-26"
  }, [_vm._v("\n        Welcome to\n        "), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v("\n        Womata Boutique Mall\n      ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "padding-row-26"
  }, [_c("div", {
    staticClass: "search-box padding-row-13",
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/search",
          query: {
            fromPage: "SelfSupport",
            toName: "SelfSupport",
            moduleType: "1"
          }
        });
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("../../assets/images/self_support/search_circular.png")
    }
  })])]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "33px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "one-category"
  }, _vm._l(_vm.selfClassifyList, function (categoryItem, index) {
    return _c("div", {
      key: index,
      staticClass: "category-item",
      on: {
        click: function click($event) {
          return _vm.toGroundPage(categoryItem);
        }
      }
    }, [_c("img", {
      attrs: {
        src: categoryItem.categoryImage
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "category-text"
    }, [_vm._v(_vm._s(categoryItem.englishTitle))])]);
  }), 0)]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "12px"
    }
  }), _vm._v(" "), _vm.flashSale.spuList != null && _vm.flashSale.spuList.length > 0 ? _c("div", {
    staticClass: "padding-row-13 page-background-color"
  }, [_c("FlashSaleCard", {
    attrs: {
      "flash-sale-list": _vm.flashSale.spuList,
      "begin-time": _vm.flashSale.startTime,
      "end-time": _vm.flashSale.endTime,
      "activity-id": _vm.flashSale.activityId
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "12px"
    }
  }), _vm._v(" "), _vm.recommedationList.length > 0 ? _c("div", {
    staticClass: "padding-row-13 page-background-color"
  }, [_c("span", {
    staticStyle: {
      "font-size": "15px",
      "font-weight": "bold"
    }
  }, [_vm._v("Recommedation")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "15px"
    }
  }), _vm._v(" "), _c("RecommendationListCom", {
    attrs: {
      "recommedation-list": _vm.recommedationList
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "page-background-color",
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("van-tabs", {
    attrs: {
      "offset-top": "46px",
      sticky: "",
      ellipsis: false,
      "line-height": "0px"
    },
    model: {
      value: _vm.activeGroud,
      callback: function callback($$v) {
        _vm.activeGroud = $$v;
      },
      expression: "activeGroud"
    }
  }, _vm._l(_vm.tabTitleList, function (tabItem, index) {
    return _c("van-tab", {
      key: index,
      attrs: {
        "title-class": _vm.tabTitleList.length !== index + 1 ? "border_right" : ""
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c("div", {
            staticClass: "r-space-between"
          }, [_c("div", {
            staticClass: "tab-title"
          }, [_c("div", {
            class: _vm.activeGroud == index ? "active-commodity-title" : "unactive-commodity-title"
          }, [_vm._v("\n                " + _vm._s(tabItem.commodityTitle) + "\n              ")]), _vm._v(" "), _c("div", {
            class: _vm.activeGroud == index ? "active-sub-title" : "unactive-sub-title"
          }, [_vm._v("\n                " + _vm._s(tabItem.commoditySubtitle) + "\n              ")])])])];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" "), _c("div", [_c("TheGoodListCom", {
      attrs: {
        "disabled-refresh": true,
        "sort-type": tabItem.type,
        "group-id": tabItem.otherGroupId
      }
    })], 1)]);
  }), 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "50px"
    }
  }), _vm._v(" "), _c("TheFooterTabbar")], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;