"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("core-js/modules/es6.function.name");

require("vant/es/uploader/style");

var _uploader = _interopRequireDefault(require("vant/es/uploader"));

var _oss = require("@/api/oss");

var _axios = _interopRequireDefault(require("axios"));

var _components;

var _default2 = {
  name: "Upload",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _uploader.default.name, _uploader.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), _components),
  props: {
    imgArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    previewSize: {
      type: Number,
      default: 80
    },
    accept: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      fileList: this.imgArr,
      addressOss: "https://womata.oss-accelerate.aliyuncs.com",
      dataOss: {
        key: "headImage/",
        ossAccessKeyId: "",
        policy: "",
        success_action_status: 200,
        signature: "",
        callback: "",
        dir: ""
      }
    };
  },
  created: function created() {
    var _this = this;

    (0, _oss.getPolicy)().then(function (res) {
      console.log(res);
      console.log("请求的地址是-----》", _this.addressOss);
      _this.dataOss = {
        key: res.dir + _this.dataOss.key,
        ossAccessKeyId: res.accessid,
        policy: res.policy,
        signature: res.signature,
        success_action_status: 200,
        callback: res.callback
      }; // 上传额外参数
    });
  },
  methods: {
    afterRead: function afterRead(files) {
      var _this2 = this;

      _toast.default.loading({
        mask: false,
        duration: 60000,
        forbidClick: true,
        message: ''
      });

      var formData = new FormData();
      var currentTimeStamp = new Date().getTime(); // 当前时间戳

      formData.append("key", this.dataOss.key + currentTimeStamp + "_" + this.random_string(10) + files.file.name);
      formData.append("ossAccessKeyId", this.dataOss.ossAccessKeyId);
      formData.append("policy", this.dataOss.policy);
      formData.append("signature", this.dataOss.signature);
      formData.append("success_action_status", this.dataOss.success_action_status);
      formData.append("callback", this.dataOss.callback);
      formData.append("file", files.file);
      (0, _axios.default)({
        method: 'post',
        url: this.addressOss,
        data: formData
      }).then(function (res) {
        _toast.default.clear();

        _toast.default.success("Upload success");

        _this2.$emit('addFileImg', {
          fileUrl: res.data.fileUrl,
          lastModified: files.file.lastModified
        });
      });
    },
    delImg: function delImg(files) {
      this.$emit('delImg', files.file.lastModified);
    },
    random_string: function random_string(len) {
      len = len || 32;
      var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      var maxPos = chars.length;
      var pwd = "";

      for (var i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }

      return pwd;
    }
  }
};
exports.default = _default2;