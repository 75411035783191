"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticStyle: {
      padding: "0 10px"
    }
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Recharge By Commission",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("main", [_c("van-cell", {
    attrs: {
      title: "Recharge by commission"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c("div", {
          staticStyle: {
            color: "#EA3340",
            fontSize: "12px"
          },
          on: {
            click: function click($event) {
              return _vm.$router.push("/ruleDescription");
            }
          }
        }, [_vm._v("About commission"), _c("van-icon", {
          attrs: {
            size: "12",
            name: "arrow",
            color: "#EA3340"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("van-form", {
    on: {
      submit: _vm.onSubmit
    }
  }, [_c("van-field", {
    attrs: {
      name: "rechargeAmount",
      type: "number",
      label: "₦",
      placeholder: "The commission you had is \u20A6".concat(Number(_vm.user.commissionBalance).toFixed(2)),
      rules: [{
        required: true,
        message: "Please enter the amount"
      }]
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("span", {
          staticStyle: {
            color: "#3F8AD3",
            fontSize: "12px"
          },
          on: {
            click: function click($event) {
              _vm.rechargeAmount = _vm.user.commissionBalance;
            }
          }
        }, [_vm._v("All in")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.rechargeAmount,
      callback: function callback($$v) {
        _vm.rechargeAmount = $$v;
      },
      expression: "rechargeAmount"
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "16px"
    }
  }, [_c("van-button", {
    attrs: {
      block: "",
      type: "info",
      "native-type": "submit",
      color: "#EA3340"
    }
  }, [_vm._v("Recharge")])], 1)], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;