"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.function.name");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "order-view"
  }, [_c("van-nav-bar", {
    attrs: {
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-search", {
          attrs: {
            shape: "round",
            disabled: "",
            "clear-trigger": "always",
            placeholder: "Please enter order ID"
          },
          on: {
            click: function click($event) {
              return _vm.$router.push({
                path: "/search",
                query: {
                  fromPage: "Order"
                }
              });
            },
            clear: _vm.changeTab
          },
          model: {
            value: _vm.orderId,
            callback: function callback($$v) {
              _vm.orderId = $$v;
            },
            expression: "orderId"
          }
        })];
      },
      proxy: true
    }, {
      key: "right",
      fn: function fn() {
        return [[-1, 4, 8, 9].includes(_vm.activeTab) && _vm.actionsList.length ? _c("van-icon", {
          attrs: {
            "class-prefix": "iconfont icon-shaixuan",
            size: "20",
            color: "#646566"
          },
          on: {
            click: function click($event) {
              _vm.actionFilterShow = true;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }), _vm._v(" "), _vm.activeTab === 9 && _vm.actionsList.length && _vm.addressTipShow ? _c("div", {
    staticClass: "address-tip"
  }, [_c("div", [_vm._v("Click to filter orders from different pickup address")]), _vm._v(" "), _c("van-icon", {
    attrs: {
      name: "cross",
      size: "12",
      color: "#fff"
    },
    on: {
      click: function click($event) {
        _vm.addressTipShow = !_vm.addressTipShow;
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm._l(_vm.tabList, function (item, index) {
    return [_vm.showUnpaid && item.name === _vm.activeTab && item.children && item.children.length ? _c("div", {
      key: index,
      staticClass: "childTab"
    }, _vm._l(item.children, function (element, i) {
      return _c("div", {
        key: i,
        class: {
          active: element.id === _vm.activeChildren
        },
        on: {
          click: function click($event) {
            _vm.grandchildrenArr = element.children || [];
            _vm.activeChildren = element.id;
            _vm.types = element.types;
            _vm.roundIndex += 1;
            _vm.totalCount = null;
            _vm.checkedAll = true;
            _vm.deliveredStatus === 1;
          }
        }
      }, [_vm._v(_vm._s(element.title) + _vm._s(_vm.totalCount !== null && element.id === _vm.activeChildren ? "(".concat(_vm.totalCount, ")") : ""))]);
    }), 0) : _vm._e()];
  }), _vm._v(" "), _vm.grandchildrenArr.length ? _c("div", {
    staticClass: "grandchildrenTab"
  }, _vm._l(_vm.grandchildrenArr, function (item, index) {
    return _c("div", {
      key: index,
      class: {
        active: item.id === _vm.deliveredStatus
      },
      on: {
        click: function click($event) {
          _vm.deliveredStatus = item.id;
          _vm.roundIndex += 1;
          _vm.totalCount = null;
          _vm.checkedAll = true;
        }
      }
    }, [item.icon ? _c("van-icon", {
      attrs: {
        "class-prefix": item.icon,
        size: "14"
      }
    }) : _vm._e(), _vm._v(_vm._s(item.title))], 1);
  }), 0) : _vm._e(), _vm._v(" "), _vm.activeTab === 0 ? _c("div", {
    staticClass: "pay-pen-notice"
  }, [_vm._m(0), _vm._v(" "), _vm._m(1)]) : _vm._e(), _vm._v(" "), _vm.activeTab === 2 && _vm.activeChildren === 1 ? _c("div", {
    staticClass: "pay-unpaid-notice"
  }, [_vm._m(2)]) : _vm._e(), _vm._v(" "), _vm.activeTab === 3 && _vm.activeChildren === 2 && _vm.deliveredStatus === 1 ? _c("van-notice-bar", {
    staticClass: "notice",
    attrs: {
      wrapable: "",
      scrollable: false
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c("div", [_c("span", {
          staticClass: "theme-color"
        }, [_vm._v("△Small weight goods need to pay part of the international freight in advance.")]), _vm._v(" It's the estimated cost, and the logistics company will make a supplement after settlement for the delivery order.")])];
      },
      proxy: true
    }], null, false, 527388670)
  }) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "page-background-color"
  }, [_c("van-tabs", {
    attrs: {
      animated: "",
      swipeable: ""
    },
    on: {
      change: _vm.changeTab
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("van-tab", {
    attrs: {
      title: "All",
      name: -1
    }
  }, [_vm.activeTab === -1 ? _c("TabRefresh", {
    key: _vm.roundIndex,
    attrs: {
      "is-show-time-out-bg": true,
      "actions-index": _vm.actionsIndex,
      "active-tab": _vm.activeTab,
      types: _vm.types
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("van-tab", {
    attrs: {
      title: "Additional Payment",
      name: 8
    }
  }, [_vm.activeTab === 8 ? _c("TabRefresh", {
    key: _vm.roundIndex,
    attrs: {
      "actions-index": _vm.actionsIndex,
      "active-tab": _vm.activeTab,
      types: _vm.types
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("van-tab", {
    staticClass: "isPayPenNoticeShow",
    attrs: {
      title: "Payment Pending",
      name: 0
    }
  }, [_vm.activeTab === 0 ? _c("TabRefresh", {
    key: _vm.roundIndex,
    attrs: {
      "actions-index": _vm.actionsIndex,
      "active-tab": _vm.activeTab,
      types: _vm.types
    },
    on: {
      dataList: _vm.getDataList
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("van-tab", {
    staticClass: "isTabChild",
    attrs: {
      title: "Warehouse Pending",
      name: 1
    }
  }, [_vm.activeTab === 1 ? _c("TabRefresh", {
    key: _vm.roundIndex,
    attrs: {
      "actions-index": _vm.actionsIndex,
      "active-tab": _vm.activeTab,
      types: _vm.types,
      "invoice-status": _vm.activeChildren
    },
    on: {
      getTotalCount: _vm.getTotalCount
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("van-tab", {
    class: {
      isTabChild: _vm.showUnpaid,
      isPayUnpaidNoticeShow: _vm.showUnpaid && _vm.activeChildren === 1
    },
    attrs: {
      title: "Global Warehouse",
      name: 2
    }
  }, [_vm.activeTab === 2 ? _c("TabRefresh", {
    key: _vm.roundIndex,
    attrs: {
      "actions-index": _vm.actionsIndex,
      "active-tab": _vm.activeTab,
      types: _vm.types,
      "invoice-status": _vm.activeChildren,
      "checked-all": _vm.checkedAll
    },
    on: {
      getTotalCount: _vm.getTotalCount,
      getTotalWeight: _vm.getTotalWeight,
      dataList: _vm.getDataList
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("van-tab", {
    class: {
      isTabChild: true,
      isTabGrandChild: _vm.activeChildren === 2,
      isNoticeShow: _vm.activeTab === 3 && _vm.activeChildren === 2 && _vm.deliveredStatus === 1
    },
    attrs: {
      title: "Delivered Reviews",
      name: 3
    }
  }, [_vm.activeTab === 3 ? _c("TabRefresh", {
    key: _vm.roundIndex,
    attrs: {
      "actions-index": _vm.actionsIndex,
      "active-tab": _vm.activeTab,
      types: _vm.types,
      "invoice-status": _vm.activeChildren,
      "delivered-status": _vm.deliveredStatus === 1 ? 2 : _vm.deliveredStatus === 2 ? 0 : 1
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("van-tab", {
    attrs: {
      title: "Pending Pickup",
      name: 9
    }
  }, [_vm.activeTab === 9 ? _c("TabRefresh", {
    key: _vm.roundIndex,
    attrs: {
      "is-show-time-out-bg": true,
      "actions-index": _vm.actionsIndex,
      "active-tab": _vm.activeTab,
      "order-ids": _vm.orderIds
    },
    on: {
      getPackageStoreWareList: _vm.getPackageStoreWareList
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("van-tab", {
    attrs: {
      title: "Picked Up",
      name: 4
    }
  }, [_vm.activeTab === 4 ? _c("TabRefresh", {
    key: _vm.roundIndex,
    attrs: {
      "actions-index": _vm.actionsIndex,
      "active-tab": _vm.activeTab,
      types: _vm.types
    }
  }) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _c("van-action-sheet", {
    attrs: {
      actions: _vm.actionsList,
      duration: "0.2",
      title: _vm.activeTab === 9 ? "Search by pickup address" : "",
      "close-on-click-action": "",
      "close-on-popstate": "",
      "safe-area-inset-bottom": ""
    },
    on: {
      select: _vm.onSelectAction
    },
    model: {
      value: _vm.actionFilterShow,
      callback: function callback($$v) {
        _vm.actionFilterShow = $$v;
      },
      expression: "actionFilterShow"
    }
  }), _vm._v(" "), _vm.activeTab === 2 ? _c("PayPassword", {
    attrs: {
      "show-pay-password": _vm.showKeyboard,
      "total-amount": _vm.order.totalPriceNGN || null
    },
    on: {
      showPayPassword: _vm.showPayPassword,
      payPasswordDown: _vm.payPasswordDown
    }
  }) : _vm.activeTab === 3 ? _c("PayPassword", {
    attrs: {
      "show-pay-password": _vm.showKeyboard,
      "total-amount": _vm.order.totalPriceNGN || null
    },
    on: {
      showPayPassword: _vm.showPayPassword,
      payPasswordDown: _vm.payPasswordDown
    }
  }) : _c("PayPassword", {
    attrs: {
      "show-pay-password": _vm.showKeyboard,
      "total-amount": Number(_vm.order.totalPriceNGN || null) - Number(_vm.order.commissionOffsetPrice ? Number(_vm.order.commissionOffsetPrice) < Number(_vm.user.commissionBalance) ? _vm.order.commissionOffsetPrice : _vm.user.commissionBalance : null)
    },
    on: {
      showPayPassword: _vm.showPayPassword,
      payPasswordDown: _vm.payPasswordDown
    }
  }), _vm._v(" "), [2].includes(_vm.activeTab) ? _c("div", {
    staticClass: "my-warehouse-footer-btn"
  }, [_vm.activeChildren === 1 ? [_c("div", [_vm._v("Total amount："), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("₦ " + _vm._s(_vm._f("numberToCurrencyNo")(_vm.selectTotalAmount)))])]), _vm._v(" "), _c("van-button", {
    attrs: {
      color: "#EA3340"
    },
    on: {
      click: _vm.paySelectOrder
    }
  }, [_vm._v("Pay now")])] : _vm._e(), _vm._v(" "), _vm.activeChildren === 2 ? [_c("div", [_c("van-checkbox", {
    attrs: {
      "icon-size": "18",
      "checked-color": "#EA3340"
    },
    on: {
      click: _vm.handleCheckbox
    },
    model: {
      value: _vm.checkedAll,
      callback: function callback($$v) {
        _vm.checkedAll = $$v;
      },
      expression: "checkedAll"
    }
  }), _vm._v("Select all")], 1), _vm._v(" "), _vm.myWarehouseSelectCount ? _c("div", {
    staticClass: "weight"
  }, [_vm._v("Estimated weight："), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v(_vm._s(_vm.myWarehouseSelectTotalEstimateWeight))]), _vm._v("KG")]) : _vm._e(), _vm._v(" "), _vm.myWarehouseSelectCount >= 0 ? _c("van-button", {
    attrs: {
      color: "#EA3340",
      disabled: !_vm.myWarehouseSelectCount
    },
    on: {
      click: _vm.goDelivery
    }
  }, [_vm._v("Delivery(" + _vm._s(_vm.myWarehouseSelectCount) + "/" + _vm._s(_vm.totalCount) + ")")]) : _vm._e()] : _vm._e()], 2) : _vm._e(), _vm._v(" "), _c("FreightFeePopupItem", {
    attrs: {
      show: _vm.freightFeeShow,
      "sensitive-line-list": _vm.sensitiveLineList,
      sensitive: _vm.sensitive,
      "select-weight": _vm.myWarehouseSelectTotalEstimateWeight,
      "select-order-id-list": _vm.selectDeliveryOrderIdList,
      "except-order-id-list": _vm.exceptDeliveryOrderIdList
    },
    on: {
      show: function show($event) {
        _vm.freightFeeShow = false;
      }
    }
  })], 2);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_vm._v("Due to upgrade, old pending orders cannot be paid. Please "), _c("span", [_vm._v("copy the product link")]), _vm._v(" to the homepage to order.")]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_vm._v("And now you can get our discount coupons on homepage to "), _c("span", [_vm._v("make your orders cheaper")]), _vm._v("!")]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_vm._v("Upgrade activity: "), _c("span", [_vm._v("Some")]), _vm._v(" forwarding orders "), _c("span", [_vm._v("can be free")]), _vm._v(" for storage fees. Please contact your agent for confirmation.")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;