"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addArtificialRecharge = addArtificialRecharge;
exports.commissionRecharge = commissionRecharge;
exports.getAndroidLink = getAndroidLink;
exports.getArtificialRechargeList = getArtificialRechargeList;
exports.getCategoryTreeList = getCategoryTreeList;
exports.getConfigList = getConfigList;
exports.getExchangeRateList = getExchangeRateList;
exports.getInternationalLogisticsTrack = getInternationalLogisticsTrack;
exports.getPay = getPay;
exports.getPayInfos = getPayInfos;
exports.getRelationshipList = getRelationshipList;
exports.getSkycargoExchangeRateList = getSkycargoExchangeRateList;
exports.getSkycargoLogisticsTrack = getSkycargoLogisticsTrack;
exports.transportOrder = transportOrder;

var _request = _interopRequireDefault(require("@/utils/request"));

// 获取Android最新版下载地址
function getAndroidLink() {
  return (0, _request.default)({
    url: 'common-service/app-update/latest',
    method: 'get'
  });
} // 获取支付信息列表


function getPayInfos(params) {
  return (0, _request.default)({
    url: 'pay-service/pay/pay_infos',
    method: 'get',
    params: params
  });
} // 充值交易


function getPay(params) {
  return (0, _request.default)({
    url: 'pay-service/pay',
    method: 'get',
    params: params
  });
} // 佣金充值


function commissionRecharge(data) {
  return (0, _request.default)({
    url: 'base_service/commission_recharge',
    method: 'post',
    data: data
  });
} // 转运接口


function transportOrder(data) {
  return (0, _request.default)({
    url: 'tms-service/proc-order/create-app',
    method: 'post',
    data: data
  });
} // 提交人工充值审批单


function addArtificialRecharge(data) {
  return (0, _request.default)({
    url: 'base_service/artificial_recharge',
    method: 'post',
    data: data
  });
} // 人工充值审批单记录


function getArtificialRechargeList(params) {
  return (0, _request.default)({
    url: 'base_service/artificial_recharge',
    method: 'get',
    params: params
  });
} // 汇率列表


function getExchangeRateList() {
  return (0, _request.default)({
    url: 'base_service/exchange_rate',
    method: 'get'
  });
} // 安宏汇率列表


function getSkycargoExchangeRateList(params) {
  return (0, _request.default)({
    url: 'wms-service/wms/rate/byApp',
    method: 'get',
    params: params
  });
} // 查询配置列表


function getConfigList(parentid) {
  return (0, _request.default)({
    url: 'common-service/sysConfig/list/' + parentid,
    method: 'get'
  });
} // 查询业务与客户关系列表


function getRelationshipList(data) {
  return (0, _request.default)({
    url: 'order-service/business-customer-relation/list',
    method: 'post',
    data: data
  });
} // 查询类目树形列表


function getCategoryTreeList() {
  return (0, _request.default)({
    url: 'tms-service/product/category/list/tree',
    method: 'post'
  });
} // 安宏物流轨迹查询


function getSkycargoLogisticsTrack(params) {
  return (0, _request.default)({
    url: 'wms-service/v1/logisticsTrack',
    method: 'get',
    baseURL: process.env.VUE_APP_SKYCARGO_BASE_API,
    params: params
  });
} // 国际物流轨迹查询


function getInternationalLogisticsTrack(data) {
  return (0, _request.default)({
    url: 'wtms-service/alibaba/logistics',
    method: 'post',
    data: data
  });
}