"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "My Team",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "team-number"
  }, [_c("div", {
    staticClass: "name"
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/my_gain/my_team.png"),
      size: "16"
    }
  }), _vm._v("Number of teams")], 1), _vm._v(" "), _c("div", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.fansCount))])])]), _vm._v(" "), _c("div", {
    staticClass: "table"
  }, [_vm._m(0), _vm._v(" "), _c("van-divider", {
    style: {
      borderColor: "#D1D1D1",
      margin: "16px 0 0"
    }
  }), _vm._v(" "), _vm.fansList.length ? _c("div", {
    staticClass: "main"
  }, [_vm._l(_vm.fansList, function (item, index) {
    return [_c("div", {
      key: "cell" + index,
      staticClass: "td",
      on: {
        click: function click($event) {
          return _vm.$set(item, "checked", !item.checked);
        }
      }
    }, [_c("div", {
      staticClass: "van-ellipsis"
    }, [_vm._v(_vm._s(item.userName.substring(0, 1)) + "***" + _vm._s(item.userName.substr(item.userName.length - 1)))]), _vm._v(" "), _c("div", {
      staticClass: "van-ellipsis pink"
    }, [_vm._v("₦" + _vm._s(Number(item.alreadyCommission).toFixed(2)))]), _vm._v(" "), _c("div", {
      staticClass: "van-ellipsis pink"
    }, [_vm._v("₦" + _vm._s(Number(item.previewCommission).toFixed(2)))])]), _vm._v(" "), item.checked ? _vm._l(item.orderCommissionFansSubordinate, function (childItem, i) {
      return _c("div", {
        key: "child" + i,
        staticClass: "child-td"
      }, [_c("div", {
        staticClass: "van-ellipsis"
      }, [_c("van-icon", {
        attrs: {
          name: i === 0 ? require("@/assets/images/my_gain/father.png") : require("@/assets/images/my_gain/son.png"),
          size: "6"
        }
      }), _vm._v(_vm._s(childItem.userName.substring(0, 1)) + "***" + _vm._s(childItem.userName.substr(childItem.userName.length - 1)))], 1), _vm._v(" "), _c("div", {
        staticClass: "van-ellipsis pink"
      }, [_vm._v("₦" + _vm._s(Number(childItem.alreadyCommission).toFixed(2)))]), _vm._v(" "), _c("div", {
        staticClass: "van-ellipsis pink"
      }, [_vm._v("₦" + _vm._s(Number(childItem.previewCommission).toFixed(2)))])]);
    }) : _vm._e(), _vm._v(" "), _c("van-divider", {
      key: "divider" + index,
      style: {
        borderColor: "#E8E8E8",
        margin: "0"
      }
    })];
  })], 2) : _vm._e()], 1)], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "td"
  }, [_c("div", [_vm._v("Name")]), _vm._v(" "), _c("div", [_vm._v("Gained")]), _vm._v(" "), _c("div", [_vm._v("Upcoming")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;