"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "logo"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/logo.png"),
      alt: "default_logo"
    }
  })])]), _vm._v(" "), _c("van-form", {
    ref: "form",
    attrs: {
      "validate-first": ""
    },
    on: {
      failed: _vm.onFailed,
      submit: _vm.submit
    }
  }, [_c("van-field", {
    attrs: {
      name: "account",
      placeholder: "Email address",
      autocomplete: "off",
      "left-icon": "envelop-o",
      rules: [{
        required: true,
        message: "UserAccount can not be empty"
      }]
    },
    on: {
      focus: function focus($event) {
        _vm.emailShow = !_vm.emailShow;
      },
      blur: _vm.handleBlur
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("van-button", {
          staticStyle: {
            "border-radius": "6px",
            width: "80px"
          },
          attrs: {
            disabled: _vm.counter ? true : false,
            color: "#EA3340",
            size: "small",
            type: "primary",
            plain: "",
            "native-type": "button"
          },
          on: {
            click: _vm.getCode
          }
        }, [_vm._v(_vm._s(_vm.counter ? _vm.counter : "Get Code"))])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.account,
      callback: function callback($$v) {
        _vm.account = $$v;
      },
      expression: "account"
    }
  }), _vm._v(" "), _vm.emailShow ? _c("div", {
    staticClass: "hintBox"
  }, _vm._l(_vm.emails, function (mail) {
    return _c("div", {
      key: mail,
      staticClass: "hintItem",
      on: {
        click: function click($event) {
          return _vm.setInput(mail);
        }
      }
    }, [_vm._v(_vm._s(mail))]);
  }), 0) : _vm._e(), _vm._v(" "), _c("van-field", {
    attrs: {
      name: "verifyCode",
      "left-icon": "shield-o",
      placeholder: "Enter Email Code",
      rules: [{
        required: true,
        message: "Email Code can not be empty"
      }, {
        pattern: /^\d{6}$/,
        message: "Email Code must be 6 digits"
      }]
    },
    model: {
      value: _vm.verifyCode,
      callback: function callback($$v) {
        _vm.verifyCode = $$v;
      },
      expression: "verifyCode"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      name: "phone",
      "left-icon": "phone-o",
      placeholder: "Phone number",
      rules: [{
        required: true,
        message: "Mobile phone number can not be empty"
      }, {
        validator: _vm.validatorTel,
        message: "Please enter the correct phone number"
      }]
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      type: "password",
      name: "tmpPassword",
      "left-icon": "lock",
      placeholder: "Set password",
      autocomplete: "new-password",
      rules: [{
        required: true,
        message: "SetPassword can not be empty"
      }, {
        validator: _vm.validPassword,
        message: "Must be a combination of 8-20 English letters, numbers or symbols"
      }]
    },
    model: {
      value: _vm.tmpPassword,
      callback: function callback($$v) {
        _vm.tmpPassword = $$v;
      },
      expression: "tmpPassword"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      type: "password",
      name: "password",
      "left-icon": "lock",
      placeholder: "Retype password",
      autocomplete: "new-password",
      rules: [{
        validator: _vm.comparePassword,
        message: "Inconsistent passwords"
      }]
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "16px"
    }
  }, [_c("van-button", {
    attrs: {
      round: "",
      block: "",
      color: "#EA3340",
      "native-type": "submit"
    }
  }, [_vm._v("Sigh up")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "more-wrap"
  }, [_vm._v("I have an account "), _c("router-link", {
    staticStyle: {
      color: "red"
    },
    attrs: {
      to: "/login"
    }
  }, [_vm._v("Login in")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;