"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "checkOut-view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Shopping Cart",
      "safe-area-inset-top": "",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "right",
      fn: function fn() {
        return [_c("span", {
          on: {
            click: function click($event) {
              _vm.deleteShow = !_vm.deleteShow;
            }
          }
        }, [_vm._v(_vm._s(_vm.deleteShow ? "Done" : "Delete"))])];
      },
      proxy: true
    }])
  }), _vm._v(" "), _vm.couponTipObj ? _c("van-notice-bar", {
    attrs: {
      background: "#FFF7F7",
      color: "#000",
      "left-icon": require("@/assets/images/coupon/good_content_coupon.png")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_vm.couponTipObj.coupon.type === 1 ? _c("div", [_vm._v(_vm._s(_vm.defaultCurrency.unit) + _vm._s(_vm._f("CNYToCurrencyComma")(_vm.couponTipObj.coupon.subtract)) + " off on purchase of " + _vm._s(_vm.defaultCurrency.unit) + _vm._s(_vm._f("CNYToCurrencyComma")(_vm.couponTipObj.coupon.reach)) + " or above")]) : _vm.couponTipObj.coupon.type === 2 ? _c("div", [_vm._v(_vm._s(100 - _vm.couponTipObj.coupon.discount) + "% off for items")]) : _vm.couponTipObj.coupon.type === 3 ? _c("div", [_vm._v(_vm._s(_vm.defaultCurrency.unit) + _vm._s(_vm._f("CNYToCurrencyComma")(_vm.couponTipObj.coupon.voucher)) + " off for particular goods")]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "right-icon",
      fn: function fn() {
        return [_c("span", {
          staticClass: "right-icon",
          on: {
            click: function click($event) {
              return _vm.$router.push("/myCoupon");
            }
          }
        }, [_vm._v("go")])];
      },
      proxy: true
    }], null, false, 2833135539)
  }) : _vm._e(), _vm._v(" "), _c("van-pull-refresh", {
    attrs: {
      "pulling-text": "Pull down refresh",
      "loosing-text": "Release to refresh",
      "loading-text": "Refreshing",
      "success-text": "Refresh completed"
    },
    on: {
      refresh: _vm.onRefresh
    },
    model: {
      value: _vm.refreshing,
      callback: function callback($$v) {
        _vm.refreshing = $$v;
      },
      expression: "refreshing"
    }
  }, [_vm.cartList.length ? _c("van-list", {
    attrs: {
      finished: _vm.finished,
      "finished-text": "No More ...",
      "immediate-check": false
    },
    on: {
      load: _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.cartList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "box"
    }, [item.pickupWarehouse && item.pickupWarehouse.id ? _c("div", {
      staticClass: "pick-title"
    }, [_c("van-icon", {
      attrs: {
        size: "18",
        name: "location-o"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.pickupWarehouse.nameEn))])], 1) : _vm._e(), _vm._v(" "), _c("div", {
      staticClass: "title"
    }, [_c("van-checkbox", {
      attrs: {
        "icon-size": "18",
        "checked-color": "#EA3340",
        disabled: _vm.deleteShow || item.invalid === 1 ? false : true
      },
      on: {
        click: function click($event) {
          return _vm.toggleAllCheckbox("checkboxGroup".concat(index), item.checkedAll);
        }
      },
      model: {
        value: item.checkedAll,
        callback: function callback($$v) {
          _vm.$set(item, "checkedAll", $$v);
        },
        expression: "item.checkedAll"
      }
    }), _vm._v(" "), _c("van-icon", {
      attrs: {
        size: "18",
        name: item.type === 1 ? require("@/assets/images/cart/wo_store.png") : item.type === 2 ? require("@/assets/images/cart/common_store.png") : ""
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.sellerCompanyName))]), _vm._v(" "), _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.deleteShow,
        expression: "!deleteShow"
      }],
      on: {
        click: function click($event) {
          return _vm.handleRevise(index);
        }
      }
    }, [_vm._v(_vm._s(item.reviseShow ? "Done" : "Revise"))])], 1), _vm._v(" "), _c("div", {
      staticClass: "content"
    }, [_c("van-checkbox-group", {
      ref: "checkboxGroup".concat(index),
      refInFor: true,
      model: {
        value: item.checkedArr,
        callback: function callback($$v) {
          _vm.$set(item, "checkedArr", $$v);
        },
        expression: "item.checkedArr"
      }
    }, _vm._l(item.cartDetails, function (childItem, childIndex) {
      return _c("van-checkbox", {
        key: childIndex,
        attrs: {
          name: childItem,
          disabled: _vm.deleteShow ? true : item.invalid === 1 ? false : true,
          "icon-size": "18",
          "checked-color": "#EA3340",
          "label-disabled": ""
        },
        on: {
          click: function click($event) {
            return _vm.changeCheckbox(index);
          }
        }
      }, [_c("van-card", {
        attrs: {
          thumb: childItem.imageUrl
        },
        on: {
          "click-thumb": function clickThumb($event) {
            return _vm.toGoodDetailPage(item.type, childItem, item.pickupWarehouse);
          }
        },
        scopedSlots: _vm._u([{
          key: "title",
          fn: function fn() {
            return [_c("div", {
              staticClass: "van-multi-ellipsis--l2 my-title-tag"
            }, [item.supplyType === 2 ? _c("span", {
              staticClass: "my-tag"
            }, [_vm._v("Goods in Nigeria")]) : _vm._e(), _c("span", {
              class: {
                "disabled-color": item.invalid === 1 ? false : true
              }
            }, [_vm._v(_vm._s(childItem.goodsNameEn))])])];
          },
          proxy: true
        }, item.invalid === 1 ? {
          key: "price",
          fn: function fn() {
            return [!item.reviseShow ? [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(childItem.unitPrice))), _c("span", {
              staticClass: "piece"
            }, [_vm._v(" /Piece")])] : [_c("van-stepper", {
              attrs: {
                min: childItem.minQuantity || 1,
                step: childItem.batchNumber,
                "input-width": "80",
                "button-size": "20",
                integer: ""
              },
              model: {
                value: childItem.quantity,
                callback: function callback($$v) {
                  _vm.$set(childItem, "quantity", $$v);
                },
                expression: "childItem.quantity"
              }
            })]];
          },
          proxy: true
        } : null, {
          key: "tags",
          fn: function fn() {
            return [item.invalid === 1 ? _c("div", {
              staticStyle: {
                display: "flex",
                marginTop: "14px"
              }
            }, [_c("van-tag", {
              attrs: {
                round: "",
                color: "#f0f0f0",
                "text-color": "#727272"
              }
            }, [_vm._v(_vm._s(_vm._f("getSkuInfosEn")(childItem.skuInfosEn && JSON.parse(childItem.skuInfosEn))))])], 1) : _c("div", {
              staticStyle: {
                lineHeight: "12px",
                marginTop: "14px",
                fontSize: "10px"
              }
            }, [_vm._v("The product is invalid, please select again")])];
          },
          proxy: true
        }, {
          key: "num",
          fn: function fn() {
            return [!item.reviseShow ? [item.invalid === 1 ? _c("div", [_vm._v("x" + _vm._s(childItem.quantity))]) : _c("van-tag", {
              attrs: {
                round: "",
                plain: "",
                color: "#EA3340"
              },
              on: {
                click: function click($event) {
                  return _vm.toGoodDetailPage(item.type, childItem, item.pickupWarehouse);
                }
              }
            }, [_vm._v("reselect")])] : _vm._e()];
          },
          proxy: true
        }], null, true)
      }), _vm._v(" "), item.reviseShow ? _c("div", {
        staticClass: "pro-del",
        attrs: {
          block: ""
        },
        on: {
          click: function click($event) {
            return _vm.handleDelete(childItem.id, index, childIndex);
          }
        }
      }, [_vm._v("Delete")]) : _vm._e()], 1);
    }), 1), _vm._v(" "), _c("div", {
      staticClass: "total"
    }, [_vm._v("Subtotal:"), _c("span", {
      staticClass: "theme-color"
    }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(item.subTotal)))])])], 1)]);
  }), 0) : _c("van-empty", {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/no_data.png")
          }
        })];
      },
      proxy: true
    }, {
      key: "description",
      fn: function fn() {
        return [_vm._v("\n        No data for shopping cart\n      ")];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _vm._v(" "), _c("van-button", {
    staticClass: "bottom-button",
    attrs: {
      round: "",
      color: "RGBA(247, 248, 250)"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/selfSupport");
      }
    }
  }, [_vm._v("Browse products")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "submit-bar"
  }, [!_vm.showCouponPop ? _c("div", {
    staticClass: "submit-bar__tip"
  }, [_c("div", {
    staticClass: "coupon-tip"
  }, [_c("van-icon", {
    attrs: {
      name: "coupon-o",
      size: "18"
    }
  }), _c("div", [_vm._v("View available discounts")]), _c("van-button", {
    staticClass: "submit-go",
    attrs: {
      round: "",
      size: "mini"
    },
    on: {
      click: _vm.openCoupon
    }
  }, [_vm._v("go")])], 1)]) : _vm._e(), _vm._v(" "), !_vm.deleteShow ? _c("div", {
    staticClass: "submit-bar__bar"
  }, [_c("div", {
    staticClass: "submit-bar__text"
  }, [_c("div", [_c("span", [_vm._v("Total Amount：")]), _vm._v(" "), _c("span", {
    staticClass: "submit-bar__price"
  }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.getTotalAmout)))])]), _vm._v(" "), _c("div", [_vm._v("Just only Commodity price")])]), _vm._v(" "), _c("van-button", {
    attrs: {
      disabled: !_vm.getCheckTotal,
      color: "linear-gradient(to right, #ff6034, #ee0a24)",
      round: ""
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v("\n        Checkout" + _vm._s(_vm.getCheckTotal ? "(".concat(_vm.getCheckTotal, ")") : "") + "\n      ")])], 1) : _c("van-button", {
    staticStyle: {
      height: "50px"
    },
    attrs: {
      block: "",
      color: "#EA3340"
    },
    on: {
      click: function click($event) {
        return _vm.handleDelete("all");
      }
    }
  }, [_vm._v("Delete")])], 1), _vm._v(" "), _c("van-popup", {
    staticStyle: {
      height: "80%",
      padding: "16px 16px 100px"
    },
    attrs: {
      round: "",
      position: "bottom",
      closeable: "",
      "close-on-popstate": "",
      "safe-area-inset-bottom": ""
    },
    model: {
      value: _vm.showCouponPop,
      callback: function callback($$v) {
        _vm.showCouponPop = $$v;
      },
      expression: "showCouponPop"
    }
  }, [_c("div", {
    staticClass: "coupon-title"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("Coupon")]), _vm._v(" "), _c("div", [_vm.couponList.length && _vm.couponList[_vm.selectCouponIndex] ? [_vm._v("\n          You save: " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm.couponList[_vm.selectCouponIndex].discountedPrice)) + "\n        ")] : [_vm._v(" ")]], 2)]), _vm._v(" "), _c("van-divider", {
    style: {
      margin: "6px 0"
    }
  }), _vm._v(" "), _vm.couponList.length ? _c("CouponList", {
    staticClass: "coupon-content",
    attrs: {
      list: _vm.couponList
    },
    on: {
      selectCoupon: _vm.selectCoupon
    }
  }) : _c("div", {
    staticClass: "noData"
  }, [_vm._v("Sorry, no discounts are currently available!")])], 1), _vm._v(" "), _c("FooterTabbar", {
    staticStyle: {
      zIndex: "9999"
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;