"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/card/style");

var _card = _interopRequireDefault(require("vant/es/card"));

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

require("vant/es/sticky/style");

var _sticky = _interopRequireDefault(require("vant/es/sticky"));

require("vant/es/empty/style");

var _empty = _interopRequireDefault(require("vant/es/empty"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/list/style");

var _list = _interopRequireDefault(require("vant/es/list"));

require("vant/es/pull-refresh/style");

var _pullRefresh = _interopRequireDefault(require("vant/es/pull-refresh"));

require("vant/es/search/style");

var _search = _interopRequireDefault(require("vant/es/search"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _goods = require("@/api/goods.js");

var _auth = require("@/utils/auth");

var _components;

var _default = {
  name: "TopSale",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _search.default.name, _search.default), (0, _defineProperty2.default)(_components, _pullRefresh.default.name, _pullRefresh.default), (0, _defineProperty2.default)(_components, _list.default.name, _list.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _empty.default.name, _empty.default), (0, _defineProperty2.default)(_components, _sticky.default.name, _sticky.default), (0, _defineProperty2.default)(_components, _image.default.name, _image.default), (0, _defineProperty2.default)(_components, _card.default.name, _card.default), _components),
  data: function data() {
    return {
      keyword: "",
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      isLogin: false,
      pageNum: 1
    };
  },
  watch: {
    $route: {
      handler: function handler(val, oldVal) {
        // 因缓存刷新页面
        if (val.name === "TopSale" && (oldVal === null || oldVal === void 0 ? void 0 : oldVal.name) !== "GoodsContent") {
          this.onRefresh();
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.isLogin = Boolean((0, _auth.getToken)());
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      (0, _goods.getPmsSpu)({
        screen: "hotSale",
        sort: 2,
        modules: 1,
        pageNum: this.pageNum,
        pageSize: 10
      }).then(function (res) {
        var _res$data, _res$data$data;

        _this.loading = false;
        _this.refreshing = false;

        if (((_res$data = res.data) === null || _res$data === void 0 ? void 0 : (_res$data$data = _res$data.data) === null || _res$data$data === void 0 ? void 0 : _res$data$data.length) > 0) {
          if (_this.pageNum === 1) {
            _this.list = res.data.data;
          } else {
            _this.list = _this.list.concat(res.data.data);
          }
        } else {
          if (_this.pageNum === 1) _this.list = [];
          _this.finished = true;
        }
      });
    },
    toGoodDetailPage: function toGoodDetailPage(item) {
      var _arguments = {
        "url": item.module === 1 ? item.spuId : item.spLink,
        "module": item.module
      };
      if (!_arguments.url) return;
      this.$router.push({
        path: "/GoodsContent",
        query: _arguments
      });
    },
    onLoad: function onLoad() {
      this.pageNum++;
      this.fetchData();
    },
    // 下拉刷新
    onRefresh: function onRefresh() {
      this.list = [];
      this.finished = false;
      this.pageNum = 1;
      this.fetchData();
    }
  }
};
exports.default = _default;