"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "price-com"
  }, [_vm.goodsContent.goodsSource === 2 ? _c("div", [_vm.productInfo.saleInfo.priceRanges.length > 1 ? _c("div", [_c("van-row", {
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, _vm._l(_vm.productInfo.saleInfo.priceRanges, function (aliPriceItem, index) {
    return _c("van-col", {
      key: index,
      attrs: {
        span: "12",
        justify: "center",
        align: "center"
      }
    }, [_c("div", {
      staticClass: "price-range"
    }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(aliPriceItem.price)))]), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "2px"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "start-quantity"
    }, [_vm._v(_vm._s(_vm._f("filtersStartQuantity")(aliPriceItem.startQuantity, index, _vm.productInfo.saleInfo.priceRanges)))])]);
  }), 1)], 1) : _c("div", [_c("van-row", {
    attrs: {
      type: "flex",
      justify: "center"
    }
  }, [_c("van-col", {
    attrs: {
      justify: "center",
      align: "center"
    }
  }, [_c("div", {
    staticClass: "price-range",
    staticStyle: {
      "font-size": "30px",
      "font-weight": "400"
    }
  }, [_vm._v("\n            " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm.aliPriceRangeList[0])) + " " + _vm._s(_vm.aliPriceRangeList.length > 1 ? "-" : "") + " " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm._f("filtersLastPrice")(_vm.aliPriceRangeList))) + "\n          ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "4px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "start-quantity",
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("≥" + _vm._s(_vm.productInfo.saleInfo.priceRanges[0].startQuantity) + " Pieces")])])], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("van-row", {
    attrs: {
      type: "flex",
      justify: "center"
    }
  }, [_c("div", {
    staticClass: "f-12"
  }, [_vm._v("Min.Order: " + _vm._s(_vm.productInfo.saleInfo.priceRanges[0].startQuantity) + " Pieces")])])], 1) : _c("div", [_c("div", {
    staticClass: "papding-row",
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "flex-wrap": "wrap"
    }
  }, [_c("div", [_c("span", {
    staticClass: "price-range",
    staticStyle: {
      "font-size": "24px",
      "font-weight": "400"
    }
  }, [_vm._v("\n          " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm._f("filtersPmsLeftPrice")(_vm.productInfo))) + " \n          "), _vm._v("\n          " + _vm._s(_vm._f("filtersPmsLeftLine")(_vm.productInfo)) + " \n          "), _vm._v("\n          " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm._f("filtersPmsRightPrice")(_vm.productInfo))) + "\n        ")]), _vm._v(" "), _c("span", {
    staticClass: "original-price"
  }, [_vm._v("\n          " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm._f("filtersOfferPrice")(_vm.productInfo))) + "\n          "), _vm._v("\n          " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm._f("filtersShowPrice")(_vm.productInfo))) + "\n        ")])]), _vm._v(" "), _vm.goodsContent.goodsEntity.productInfo.saleInfo.minOrderQuantity ? _c("div", {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("MOQ：" + _vm._s(_vm.goodsContent.goodsEntity.productInfo.saleInfo.minOrderQuantity) + " pcs")]) : _vm._e()])])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;