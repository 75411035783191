"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/radio/style");

var _radio = _interopRequireDefault(require("vant/es/radio"));

require("vant/es/radio-group/style");

var _radioGroup = _interopRequireDefault(require("vant/es/radio-group"));

require("vant/es/notice-bar/style");

var _noticeBar = _interopRequireDefault(require("vant/es/notice-bar"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _cmmission = require("api/cmmission");

var _components;

var _default = {
  name: "SelectAccount",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _noticeBar.default.name, _noticeBar.default), (0, _defineProperty2.default)(_components, _radioGroup.default.name, _radioGroup.default), (0, _defineProperty2.default)(_components, _radio.default.name, _radio.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), _components),
  data: function data() {
    return {
      selectAccountId: "",
      withdrawAccountList: []
    };
  },
  watch: {
    $route: {
      handler: function handler(val, oldVal) {
        var _this$$route$query,
            _val$params,
            _this = this;

        // 因缓存刷新页面
        if ((_this$$route$query = this.$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.selectAccountId) {
          var _this$$route$query2;

          this.selectAccountId = (_this$$route$query2 = this.$route.query) === null || _this$$route$query2 === void 0 ? void 0 : _this$$route$query2.selectAccountId;
        }

        if ((_val$params = val.params) !== null && _val$params !== void 0 && _val$params.editSuccess) {
          (0, _toast.default)("Successfully");
          (0, _cmmission.getWithdrawAccountList)().then(function (res) {
            _this.withdrawAccountList = res.data || [];
          });
        }
      },
      immediate: true
    }
  },
  created: function created() {
    var _this2 = this;

    (0, _cmmission.getWithdrawAccountList)().then(function (res) {
      _this2.withdrawAccountList = res.data || [];
    });
  },
  methods: {
    // 后退
    onClickLeft: function onClickLeft() {
      var _this$$route$params;

      if ((_this$$route$params = this.$route.params) !== null && _this$$route$params !== void 0 && _this$$route$params.editSuccess) {
        this.$router.go(-2);
      } else {
        this.$router.go(-1);
      }
    }
  }
};
exports.default = _default;