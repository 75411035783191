"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/empty/style");

var _empty = _interopRequireDefault(require("vant/es/empty"));

require("vant/es/search/style");

var _search = _interopRequireDefault(require("vant/es/search"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _order = require("api/order");

var _TabList = _interopRequireDefault(require("./TabList"));

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "QueryOrder",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _search.default.name, _search.default), (0, _defineProperty2.default)(_components, _empty.default.name, _empty.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, "TabList", _TabList.default), _components),
  data: function data() {
    return {
      value: "",
      list: []
    };
  },
  created: function created() {
    var _this$$route$params,
        _this = this;

    if ((_this$$route$params = this.$route.params) !== null && _this$$route$params !== void 0 && _this$$route$params.id) {
      var _this$$route$params2, _this$$route$params3;

      this.value = (_this$$route$params2 = this.$route.params) === null || _this$$route$params2 === void 0 ? void 0 : _this$$route$params2.id;
      (0, _order.getOrderList)({
        id: (_this$$route$params3 = this.$route.params) === null || _this$$route$params3 === void 0 ? void 0 : _this$$route$params3.id,
        queryPage: 2,
        queryPremium: 2,
        queryRefund: 2,
        queryRefundOrder: 2,
        pageNum: this.pageNum,
        pageSize: 10
      }).then(function (res) {
        _this.list = _this.deepObj(res.data.data || []);
      });
    } else {
      this.$router.go(-1);
    }
  },
  methods: {
    // 组装为一个退款数组
    deepObj: function deepObj(list) {
      var _this2 = this;

      list === null || list === void 0 ? void 0 : list.forEach(function (tmp) {
        var _tmp$refunds, _tmp$orderDetails, _tmp$localGoodsRefund, _tmp$orderDetails2;

        if (tmp !== null && tmp !== void 0 && (_tmp$refunds = tmp.refunds) !== null && _tmp$refunds !== void 0 && _tmp$refunds.length && tmp !== null && tmp !== void 0 && (_tmp$orderDetails = tmp.orderDetails) !== null && _tmp$orderDetails !== void 0 && _tmp$orderDetails.length) {
          // 代购
          tmp.myRefunds = tmp.refunds.map(function (item) {
            _this2.$set(item, "myRefundDetails", item.refundDetails.map(function (item) {
              return _objectSpread(_objectSpread(_objectSpread({}, item), tmp.orderDetails.filter(function (ele) {
                return ele.orderDetailId === item.orderDetailId;
              })[0]), {}, {
                quantity2: item.quantity
              });
            }));

            return item;
          });
        } else if (tmp !== null && tmp !== void 0 && (_tmp$localGoodsRefund = tmp.localGoodsRefunds) !== null && _tmp$localGoodsRefund !== void 0 && _tmp$localGoodsRefund.length && tmp !== null && tmp !== void 0 && (_tmp$orderDetails2 = tmp.orderDetails) !== null && _tmp$orderDetails2 !== void 0 && _tmp$orderDetails2.length) {
          // 海外仓
          tmp.myRefunds = tmp.localGoodsRefunds.map(function (item) {
            _this2.$set(item, "status", item.status - 1);

            _this2.$set(item, "myRefundDetails", item.localGoodsRefundDetails.map(function (item) {
              return _objectSpread(_objectSpread(_objectSpread({}, item), tmp.orderDetails.filter(function (ele) {
                return ele.orderDetailId === item.orderDetailId;
              })[0]), {}, {
                quantity2: item.quantity
              });
            }));

            return item;
          });
        }
      });
      return list;
    }
  }
};
exports.default = _default;