"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/divider/style");

var _divider = _interopRequireDefault(require("vant/es/divider"));

require("vant/es/col/style");

var _col = _interopRequireDefault(require("vant/es/col"));

require("vant/es/row/style");

var _row = _interopRequireDefault(require("vant/es/row"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _api = require("api");

var _vuex = require("vuex");

var _utils = require("utils");

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "BankRechargeOne",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _row.default.name, _row.default), (0, _defineProperty2.default)(_components, _col.default.name, _col.default), (0, _defineProperty2.default)(_components, _divider.default.name, _divider.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), _components),
  data: function data() {
    return {
      bank: "",
      companyName: "",
      receivingAccount: ""
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    user: function user(state) {
      return state.user['user'];
    }
  })),
  created: function created() {
    var _this = this;

    (0, _api.getConfigList)("1229308509821091800").then(function (res) {
      res.data.forEach(function (element) {
        switch (element.sysKey) {
          case "COMPANY_NAME":
            _this.companyName = element.sysValue;
            break;

          case "RECEIVING_ACCOUNT":
            _this.receivingAccount = element.sysValue;
            break;

          case "BANK":
            _this.bank = element.sysValue;
            break;

          default:
            break;
        }
      });
    });
  },
  methods: {
    copyUserCode: function copyUserCode(val) {
      (0, _utils.copyTextToClipboard)(val);

      _toast.default.success('Copy success');
    },
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    }
  }
};
exports.default = _default;