"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/cell/style");

var _cell = _interopRequireDefault(require("vant/es/cell"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/field/style");

var _field = _interopRequireDefault(require("vant/es/field"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/form/style");

var _form = _interopRequireDefault(require("vant/es/form"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _vuex = require("vuex");

var _user = require("api/user");

var _cmmission = require("api/cmmission");

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "Withdrawal",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _form.default.name, _form.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _field.default.name, _field.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _cell.default.name, _cell.default), _components),
  data: function data() {
    return {
      commissionBalance: null,
      userCommissionInfo: {},
      formData: {
        amount: "",
        email: "",
        verifyCode: "",
        selectAccountItem: ""
      },
      counter: '',
      timer: null
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    user: function user(state) {
      return state.user['user'];
    }
  })),
  watch: {
    $route: {
      handler: function handler(val, oldVal) {
        var _val$params;

        // 因缓存刷新页面
        if (["MyGain"].includes(oldVal === null || oldVal === void 0 ? void 0 : oldVal.name) && val.name === "Withdrawal") {
          this.formData = {
            amount: "",
            email: "",
            verifyCode: "",
            selectAccountItem: ""
          };
        }

        if ((_val$params = val.params) !== null && _val$params !== void 0 && _val$params.accountItem) {
          this.$set(this.formData, "selectAccountItem", val.params.accountItem);
        }

        clearInterval(this.timer);
        this.counter = "";
      },
      immediate: true
    }
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    if (!from.name || from.name === "MyGain") {
      var _to$query;

      // 判断是否有携带佣金金额
      if ((_to$query = to.query) !== null && _to$query !== void 0 && _to$query.commissionBalance) {
        next(function (vm) {
          var _to$query2;

          if ((_to$query2 = to.query) !== null && _to$query2 !== void 0 && _to$query2.commissionBalance) {
            vm.commissionBalance = Number(to.query.commissionBalance).toFixed(2);
          }
        });
      } else {
        next({
          path: '/myGain'
        });
      }
    } else {
      next();
    }
  },
  created: function created() {
    var _this = this;

    (0, _cmmission.getCommissionInfo)().then(function (res) {
      _this.userCommissionInfo = res.data;
    });
  },
  methods: {
    validAmount: function validAmount() {
      return +this.formData.amount >= +(this.userCommissionInfo.canWithdrawLimit || 0);
    },
    validEmail: function validEmail() {
      return this.user.email === this.formData.email;
    },
    // 金额两位小数
    formatterAmount: function formatterAmount(val) {
      var _val$split$;

      if (((_val$split$ = val.split(".")[1]) === null || _val$split$ === void 0 ? void 0 : _val$split$.length) > 2) {
        return val.substr(0, val.length - 1);
      } else {
        return val;
      }
    },
    // 获取邮箱验证码
    getCode: function getCode() {
      var _this2 = this;

      this.$refs['form'].validate('email').then(function (_) {
        (0, _user.getCode)(_this2.formData.email).then(function (res) {
          _this2.countDown();

          _toast.default.success(res.message);
        });
      }).catch(function (e) {
        console.log(e);
      });
    },
    // 选择银行卡
    handleSelectAccount: function handleSelectAccount() {
      var _this3 = this;

      this.$refs['form'].validate(['email', 'verifyCode']).then(function (_) {
        _this3.$router.push({
          path: '/selectAccount',
          query: {
            selectAccountId: _this3.formData.selectAccountItem ? _this3.formData.selectAccountItem.id : null
          }
        });
      }).catch(function (e) {
        console.log(e);
      }); // this.$router.push({ path: '/selectAccount', query: { selectAccountId: this.formData.selectAccountItem ? this.formData.selectAccountItem.id : null }})
    },
    // 提交
    onSubmit: function onSubmit(values) {
      var _this4 = this;

      if (!this.formData.selectAccountItem) return _toast.default.fail("Please enter receiving bank account");
      console.log('submit', values);
      (0, _cmmission.commissionWithdrawal)({
        accountId: this.formData.selectAccountItem.id,
        amount: values.amount,
        email: values.email,
        verifyCode: values.verifyCode
      }).then(function (res) {
        _this4.$router.replace({
          name: "WithdrawalSuccess"
        });
      });
    },
    // 倒计时
    countDown: function countDown() {
      var _this5 = this;

      this.counter = 60;
      this.timer = setInterval(function () {
        _this5.counter--;

        if (_this5.counter === 0) {
          // 当计时等于零时，取消该计时器
          clearInterval(_this5.timer);
        }
      }, 1000);
    },
    // 后退
    onClickLeft: function onClickLeft() {
      var _this$$route$params;

      if ((_this$$route$params = this.$route.params) !== null && _this$$route$params !== void 0 && _this$$route$params.accountItem) {
        this.$router.go(-2);
      } else {
        this.$router.go(-1);
      }
    }
  }
};
exports.default = _default;