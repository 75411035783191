"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticStyle: {
      overflowY: "scroll",
      paddingBottom: "46px"
    }
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Bnak transfer recharge",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("header", [_c("div", {
    staticClass: "photo"
  }, [_c("van-icon", {
    attrs: {
      name: "credit-pay",
      size: "24"
    }
  }), _vm._v(" "), _c("van-divider", {
    staticClass: "opacity",
    style: {
      background: "#fff",
      flex: "1",
      margin: "0 16px"
    }
  }), _vm._v(" "), _c("van-icon", {
    staticClass: "opacity",
    attrs: {
      name: "edit",
      size: "24"
    }
  }), _vm._v(" "), _c("van-divider", {
    staticClass: "opacity",
    style: {
      background: "#fff",
      flex: "1",
      margin: "0 16px"
    }
  }), _vm._v(" "), _c("van-icon", {
    staticClass: "opacity",
    attrs: {
      name: "success",
      size: "24"
    }
  })], 1), _vm._v(" "), _vm._m(0)]), _vm._v(" "), _c("main", [_c("div", {
    staticClass: "title"
  }, [_vm._v("Attention Bank account information")]), _vm._v(" "), _c("div", {
    staticClass: "box-row",
    on: {
      click: function click($event) {
        return _vm.copyUserCode(_vm.bank);
      }
    }
  }, [_c("div", [_vm._v("*Bank Name :")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.bank))])]), _vm._v(" "), _c("div", {
    staticClass: "box-row",
    on: {
      click: function click($event) {
        return _vm.copyUserCode(_vm.companyName);
      }
    }
  }, [_c("div", [_vm._v("*Company Name :")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.companyName))])]), _vm._v(" "), _c("div", {
    staticClass: "box-row",
    on: {
      click: function click($event) {
        return _vm.copyUserCode(_vm.receivingAccount);
      }
    }
  }, [_c("div", [_vm._v("*Receiving Account :")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.receivingAccount))])]), _vm._v(" "), _c("div", {
    staticClass: "box-row",
    staticStyle: {
      color: "red",
      fontWeight: "600"
    },
    on: {
      click: function click($event) {
        return _vm.copyUserCode(_vm.user.userCode);
      }
    }
  }, [_vm._m(1), _vm._v(" "), _c("div", {
    staticStyle: {
      display: "flex",
      alignItems: "center"
    }
  }, [_vm._v(_vm._s(_vm.user.userCode))])]), _vm._v(" "), _c("div", {
    staticClass: "info"
  }, [_vm._v("You can click on th right information to copy")])]), _vm._v(" "), _vm._m(2), _vm._v(" "), _c("div", {
    staticClass: "van-contact-list__bottom"
  }, [_c("van-button", {
    staticClass: "van-contact-list__add",
    attrs: {
      round: "",
      block: "",
      type: "info",
      color: "#EA3340"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/bankRechargeTwo");
      }
    }
  }, [_vm._v("I have remitted money, fill in remittance voucher")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "explain"
  }, [_c("div", [_vm._v("Transfer Money to Designated Account")]), _vm._v(" "), _c("div", {
    staticClass: "opacity"
  }, [_vm._v("Fill the transfer infomation")]), _vm._v(" "), _c("div", {
    staticClass: "opacity"
  }, [_vm._v("Finish Submit")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("div", [_vm._v("*Transaction")]), _vm._v(" "), _c("div", [_vm._v("Description/Reference :")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("footer", [_c("div", {
    staticClass: "title"
  }, [_vm._v("Attention")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "#6a6969"
    }
  }, [_vm._v("Please check carefully as follow :")]), _vm._v(" "), _c("div", {
    staticClass: "box"
  }, [_c("ul", [_c("li", {
    staticStyle: {
      color: "#ea666f"
    }
  }, [_vm._v('When you pay to our account please mark your "transaction\n          reference number/description", so that your bank messageg will\n          match with our app wallet, it make you fund your money soon.')]), _vm._v(" "), _c("li", [_vm._v("During the balance recharge process, we will verify your\n          payment. The specific amount of the money recharge shall be\n          subject to the actual amount received by the bank.")]), _vm._v(" "), _c("li", [_vm._v("When your money arrives at our bank account, we will\n          immediately update your wallet, but this usually needs to be\n          processed within "), _c("span", {
    staticStyle: {
      color: "red",
      fontWeight: "600"
    }
  }, [_vm._v("24 hours")]), _vm._v(". In case of holiday day, it may be\n          postponed for one working day, please don't worry.")]), _vm._v(" "), _c("li", {
    staticStyle: {
      color: "#ea666f"
    }
  }, [_vm._v('If you forget to fill in the "transaction reference number\n          description" during the remittance process, please click the "I\n          have remitted" button to upload our pavment evidence,\n          otherwise we will not process your payment.')]), _vm._v(" "), _c("li", [_vm._v("If you have any questions, please contact us.")])])])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;