"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("van-uploader", {
    attrs: {
      "preview-size": _vm.previewSize,
      accept: _vm.accept,
      "after-read": _vm.afterRead
    },
    on: {
      delete: _vm.delImg
    },
    model: {
      value: _vm.fileList,
      callback: function callback($$v) {
        _vm.fileList = $$v;
      },
      expression: "fileList"
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;