"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "product-list-from-img"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Looking for products",
      "left-arrow": ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "product-list"
  }, [_c("div", {
    staticClass: "r-space-between"
  }, [_c("div", {
    staticClass: "r-start"
  }, [_c("van-image", {
    attrs: {
      width: "12vw",
      height: "12vw",
      "lazy-load": "",
      fit: "cover",
      src: _vm.searchImg
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      width: "20px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "f-16"
  }, [_vm._v("Products found")])], 1), _vm._v(" "), _c("span", {
    staticClass: "iconfont",
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("")])]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _vm.isLoading ? _c("div", {
    staticClass: "c-center-center",
    staticStyle: {
      height: "40vh"
    }
  }, [_c("van-loading", {
    attrs: {
      type: "spinner"
    }
  })], 1) : _c("div", [_vm.blurbList.length > 0 ? _c("div", [_c("masonry", {
    attrs: {
      cols: 2,
      gutter: 4
    }
  }, _vm._l(_vm.blurbList, function (item, index) {
    return _c("div", {
      key: index,
      on: {
        click: function click($event) {
          return _vm.toGoodDetail(item);
        }
      }
    }, [_c("TheWaterGoodCard", {
      attrs: {
        "spu-name": item.subject,
        "image-url": item.imageUrl,
        "offer-price": (item.oldPrice || 0) / 100 + ""
      }
    })], 1);
  }), 0)], 1) : _vm._e(), _vm._v(" "), _vm.blurbList.length === 0 && _vm.result === "" ? _c("div", {
    staticClass: "c-center-center"
  }, [_c("van-empty", {
    attrs: {
      description: "No more data"
    }
  }), _vm._v(" "), _c("van-button", {
    attrs: {
      round: "",
      plain: "",
      hairline: "",
      type: "info"
    }
  }, [_vm._v("Refresh again")])], 1) : _vm._e(), _vm._v(" "), _vm.blurbList.length === 0 && _vm.result !== "" ? _c("div", {
    staticClass: "c-center-center"
  }, [_c("van-empty", {
    attrs: {
      image: "error",
      description: "Service error"
    }
  }), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.result))]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _c("van-button", {
    attrs: {
      round: "",
      plain: "",
      hairline: "",
      type: "info"
    },
    on: {
      click: _vm.initData
    }
  }, [_vm._v("Refresh again")])], 1) : _vm._e()])])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;