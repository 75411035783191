"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/search/style");

var _search = _interopRequireDefault(require("vant/es/search"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _TheGoodListCom = _interopRequireDefault(require("./components/TheGoodListCom.vue"));

var _components;

var HEADER_LIST = [{
  name: 'New arrival',
  arg: 'newArrival',
  isSelected: true,
  desc: true // 默认降序（从大到小，从新到旧）

}, {
  name: 'Hot sale',
  arg: 'hotSale',
  isSelected: false,
  desc: true // 默认降序（从大到小，从新到旧）

}, {
  name: 'Price',
  arg: 'price',
  isSelected: false,
  desc: true // 默认降序（从大到小，从新到旧）

}];
var _default = {
  name: "ProductListPage",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _search.default.name, _search.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, "TheGoodListCom", _TheGoodListCom.default), _components),
  data: function data() {
    return {
      headerList: JSON.parse(JSON.stringify(HEADER_LIST)),
      // 下拉列表
      groupId: null,
      moduleType: null,
      screen: '',
      // new arrival，Price，Host sale（不传默认按创建时间降序排序）
      sort: null,
      categoryId1: null,
      categoryId2: null,
      content: null,
      // 按内容搜索
      searchKey: null
    };
  },
  created: function created() {
    var _this$$route$query = this.$route.query,
        groupId = _this$$route$query.groupId,
        moduleType = _this$$route$query.moduleType,
        categoryId1 = _this$$route$query.categoryId1,
        categoryId2 = _this$$route$query.categoryId2,
        content = _this$$route$query.content,
        searchKey = _this$$route$query.searchKey;
    this.groupId = groupId;
    this.moduleType = moduleType;
    this.categoryId1 = categoryId1;
    this.categoryId2 = categoryId2;
    this.content = content;
    this.searchKey = searchKey;
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var activeItem = this.headerList.filter(function (element) {
        return element.isSelected;
      })[0];
      this.screen = activeItem.arg || '';
      this.sort = activeItem.desc ? 2 : 1;
    },
    // 切换
    changeItem: function changeItem(headerItem) {
      this.headerList = this.headerList.map(function (item) {
        if (item.name === headerItem.name) {
          // 当已经选中的时候，切换升降序
          if (item.isSelected) {
            item.desc = !item.desc;
          }

          item.isSelected = true;
        } else {
          item.isSelected = false;
        }

        return item;
      });
      this.initData();
    }
  }
};
exports.default = _default;