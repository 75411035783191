"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getReToken = getReToken;
exports.getToken = getToken;
exports.removeReToken = removeReToken;
exports.removeToken = removeToken;
exports.setReToken = setReToken;
exports.setToken = setToken;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var TokenKey = 'h5-vue-cli_token';
var RefreshTokenKey = 'RefreshToken';

function getToken() {
  return _jsCookie.default.get(TokenKey);
}

function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
} // refresh_token


function getReToken() {
  return _jsCookie.default.get(RefreshTokenKey);
}

function setReToken(token) {
  return _jsCookie.default.set(RefreshTokenKey, token);
}

function removeReToken() {
  return _jsCookie.default.remove(RefreshTokenKey);
}