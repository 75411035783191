"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Remove cartons or not",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.toReturn
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "card",
    class: {
      active: _vm.isRemoveCartons === 1
    },
    on: {
      click: function click($event) {
        return _vm.selectIsRemoveCartons(1);
      }
    }
  }, [_c("div", {
    staticClass: "cell"
  }, [_c("van-icon", {
    attrs: {
      size: "36",
      name: require("@/assets/images/order/peeled.png")
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_vm._v("Yes,I need to remove the unnecessary cartons of goods.")])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "card",
    class: {
      active: _vm.isRemoveCartons === 2
    },
    on: {
      click: function click($event) {
        return _vm.selectIsRemoveCartons(2);
      }
    }
  }, [_c("div", {
    staticClass: "cell"
  }, [_c("van-icon", {
    attrs: {
      size: "36",
      name: require("@/assets/images/order/not_peeled.png")
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_vm._v("No,I don't need to remove the cartons of goods.")])], 1)]), _vm._v(" "), _vm._m(0)], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "card"
  }, [_c("div", [_vm._v("△ Tip:")]), _vm._v(" "), _c("div", [_vm._v("We only remove the cartons sent by the merchants, and do not remove the original packaging of the goods.")]), _vm._v(" "), _c("div", [_vm._v("We will try our best to help you remove the extra cartons, but we can't guarantee that all cartons can be removed, because some goods need to keep the packaging to protect the goods from damage.")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;