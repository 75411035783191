"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("van-tabbar", {
    attrs: {
      "safe-area-inset-bottom": "",
      "active-color": "#EA3340",
      "inactive-color": "#666666"
    },
    on: {
      change: _vm.onchange
    },
    model: {
      value: _vm.active,
      callback: function callback($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, _vm._l(_vm.tabbars, function (item, index) {
    return _c("van-tabbar-item", {
      key: index
    }, [_c("span", [_vm._v(_vm._s(item.title))]), _vm._v(" "), _c("span", {
      staticClass: "iconfont",
      domProps: {
        innerHTML: _vm._s(item.icon)
      }
    }, [_vm._v(";")])]);
  }), 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;