"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "swiper-box"
  }, [_c("van-swipe", {
    staticClass: "my-swipe",
    attrs: {
      autoplay: 3000,
      "stop-propagation": false
    }
  }, _vm._l(_vm.swiperDataList, function (image, index) {
    return _c("van-swipe-item", {
      key: index,
      attrs: {
        "lazy-render": ""
      },
      on: {
        click: function click($event) {
          return _vm.handleClick(image);
        }
      }
    }, [_c("img", {
      directives: [{
        name: "lazy",
        rawName: "v-lazy",
        value: image.image,
        expression: "image.image"
      }],
      staticClass: "swipe-img"
    })]);
  }), 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;