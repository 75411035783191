"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

var _typeof = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/typeof.js");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.weak-map");

require("vant/es/lazyload/style");

var _lazyload = _interopRequireDefault(require("vant/es/lazyload"));

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("vant/es/locale/style");

var _locale = _interopRequireDefault(require("vant/es/locale"));

require("/root/workspace/womata-web-app_dYpF/node_modules/core-js/modules/es6.array.iterator.js");

require("/root/workspace/womata-web-app_dYpF/node_modules/core-js/modules/es6.promise.js");

require("/root/workspace/womata-web-app_dYpF/node_modules/core-js/modules/es6.object.assign.js");

require("/root/workspace/womata-web-app_dYpF/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("lib-flexible");

var _App = _interopRequireDefault(require("./App.vue"));

var _router = _interopRequireDefault(require("@/router"));

var _store = _interopRequireDefault(require("@/store"));

var _fastclick = _interopRequireDefault(require("fastclick"));

require("animate.css");

require("utils/permission");

var _SvgIcon = _interopRequireDefault(require("components/SvgIcon"));

require("@/icons");

require("@/style/common.scss");

require("@/style/theme.scss");

require("vant/lib/index.less");

require("@/utils/sitemap");

var _enUS = _interopRequireDefault(require("vant/es/locale/lang/en-US"));

var _settings = _interopRequireDefault(require("@/settings"));

var globalFilter = _interopRequireWildcard(require("@/filter"));

require("@/assets/iconfont/iconfont.css");

var _vueMasonryCss = _interopRequireDefault(require("vue-masonry-css"));

var _dayjs = _interopRequireDefault(require("dayjs"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// icon
// 过滤器
// 引入iconfont
var utc = require('dayjs/plugin/utc');

_dayjs.default.extend(utc); // 引入英文语言包


_locale.default.use('en-US', _enUS.default);

_fastclick.default.attach(document.body);

_vue.default.prototype.$EventBus = new _vue.default(); // 注册全局过滤

Object.keys(globalFilter).forEach(function (key) {
  _vue.default.filter(key, globalFilter[key]);
}); // options 为可选参数，无则不传

_vue.default.use(_lazyload.default); // 瀑布流


_vue.default.use(_vueMasonryCss.default);

_vue.default.component('svg-icon', _SvgIcon.default);

if (process.env.NODE_ENV === 'development' && _settings.default.vconsole) {
  var VConsole = require('vconsole');

  new VConsole();
}

_vue.default.config.productionTip = false;
new _vue.default({
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
}).$mount('#app');