"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.set");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.match");

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/share-sheet/style");

var _shareSheet = _interopRequireDefault(require("vant/es/share-sheet"));

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

require("core-js/modules/es6.function.name");

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

var _cmmission = require("@/api/cmmission.js");

var _utils = require("@/utils");

var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));

var _html2canvas = _interopRequireDefault(require("html2canvas"));

var _components;

var _default2 = {
  name: "TheGoodShare",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _image.default.name, _image.default), (0, _defineProperty2.default)(_components, _shareSheet.default.name, _shareSheet.default), _components),
  filters: {
    filtersStartQuantity: function filtersStartQuantity(value, index, priceRanges) {
      var _moreThan = '';
      var _betweenAnd = '';
      var _length = priceRanges.length; // 最后一个显示≥

      if (index === _length - 1) {
        _moreThan = '≥';
      }

      if (index < _length - 1) {
        _betweenAnd = "-".concat(priceRanges[index + 1].startQuantity - 1); // 起批量
      }

      return "".concat(_moreThan).concat(priceRanges[index].startQuantity).concat(_betweenAnd, " Pieces");
    },
    filtersLastPrice: function filtersLastPrice(value) {
      return value.length > 1 ? value[value.length - 1] : '';
    },
    // 自营商品左边价格
    filtersPmsLeftPrice: function filtersPmsLeftPrice(value) {
      return value.isActivity === 1 ? value.discountPrice : value.offerPrice;
    },
    // 自营商品左边价格横线
    filtersPmsLeftLine: function filtersPmsLeftLine(value) {
      return value.highestPrice != null && (value.isActivity === 1 ? value.discountPrice : value.offerPrice) * 1 < value.highestPrice * 1 ? '-' : '';
    },
    // 自营商品右边价格
    filtersPmsRightPrice: function filtersPmsRightPrice(value) {
      return value.highestPrice != null && (value.isActivity === 1 ? value.discountPrice : value.offerPrice) * 1 < value.highestPrice * 1 ? value.highestPrice : '';
    },
    // 自营原价 offerPrice
    filtersOfferPrice: function filtersOfferPrice(productInfo) {
      return productInfo.offerPrice != null && productInfo.isActivity === 1 ? productInfo.offerPrice : '';
    },
    // 自营显示价
    filtersShowPrice: function filtersShowPrice(productInfo) {
      return productInfo.isActivity !== 1 && productInfo.showPrice.trim() !== '0' ? productInfo.showPrice : '';
    }
  },
  props: {
    showShare: {
      type: Boolean,
      default: false
    },
    goodsSource: {
      type: Number,
      default: 1
    },
    headImg: {
      type: String,
      default: ""
    },
    activityId: {
      type: String,
      default: ""
    },
    subjectEn: {
      type: String,
      default: ""
    },
    subjectZh: {
      type: String,
      default: ""
    },
    productInfo: {
      type: Object,
      default: function _default() {
        return {
          productID: ""
        };
      }
    }
  },
  data: function data() {
    return {
      zIndex: 50,
      aliPriceRangeList: [],
      // 阿里巴巴价格区间
      canvasImg: "",
      shareOptions: [{
        id: 1,
        name: 'Whatsapp',
        icon: require('@/assets/images/account/whatsapp_icon.png')
      }, {
        id: 2,
        name: 'Copy Link',
        icon: 'link'
      }],
      shareLink: "" // 分销链接

    };
  },
  computed: {
    _showShare: {
      get: function get() {
        return this.showShare;
      },
      set: function set(val) {
        this.$emit("changeShowShare", val);
      }
    },
    myStyleZIndex: function myStyleZIndex() {
      return {
        "--zIndex": this.zIndex
      };
    }
  },
  created: function created() {
    var _this$productInfo,
        _window$location,
        _this = this;

    this.aliPriceRangeList = this.getPriceRange(this.productInfo);
    (0, _cmmission.createShareLink)({
      spu: String((_this$productInfo = this.productInfo) === null || _this$productInfo === void 0 ? void 0 : _this$productInfo.productID),
      module: this.goodsSource,
      url: "".concat((_window$location = window.location) === null || _window$location === void 0 ? void 0 : _window$location.origin, "/GoodsContent?openType=binding").concat(this.activityId ? '&activityId=' + this.activityId : '')
    }).then(function (res) {
      try {
        if (res.code === 200) {
          _this.shareLink = res.data;

          _this.$nextTick(function () {
            var _this$$refs$shareShe, _this$$refs$shareShe$;

            _this.zIndex = ((_this$$refs$shareShe = _this.$refs["share-sheet"].$el) === null || _this$$refs$shareShe === void 0 ? void 0 : (_this$$refs$shareShe$ = _this$$refs$shareShe.style) === null || _this$$refs$shareShe$ === void 0 ? void 0 : _this$$refs$shareShe$.zIndex) || 50;
            _this.$refs.qrcodeContainer.innerHTML = '';
            new _qrcodejs.default(_this.$refs.qrcodeContainer, {
              text: _this.shareLink,
              width: 60,
              height: 60,
              colorDark: '#000000',
              colorLight: '#ffffff',
              correctLevel: _qrcodejs.default.CorrectLevel.H
            });
            (0, _html2canvas.default)(_this.$refs["share-photo"], {
              dpi: 300,
              allowTaint: true,
              useCORS: true,
              scrollY: 0,
              scrollX: 0
            }).then(function (canvas) {
              _this.canvasImg = canvas.toDataURL('image/png', 1.0);
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    });
  },
  methods: {
    // 分销分享选中
    onSelectShare: function onSelectShare(option) {
      switch (option.id) {
        case 1:
          this.jumpApp("whatsapp://send?text=".concat(encodeURIComponent(this.shareLink)));
          break;

        case 2:
          (0, _utils.copyTextToClipboard)("".concat(this.shareLink));
          (0, _toast.default)("Copy success");
          break;

        default:
          break;
      }
    },
    // 跳转打开app
    jumpApp: function jumpApp(t) {
      try {
        var e = navigator.userAgent.toLowerCase();
        var n = e.match(/cpu iphone os (.*?) like mac os/);

        if (n = n !== null ? n[1].replace(/_/g, ".") : 0, parseInt(n) >= 9) {
          window.location.href = t;
        } else {
          // var r = document.createElement("iframe");
          // (r.src = t), (r.style.display = "none"), document.body.appendChild(r);
          window.location.href = t;
        }
      } catch (e) {
        window.location.href = t;
      }
    },
    // 获取价格区间
    getPriceRange: function getPriceRange(productInfo) {
      var _tempAry = [];
      var quoteType = this.productInfo.saleInfo.quoteType || 1; // 出价类型

      try {
        if (quoteType === 1) {
          if (productInfo.skuInfos != null && productInfo.skuInfos[0].price != null) {
            // 有sku时,根据sku价格排序
            _tempAry = productInfo.skuInfos.map(function (index) {
              return index.price;
            });
          } else {
            // 无sku，拿已定义的价格排序
            _tempAry = productInfo.saleInfo.priceRanges.map(function (index) {
              return index.price;
            });
          }
        } else if ([0, 2].includes(quoteType)) {
          // 无sku，拿已定义的价格排序
          _tempAry = productInfo.saleInfo.priceRanges.map(function (index) {
            return index.price;
          });
        }

        _tempAry = Array.from(new Set(_tempAry)); // 用set进行去重 把数组塞进set里

        _tempAry.sort(function (a, b) {
          return a - b;
        });

        console.log("🚀 ~ file: TheGoodDetailPrice.vue ~ line 86 ~ getPriceRange ~ _tempAry", _tempAry);
      } catch (e) {
        console.log("getPriceRange---------ee-----".concat(e, "}"));
      }

      return _tempAry;
    }
  }
};
exports.default = _default2;