"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.regexp.replace");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Select account",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("van-notice-bar", {
    attrs: {
      wrapable: "",
      "left-icon": "info-o"
    }
  }, [_vm._v("Please fill in the real account information")]), _vm._v(" "), _c("van-radio-group", {
    model: {
      value: _vm.selectAccountId,
      callback: function callback($$v) {
        _vm.selectAccountId = $$v;
      },
      expression: "selectAccountId"
    }
  }, _vm._l(_vm.withdrawAccountList, function (item, index) {
    return _c("van-radio", {
      key: index,
      attrs: {
        name: item.id,
        "checked-color": "#EA3340",
        "icon-size": "16"
      },
      on: {
        click: function click($event) {
          return _vm.$router.replace({
            name: "Withdrawal",
            params: {
              accountItem: item
            }
          });
        }
      }
    }, [_c("div", {
      staticClass: "card"
    }, [_c("div", {
      staticClass: "card-left"
    }, [_c("div", {
      staticClass: "withdrawName"
    }, [item.isDefault === 1 ? _c("span", [_vm._v("default")]) : _vm._e(), _c("div", {
      staticClass: "van-ellipsis"
    }, [_vm._v(_vm._s(item.withdrawName))])]), _vm._v(" "), _c("div", {
      staticClass: "bankName van-ellipsis"
    }, [_vm._v(_vm._s(item.bankName))]), _vm._v(" "), _c("div", {
      staticClass: "account van-ellipsis"
    }, [_vm._v(_vm._s(item.account.replace(/\D/g, "").replace(/....(?!$)/g, "$& ")))])]), _vm._v(" "), _c("van-icon", {
      attrs: {
        name: require("@/assets/images/account/edit.png"),
        size: "20"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.$router.push({
            path: "/editAccount",
            query: {
              selectAccountItem: item
            }
          });
        }
      }
    })], 1)]);
  }), 1), _vm._v(" "), _c("div", {
    staticClass: "bottom"
  }, [_c("van-button", {
    attrs: {
      block: "",
      round: "",
      icon: "plus",
      color: "#EA3340"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.$router.push({
          path: "/editAccount"
        });
      }
    }
  }, [_vm._v("Add New Account")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;