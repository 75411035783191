"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/notify/style");

var _notify = _interopRequireDefault(require("vant/es/notify"));

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/checkbox/style");

var _checkbox = _interopRequireDefault(require("vant/es/checkbox"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/empty/style");

var _empty = _interopRequireDefault(require("vant/es/empty"));

require("vant/es/list/style");

var _list = _interopRequireDefault(require("vant/es/list"));

require("vant/es/pull-refresh/style");

var _pullRefresh = _interopRequireDefault(require("vant/es/pull-refresh"));

require("vant/es/tab/style");

var _tab = _interopRequireDefault(require("vant/es/tab"));

require("vant/es/tabs/style");

var _tabs = _interopRequireDefault(require("vant/es/tabs"));

require("vant/es/search/style");

var _search = _interopRequireDefault(require("vant/es/search"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _myLike = require("api/myLike");

var _GoodCardItem = _interopRequireDefault(require("./components/GoodCardItem"));

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "MyLike",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _search.default.name, _search.default), (0, _defineProperty2.default)(_components, _tabs.default.name, _tabs.default), (0, _defineProperty2.default)(_components, _tab.default.name, _tab.default), (0, _defineProperty2.default)(_components, _pullRefresh.default.name, _pullRefresh.default), (0, _defineProperty2.default)(_components, _list.default.name, _list.default), (0, _defineProperty2.default)(_components, _empty.default.name, _empty.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _checkbox.default.name, _checkbox.default), (0, _defineProperty2.default)(_components, "GoodCardItem", _GoodCardItem.default), _components),
  data: function data() {
    return {
      keyword: "",
      // 移除按钮
      removeShow: false,
      activeTab: 2,
      refreshing: false,
      loading: false,
      finished: false,
      tabList: [{
        id: 2,
        title: "1688"
      }, {
        id: 1,
        title: "Womall"
      }],
      list: [],
      totalCount: 0,
      pageNum: 1
    };
  },
  computed: {
    getTotalChecked: function getTotalChecked() {
      return this.list.reduce(function (total, currentValue) {
        if (currentValue.checked) total += currentValue.checked;
        return total;
      }, 0);
    }
  },
  watch: {
    $route: {
      handler: function handler(val, oldVal) {
        var _val$params;

        // 因缓存刷新页面
        if (["Account"].includes(oldVal === null || oldVal === void 0 ? void 0 : oldVal.name) && val.name === "MyLike") {
          this.removeShow = false;
          this.pageNum = 1;
          this.activeTab = 2;
          this.fetchData();
        }

        if ((_val$params = val.params) !== null && _val$params !== void 0 && _val$params.keyword) {
          var _val$params2;

          this.keyword = (_val$params2 = val.params) === null || _val$params2 === void 0 ? void 0 : _val$params2.keyword;
          this.removeShow = false;
          this.pageNum = 1;
          this.activeTab = 2;
          this.fetchData();
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      (0, _myLike.getMyLikeList)({
        keyword: this.keyword,
        module: this.activeTab,
        pageNum: this.pageNum,
        pageSize: 10
      }).then(function (res) {
        var _res$data, _res$data$data;

        _this.loading = false;
        _this.refreshing = false;
        _this.totalCount = res.data.totalCount;

        if (((_res$data = res.data) === null || _res$data === void 0 ? void 0 : (_res$data$data = _res$data.data) === null || _res$data$data === void 0 ? void 0 : _res$data$data.length) > 0) {
          if (_this.pageNum === 1) {
            _this.list = res.data.data.map(function (item) {
              return _objectSpread(_objectSpread({}, item), {}, {
                checked: false
              });
            });
          } else {
            _this.list = _this.list.concat(res.data.data.map(function (item) {
              return _objectSpread(_objectSpread({}, item), {}, {
                checked: false
              });
            }));
          }
        } else {
          if (_this.pageNum === 1) _this.list = [];
          _this.finished = true;
        }
      });
    },
    // 移除状态时触发
    handleChecked: function handleChecked(item) {
      if (this.removeShow) this.$set(item, "checked", !item.checked);
    },
    // 取消收藏商品
    handleRemove: function handleRemove() {
      var _this2 = this;

      if (!this.getTotalChecked) return _toast.default.fail("Please select first");
      (0, _myLike.removeMyLike)({
        userLikeIds: this.list.filter(function (item) {
          return item.checked;
        }).map(function (item) {
          return item.userLikeId;
        })
      }).then(function (res) {
        (0, _notify.default)({
          type: 'success',
          duration: 2000,
          message: 'Successfully'
        });
        _this2.pageNum = 1;

        _this2.fetchData();
      });
    },
    changeTab: function changeTab() {
      this.list = [];
      this.finished = false;
      this.pageNum = 1;
      this.fetchData();
    },
    onLoad: function onLoad() {
      this.pageNum++;
      this.fetchData();
    },
    // 下拉刷新
    onRefresh: function onRefresh() {
      this.finished = false;
      this.pageNum = 1;
      this.fetchData();
    }
  }
};
exports.default = _default;