"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _typeof2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/field/style");

var _field = _interopRequireDefault(require("vant/es/field"));

require("core-js/modules/es6.function.name");

require("vant/es/form/style");

var _form = _interopRequireDefault(require("vant/es/form"));

var _user = require("api/user");

var _cmmission = require("@/api/cmmission.js");

var _validate = require("utils/validate");

var _localStorage = require("@/utils/local-storage.js");

var _components;

var _default = {
  name: "Register",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _form.default.name, _form.default), (0, _defineProperty2.default)(_components, _field.default.name, _field.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), _components),
  data: function data() {
    return {
      account: '',
      phone: '',
      password: '',
      tmpPassword: '',
      verifyCode: '',
      counter: '',
      emailShow: false,
      timer: null
    };
  },
  computed: {
    emails: function emails() {
      if (!this.account) return []; // 考虑到用户自己要输@符号，如果@符号首次出现的位置大于等于第零个位置时，则不处理

      if (this.account.indexOf('@') > -1) return [];
      return [this.account + '@gmail.com', this.account + '@yahoo.com', this.account + '@qq.com', this.account + '@hotmail.com', this.account + '@foxmail.com', this.account + '@163.com', this.account + '@126.com', this.account + '@mail.com'];
    }
  },
  methods: {
    validPassword: _validate.validPassword,
    validatorTel: function validatorTel(val) {
      return /^(([0]\d{10})|([1-9]\d{9})|(1[3-9]\d{9}))$/.test(val);
    },
    // 密码不一致
    comparePassword: function comparePassword(val) {
      return val === this.tmpPassword;
    },
    // 取消邮箱提示
    handleBlur: function handleBlur() {
      var _this = this;

      this.$nextTick(function () {
        var timer = setTimeout(function () {
          _this.emailShow = false;
          clearTimeout(timer);
        }, 20);
      });
    },
    // 选中邮箱提示
    setInput: function setInput(mail) {
      this.account = mail;
      this.emailShow = !this.emailShow;
    },
    // 获取邮箱验证码
    getCode: function getCode() {
      var _this2 = this;

      this.$refs['form'].validate('account').then(function (_) {
        (0, _user.getCode)(_this2.account).then(function (res) {
          _this2.countDown();

          _toast.default.success(res.message);
        });
      }).catch(function (e) {
        console.log(e);
      });
    },
    // 表单验证无误
    submit: function submit(values) {
      var _this3 = this;

      (0, _user.register)({
        account: this.account,
        password: this.password,
        phone: this.phone,
        verifyCode: this.verifyCode
      }).then(function (res) {
        _this3.$store.dispatch("systemConfig/firebaseLogEvent", "H5_click_submit_register"); // 判断是否绑定分销关系


        if ((0, _localStorage.get)("saleQuery") && (0, _typeof2.default)(JSON.parse((0, _localStorage.get)("saleQuery"))) === "object") {
          var _JSON$parse = JSON.parse((0, _localStorage.get)("saleQuery")),
              userCode = _JSON$parse.userCode,
              roleId = _JSON$parse.roleId,
              module = _JSON$parse.module,
              parentRoleId = _JSON$parse.parentRoleId,
              parentCode = _JSON$parse.parentCode;

          (0, _cmmission.bindSale)({
            userId: res.data,
            userCode: userCode,
            roleId: roleId,
            module: Number(module),
            parentRoleId: parentRoleId,
            parentCode: parentCode,
            webNewUser: 1
          }).then(function (res) {
            console.log("绑定成功");
            (0, _localStorage.remove)("saleQuery");
          }).catch(function (e) {
            console.log(e);
          });
        }

        _toast.default.success(res.message);

        _this3.$router.push("/login");
      });
    },
    // 倒计时
    countDown: function countDown() {
      var _this4 = this;

      this.counter = 60;
      this.timer = setInterval(function () {
        _this4.counter--;

        if (_this4.counter === 0) {
          // 当计时等于零时，取消该计时器
          clearInterval(_this4.timer);
        }
      }, 1000);
    },
    // 表单验证错误
    onFailed: function onFailed(errorInfo) {
      console.log("failed", errorInfo);
    }
  }
};
exports.default = _default;