"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/lazyload/style");

var _lazyload = _interopRequireDefault(require("vant/es/lazyload"));

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

require("vant/es/swipe-item/style");

var _swipeItem = _interopRequireDefault(require("vant/es/swipe-item"));

require("vant/es/swipe/style");

var _swipe = _interopRequireDefault(require("vant/es/swipe"));

require("core-js/modules/es6.function.name");

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

var _components;

var _default = {
  name: "UserHelpPurchase",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _swipe.default.name, _swipe.default), (0, _defineProperty2.default)(_components, _swipeItem.default.name, _swipeItem.default), (0, _defineProperty2.default)(_components, _image.default.name, _image.default), (0, _defineProperty2.default)(_components, _lazyload.default.name, _lazyload.default), _components),
  data: function data() {
    return {
      autoplayTime: 5000,
      scrollTop: 0,
      // 顶部滑动距离
      // 步骤一图片
      titleImg1: [{
        image: require("../../static/asset/images/help_buy/step1/pic_1.png")
      }, {
        image: require("../../static/asset/images/help_buy/step1/pic_2.png")
      }, {
        image: require("../../static/asset/images/help_buy/step1/pic_3.png")
      }],
      // 步骤二图片
      titleImg2: [{
        image: require("../../static/asset/images/help_buy/step2/pic_1.png")
      }, {
        image: require("../../static/asset/images/help_buy/step2/pic_2.png")
      }, {
        image: require("../../static/asset/images/help_buy/step2/pic_3.png")
      }],
      // 步骤三图片
      titleImg4: [{
        image: require("../../static/asset/images/help_buy/step4/pic_1.png")
      }, {
        image: require("../../static/asset/images/help_buy/step4/pic_2.png")
      }]
    };
  },
  mounted: function mounted() {// goAnchor();
  },
  methods: {
    onChange1: function onChange1(index) {
      this.onChange(index, this.titleImg1, this.$refs.swipe1);
    },
    onChange2: function onChange2(index) {
      this.onChange(index, this.titleImg2, this.$refs.swipe2);
    },
    onChange4: function onChange4(index) {
      this.onChange(index, this.titleImg4, this.$refs.swipe4);
    },
    onChange: function onChange(index, imgList, slotItem) {
      if (index === imgList.length - 1) {
        setTimeout(function () {
          slotItem.swipeTo(0);
        }, this.autoplayTime);
      }
    }
  }
};
exports.default = _default;