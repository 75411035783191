"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/image-preview/style");

var _imagePreview = _interopRequireDefault(require("vant/es/image-preview"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/pull-refresh/style");

var _pullRefresh = _interopRequireDefault(require("vant/es/pull-refresh"));

require("vant/es/list/style");

var _list = _interopRequireDefault(require("vant/es/list"));

require("vant/es/collapse-item/style");

var _collapseItem = _interopRequireDefault(require("vant/es/collapse-item"));

require("vant/es/collapse/style");

var _collapse = _interopRequireDefault(require("vant/es/collapse"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _vuex = require("vuex");

var _api = require("api");

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "BankRechargeRecord",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _collapse.default.name, _collapse.default), (0, _defineProperty2.default)(_components, _collapseItem.default.name, _collapseItem.default), (0, _defineProperty2.default)(_components, _list.default.name, _list.default), (0, _defineProperty2.default)(_components, _pullRefresh.default.name, _pullRefresh.default), _components),
  filters: {
    // 审批状态 approvalStatus(0：待审核，1：已通过，2：未通过，3：已取消)
    getStatusClass: function getStatusClass(status) {
      var typeString = '';

      switch (status) {
        case 0:
          typeString = 'round blue';
          break;

        case 1:
          typeString = 'round green';
          break;

        case 2:
          typeString = 'round red';
          break;

        default:
          typeString = 'round';
          break;
      }

      return typeString;
    },
    getStatusText: function getStatusText(status) {
      var typeString = '';

      switch (status) {
        case 0:
          typeString = 'Audit';
          break;

        case 1:
          typeString = 'Pass';
          break;

        case 2:
          typeString = 'Fail';
          break;

        default:
          typeString = 'Cancel';
          break;
      }

      return typeString;
    }
  },
  data: function data() {
    return {
      loading: false,
      finished: false,
      pageNum: 1,
      pageSize: 10,
      activeNames: [],
      dataList: [],
      refreshing: false
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    user: function user(state) {
      return state.user['user'];
    }
  })),
  created: function created() {
    this.getListData();
  },
  methods: {
    getListData: function getListData() {
      var _this = this;

      (0, _api.getArtificialRechargeList)({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(function (res) {
        _this.refreshing = false;
        _this.loading = false;

        if (res.data.length) {
          if (_this.pageNum === 1) {
            _this.dataList = [];
            _this.dataList = res.data;
          } else {
            _this.dataList = _this.dataList.concat(res.data);
          }
        } else {
          _this.finished = true;
        }
      });
    },
    // 下拉刷新
    onRefresh: function onRefresh() {
      this.finished = false;
      this.pageNum = 1;
      this.getListData();
    },
    // 加载更多
    onLoad: function onLoad() {
      this.pageNum++;
      this.getListData();
    },
    // 预览图片
    imagePreview: function imagePreview(imgArr) {
      (0, _imagePreview.default)({
        images: imgArr,
        showIndicators: true
      });
    },
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    }
  }
};
exports.default = _default;