"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/empty/style");

var _empty = _interopRequireDefault(require("vant/es/empty"));

require("vant/es/steps/style");

var _steps = _interopRequireDefault(require("vant/es/steps"));

require("vant/es/step/style");

var _step = _interopRequireDefault(require("vant/es/step"));

require("vant/es/collapse-item/style");

var _collapseItem = _interopRequireDefault(require("vant/es/collapse-item"));

require("vant/es/collapse/style");

var _collapse = _interopRequireDefault(require("vant/es/collapse"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _api = require("api");

var _components;

var _default = {
  name: "LogisticsTrack",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _collapse.default.name, _collapse.default), (0, _defineProperty2.default)(_components, _collapseItem.default.name, _collapseItem.default), (0, _defineProperty2.default)(_components, _step.default.name, _step.default), (0, _defineProperty2.default)(_components, _steps.default.name, _steps.default), (0, _defineProperty2.default)(_components, _empty.default.name, _empty.default), _components),
  data: function data() {
    return {
      logisticsTrackList: [],
      activeNames: [0],
      activeNames2: ["0-0"]
    };
  },
  created: function created() {
    var _this = this;

    if (this.$route.query.waybill) {
      (0, _api.getSkycargoLogisticsTrack)({
        packId: this.$route.query.waybill
      }).then(function (res) {
        var _res$data;

        _this.logisticsTrackList = (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.result;
      });
    }
  },
  methods: {}
};
exports.default = _default;