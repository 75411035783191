"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

var SELECT_CONSIGNEE = 'selectConsignee'; // 选择联系人

var _default = {
  namespaced: true,
  state: {
    consigneeItem: null
  },
  mutations: (0, _defineProperty2.default)({}, SELECT_CONSIGNEE, function (state, data) {
    state.consigneeItem = data;
  }),
  actions: {
    selectConsignee: function selectConsignee(state, data) {
      console.log("selectConsignee -> data", data);
      state.commit(SELECT_CONSIGNEE, data);
    }
  },
  getters: {
    consigneeItem: function consigneeItem(state) {
      return state.consigneeItem;
    }
  }
};
exports.default = _default;