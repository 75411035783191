"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("van-calendar", {
    attrs: {
      "default-date": _vm._selectPickupTime ? new Date(_vm._selectPickupTime) : null,
      "max-date": _vm.dayjs(new Date()).hour(0).minute(0).second(0).add(_vm.businessTime.longDay ? _vm.businessTime.longDay - 1 : 0, "day").toDate(),
      formatter: _vm.formatter,
      color: "#FF0037"
    },
    on: {
      select: _vm.selectDate
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("div", {
          staticClass: "title"
        }, [_c("span", [_vm._v("Click")]), _vm._v(" date to select pickup time")]), _vm._v(" "), _c("van-notice-bar", {
          attrs: {
            color: "#FF0037",
            background: "#FFF9E3",
            "left-icon": "info-o",
            delay: "2",
            speed: "35"
          }
        }, [_vm._v("\n      Please pick up the goods on that date you select.\n    ")])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_vm.businessHoursTmp && _vm.businessHoursTmp.begin && _vm.businessHoursTmp.end ? _c("div", {
          staticClass: "business"
        }, [_vm._v("Business Hours："), _c("span", {
          staticClass: "theme-color"
        }, [_vm._v(_vm._s(_vm.businessHoursTmp.begin) + "-" + _vm._s(_vm.businessHoursTmp.end))])]) : _vm._e(), _vm._v(" "), _c("van-button", {
          attrs: {
            block: "",
            round: "",
            color: "#FF0037",
            disabled: !_vm.selectDateTmp
          },
          on: {
            click: _vm.confirm
          }
        }, [_vm._v("Submit")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.show_,
      callback: function callback($$v) {
        _vm.show_ = $$v;
      },
      expression: "show_"
    }
  });
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;