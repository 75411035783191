"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCouponCode = getCouponCode;
exports.getCouponCodeCart = getCouponCodeCart;
exports.getCouponCodeCount = getCouponCodeCount;
exports.getCouponSpuById = getCouponSpuById;
exports.getCouponsCenter = getCouponsCenter;
exports.obtainCoupon = obtainCoupon;

var _request = _interopRequireDefault(require("@/utils/request"));

var _qs = _interopRequireDefault(require("qs"));

/**
 * 
 * 查询券码
 */
function getCouponCode(params) {
  return (0, _request.default)({
    url: 'coupon_app_service/coupon_code?' + _qs.default.stringify(params, {
      indices: false,
      arrayFormat: 'brackets'
    }),
    method: 'get'
  });
}
/**
 * 
 * 查询券码数量
 */


function getCouponCodeCount(data) {
  return (0, _request.default)({
    url: 'coupon_app_service/coupon_code/count',
    method: 'get',
    params: data
  });
}
/**
 * 
 * 购物车查询优惠券码
 */


function getCouponCodeCart(params) {
  return (0, _request.default)({
    url: 'coupon_app_service/coupon_code/cart?' + _qs.default.stringify(params, {
      indices: false,
      arrayFormat: 'brackets'
    }),
    method: 'get'
  });
}
/**
 * 领取优惠券
 */


function obtainCoupon(data) {
  return (0, _request.default)({
    url: 'coupon_app_service/coupon_code/receive',
    method: 'post',
    data: data
  });
}
/**
 * 获取优惠券中心列表
 */


function getCouponsCenter(params) {
  return (0, _request.default)({
    url: 'coupon_app_service/coupon',
    method: 'get',
    params: params
  });
}
/**
 * 
 * 根据优惠券id查询商品信息
 */


function getCouponSpuById(params) {
  return (0, _request.default)({
    url: 'spu-service/coupon/appToGetSpuById',
    method: 'get',
    params: params
  });
}