"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.alibabaGoodsLink = alibabaGoodsLink;
exports.copyTextToClipboard = void 0;
exports.deepClone = deepClone;
exports.getGoodImg = getGoodImg;
exports.param2Obj = param2Obj;
exports.queryParams = queryParams;
exports.regMobile = exports.regEmail = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _typeof2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.split");

var _settings = require("@/settings");

/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  var search = url.split('?')[1];

  if (!search) {
    return {};
  }

  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

function deepClone(source) {
  if (!source && (0, _typeof2.default)(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }

  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && (0, _typeof2.default)(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

var copyTextToClipboard = function copyTextToClipboard(value) {
  var textArea = document.createElement("textarea");
  textArea.style.background = 'transparent';
  textArea.value = value;
  document.body.appendChild(textArea);
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    console.log(successful);
  } catch (err) {
    console.log('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
};

exports.copyTextToClipboard = copyTextToClipboard;

var regEmail = function regEmail(email) {
  if (String(email).indexOf('@') > 0) {
    var str = email.split('@');
    var _s = '';

    if (str[0].length > 2) {
      for (var i = 0; i < str[0].length - 3; i++) {
        _s += '*';
      }
    }

    var new_email = str[0].substr(0, 2) + _s + '@' + str[1];
  }

  return new_email;
}; // 替换手机字符


exports.regEmail = regEmail;

var regMobile = function regMobile(tel) {
  if (!tel.length) return;

  if (tel.length === 11) {
    return tel.substring(0, 3) + "****" + tel.substr(tel.length - 4);
  } else if (tel.length === 10) {
    return tel.substring(0, 2) + "****" + tel.substr(tel.length - 4);
  }

  return tel;
}; // 获取图片


exports.regMobile = regMobile;

function getGoodImg(imgUrl, modules) {
  modules = modules || 2;

  if (modules === 2) {
    // 1688图片
    return _settings.imgAliUrl + imgUrl;
  } else {
    return imgUrl;
  }
} // 阿里巴巴商品链接


function alibabaGoodsLink(productID) {
  // 阿里巴巴商品链接
  return "".concat(_settings.alibabaMobileLink, "/offer/").concat(productID, ".html");
}
/**
 * 对象转url参数
 * @param {Object} data,对象
 */


function queryParams() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var arrayFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'brackets';
  if (data === null) return '';
  var _result = [];
  if (['indices', 'brackets', 'repeat', 'comma'].indexOf(arrayFormat) === -1) arrayFormat = 'brackets';

  var _loop = function _loop(key) {
    var value = data[key]; // 去掉为空的参数

    if (['', undefined, null].indexOf(value) >= 0) {
      return "continue";
    } // 如果值为数组，另行处理


    if (value.constructor === Array) {
      // e.g. {ids: [1, 2, 3]}
      switch (arrayFormat) {
        case 'indices':
          // 结果: ids[0]=1&ids[1]=2&ids[2]=3
          for (var i = 0; i < value.length; i++) {
            _result.push(key + '[' + i + ']=' + value[i]);
          }

          break;

        case 'brackets':
          // 结果: ids[]=1&ids[]=2&ids[]=3
          value.forEach(function (_value) {
            _result.push(key + '[]=' + _value);
          });
          break;

        case 'repeat':
          // 结果: ids=1&ids=2&ids=3
          value.forEach(function (_value) {
            _result.push(key + '=' + _value);
          });
          break;

        case 'comma':
          // 结果: ids=1,2,3
          commaStr = "";
          value.forEach(function (_value) {
            commaStr += (commaStr ? "," : "") + _value;
          });

          _result.push(key + '=' + commaStr);

          break;

        default:
          value.forEach(function (_value) {
            _result.push(key + '[]=' + _value);
          });
      }
    } else {
      _result.push(key + '=' + value);
    }
  };

  for (var key in data) {
    var commaStr;

    var _ret = _loop(key);

    if (_ret === "continue") continue;
  }

  return _result.length ? _result.join('&') : '';
}