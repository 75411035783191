"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "search-page"
  }, [_c("div", {
    staticClass: "nav-bar-box"
  }, [_c("div", {
    staticClass: "r-space-between nav-bar padding-row-13"
  }, [_c("div", {
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c("van-icon", {
    attrs: {
      name: "arrow-left",
      size: "16"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      width: "70vw"
    }
  }, [_c("van-search", {
    attrs: {
      shape: "round",
      clearable: true,
      autofocus: "",
      placeholder: _vm.placeholder
    },
    on: {
      search: function search($event) {
        return _vm.onClickRight();
      }
    },
    scopedSlots: _vm._u([{
      key: "left-icon",
      fn: function fn() {
        return [_c("span", {
          staticClass: "iconfont"
        }, [_vm._v("")])];
      },
      proxy: true
    }, {
      key: "right-icon",
      fn: function fn() {
        return [_vm.isLogin ? _c("div", [_c("HeadImgUpload", {
          attrs: {
            "oss-key": "womataH5/searchImg/"
          },
          on: {
            fileImg: _vm.getfileImg
          }
        }, [_c("span", {
          staticClass: "iconfont"
        }, [_vm._v("")])])], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "f-14",
    on: {
      click: function click($event) {
        return _vm.onClickRight();
      }
    }
  }, [_vm._v("Search")])])]), _vm._v(" "), _vm.cacheList != null && _vm.cacheList.length > 0 ? _c("div", {
    staticClass: "history-box"
  }, [_c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "r-space-between padding-row-13"
  }, [_c("div", {
    staticClass: "f-16"
  }, [_vm._v(" Recent Searchs")]), _vm._v(" "), _c("van-icon", {
    attrs: {
      size: "20",
      name: "delete-o"
    },
    on: {
      click: function click($event) {
        return _vm.doRemove();
      }
    }
  })], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "cache-list"
  }, _vm._l(_vm.cacheList, function (cacheItem, index) {
    return _c("div", {
      key: index,
      staticClass: "cache-item",
      on: {
        click: function click($event) {
          return _vm.onClickRight(cacheItem);
        }
      }
    }, [_vm._v("\n        " + _vm._s(cacheItem) + "\n      ")]);
  }), 0)]) : _vm._e()]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;