"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/checkbox/style");

var _checkbox = _interopRequireDefault(require("vant/es/checkbox"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/col/style");

var _col = _interopRequireDefault(require("vant/es/col"));

require("vant/es/row/style");

var _row = _interopRequireDefault(require("vant/es/row"));

require("core-js/modules/es6.function.name");

require("vant/es/popup/style");

var _popup = _interopRequireDefault(require("vant/es/popup"));

var _components;

var _default2 = {
  name: "FreightFeePopupItem",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _popup.default.name, _popup.default), (0, _defineProperty2.default)(_components, _row.default.name, _row.default), (0, _defineProperty2.default)(_components, _col.default.name, _col.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _checkbox.default.name, _checkbox.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), _components),
  props: {
    // 展示密码输入框
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    sensitive: {
      type: Number,
      default: null
    },
    selectWeight: {
      type: String,
      default: "0.0"
    },
    sensitiveLineList: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    selectOrderIdList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    exceptOrderIdList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      checked: true
    };
  },
  computed: {
    show_: {
      get: function get() {
        return this.show;
      },
      set: function set(val) {
        this.$emit('show', val);
      }
    }
  },
  created: function created() {},
  methods: {
    sendDelivery: function sendDelivery() {
      this.$router.push({
        path: '/previewDeliveryDetails',
        query: {
          sensitive: this.sensitive,
          selectOrderIdList: this.selectOrderIdList.toString(),
          exceptOrderIdList: this.exceptOrderIdList.toString()
        }
      });
    }
  }
};
exports.default = _default2;