"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-search", {
          attrs: {
            shape: "round",
            disabled: "",
            placeholder: "Plese search"
          },
          on: {
            click: function click($event) {
              return _vm.$router.push({
                path: "/search",
                query: {
                  fromPage: "Local"
                }
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function fn() {
              return [_vm.isLogin ? _c("div", [_c("span", {
                staticClass: "iconfont"
              }, [_vm._v("")])]) : _vm._e()];
            },
            proxy: true
          }]),
          model: {
            value: _vm.keyword,
            callback: function callback($$v) {
              _vm.keyword = $$v;
            },
            expression: "keyword"
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("header", [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "nav"
  }, _vm._l(_vm.localClassifyList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "nav-box",
      on: {
        click: function click($event) {
          return _vm.handleClassify(item.categoryId);
        }
      }
    }, [_c("van-icon", {
      attrs: {
        size: "24",
        name: item.categoryImage
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "van-ellipsis nav-box-title",
      class: {
        "nav-box-title-acitve": _vm.activeClassify === item.categoryId
      }
    }, [_vm._v(_vm._s(item.englishTitle))])], 1);
  }), 0)]), _vm._v(" "), _c("div", {
    staticClass: "local-good-list"
  }, [_c("TheGoodListCom", {
    key: _vm.activeClassify,
    attrs: {
      "category-id1": _vm.activeClassify === "0" ? null : _vm.activeClassify,
      "module-type": "1",
      "supply-type": 2
    }
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "title"
  }, [_c("div", [_vm._v("Goods in Nigeria")]), _vm._v(" "), _c("div", [_vm._v(">>>")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;