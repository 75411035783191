"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("core-js/modules/es6.function.name");

require("vant/es/uploader/style");

var _uploader = _interopRequireDefault(require("vant/es/uploader"));

var _oss = require("@/api/oss");

var _axios = _interopRequireDefault(require("axios"));

var _upload = require("@/api/upload");

var _components;

var _default = {
  name: "HeadImgUpload",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _uploader.default.name, _uploader.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), _components),
  props: {
    headImage: {
      type: String,
      default: ''
    },
    // 存放阿里云的文件路径  womataH5/headImage/
    ossKey: {
      type: String,
      default: 'womataH5/headImage/'
    }
  },
  data: function data() {
    return {
      fileList: [{
        url: this.headImage,
        status: 'done'
      }],

      /**
       *   'uploadImgUrlUK': 'https://womata.oss-accelerate.aliyuncs.com', //英国路线
            'uploadImgUrlUSA': 'https://womata-usa.oss-accelerate.aliyuncs.com', //美国路线
            'uploadImgUrlGR': 'https://womata-gr.oss-accelerate.aliyuncs.com', //德国路线
       */
      addressOss: (0, _toConsumableArray2.default)(_upload.uploadUrlList)[0],
      dataOss: {
        key: "headImage/",
        ossAccessKeyId: "",
        policy: "",
        success_action_status: 200,
        signature: "",
        callback: "",
        dir: ""
      }
    };
  },
  created: function created() {
    var _this = this;

    this.key = this.ossKey || 'womataH5/headImage/';
    (0, _oss.getPolicy)().then(function (res) {
      console.log(res);
      console.log("请求的地址是-----》", _this.addressOss);
      _this.dataOss = {
        key: res.dir + _this.dataOss.key,
        ossAccessKeyId: res.accessid,
        policy: res.policy,
        signature: res.signature,
        success_action_status: 200,
        callback: res.callback
      }; // 上传额外参数
    });
  },
  methods: {
    beforeRead: function beforeRead() {
      this.fileList = [{
        url: '',
        status: 'uploading'
      }];
    },
    afterRead: function afterRead(files) {
      var _this2 = this;

      try {
        this.$toast.loading({
          message: 'Loading...',
          duration: 0,
          forbidClick: true
        });
        var formData = new FormData();
        var currentTimeStamp = new Date().getTime(); // 当前时间戳

        formData.append("key", this.dataOss.key + currentTimeStamp + "_" + this.random_string(10) + files.file.name);
        formData.append("ossAccessKeyId", this.dataOss.ossAccessKeyId);
        formData.append("policy", this.dataOss.policy);
        formData.append("signature", this.dataOss.signature);
        formData.append("success_action_status", this.dataOss.success_action_status);
        formData.append("callback", this.dataOss.callback);
        formData.append("file", files.file);
        (0, _axios.default)({
          method: 'post',
          url: this.addressOss,
          data: formData
        }).then(function (res) {
          _this2.$toast.clear();

          _this2.$emit('fileImg', res.data.fileUrl);
        });
      } catch (e) {
        this.$toast.clear();
      }
    },
    handleUpload: function handleUpload() {
      var _this3 = this;

      this.$nextTick(function () {
        _this3.$refs['upload'].chooseFile();
      });
    },
    random_string: function random_string(len) {
      len = len || 32;
      var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      var maxPos = chars.length;
      var pwd = "";

      for (var i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }

      return pwd;
    }
  }
};
exports.default = _default;