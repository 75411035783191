"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "good-list page-background-color"
  }, [_c("van-pull-refresh", {
    attrs: {
      disabled: _vm.disabledRefresh
    },
    on: {
      refresh: _vm.initData
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [!_vm.isNullStatus ? _c("van-list", {
    attrs: {
      finished: _vm.finished,
      "finished-text": "No More ...",
      error: _vm.error,
      "error-text": ""
    },
    on: {
      "update:error": function updateError($event) {
        _vm.error = $event;
      },
      load: _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_c("masonry", {
    attrs: {
      cols: 2,
      gutter: 4
    }
  }, _vm._l(_vm.goodsList, function (item, index) {
    return _c("div", {
      key: index,
      on: {
        click: function click($event) {
          return _vm.toGoodDetail(item);
        }
      }
    }, [_c("TheWaterGoodCard", {
      attrs: {
        "show-womall": item.module === 1,
        "spu-name": item.spuName,
        "image-url": item.images,
        "offer-price": item.offerPrice,
        "supply-type": item.supplyType,
        "show-price": item.module === 1 && item.showPrice !== null && item.showPrice + "" !== "0" ? item.showPrice : null
      }
    })], 1);
  }), 0)], 1) : _c("van-empty", {
    attrs: {
      description: "No more data!"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/no_data.png")
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;