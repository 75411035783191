"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("van-card", {
    attrs: {
      thumb: _vm.ele.imagesUrl
    },
    on: {
      "click-thumb": function clickThumb($event) {
        return _vm.toGoodDetailPage(_vm.ele);
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("div", {
          staticClass: "van-multi-ellipsis--l3 my-title-tag"
        }, [_vm.ele.supplyType === 2 ? _c("span", {
          staticClass: "my-tag"
        }, [_vm._v("Goods in Nigeria")]) : _vm._e(), _c("span", {
          class: {
            isSale: !_vm.ele.isSale
          }
        }, [_vm._v(_vm._s(_vm.ele.spuNameEn))])])];
      },
      proxy: true
    }, {
      key: "price",
      fn: function fn() {
        return [_vm.ele.isSale ? _c("div", {
          staticClass: "price"
        }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.ele.price)))]) : _c("div", {
          staticClass: "another-one"
        }, [_vm._v("Please select another one.")])];
      },
      proxy: true
    }, {
      key: "num",
      fn: function fn() {
        return [_vm.ele.isSale ? _c("van-icon", {
          attrs: {
            name: require("@/assets/images/my_like/cart.png"),
            size: "20"
          }
        }) : _c("div", {
          staticClass: "invalid"
        }, [_vm._v("Invalid")])];
      },
      proxy: true
    }])
  });
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;