"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/overlay/style");

var _overlay = _interopRequireDefault(require("vant/es/overlay"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("core-js/modules/es6.function.name");

require("vant/es/field/style");

var _field = _interopRequireDefault(require("vant/es/field"));

var _vuex = require("vuex");

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// import VerifyCodeBtn from '@/components/VerifyCodeBtn'
var _default = {
  name: 'Login',
  components: (_components = {}, (0, _defineProperty2.default)(_components, _field.default.name, _field.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _overlay.default.name, _overlay.default), _components),
  data: function data() {
    return {
      showOverlay: false,
      emailShow: false,
      phoneNumber: '',
      password: '',
      passwordType: 'password'
    };
  },
  computed: {
    passwordIcon: function passwordIcon() {
      return this.passwordType === 'password' ? 'closed-eye' : 'eye';
    },
    emails: function emails() {
      if (!this.phoneNumber) return []; // 考虑到用户自己要输@符号，如果@符号首次出现的位置大于等于第零个位置时，则不处理

      if (this.phoneNumber.indexOf('@') > -1) return [];
      return [this.phoneNumber + '@gmail.com', this.phoneNumber + '@yahoo.com', this.phoneNumber + '@qq.com', this.phoneNumber + '@hotmail.com', this.phoneNumber + '@foxmail.com', this.phoneNumber + '@163.com', this.phoneNumber + '@126.com', this.phoneNumber + '@mail.com'];
    }
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    this.showOverlay = false;
    next();
  },
  methods: _objectSpread(_objectSpread({
    switchPasswordType: function switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    },
    handleLogin: function handleLogin() {
      var _this = this;

      this.$store.dispatch("systemConfig/firebaseLogEvent", "H5_click_login");
      this.showOverlay = true;
      var data = {
        phoneNumber: this.phoneNumber,
        password: this.password,
        $router: this.$router,
        $route: this.$route
      };
      this.login(data).then(function (res) {
        if (!res) _this.showOverlay = false;
      });
    }
  }, (0, _vuex.mapActions)({
    login: 'user/login'
  })), {}, {
    // 取消邮箱提示
    handleBlur: function handleBlur() {
      var _this2 = this;

      this.$nextTick(function () {
        var timer = setTimeout(function () {
          _this2.emailShow = false;
          clearTimeout(timer);
        }, 20);
      });
    },
    // 选中邮箱提示
    setInput: function setInput(mail) {
      this.phoneNumber = mail;
      this.emailShow = !this.emailShow;
    }
  })
};
exports.default = _default;