"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("van-nav-bar", {
    attrs: {
      title: "Settings",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "terms"
  }, [_c("van-cell", {
    attrs: {
      title: "Terms of service",
      "is-link": "",
      to: "terms"
    }
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Version Update",
      value: _vm.defaultSettings.Version
    }
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;