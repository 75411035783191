"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("vant/es/dialog/style");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/swipe-item/style");

var _swipeItem = _interopRequireDefault(require("vant/es/swipe-item"));

require("vant/es/swipe/style");

var _swipe = _interopRequireDefault(require("vant/es/swipe"));

require("vant/es/pull-refresh/style");

var _pullRefresh = _interopRequireDefault(require("vant/es/pull-refresh"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/cell/style");

var _cell = _interopRequireDefault(require("vant/es/cell"));

require("vant/es/badge/style");

var _badge = _interopRequireDefault(require("vant/es/badge"));

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

require("core-js/modules/es6.function.name");

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

var _FooterTabbar = _interopRequireDefault(require("components/FooterTabbar"));

var _vuex = require("vuex");

var _order = require("api/order");

var _coupon = require("api/coupon");

var _utils = require("utils");

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "Account",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _image.default.name, _image.default), (0, _defineProperty2.default)(_components, _badge.default.name, _badge.default), (0, _defineProperty2.default)(_components, _cell.default.name, _cell.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _pullRefresh.default.name, _pullRefresh.default), (0, _defineProperty2.default)(_components, _swipe.default.name, _swipe.default), (0, _defineProperty2.default)(_components, _swipeItem.default.name, _swipeItem.default), (0, _defineProperty2.default)(_components, "FooterTabbar", _FooterTabbar.default), _components),
  data: function data() {
    return {
      // 待付款订单数量
      paymentPending: "",
      // 待入库订单数量
      warehousePending: "",
      // 待发货订单数量
      globalWarehouse: "",
      // 待收货订单数量
      deliveredReviews: "",
      // 待提货订单数量
      pendingPickUp: "",
      // 已提货订单数量
      pickedUp: "",
      // 券码数量
      couponCodeCount: "",
      swipeList: [],
      refreshing: false
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    user: function user(state) {
      return state.user['user'];
    }
  })),
  created: function created() {
    this.init();
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)({
    loginOut: 'user/loginOut',
    getInfo: 'user/getInfo'
  })), {}, {
    // 退出登录提示
    handleLoginOut: function handleLoginOut() {
      var _this = this;

      _dialog.default.confirm({
        title: 'Logout?',
        theme: 'round-button'
      }).then(function () {
        _this.loginOut();
      }).catch(function () {// on cancel
      });
    },
    onRefresh: function onRefresh() {
      // this.pageNumber = 1
      this.init(); // window.location.reload()
    },
    copyUserCode: function copyUserCode(userCode) {
      (0, _utils.copyTextToClipboard)(userCode);

      _toast.default.success('Copy ID success');
    },
    init: function init() {
      var _this2 = this;

      this.refreshing = false;
      this.swipeList = [];
      this.getInfo();
      (0, _order.getOrderStatus)().then(function (res) {
        _this2.paymentPending = res.data.paymentPending;
        _this2.warehousePending = res.data.warehousePending;
        _this2.globalWarehouse = res.data.globalWarehouse;
        _this2.deliveredReviews = res.data.deliveredReviews;
        _this2.pendingPickUp = res.data.pendingPickUp;
        _this2.pickedUp = res.data.pickedUp;
      });
      (0, _coupon.getCouponCodeCount)({
        status: 1
      }).then(function (res) {
        _this2.couponCodeCount = res.data;
      });
      (0, _order.getInvoiceList)({
        status: 3
      }).then(function (res) {
        var _res$data, _res$data$lists;

        if ((_res$data = res.data) !== null && _res$data !== void 0 && (_res$data$lists = _res$data.lists) !== null && _res$data$lists !== void 0 && _res$data$lists.length) {
          _this2.swipeList.push(_objectSpread(_objectSpread({}, res.data.lists[0]), {}, {
            type: 2
          }));
        }
      });
      (0, _order.getOrderList)({
        status: 8,
        queryPage: 2,
        queryPremium: 2
      }).then(function (res) {
        var _res$data2, _res$data2$data;

        if ((_res$data2 = res.data) !== null && _res$data2 !== void 0 && (_res$data2$data = _res$data2.data) !== null && _res$data2$data !== void 0 && _res$data2$data.length) {
          _this2.swipeList.push(_objectSpread(_objectSpread({}, res.data.data[0]["orderDetails"][0]), {}, {
            type: 1
          }));
        }
      });
      (0, _order.getOrderList)({
        status: 9,
        queryPage: 2
      }).then(function (res) {
        var _res$data3, _res$data3$data;

        if ((_res$data3 = res.data) !== null && _res$data3 !== void 0 && (_res$data3$data = _res$data3.data) !== null && _res$data3$data !== void 0 && _res$data3$data.length) {
          _this2.swipeList.push(_objectSpread(_objectSpread({}, res.data.data[0]["orderDetails"][0]), {}, {
            type: 3
          }));
        }
      });
    }
  })
};
exports.default = _default;