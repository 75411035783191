"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-search", {
          attrs: {
            shape: "round",
            disabled: "",
            placeholder: "Plese paste the product link and search"
          },
          on: {
            click: function click($event) {
              return _vm.$router.push({
                path: "/search",
                query: {
                  fromPage: "NewArrival"
                }
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function fn() {
              return [_vm.isLogin ? _c("div", [_c("span", {
                staticClass: "iconfont"
              }, [_vm._v("")])]) : _vm._e()];
            },
            proxy: true
          }]),
          model: {
            value: _vm.keyword,
            callback: function callback($$v) {
              _vm.keyword = $$v;
            },
            expression: "keyword"
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("div", {
    staticClass: "title"
  }, [_vm._v("New arrivals")]), _vm._v(" "), _c("van-pull-refresh", {
    attrs: {
      "pulling-text": "Pull down refresh",
      "loosing-text": "Release to refresh",
      "loading-text": "Refreshing",
      "success-text": "Refresh completed"
    },
    on: {
      refresh: _vm.onRefresh
    },
    model: {
      value: _vm.refreshing,
      callback: function callback($$v) {
        _vm.refreshing = $$v;
      },
      expression: "refreshing"
    }
  }, [_vm.list.length ? _c("van-list", {
    attrs: {
      finished: _vm.finished,
      "finished-text": "No More ...",
      "immediate-check": false
    },
    on: {
      load: _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (item, index) {
    return _c("van-card", {
      key: index,
      attrs: {
        thumb: item.images
      },
      on: {
        click: function click($event) {
          return _vm.toGoodDetailPage(item);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c("div", {
            staticClass: "van-multi-ellipsis--l3 my-title-tag"
          }, [_vm._v(_vm._s(item.spuName))])];
        },
        proxy: true
      }, {
        key: "price",
        fn: function fn() {
          return [_c("span", {
            staticClass: "offer-price"
          }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(item.offerPrice)))])];
        },
        proxy: true
      }], null, true)
    });
  }), 1) : _c("van-empty", {
    attrs: {
      description: "No more data!"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/no_data.png")
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;