"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.function.name");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticStyle: {
      overflowY: "scroll",
      paddingBottom: "46px"
    }
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Bnak transfer recharge",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("header", [_c("div", {
    staticClass: "photo"
  }, [_c("van-icon", {
    attrs: {
      name: "credit-pay",
      size: "24"
    }
  }), _vm._v(" "), _c("van-divider", {
    style: {
      background: "#fff",
      flex: "1",
      margin: "0 16px"
    }
  }), _vm._v(" "), _c("van-icon", {
    attrs: {
      name: "edit",
      size: "24"
    }
  }), _vm._v(" "), _c("van-divider", {
    staticClass: "opacity",
    style: {
      background: "#fff",
      flex: "1",
      margin: "0 16px"
    }
  }), _vm._v(" "), _c("van-icon", {
    staticClass: "opacity",
    attrs: {
      name: "success",
      size: "24"
    }
  })], 1), _vm._v(" "), _vm._m(0)]), _vm._v(" "), _c("van-notice-bar", {
    attrs: {
      "left-icon": "info-o",
      wrapable: "",
      scrollable: false,
      mode: "closeable",
      text: "Please fill in your WOMATA's ID when using bank transfer and we will confirm it within 24 hours. Sunday will be delayed by one day."
    }
  }), _vm._v(" "), _c("van-form", {
    ref: "form",
    staticClass: "form",
    attrs: {
      "validate-first": ""
    },
    on: {
      failed: _vm.onFailed,
      submit: _vm.submit
    }
  }, [_c("van-field", {
    attrs: {
      name: "name",
      label: "Name of remitter：",
      required: "",
      autocomplete: "off",
      rules: [{
        required: true,
        message: "Name of remitter is required"
      }]
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      name: "account",
      type: "digit",
      required: "",
      label: "Remittance bank account：",
      rules: [{
        required: true,
        message: "Remittance bank account is required"
      }]
    },
    model: {
      value: _vm.account,
      callback: function callback($$v) {
        _vm.account = $$v;
      },
      expression: "account"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      name: "amount",
      type: "digit",
      required: "",
      label: "Transfer amount：",
      formatter: _vm.formatter,
      rules: [{
        required: true,
        message: "Please enter the transfer amount"
      }]
    },
    model: {
      value: _vm.amount,
      callback: function callback($$v) {
        _vm.amount = $$v;
      },
      expression: "amount"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      name: "remark",
      label: "Reference Number："
    },
    model: {
      value: _vm.remark,
      callback: function callback($$v) {
        _vm.remark = $$v;
      },
      expression: "remark"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "upload-title"
  }, [_vm._v("Upload transfer voucher")]), _vm._v(" "), _c("div", {
    staticClass: "upload"
  }, [_c("Upload", {
    attrs: {
      "img-arr": _vm.screenshots,
      accept: "image/*"
    },
    on: {
      addFileImg: _vm.addFileImg,
      delImg: _vm.delImg
    }
  }), _vm._v(" "), _vm.shadow ? _c("div", {
    staticClass: "shadow",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.handleShadow.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "submit"
  }, [_c("van-button", {
    staticStyle: {
      width: "36%"
    },
    attrs: {
      round: "",
      color: "#EA3340",
      "native-type": "submit"
    }
  }, [_vm._v("OK")])], 1)], 1), _vm._v(" "), _c("van-dialog", {
    attrs: {
      "confirm-button-text": "OK",
      "confirm-button-color": "#EA3340",
      theme: "round-button",
      transition: "fade"
    },
    model: {
      value: _vm.dialogShow,
      callback: function callback($$v) {
        _vm.dialogShow = $$v;
      },
      expression: "dialogShow"
    }
  }, [_c("div", {
    staticClass: "paragraph"
  }, [_vm._v("You need to upload a picture to prove your\n      transfer to our account, similar to the\n      following:")]), _vm._v(" "), _c("img", {
    attrs: {
      src: require("@/assets/images/account/bank_recharge_eg.png")
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "paragraph"
  }, [_vm._v("*The key information to be provided includes "), _c("span", {
    staticStyle: {
      color: "#d12f3a",
      textDecoration: "underline"
    }
  }, [_vm._v("the remitter's full name, bank serial number.\n      transaction time. transaction amount. receivina account.")])])])], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "explain"
  }, [_c("div", [_vm._v("Transfer Money to Designated Account")]), _vm._v(" "), _c("div", [_vm._v("Fill the transfer infomation")]), _vm._v(" "), _c("div", {
    staticClass: "opacity"
  }, [_vm._v("Finish Submit")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;