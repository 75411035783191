"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-search", {
          attrs: {
            shape: "round",
            disabled: "",
            placeholder: "Plese search"
          },
          on: {
            click: function click($event) {
              return _vm.$router.push({
                path: "/search",
                query: {
                  fromPage: "TopSale"
                }
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "right-icon",
            fn: function fn() {
              return [_vm.isLogin ? _c("div", [_c("span", {
                staticClass: "iconfont"
              }, [_vm._v("")])]) : _vm._e()];
            },
            proxy: true
          }]),
          model: {
            value: _vm.keyword,
            callback: function callback($$v) {
              _vm.keyword = $$v;
            },
            expression: "keyword"
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("van-pull-refresh", {
    attrs: {
      "pulling-text": "Pull down refresh",
      "loosing-text": "Release to refresh",
      "loading-text": "Refreshing",
      "success-text": "Refresh completed"
    },
    on: {
      refresh: _vm.onRefresh
    },
    model: {
      value: _vm.refreshing,
      callback: function callback($$v) {
        _vm.refreshing = $$v;
      },
      expression: "refreshing"
    }
  }, [_vm.list.length ? _c("van-list", {
    attrs: {
      finished: _vm.finished,
      "finished-text": "No More ...",
      "immediate-check": false
    },
    on: {
      load: _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_c("div", {
    staticClass: "top"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("Top Sale")]), _vm._v(" "), _c("div", {
    staticClass: "wrap"
  }, [_vm._l(_vm.list, function (item, index) {
    return [index < 6 ? _c("div", {
      key: index,
      staticClass: "box",
      on: {
        click: function click($event) {
          return _vm.toGoodDetailPage(item);
        }
      }
    }, [_c("van-image", {
      attrs: {
        width: "94",
        height: "94",
        src: item.images
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "box-title van-ellipsis"
    }, [_vm._v(_vm._s(item.spuName))]), _vm._v(" "), _c("div", {
      staticClass: "box-price van-ellipsis"
    }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(item.offerPrice)))])], 1) : _vm._e()];
  })], 2)]), _vm._v(" "), _vm._l(_vm.list, function (item, index) {
    return [index > 5 ? _c("van-card", {
      key: index,
      attrs: {
        thumb: item.images
      },
      on: {
        click: function click($event) {
          return _vm.toGoodDetailPage(item);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c("div", {
            staticClass: "van-multi-ellipsis--l3 my-title-tag"
          }, [item.supplyType === 2 ? _c("span", {
            staticClass: "my-tag"
          }, [_vm._v("Goods in Nigeria")]) : _vm._e(), _vm._v(_vm._s(item.spuName))])];
        },
        proxy: true
      }, {
        key: "tags",
        fn: function fn() {
          return [item.spuTitle.filter(Boolean).length ? _c("div", {
            staticClass: "theme-color van-ellipsis",
            staticStyle: {
              marginTop: "7px"
            }
          }, _vm._l(item.spuTitle.filter(Boolean), function (e, i) {
            return _c("span", {
              key: i
            }, [_vm._v("\n                " + _vm._s("".concat(e, " ").concat(item.spuTitle[i + 1] ? "|" : "")) + "\n              ")]);
          }), 0) : _vm._e()];
        },
        proxy: true
      }, {
        key: "price",
        fn: function fn() {
          return [_c("span", {
            staticClass: "offer-price"
          }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(item.offerPrice)))]), Number(item.showPrice) ? _c("span", {
            staticClass: "show-price"
          }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(item.showPrice)))]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }) : _vm._e()];
  })], 2) : _c("van-empty", {
    attrs: {
      description: "No more data!"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/no_data.png")
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;