"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCart = addCart;
exports.delCart = delCart;
exports.editCart = editCart;
exports.getCartCount = getCartCount;
exports.getCartList = getCartList;

var _request = _interopRequireDefault(require("@/utils/request"));

// 加入购物车
function addCart(data) {
  return (0, _request.default)({
    url: 'cart_service/cart',
    method: 'post',
    data: data
  });
} // 获取购物车列表


function getCartList(params) {
  return (0, _request.default)({
    url: 'cart_service/cart',
    method: 'get',
    params: params
  });
} // 删除购物车


function delCart(data) {
  return (0, _request.default)({
    url: 'cart_service/cart',
    method: 'delete',
    data: data
  });
} // 编辑购物车商品数量


function editCart(cartId, data) {
  return (0, _request.default)({
    url: 'cart_service/cart/' + cartId,
    method: 'put',
    data: data
  });
} // 获取购物车数量


function getCartCount() {
  return (0, _request.default)({
    url: 'cart_service/cart/detail_count',
    method: 'get'
  });
}