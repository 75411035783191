"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("div", {
    staticClass: "title"
  }, [_c("div", {
    staticClass: "company-name"
  }, [_vm._t("checkbox"), _vm._v(" "), _c("van-icon", {
    attrs: {
      size: "16",
      name: _vm.order.type === 1 ? require("@/assets/images/cart/wo_store.png") : require("@/assets/images/cart/common_store.png")
    }
  }), _vm._v(_vm._s(_vm.order.sellerCompanyName) + "\n    ")], 2), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("getOrderStatusName")(_vm.order.status)))])]), _vm._v(" "), _c("div", {
    staticClass: "wrap",
    on: {
      click: _vm.handleGoodCard
    }
  }, [_c("van-image", {
    attrs: {
      width: "70",
      height: "70",
      src: require("@/assets/images/order/pack.png")
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "good-name"
  }, [_vm._v(_vm._s(_vm.order.agentGoodName))]), _vm._v(" "), _c("div", {
    staticClass: "express"
  }, [_vm._v("Express No : " + _vm._s(_vm.order.agentExpress))]), _vm._v(" "), [2, 3, 4, 6, 7].includes(_vm.order.status) ? _c("div", {
    staticClass: "size"
  }, [_c("span", [_vm._v("Size : " + _vm._s(_vm.order.specs))]), _vm._v(" "), _c("span", [_vm._v("x" + _vm._s(_vm.order.specNumber))])]) : _vm._e()])], 1), _vm._v(" "), _vm.order.sensitivity === 1 ? _c("van-button", {
    staticStyle: {
      marginTop: "8px",
      padding: "3px 8px",
      height: "auto",
      lineHeight: "1"
    },
    attrs: {
      plain: "",
      hairline: "",
      round: "",
      size: "mini",
      color: "#EA3340"
    }
  }, [_vm._v("Sensitivity")]) : _vm._e(), _vm._v(" "), [2, 3, 4, 6, 7].includes(_vm.order.status) ? _c("div", {
    staticClass: "total"
  }, [_vm._v("Total Amount: \n    "), _vm.order.payCurrency === "CNY" ? _c("span", [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.order.subtotal, _vm.order.exchangeRate ? JSON.parse(_vm.order.exchangeRate || null) : null)))]) : _c("span", [_vm._v("$" + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.order.subtotal).toFixed(2))))])]) : _vm._e(), _vm._v(" "), _vm.order.refundStatus === 1 ? _c("div", {
    staticClass: "amount"
  }, [_c("div", {
    staticClass: "refunding"
  }, [_c("van-icon", {
    attrs: {
      size: "12",
      name: require("@/assets/images/order/refresh.png")
    }
  }), _c("span", [_vm._v("Refunding")])], 1)]) : _vm._e(), _vm._v(" "), _vm.order.refundStatus === 2 ? _c("div", {
    staticClass: "amount"
  }, [_vm._v("Refunded Amount: "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.order.refundAmount).toFixed(2))))])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "footer"
  }, [_vm.order.status > 1 && Number(_vm.order.estimateWeight) ? _c("div", {
    staticClass: "weight"
  }, [_vm._v("Estimated weight："), _c("span", [_vm._v(_vm._s(_vm.order.estimateWeight) + "KG")])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "btn"
  }, [_vm.order.status === 1 ? _c("van-button", {
    attrs: {
      plain: "",
      hairline: "",
      round: "",
      size: "mini",
      color: "#515151"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.cancelOrder(_vm.order.id);
      }
    }
  }, [_vm._v("Order cancel")]) : _vm._e()], 1)])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;