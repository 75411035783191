"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "My Gain",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "card balance-card"
  }, [_c("div", {
    staticClass: "wallet-title"
  }, [_vm._v("Current commission balance")]), _vm._v(" "), _c("div", {
    staticClass: "balance"
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/my_gain/wallet.png"),
      size: "24"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "van-ellipsis money"
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.userCommissionInfo.commissionBalance).toFixed(2))))]), _vm._v(" "), _vm.userCommissionInfo.canWithdraw === 1 ? _c("div", {
    staticClass: "withdrawal",
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/withdrawal",
          query: {
            commissionBalance: _vm.userCommissionInfo.commissionBalance
          }
        });
      }
    }
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/my_gain/withdrawal.png"),
      size: "20"
    }
  }), _vm._v("Withdrawal")], 1) : _vm._e()], 1)]), _vm._v(" "), _c("div", {
    staticClass: "card transactions-card"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("div", {
    staticClass: "kind",
    on: {
      click: function click($event) {
        return _vm.$router.push("/allCommissioinOrders");
      }
    }
  }, [_c("div", {
    staticClass: "van-ellipsis"
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.userCommissionInfo.alreadyCommission).toFixed(2))))]), _vm._v(" "), _c("div", [_vm._v("Gained")])]), _vm._v(" "), _c("div", {
    staticClass: "kind",
    on: {
      click: function click($event) {
        return _vm.$router.push("/allCommissioinOrders");
      }
    }
  }, [_c("div", {
    staticClass: "van-ellipsis"
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.userCommissionInfo.awaitCommission).toFixed(2))))]), _vm._v(" "), _c("div", [_vm._v("Upcoming")])]), _vm._v(" "), _c("div", {
    staticClass: "kind",
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/transactions",
          query: {
            balanceType: 2
          }
        });
      }
    }
  }, [_c("div", {
    staticClass: "van-ellipsis"
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.userCommissionInfo.usedCommission).toFixed(2))))]), _vm._v(" "), _c("div", [_vm._v("Used")])])]), _vm._v(" "), _c("van-divider"), _vm._v(" "), _c("div", {
    staticClass: "theme-color",
    staticStyle: {
      fontSize: "8px"
    }
  }, [_vm._v("*Commission can't be obtained for orders with refund or using coupons.")])], 1), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("van-cell", {
    attrs: {
      title: "All commission orders",
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/allCommissioinOrders");
      }
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c("van-icon", {
          staticClass: "left-icon",
          attrs: {
            name: require("@/assets/images/my_gain/all.png"),
            size: "16"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("van-cell", {
    attrs: {
      title: "My team",
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/myTeam");
      }
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c("van-icon", {
          staticClass: "left-icon",
          attrs: {
            name: require("@/assets/images/my_gain/team.png"),
            size: "16"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("van-cell", {
    attrs: {
      title: "Rule description",
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/ruleDescription");
      }
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c("van-icon", {
          staticClass: "left-icon",
          attrs: {
            name: require("@/assets/images/my_gain/description.png"),
            size: "16"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _c("GainList", {
    staticClass: "gain-list"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;