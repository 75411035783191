"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var _typeof2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.regexp.match");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

var _api = require("@/api");

var _cmmission = require("@/api/cmmission.js");

var _vuex = require("vuex");

var _auth = require("@/utils/auth");

var _localStorage = require("@/utils/local-storage.js");

var _utils = require("@/utils");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// import { setLocation } from '@/utils/location' // get loaction
var _default = {
  name: "App",
  data: function data() {
    return {
      showOpen: true
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    user: function user(state) {
      return state.user['user'];
    },
    downloadUrl: function downloadUrl(state) {
      return state.systemConfig['downloadUrl'];
    },
    schemeUrl: function schemeUrl(state) {
      return state.systemConfig['schemeUrl'];
    },
    exchangeRateList: function exchangeRateList(state) {
      return state.exchangeRate['exchangeRateList'];
    }
  })),
  created: function created() {
    var _this = this;

    if (!this.exchangeRateList.length) {
      this.$store.dispatch('exchangeRate/getExchangeRate');
    }

    this.$store.dispatch("systemConfig/firebaseLogEvent", "H5_to_index");
    this.haveBindSale();
    var u = window.navigator.userAgent;
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

    if (isAndroid) {
      (0, _api.getAndroidLink)().then(function (res) {
        _this.$store.commit("systemConfig/setDownloadUrl", res.data);
      }).catch(function (e) {
        console.log(e);
      });
    }

    if (isIOS) {
      this.$store.commit("systemConfig/setDownloadUrl", "https://apps.apple.com/cn/app/womata/id1519799980");
    } // setLocation(); // 2.0版本关闭

  },
  methods: {
    haveBindSale: function haveBindSale() {
      var _this$$route$query = this.$route.query,
          openType = _this$$route$query.openType,
          userCode = _this$$route$query.userCode,
          roleId = _this$$route$query.roleId,
          module = _this$$route$query.module,
          parentRoleId = _this$$route$query.parentRoleId,
          parentCode = _this$$route$query.parentCode; // 判断是否有分销

      if (openType === "binding") {
        console.log("分销分享链接----------------");

        if ((0, _auth.getToken)()) {
          (0, _cmmission.bindSale)({
            userId: this.user.userId,
            userCode: userCode,
            roleId: roleId,
            module: Number(module),
            parentRoleId: parentRoleId,
            parentCode: parentCode
          }).then(function (res) {
            console.log("绑定成功");
          }).catch(function (e) {
            console.log(e);
          });
        } else {
          // 保存分销参数 等用户注册或登录后再绑定
          (0, _localStorage.add)("saleQuery", JSON.stringify(this.$route.query));
        }
      }
    },
    // 点击打开app
    openTuer: function openTuer() {
      var _this2 = this;

      this.$store.dispatch("systemConfig/firebaseLogEvent", "H5_click_open_app");
      this.jumpApp(this.schemeUrl); // 无响应或者没安装跳转下载

      var t = Date.now();
      var r = this.downloadUrl;
      this.timer = setTimeout(function () {
        return Date.now() - t > 2200 ? (clearTimeout(_this2.timer), !1) : !document.webkitHidden && !document.hidden && void (location.href = r);
      }, 2000);
    },
    // 跳转打开app
    jumpApp: function jumpApp(t) {
      var openType = null;
      var data = null;

      if ((0, _localStorage.get)("saleQuery") && (0, _typeof2.default)(JSON.parse((0, _localStorage.get)("saleQuery"))) === "object") {
        data = JSON.parse((0, _localStorage.get)("saleQuery"));
        openType = data["openType"];
      }

      console.log((0, _utils.queryParams)(data));
      var query = openType === "binding" ? "?" + (0, _utils.queryParams)(data) : "";

      try {
        var e = navigator.userAgent.toLowerCase();
        var n = e.match(/cpu iphone os (.*?) like mac os/);

        if (n = n !== null ? n[1].replace(/_/g, ".") : 0, parseInt(n) >= 9) {
          window.location.href = t + query;
        } else {
          // var r = document.createElement("iframe");
          // (r.src = t), (r.style.display = "none"), document.body.appendChild(r);
          window.location.href = t + query;
        }
      } catch (e) {
        window.location.href = t + query;
      }
    }
  }
};
exports.default = _default;