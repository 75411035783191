"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/number-keyboard/style");

var _numberKeyboard = _interopRequireDefault(require("vant/es/number-keyboard"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/field/style");

var _field = _interopRequireDefault(require("vant/es/field"));

require("vant/es/form/style");

var _form = _interopRequireDefault(require("vant/es/form"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _user = require("api/user");

var _vuex = require("vuex");

var _validate = require("utils/validate");

var _utils = require("utils");

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "PaymentPassword",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _form.default.name, _form.default), (0, _defineProperty2.default)(_components, _field.default.name, _field.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _numberKeyboard.default.name, _numberKeyboard.default), _components),
  data: function data() {
    return {
      keyboardShow: false,
      keyboardShow2: false,
      account: '',
      account2: '',
      payPassword: '',
      tmpPassword: '',
      verifyCode: '',
      counter: '',
      pattern: /\d{6}/,
      timer: null
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    user: function user(state) {
      return state.user['user'];
    }
  })),
  created: function created() {
    this.account = this.user.email;
    this.account2 = (0, _utils.regEmail)(this.user.email);
  },
  methods: {
    validPassword: _validate.validPassword,
    noBomBox: function noBomBox() {
      document.activeElement.blur();
    },
    // 密码不一致
    comparePassword: function comparePassword(val) {
      return val === this.tmpPassword;
    },
    // 后退
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    },
    // 获取邮箱验证码
    getCode: function getCode() {
      var _this = this;

      (0, _user.getCode)(this.account).then(function (res) {
        _this.countDown();

        _toast.default.success(res.message);
      });
    },
    // 表单验证无误
    submit: function submit(values) {
      var _this2 = this;

      (0, _user.PayPassword)({
        payPassword: this.payPassword,
        verifyCode: this.verifyCode,
        type: 0
      }).then(function (res) {
        _toast.default.success(res.message);

        _this2.$router.go(-1);
      });
    },
    // 倒计时
    countDown: function countDown() {
      var _this3 = this;

      this.counter = 60;
      this.timer = setInterval(function () {
        _this3.counter--;

        if (_this3.counter === 0) {
          // 当计时等于零时，取消该计时器
          clearInterval(_this3.timer);
        }
      }, 1000);
    },
    // 表单验证错误
    onFailed: function onFailed(errorInfo) {
      console.log("failed", errorInfo);
    }
  }
};
exports.default = _default;