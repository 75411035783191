"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: _vm.navBarTitle,
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go("-1");
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, _vm._l(_vm.dataObj.orderDetails, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "wrap"
    }, [_c("van-image", {
      attrs: {
        width: "70",
        height: "70",
        src: item.mainImage
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "content"
    }, [_c("div", {
      staticClass: "good-name"
    }, [_vm._v(_vm._s(item.goodsNameEn))]), _vm._v(" "), _c("div", {
      staticClass: "size"
    }, [_vm._v(_vm._s(_vm._f("getSkuInfosEn")(item.skuInfosEn && JSON.parse(item.skuInfosEn))))]), _vm._v(" "), _c("div", {
      staticClass: "info"
    }, [_c("div", {
      staticClass: "piece"
    }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(item.unitPrice, JSON.parse(_vm.dataObj.exchangeRate)))), _c("span", {
      staticClass: "piece"
    }, [_vm._v(" /Piece")])]), _vm._v(" "), _c("div", [_vm._v("x" + _vm._s(item.quantity)), _c("van-stepper", {
      attrs: {
        min: 0,
        max: item.quantity,
        step: 1,
        "default-value": "0",
        "input-width": "40",
        "button-size": "16",
        integer: "",
        "disable-input": ""
      },
      on: {
        change: _vm._getPostStationPrice
      },
      model: {
        value: item.quantity2,
        callback: function callback($$v) {
          _vm.$set(item, "quantity2", $$v);
        },
        expression: "item.quantity2"
      }
    })], 1)])])], 1);
  }), 0), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "reason-title"
  }, [_vm._v("Reason for " + _vm._s(_vm.$route.query.afterType === "1" ? "return&refund" : _vm.$route.query.afterType === "2" ? "refund" : "exchange"))]), _vm._v(" "), ["1", "2"].includes(_vm.$route.query.afterType) ? _c("van-field", {
    attrs: {
      readonly: "",
      label: "Refund Amount",
      "input-align": "right",
      "label-width": "7em"
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c("span", {
          staticClass: "theme-color"
        }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.getRefundAmount, JSON.parse(_vm.dataObj.exchangeRate))))])];
      },
      proxy: true
    }], null, false, 961628855)
  }) : _vm._e(), _vm._v(" "), ["1", "3"].includes(_vm.$route.query.afterType) ? _c("van-cell", {
    attrs: {
      "is-link": ""
    },
    on: {
      click: _vm.handlePackageStore
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm.selectPackageStore ? _c("span", [_vm._v(_vm._s(_vm.selectPackageStore.nameEn))]) : _c("span", [_vm._v("Return address")])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn() {
        return [_vm.selectPackageStore ? _c("span", {
          staticStyle: {
            color: "#FF0037"
          }
        }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(_vm.selectPackageStore.warehouseType === 1 ? "0.00" : _vm.stationBill && Number(_vm.stationBill.totalAmount).toFixed(2))))]) : _vm._e()];
      },
      proxy: true
    }], null, false, 2021179377)
  }) : _vm._e(), _vm._v(" "), _c("van-field", {
    attrs: {
      readonly: "",
      clickable: "",
      label: "Reason",
      value: _vm.formData.reason,
      placeholder: "Please select",
      "input-align": "right"
    },
    on: {
      click: function click($event) {
        _vm.showPicker = true;
      }
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      autosize: "",
      label: "Description",
      type: "textarea",
      maxlength: "200",
      placeholder: "Please fill in the application instructions in detail",
      "show-word-limit": ""
    },
    model: {
      value: _vm.formData.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "remark", $$v);
      },
      expression: "formData.remark"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      name: "uploader",
      label: "Please upload voucher",
      "label-width": "10.4em"
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c("Upload", {
          key: _vm.indexTmp,
          attrs: {
            "img-arr": _vm.screenshots,
            accept: "image/*",
            "preview-size": 66
          },
          on: {
            addFileImg: _vm.addFileImg,
            delImg: _vm.delImg
          }
        })];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "button-wrap"
  }, [_c("van-button", {
    attrs: {
      size: "large",
      round: "",
      type: "info",
      block: "",
      color: "#EA3340"
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("Submit")])], 1), _vm._v(" "), _c("van-popup", {
    attrs: {
      round: "",
      position: "bottom"
    },
    model: {
      value: _vm.showPicker,
      callback: function callback($$v) {
        _vm.showPicker = $$v;
      },
      expression: "showPicker"
    }
  }, [_c("van-picker", {
    attrs: {
      "show-toolbar": "",
      columns: _vm.columns
    },
    on: {
      cancel: function cancel($event) {
        _vm.showPicker = false;
      },
      confirm: _vm.onConfirm
    }
  })], 1), _vm._v(" "), _vm.showDialog ? _c("van-dialog", {
    attrs: {
      title: "Tips",
      theme: "round-button",
      "confirm-button-text": "Yes,I see."
    },
    on: {
      confirm: function confirm($event) {
        _vm.showDialog = false;

        _vm.$router.replace({
          name: "Refund",
          params: {
            type: 1,
            status: 1
          }
        });
      }
    },
    model: {
      value: _vm.showDialog,
      callback: function callback($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("div", {
    staticStyle: {
      textAlign: "center"
    }
  }, [_vm._v("Submitted successfully!")]), _vm._v(" "), _c("div", {
    staticStyle: {
      textAlign: "center"
    }
  }, [_vm._v("Please wait for approval.")])]) : _vm._e()], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;