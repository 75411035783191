"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("core-js/modules/es6.function.name");

var _api = require("api");

var _JSON$parse, _JSON$parse2, _mutations;

var SET_DEFAULT_CURRENCY = 'setDefaultCurrency'; // 默认币种

var SET_EXCHANGE_RATE = 'setExchangeRate'; // 汇率列表

var _default = {
  namespaced: true,
  state: {
    defaultCurrency: {
      name: ((_JSON$parse = JSON.parse(localStorage.getItem('defaultCurrency'))) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.name) || 'NGN',
      unit: ((_JSON$parse2 = JSON.parse(localStorage.getItem('defaultCurrency'))) === null || _JSON$parse2 === void 0 ? void 0 : _JSON$parse2.unit) || '₦'
    },
    exchangeRateList: []
  },
  mutations: (_mutations = {}, (0, _defineProperty2.default)(_mutations, SET_DEFAULT_CURRENCY, function (state, data) {
    state.defaultCurrency = data;
  }), (0, _defineProperty2.default)(_mutations, SET_EXCHANGE_RATE, function (state, data) {
    state.exchangeRateList = data;
  }), _mutations),
  actions: {
    setDefaultCurrency: function setDefaultCurrency(_ref, data) {
      var commit = _ref.commit;
      localStorage.setItem('defaultCurrency', JSON.stringify(data));
      commit(SET_DEFAULT_CURRENCY, data);
    },
    getExchangeRate: function getExchangeRate(_ref2) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        (0, _api.getExchangeRateList)().then(function (response) {
          commit(SET_EXCHANGE_RATE, response.data);
          resolve(response.data);
        }).catch(function (error) {
          reject(error);
        });
      });
    }
  },
  getters: {
    defaultCurrency: function defaultCurrency(state) {
      return state.defaultCurrency;
    },
    exchangeRateList: function exchangeRateList(state) {
      return state.exchangeRateList;
    }
  }
};
exports.default = _default;