"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "good-detail-view page-background-color"
  }, [_c("div", {
    staticClass: "nav-bar"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/goods_detail/on_back.png")
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", [_c("img", {
    attrs: {
      src: require("@/assets/images/goods_detail/on_home.png")
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/");
      }
    }
  }), _vm._v(" "), _c("img", {
    attrs: {
      src: require("@/assets/images/goods_detail/on_share.png")
    },
    on: {
      click: _vm.copy
    }
  })])]), _vm._v(" "), _vm.goodsContent ? _c("div", {
    staticClass: "page-view"
  }, [_c("div", {
    staticClass: "swipe-and-price"
  }, [_c("van-swipe", {
    staticStyle: {
      color: "black"
    },
    attrs: {
      autoplay: 3000,
      "lazy-render": "",
      height: 400,
      "stop-propagation": false
    },
    on: {
      change: _vm.changeSwipe
    },
    scopedSlots: _vm._u([{
      key: "indicator",
      fn: function fn() {
        return [_c("div", {
          staticClass: "swipe-indicator"
        }, [_c("div", {
          staticClass: "custom-indicator"
        }, [_c("div", [_c("van-icon", {
          attrs: {
            name: "photo-o",
            size: "14"
          }
        }), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.startPosition + 1) + "/" + _vm._s(_vm.imgList.length))])], 1)]), _vm._v(" "), Number(_vm.goodsCommission) ? _c("div", {
          staticClass: "commission",
          on: {
            click: function click($event) {
              _vm.showShare = true;
            }
          }
        }, [_c("div", [_vm._v("You may gain "), _c("span", {
          staticClass: "commission-price"
        }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.goodsCommission)))]), _vm._v(" by recommending friends to buy")]), _vm._v(" "), _c("van-icon", {
          attrs: {
            name: "share-o",
            size: "20"
          }
        })], 1) : _vm._e()])];
      },
      proxy: true
    }], null, false, 1758658884)
  }, _vm._l(_vm.imgList, function (image, imageIndex) {
    return _c("van-swipe-item", {
      key: imageIndex,
      on: {
        click: function click($event) {
          return _vm.onTapSwipe();
        }
      }
    }, [_c("img", {
      staticClass: "swipe-img",
      attrs: {
        src: _vm._f("filterGoodImg")(image, _vm.goodsContent.goodsSource)
      }
    })]);
  }), 1), _vm._v(" "), _c("van-image-preview", {
    attrs: {
      images: _vm.imagesPreviewLists,
      "start-position": _vm.startPosition
    },
    model: {
      value: _vm.imagePreviewShow,
      callback: function callback($$v) {
        _vm.imagePreviewShow = $$v;
      },
      expression: "imagePreviewShow"
    }
  }), _vm._v(" "), _c("div", [_vm.goodsContent ? _c("TheGoodDetailPrice", {
    attrs: {
      "goods-content": _vm.goodsContent
    }
  }) : _vm._e(), _vm._v(" "), _vm.activityId && _vm.productInfo.isActivity === 1 ? _c("div", {
    staticClass: "flash-sale padding-row"
  }, [_c("van-image", {
    attrs: {
      src: require("@/assets/images/goods_detail/flash_sale.png"),
      width: "100%",
      height: "100%"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "flash-sale-content"
  }, [_c("div", [_vm._v("Flash sales")]), _vm._v(" "), _c("CountDown", {
    attrs: {
      "begin-time": _vm.productInfo.startTime,
      "end-time": _vm.productInfo.endTime,
      "bg-color": "#fff",
      color: "#FF0037"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticStyle: {
      "background-color": "white"
    }
  }, [_c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "padding-row"
  }, [_vm.goodsContent.supplyType == 2 ? _c("span", {
    staticClass: "supply-tag"
  }, [_vm._v("Goods in Nigeria ")]) : _vm._e(), _vm._v(" "), _vm.goodsContent.supplyType == 1 ? _c("span", {
    staticClass: "supply-tag"
  }, [_vm._v(" Shipped from abroad ")]) : _vm._e(), _vm._v(" "), _c("span", {
    staticClass: "subject-text"
  }, [_vm._v(_vm._s(_vm.subjectEn || _vm.subjectZh))])]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "15px"
    }
  }), _vm._v(" "), _vm.goodsContent && _vm.goodsContent.goodsSource !== 2 ? _c("van-row", {
    staticClass: "padding-row",
    attrs: {
      type: "flex",
      align: "center",
      gutter: "10"
    }
  }, _vm._l(_vm.productInfo.spuTitles, function (spuTitle, index) {
    return _c("van-col", {
      key: index,
      attrs: {
        type: "flex",
        align: "center"
      }
    }, [_c("div", {
      staticClass: "tag-style theme-color",
      style: {
        borderRight: index == _vm.productInfo.spuTitles.length - 1 ? "0px" : "1px solid rgba(234, 51, 64, 1)"
      }
    }, [_vm._v(_vm._s(spuTitle))])]);
  }), 1) : _vm._e(), _vm._v(" "), _vm.goodsContent && _vm.goodsContent.goodsSource == 2 ? _c("div", [_c("van-row", {
    staticClass: "padding-row",
    attrs: {
      type: "flex",
      justify: "space-between",
      align: "center"
    }
  }, [_c("van-row", {
    attrs: {
      type: "flex",
      align: "center"
    }
  }, [_c("span", {
    staticClass: "iconfont ali-icon-style"
  }, [_vm._v("")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("  from 1688")])]), _vm._v(" "), _c("van-col", {
    attrs: {
      span: "6",
      justify: "center",
      align: "center"
    }
  }, [_c("van-button", {
    attrs: {
      type: "default",
      hairline: "",
      round: "",
      size: "mini"
    },
    on: {
      click: _vm.toOriginalPage
    }
  }, [_vm._v("Original Page")])], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "12px"
    }
  })], 1) : _vm._e(), _vm._v(" "), !(_vm.activityId && _vm.productInfo.isActivity === 1) && _vm.couponList.length > 0 ? _c("div", {
    staticClass: "padding-row"
  }, [_c("div", {
    staticClass: "coupon-content",
    on: {
      click: _vm.showCoupon
    }
  }, [_c("van-icon", {
    attrs: {
      size: "18",
      name: require("@/assets/images/goods_detail/coupon.png")
    }
  }), _vm._v(" "), _vm._m(0), _vm._v(" "), _c("span", {
    staticClass: "f-12"
  }, [_vm._v("\n                " + _vm._s(_vm._f("getCouponStr")(_vm.couponList[0])) + "\n                "), _c("span", {
    staticClass: "iconfont",
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("")])])], 1)]) : _vm._e(), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "van-hairline--bottom"
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "padding-row r-start",
    staticStyle: {
      color: "rgba(222, 168, 98, 1)"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-right": "11px"
    }
  }, [_vm.goodsContent.supplyType == 1 ? _c("span", {
    staticClass: "iconfont",
    staticStyle: {
      "font-size": "35px"
    }
  }, [_vm._v("")]) : _vm._e(), _vm._v(" "), _vm.goodsContent.supplyType == 2 ? _c("span", {
    staticClass: "iconfont",
    staticStyle: {
      "font-size": "35px"
    }
  }, [_vm._v("")]) : _vm._e()]), _vm._v(" "), _c("div", [_vm.goodsContent.supplyType == 1 ? _c("span", {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("Global sales")]) : _vm._e(), _vm._v(" "), _vm.goodsContent.supplyType == 2 ? _c("span", {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("Sale in Nigeria warehouse")]) : _vm._e(), _vm._v(" "), _c("br"), _vm._v(" "), _vm.goodsContent.supplyType == 1 ? _c("span", {
    staticStyle: {
      "font-size": "10px"
    }
  }, [_vm._v("It's shipped from China and need to pay international freight fee.")]) : _vm._e(), _vm._v(" "), _vm.goodsContent.supplyType == 2 ? _c("span", {
    staticStyle: {
      "font-size": "10px"
    }
  }, [_vm._v("Goods can be picked up quickly within " + _vm._s(_vm.quicklyPickupTime) + ".")]) : _vm._e()])]), _vm._v(" "), _vm.goodsContent.supplyType == 1 ? _c("div", [_c("div", {
    staticStyle: {
      height: "15px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "address-title padding-row"
  }, [_vm._v("Shopping process")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _c("div", {
    on: {
      click: function click($event) {
        return _vm.$router.push("/user_help_purchase");
      }
    }
  }, [_c("van-row", {
    staticClass: "padding-row",
    attrs: {
      type: "flex",
      justify: "space-around",
      align: "center"
    }
  }, [_c("img", {
    staticClass: "steps-img",
    attrs: {
      src: require("@/assets/images/goods_detail/send_to_gz.png")
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "theme-background-color",
    staticStyle: {
      height: "1px",
      width: "10vw"
    }
  }), _vm._v(" "), _c("img", {
    staticClass: "steps-img",
    attrs: {
      src: require("@/assets/images/goods_detail/select_for_delivery.png")
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "theme-background-color",
    staticStyle: {
      height: "1px",
      width: "10vw"
    }
  }), _vm._v(" "), _c("img", {
    staticClass: "steps-img",
    attrs: {
      src: require("@/assets/images/goods_detail/international_transport.png")
    }
  })]), _vm._v(" "), _c("van-row", {
    attrs: {
      type: "flex",
      justify: "space-between",
      align: "center"
    }
  }, [_c("van-col", {
    attrs: {
      span: "8",
      justify: "center",
      align: "center"
    }
  }, [_c("div", {
    staticClass: "steps-span theme-color"
  }, [_vm._v("Send to GZ warehouse")])]), _vm._v(" "), _c("van-col", {
    attrs: {
      span: "8",
      justify: "center",
      align: "center"
    }
  }, [_c("div", {
    staticClass: "steps-span theme-color"
  }, [_vm._v("Select for delivery")])]), _vm._v(" "), _c("van-col", {
    attrs: {
      span: "8",
      justify: "center",
      align: "center"
    }
  }, [_c("div", {
    staticClass: "steps-span theme-color"
  }, [_vm._v("International transport")])])], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _vm._m(1)]) : _vm._e(), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _vm.goodsContent.supplyType == 1 ? _c("div", {
    staticClass: "padding-row"
  }, [_c("div", {
    staticClass: "r-space-between",
    on: {
      click: _vm.toWarehouse
    }
  }, [_vm._m(2), _vm._v(" "), _c("span", {
    staticClass: "iconfont",
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("")])]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "7px"
    }
  })]) : _vm._e(), _vm._v(" "), _vm.goodsContent.supplyType == 2 ? _c("div", {
    staticClass: "padding-row"
  }, [_c("div", {
    staticClass: "address-title"
  }, [_vm._v("Delivery")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "7px"
    }
  }), _vm._v(" "), _c("div", [_vm._v("Pickup address of the current commodity")]), _vm._v(" "), _c("div", {
    staticClass: "select",
    on: {
      click: _vm.handlePackageStore
    }
  }, [_c("van-icon", {
    attrs: {
      size: "16",
      name: require("@/assets/images/home/home_mall.png")
    }
  }), _vm._v(" "), _vm.selectPackageStore ? _c("div", {
    staticClass: "receiving"
  }, [_c("span", [_vm._v(_vm._s(_vm.selectPackageStore.nameEn))]), _vm._v(" "), _vm.selectPackageStore.price ? _c("span", [_vm._v("₦" + _vm._s(Number(_vm.selectPackageStore.price).toFixed(2)))]) : _vm._e()]) : _c("div", [_vm._v("View the nearest pickup address from you")]), _vm._v(" "), _c("van-icon", {
    attrs: {
      size: "12",
      name: "arrow",
      color: "#696969"
    }
  })], 1), _vm._v(" "), _vm.selectPackageStore ? _c("div", {
    staticClass: "selected-item"
  }, [_vm.selectPackageStore["estimatedPickupTime"] ? [_c("div", {
    staticClass: "f-12",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_c("span", [_vm._v("Estimated pickup time：")]), _vm._v(" "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v(_vm._s(_vm._f("formatTime")(_vm.selectPackageStore["estimatedPickupTime"])))])]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "7px"
    }
  })] : _vm._e(), _vm._v(" "), _vm.selectPackageStore["address"] ? [_c("div", {
    staticClass: "f-12",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("Pickup address:")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "7px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "f-12"
  }, [_vm._v(_vm._s(_vm.selectPackageStore["address"]))])] : _vm._e()], 2) : _vm._e()]) : _vm._e(), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "20px"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "page-background-color",
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _vm.goodsContent && _vm.goodsContent.goodsSource === 1 ? _c("div", {
    staticClass: "parameters-style"
  }, [_c("van-row", {
    staticStyle: {
      padding: "15px 0px"
    },
    attrs: {
      type: "flex",
      justify: "space-between",
      align: "center"
    }
  }, [_c("div", [_vm._v("Local Freight")]), _vm._v(" "), _c("div", {
    staticClass: "theme-color"
  }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.productInfo.postage)))])]), _vm._v(" "), _c("van-row", {
    staticStyle: {
      padding: "15px 0px"
    },
    attrs: {
      type: "flex",
      justify: "space-between",
      align: "center"
    },
    on: {
      click: _vm.onCommodityParameters
    }
  }, [_c("div", [_vm._v("Commodity parameters")]), _vm._v(" "), _c("span", {
    staticClass: "iconfont",
    staticStyle: {
      "font-size": "10px",
      "font-weight": "initial"
    }
  }, [_vm._v("")])])], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "page-background-color",
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "description-style"
  }, [_c("van-row", {
    attrs: {
      type: "flex",
      justify: "center"
    }
  }, [_c("div", {
    staticClass: "f-12"
  }, [_vm._v("Product Introduction")])]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "18px"
    }
  }), _vm._v(" "), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.productInfo.description)
    }
  })], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "60px"
    }
  })], 1)], 1)]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "good-bug"
  }, [_c("div", {
    staticClass: "good-bug-div"
  }, [_c("van-row", {
    attrs: {
      type: "flex",
      justify: "space-between",
      align: "center"
    }
  }, [_c("div", {
    staticClass: "btn",
    on: {
      click: _vm.onWishlist
    }
  }, [_c("div", [!_vm.isInWishList ? _c("span", {
    staticClass: "iconfont",
    staticStyle: {
      "font-size": "24px"
    }
  }, [_vm._v("")]) : _c("span", {
    staticClass: "iconfont theme-color",
    staticStyle: {
      "font-size": "24px"
    }
  }, [_vm._v("")])]), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-size": "8px"
    }
  }, [_vm._v("Wishlist")])])])], 1)]), _vm._v(" "), _c("van-popup", {
    style: {
      height: "70%"
    },
    attrs: {
      closeable: "",
      round: "",
      position: "bottom"
    },
    model: {
      value: _vm.showCommodityParametersPop,
      callback: function callback($$v) {
        _vm.showCommodityParametersPop = $$v;
      },
      expression: "showCommodityParametersPop"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "26px 26px 0px 26px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v("Commodity parameters")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _vm._l(_vm.commodityParametersList, function (commodityParameterItem, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticStyle: {
        height: "15px"
      }
    }), _vm._v(" "), _c("van-row", {
      staticStyle: {
        "font-size": "12px"
      },
      attrs: {
        type: "flex"
      }
    }, [_c("van-col", {
      staticClass: "text-overflow",
      attrs: {
        type: "flex",
        span: "7"
      }
    }, [_vm._v("\n            " + _vm._s(commodityParameterItem.attributeName) + "\n          ")]), _vm._v(" "), _c("van-col", {
      attrs: {
        type: "flex",
        span: "16"
      }
    }, [_vm._v("\n            " + _vm._s(commodityParameterItem.value) + "\n          ")])], 1), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "15px"
      }
    }), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "0.1px",
        "background-color": "rgba(186,186,186,0.2)"
      }
    })], 1);
  })], 2)]), _vm._v(" "), _c("van-popup", {
    style: {
      height: "85%"
    },
    attrs: {
      closeable: "",
      round: "",
      position: "bottom",
      "lazy-render": false,
      "z-index": 20,
      "close-on-popstate": true
    },
    model: {
      value: _vm.showSkuPop,
      callback: function callback($$v) {
        _vm.showSkuPop = $$v;
      },
      expression: "showSkuPop"
    }
  }, [_vm.goodsContent ? _c("TheSkuContent", {
    attrs: {
      "activity-id": _vm.activityId,
      "subject-zh": _vm.subjectZh,
      "subject-en": _vm.subjectEn,
      "goods-content": _vm.goodsContent,
      "button-type": _vm.buttonType,
      "select-package-store": _vm.selectPackageStore
    },
    on: {
      onCloseSkuPop: _vm.onCloseSkuPop
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("van-popup", {
    style: {
      height: "85%"
    },
    attrs: {
      closeable: "",
      round: "",
      position: "bottom",
      "z-index": 20,
      "close-on-popstate": true
    },
    model: {
      value: _vm.showCouponList,
      callback: function callback($$v) {
        _vm.showCouponList = $$v;
      },
      expression: "showCouponList"
    }
  }, [_c("div", {
    staticClass: "coupon-list"
  }, [_c("div", {
    staticStyle: {
      "text-align": "center",
      "font-size": "16px"
    }
  }, [_c("span", [_vm._v("Coupon")])]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _vm.couponList ? _c("CouponList", {
    attrs: {
      list: _vm.couponList,
      "checked-icon-show": false
    },
    on: {
      receivedCouponSuccess: _vm.receivedCouponSuccess
    }
  }) : _vm._e()], 1)]), _vm._v(" "), _vm.showShare ? _c("TheGoodShare", {
    attrs: {
      "show-share": _vm.showShare,
      "head-img": _vm.headImg,
      "activity-id": _vm.activityId,
      "product-info": _vm.productInfo,
      "goods-source": _vm.goodsSource,
      "subject-zh": _vm.subjectZh,
      "subject-en": _vm.subjectEn
    },
    on: {
      changeShowShare: _vm.changeShowShare
    }
  }) : _vm._e()], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "conpon-items"
  }, [_c("div", {
    staticClass: "f-12 theme-color",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("My discount")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "padding-row"
  }, [_c("div", {
    staticStyle: {
      color: "rgba(186, 186, 186, 1)",
      "font-size": "10px"
    }
  }, [_vm._v("\n                Orders paid between 9:00-18:00 (BT) will be processed in 6 hour. \n                "), _c("br"), _vm._v("\n                Orders paid between 18:00-09:00 of the next day(BT) will beprocessed by 14:00\n              ")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "r-start"
  }, [_c("span", {
    staticClass: "iconfont f-16"
  }, [_vm._v("")]), _vm._v(" "), _c("span", {
    staticClass: "r-start f-13",
    staticStyle: {
      "padding-left": "7px",
      "font-weight": "bold"
    }
  }, [_vm._v(" Pickup address")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;