"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

require("core-js/modules/es6.regexp.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/notice-bar/style");

var _noticeBar = _interopRequireDefault(require("vant/es/notice-bar"));

require("core-js/modules/es6.function.name");

require("vant/es/calendar/style");

var _calendar = _interopRequireDefault(require("vant/es/calendar"));

var _warehouse = require("@/api/warehouse.js");

var _dayjs = _interopRequireDefault(require("dayjs"));

var _components;

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var _default2 = {
  name: "PickupTime",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _calendar.default.name, _calendar.default), (0, _defineProperty2.default)(_components, _noticeBar.default.name, _noticeBar.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), _components),
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    selectPickupTime: {
      type: String,
      default: null
    },
    orderCreateTime: {
      type: String,
      default: null
    },
    selectPackageStore: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      businessTime: {
        longDay: null,
        businessHours: {},
        pickups: []
      },
      // 暂时选中的日期
      selectDateTmp: null,
      // 暂时选中的日期的营业时间
      businessHoursTmp: {
        begin: "",
        end: ""
      }
    };
  },
  computed: {
    show_: {
      get: function get() {
        return this.show;
      },
      set: function set(newVal) {
        return this.$emit("closePickupTime", newVal);
      }
    },
    _selectPickupTime: {
      get: function get() {
        return this.selectPickupTime;
      },
      set: function set(newVal) {
        return newVal;
      }
    }
  },
  watch: {
    "selectPackageStore": {
      handler: function handler(val) {
        var _this = this;

        if (val && val.whId) {
          (0, _warehouse.getPackageStoreBusiness)({
            whId: this.selectPackageStore.whId,
            type: this.selectPackageStore.type,
            requestTime: this.orderCreateTime ? (0, _dayjs.default)(this.orderCreateTime).unix() : null
          }).then(function (res) {
            _this.businessTime = res.data;
            if (_this._selectPickupTime) _this.businessHoursTmp = _this.businessTime.pickups.filter(function (item) {
              return item.date === (0, _dayjs.default)(_this._selectPickupTime).format('YYYY-MM-DD');
            })[0];
          });
        }
      },
      immediate: true
    }
  },
  created: function created() {},
  methods: {
    dayjs: _dayjs.default,
    formatter: function formatter(dayObj) {
      var _this$businessTime$pi;

      var isDateArr = (_this$businessTime$pi = this.businessTime.pickups) === null || _this$businessTime$pi === void 0 ? void 0 : _this$businessTime$pi.map(function (item) {
        return {
          year: (0, _dayjs.default)(item.date).year(),
          month: (0, _dayjs.default)(item.date).month() + 1,
          date: (0, _dayjs.default)(item.date).date()
        };
      });
      var isTrue = false; // 标记是否有这天可选

      var _iterator = _createForOfIteratorHelper(isDateArr),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var ele = _step.value;

          if ((0, _dayjs.default)(dayObj.date).year() === ele.year && (0, _dayjs.default)(dayObj.date).month() + 1 === ele.month && (0, _dayjs.default)(dayObj.date).date() === ele.date) {
            isTrue = true;
            break;
          }
        } // 如果标记为false设置不可选

      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      if (!isTrue) {
        dayObj.type = "disabled";
      } // 当天不可选


      if ((0, _dayjs.default)(dayObj.date).year() === (0, _dayjs.default)(this._selectPickupTime).year() && (0, _dayjs.default)(dayObj.date).month() + 1 === (0, _dayjs.default)(this._selectPickupTime).month() + 1 && (0, _dayjs.default)(dayObj.date).date() === (0, _dayjs.default)(this._selectPickupTime).date()) {
        dayObj.type = "disabled";
        dayObj.bottomInfo = "Selected";
      }

      return dayObj;
    },
    selectDate: function selectDate(val) {
      console.log(val);
      this.selectDateTmp = (0, _dayjs.default)(val).format('YYYY-MM-DD');
      this.businessHoursTmp = this.businessTime.pickups.filter(function (item) {
        return item.date === (0, _dayjs.default)(val).format('YYYY-MM-DD');
      })[0];
    },
    confirm: function confirm() {
      this.$emit("handlePickupTime", this.selectDateTmp);
      this.$emit("closePickupTime", false);
    }
  }
};
exports.default = _default2;