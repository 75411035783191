"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.set");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/row/style");

var _row = _interopRequireDefault(require("vant/es/row"));

require("core-js/modules/es6.function.name");

require("vant/es/col/style");

var _col = _interopRequireDefault(require("vant/es/col"));

var _components;

var _default = {
  name: "TheGoodDetailPrice",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _col.default.name, _col.default), (0, _defineProperty2.default)(_components, _row.default.name, _row.default), _components),
  filters: {
    filtersStartQuantity: function filtersStartQuantity(value, index, priceRanges) {
      var _moreThan = '';
      var _betweenAnd = '';
      var _length = priceRanges.length; // 最后一个显示≥

      if (index === _length - 1) {
        _moreThan = '≥';
      }

      if (index < _length - 1) {
        _betweenAnd = "-".concat(priceRanges[index + 1].startQuantity - 1); // 起批量
      }

      return "".concat(_moreThan).concat(priceRanges[index].startQuantity).concat(_betweenAnd, " Pieces");
    },
    filtersLastPrice: function filtersLastPrice(value) {
      return value.length > 1 ? value[value.length - 1] : '';
    },
    // 自营商品左边价格
    filtersPmsLeftPrice: function filtersPmsLeftPrice(value) {
      return value.isActivity === 1 ? value.discountPrice : value.offerPrice;
    },
    // 自营商品左边价格横线
    filtersPmsLeftLine: function filtersPmsLeftLine(value) {
      return value.highestPrice != null && (value.isActivity === 1 ? value.discountPrice : value.offerPrice) * 1 < value.highestPrice * 1 ? '-' : '';
    },
    // 自营商品右边价格
    filtersPmsRightPrice: function filtersPmsRightPrice(value) {
      return value.highestPrice != null && (value.isActivity === 1 ? value.discountPrice : value.offerPrice) * 1 < value.highestPrice * 1 ? value.highestPrice : '';
    },
    // 自营原价 offerPrice
    filtersOfferPrice: function filtersOfferPrice(productInfo) {
      return productInfo.offerPrice != null && productInfo.isActivity === 1 ? productInfo.offerPrice : '';
    },
    // 自营显示价
    filtersShowPrice: function filtersShowPrice(productInfo) {
      return productInfo.isActivity !== 1 && productInfo.showPrice.trim() !== '0' ? productInfo.showPrice : '';
    }
  },
  props: {
    // 商品详情
    goodsContent: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      startQuantity: 0,
      // 起批量
      aliPriceRangeList: [],
      // 1688商品阶梯价
      productInfo: null
    };
  },
  created: function created() {
    this.productInfo = this.goodsContent.goodsEntity.productInfo; // 数据筛选

    this.aliPriceRangeList = this.getPriceRange(this.productInfo);
  },
  methods: {
    // 获取价格区间
    getPriceRange: function getPriceRange(productInfo) {
      var _tempAry = [];
      var quoteType = this.productInfo.saleInfo.quoteType || 1; // 出价类型

      try {
        if (quoteType === 1) {
          if (productInfo.skuInfos != null && productInfo.skuInfos[0].price != null) {
            // 有sku时,根据sku价格排序
            _tempAry = productInfo.skuInfos.map(function (index) {
              return index.price;
            });
          } else {
            // 无sku，拿已定义的价格排序
            _tempAry = productInfo.saleInfo.priceRanges.map(function (index) {
              return index.price;
            });
          }
        } else if ([0, 2].includes(quoteType)) {
          // 无sku，拿已定义的价格排序
          _tempAry = productInfo.saleInfo.priceRanges.map(function (index) {
            return index.price;
          });
        }

        _tempAry = Array.from(new Set(_tempAry)); // 用set进行去重 把数组塞进set里

        _tempAry.sort(function (a, b) {
          return a - b;
        });

        console.log("🚀 ~ file: TheGoodDetailPrice.vue ~ line 86 ~ getPriceRange ~ _tempAry", _tempAry);
      } catch (e) {
        console.log("getPriceRange---------ee-----".concat(e, "}"));
      }

      return _tempAry;
    }
  }
};
exports.default = _default;