"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "logo"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/logo.png"),
      alt: "default_logo"
    }
  })])]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("van-field", {
    attrs: {
      placeholder: "Enter your email",
      "left-icon": "user-o"
    },
    on: {
      focus: function focus($event) {
        _vm.emailShow = !_vm.emailShow;
      },
      blur: _vm.handleBlur
    },
    model: {
      value: _vm.phoneNumber,
      callback: function callback($$v) {
        _vm.phoneNumber = $$v;
      },
      expression: "phoneNumber"
    }
  }), _vm._v(" "), _vm.emailShow ? _c("div", {
    staticClass: "hintBox"
  }, _vm._l(_vm.emails, function (mail) {
    return _c("div", {
      key: mail,
      staticClass: "hintItem",
      on: {
        click: function click($event) {
          return _vm.setInput(mail);
        }
      }
    }, [_vm._v(_vm._s(mail))]);
  }), 0) : _vm._e(), _vm._v(" "), _c("van-field", {
    attrs: {
      placeholder: "Enter your password",
      "left-icon": "lock",
      type: _vm.passwordType
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }, [_c("van-icon", {
    attrs: {
      slot: "right-icon",
      name: _vm.passwordIcon
    },
    on: {
      click: _vm.switchPasswordType
    },
    slot: "right-icon"
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "button-wrap"
  }, [_c("van-button", {
    attrs: {
      size: "large",
      round: "",
      type: "info"
    },
    on: {
      click: _vm.handleLogin
    }
  }, [_vm._v("Sign in")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "more-wrap"
  }, [_c("div", [_vm._v("\n      Forgot password? "), _c("router-link", {
    staticStyle: {
      color: "red"
    },
    attrs: {
      to: "/forgot"
    }
  }, [_vm._v("Reset")])], 1), _vm._v(" "), _c("div", [_vm._v("\n      Dont have an account? "), _c("router-link", {
    staticStyle: {
      color: "red"
    },
    attrs: {
      to: "/register"
    }
  }, [_vm._v("Sign up")])], 1)]), _vm._v(" "), _c("van-overlay", {
    attrs: {
      show: _vm.showOverlay
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;