"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "About after service",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go("-1");
      }
    }
  }), _vm._v(" "), _c("van-notice-bar", {
    attrs: {
      "left-icon": "info-o",
      scrollable: false,
      color: "#FF0037",
      text: "Only applicable to local orders"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("Check after receiving the goods")]), _vm._v(" "), _c("van-divider"), _vm._v(" "), _vm._m(0)], 1), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("After service If any problem")]), _vm._v(" "), _c("van-divider"), _vm._v(" "), _vm._m(1)], 1), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("Not allowed to be exchanged or returned")]), _vm._v(" "), _c("van-divider"), _vm._v(" "), _vm._m(2)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "content"
  }, [_vm._v("\n      The goods sold by womata are of high quality. After receiving the goods, please inspect the goods at the first time to\n      "), _c("span", [_vm._v(" check whether the appearance of the goods is normal, whether there is damage, or whether the accessories are complete")]), _vm._v(".\n      In case of any problem, please contact the salesman in time and do not discard the package.\n    ")]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "content"
  }, [_vm._v("\n      ln case of"), _c("span", [_vm._v(" non-human damage or failure ")]), _vm._v("of the goods, the consumercan contact the salesman and"), _c("span", [_vm._v(" provide relevant vouchers")]), _vm._v(",\n      such asphotos or videos,"), _c("span", [_vm._v(" within 3 days ")]), _vm._v("from the date of signing for thegoods, and then can exchange or return the goods"), _c("span", [_vm._v(" until approvedby the salesman and warehouse personnel.")]), _vm._v("\n      The goods shall bereturned to the designated warehouse"), _c("span", [_vm._v(" within 7 days ")]), _vm._v("after signing forgoods, otherwise they cannot be exchanged or returned.\n    ")]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "content"
  }, [_c("div", [_vm._v("1)"), _c("span", [_vm._v(" No quality problems, no exchange or return is allowed.")])]), _vm._v(" "), _c("div", [_vm._v("2) Customers who exchange or return goods must ensure the intactness of the goods, including the original packaging, tag, all its accessories and gifts."), _c("br"), _vm._v("\n            The goods to be exchanged or returned must be free of any traces of use, which will not affect the secondary sales of the goods."), _c("br"), _vm._v("\n            If a part is lost or damaged, it does not comply with womata's terms of exchange and return. So that womata will not be able to provide exchange or return services. Therefore, it is very important to check the goods as soon as they are received. If they need to be exchanged or returned, they need to keep the original packaging and other accessories related to the goods.\n      ")]), _vm._v(" "), _c("div", [_vm._v("3) Goods that do not conform to the original order are not allowed to be returned or exchanged.")]), _vm._v(" "), _c("div", [_vm._v("4) No exchange or return is allowed for damage caused by human factors.")]), _vm._v(" "), _c("div", [_vm._v("5) The goods will not be allowed to be exchanged or returned beyond the expiration date of the exchange and return.")]), _vm._v(" "), _c("div", [_vm._v("6) Once the goods are cleaned, they are not allowed to be exchanged or returned.")]), _vm._v(" "), _c("div", [_vm._v("7) All interpretation rights belong to womata.")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;