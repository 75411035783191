"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Browsed",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("van-pull-refresh", {
    attrs: {
      "pulling-text": "Pull down refresh",
      "loosing-text": "Release to refresh",
      "loading-text": "Refreshing",
      "success-text": "Refresh completed"
    },
    on: {
      refresh: _vm.onRefresh
    },
    model: {
      value: _vm.refreshing,
      callback: function callback($$v) {
        _vm.refreshing = $$v;
      },
      expression: "refreshing"
    }
  }, [_vm.list.length ? _c("div", {
    staticClass: "content"
  }, _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "date"
    }, [_vm._v(_vm._s(item.date))]), _vm._v(" "), _c("van-divider"), _vm._v(" "), _c("div", {
      staticClass: "wrap"
    }, _vm._l(item.spuList, function (element, i) {
      return _c("div", {
        key: i,
        staticClass: "card",
        on: {
          click: function click($event) {
            return _vm.toGoodsContent(element);
          }
        }
      }, [_c("van-image", {
        attrs: {
          width: "100%",
          height: "80%",
          radius: "4",
          "lazy-load": "",
          src: element.images
        }
      }), _vm._v(" "), _c("div", {
        staticClass: "card-bottom"
      }, [_c("div", {
        staticClass: "price theme-color"
      }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(element.offerPrice)))]), _vm._v(" "), _c("van-icon", {
        attrs: {
          name: require("@/assets/images/my_like/cart.png"),
          size: "14"
        }
      })], 1)], 1);
    }), 0)], 1);
  }), 0) : _c("van-empty", {
    attrs: {
      description: "No more data!"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/no_data.png")
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;