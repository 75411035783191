"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Withdrawal Record",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "header"
  }, [_vm._m(0), _vm._v(" "), _c("van-divider", {
    style: {
      borderColor: "#D1D1D1",
      margin: "16px 0 0"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "main"
  }, [_vm.withdrawalRecordList.length ? _c("van-list", {
    attrs: {
      finished: _vm.finished,
      "finished-text": "No More ...",
      "immediate-check": false
    },
    on: {
      load: _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm._l(_vm.withdrawalRecordList, function (item, index) {
    return [_c("div", {
      key: "cell" + index,
      staticClass: "td"
    }, [_c("div", {
      staticClass: "van-ellipsis"
    }, [_vm._v(_vm._s(_vm._f("parseTime")(item.createTime)))]), _vm._v(" "), _c("div", {
      staticClass: "van-ellipsis theme-color"
    }, [_vm._v("₦" + _vm._s(Number(item.amount).toFixed(2)))]), _vm._v(" "), _c("div", {
      staticClass: "van-ellipsis theme-color"
    }, [_vm._v(_vm._s(_vm._f("getApprovalStatusString")(item.approvalStatus)))])]), _vm._v(" "), _c("van-divider", {
      key: "divider" + index,
      style: {
        borderColor: "#E8E8E8",
        margin: "0"
      }
    })];
  })], 2) : _vm._e()], 1)])], 1);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "td"
  }, [_c("div", [_vm._v("Creat time")]), _vm._v(" "), _c("div", [_vm._v("Withdrawal amount")]), _vm._v(" "), _c("div", [_vm._v("Withdrawal status")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;