"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "address-list-view"
  }, [_c("van-nav-bar", {
    attrs: {
      center: "",
      title: "Address selection",
      "left-text": "Back",
      "left-arrow": ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("van-address-list", {
    attrs: {
      list: _vm.list,
      "default-tag-text": "Default",
      "add-button-text": "Add Address"
    },
    on: {
      add: _vm.onAdd,
      edit: _vm.onEdit,
      select: _vm.onSelect
    },
    model: {
      value: _vm.chosenAddressId,
      callback: function callback($$v) {
        _vm.chosenAddressId = $$v;
      },
      expression: "chosenAddressId"
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "60px"
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;