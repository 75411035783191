"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "user_help_purchase_page"
  }, [_c("div", {
    staticClass: "page-box"
  }, [_c("div", {
    staticStyle: {
      height: "30px"
    }
  }), _vm._v(" "), _vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "title_1"
  }, [_c("van-image", {
    attrs: {
      src: require("../../static/asset/images/help_buy/buy_title1@2x.png"),
      width: "100%"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "img-box"
  }, [_c("van-swipe", {
    ref: "swipe1",
    staticClass: "my-swipe",
    attrs: {
      autoplay: _vm.autoplayTime,
      "indicator-color": "#FF0037",
      "stop-propagation": false,
      loop: false,
      "lazy-render": true
    },
    on: {
      change: _vm.onChange1
    }
  }, _vm._l(_vm.titleImg1, function (_ref) {
    var image = _ref.image,
        index = _ref.index;
    return _c("div", {
      key: index
    }, [_c("van-swipe-item", [_c("van-image", {
      attrs: {
        "lazy-load": "",
        src: image,
        width: "100%"
      }
    })], 1)], 1);
  }), 0)], 1), _vm._v(" "), _c("div", {
    staticClass: "title_2",
    attrs: {
      id: "to_2"
    }
  }, [_c("van-image", {
    attrs: {
      "lazy-load": "",
      src: require("../../static/asset/images/help_buy/buy_title2@2x.png"),
      width: "100%"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "img-box"
  }, [_c("van-swipe", {
    ref: "swipe2",
    staticClass: "my-swipe",
    attrs: {
      autoplay: _vm.autoplayTime,
      "indicator-color": "#FF0037",
      "stop-propagation": false,
      loop: false,
      "lazy-render": true
    },
    on: {
      change: _vm.onChange2
    }
  }, _vm._l(_vm.titleImg2, function (_ref2) {
    var image = _ref2.image,
        index = _ref2.index;
    return _c("div", {
      key: index
    }, [_c("van-swipe-item", [_c("van-image", {
      attrs: {
        "lazy-load": "",
        src: image,
        width: "100%"
      }
    })], 1)], 1);
  }), 0), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "30px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "img_recharge_box col_center_center"
  }, [_c("van-image", {
    attrs: {
      "lazy-load": "",
      src: require("../../static/asset/images/help_buy/step2/pic_2_1.png"),
      width: "100%"
    }
  }), _vm._v(" "), _c("van-button", {
    staticClass: "btn btn_recharge",
    attrs: {
      plain: "",
      round: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/user_help_page_1",
          query: {
            id: "2"
          }
        });
      }
    }
  }, [_vm._v("Next")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "title_3"
  }, [_c("van-image", {
    attrs: {
      src: require("../../static/asset/images/help_buy/buy_title3@2x.png"),
      width: "100%"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "img-box"
  }, [_c("van-image", {
    attrs: {
      "lazy-load": "",
      src: require("../../static/asset/images/help_buy/step3/pic_1.png"),
      width: "100%"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "title_4"
  }, [_c("van-image", {
    attrs: {
      "lazy-load": "",
      src: require("../../static/asset/images/help_buy/buy_title4@2x.png"),
      width: "100%"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "img-box"
  }, [_c("van-swipe", {
    ref: "swipe4",
    staticClass: "my-swipe",
    attrs: {
      autoplay: _vm.autoplayTime,
      "indicator-color": "#FF0037",
      "stop-propagation": false,
      loop: false,
      "lazy-render": true
    },
    on: {
      change: _vm.onChange4
    }
  }, _vm._l(_vm.titleImg4, function (_ref3) {
    var image = _ref3.image,
        index = _ref3.index;
    return _c("div", {
      key: index
    }, [_c("van-swipe-item", [_c("van-image", {
      attrs: {
        "lazy-load": "",
        src: image,
        width: "100%",
        alt: ""
      }
    })], 1)], 1);
  }), 0), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "50px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "img_recharge_box col_center_center"
  }, [_c("van-image", {
    attrs: {
      "lazy-load": "",
      src: require("../../static/asset/images/help_buy/step4/pic_tip.png"),
      width: "100%"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "80px"
    }
  })])]);
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("video", {
    attrs: {
      width: "100%",
      controls: "",
      autoplay: ""
    }
  }, [_c("source", {
    attrs: {
      src: require("../../static/asset/video/help_video.mp4"),
      type: "video/mp4"
    }
  }), _vm._v("\n      Your browser does not support the HTML5 video.\n    ")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;