"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/popup/style");

var _popup = _interopRequireDefault(require("vant/es/popup"));

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

require("vant/es/tag/style");

var _tag = _interopRequireDefault(require("vant/es/tag"));

require("vant/es/card/style");

var _card = _interopRequireDefault(require("vant/es/card"));

require("core-js/modules/es6.function.name");

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

var _components;

var _default2 = {
  name: "CheckoutGoodItem",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _card.default.name, _card.default), (0, _defineProperty2.default)(_components, _tag.default.name, _tag.default), (0, _defineProperty2.default)(_components, _image.default.name, _image.default), (0, _defineProperty2.default)(_components, _popup.default.name, _popup.default), _components),
  props: {
    orderList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      show: false,
      order: {
        subGoodsList: []
      }
    };
  },
  created: function created() {},
  methods: {
    openSubGood: function openSubGood(supplyType, goodsList) {
      this.show = true;
      this.order = {
        supplyType: supplyType,
        subGoodsList: goodsList
      };
    }
  }
};
exports.default = _default2;