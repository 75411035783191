"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.function.name");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _vm.goodsContent ? _c("div", {
    staticClass: "sku-content-com"
  }, [_c("div", {
    staticStyle: {
      padding: "20px 20px 6px 16px"
    }
  }, [_c("div", {
    staticClass: "div-info"
  }, [_vm.group.length > 0 ? _c("van-image", {
    attrs: {
      width: "70px",
      height: "70px",
      fit: "cover",
      src: _vm._f("filterGoodImg")(_vm.getSkuImg, _vm.goodsContent.goodsSource)
    },
    on: {
      click: function click($event) {
        return _vm.onTapImage();
      }
    }
  }) : _vm._e(), _vm._v(" "), _c("van-image-preview", {
    attrs: {
      images: _vm.imagesPreviewLists
    },
    model: {
      value: _vm.imagePreviewShow,
      callback: function callback($$v) {
        _vm.imagePreviewShow = $$v;
      },
      expression: "imagePreviewShow"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "subject-price"
  }, [_vm.productInfo ? _c("div", {
    staticClass: "f-12"
  }, [_vm._v(_vm._s(_vm.subjectEn || _vm.subjectZh))]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "f-14 theme-color"
  }, [_vm._v("\n          " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm.priceRange[0])) + "\n          " + _vm._s(_vm.priceRange.length > 1 ? "-" : "") + "\n          " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm._f("filtersLastPrice")(_vm.priceRange))) + "\n        ")])])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "van-hairline--bottom"
  }), _vm._v(" "), _c("div", {
    staticClass: "wo-tab"
  }, [_vm.group.length === 2 ? _c("div", [_c("van-tabs", {
    attrs: {
      animated: "",
      swipeable: true,
      ellipsis: false
    },
    model: {
      value: _vm.active,
      callback: function callback($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, _vm._l(_vm.groupSku, function (groupItem, index) {
    return _c("van-tab", {
      key: index,
      attrs: {
        title: groupItem.name,
        badge: _vm.getGroupCount(groupItem.name, _vm.group[1].items)
      }
    }, [_c("div", {
      staticClass: "tab-view padding-row"
    }, _vm._l(groupItem.items, function (skuItem, skuIndex) {
      return _c("div", {
        key: skuIndex
      }, [_c("TheSkuItem", {
        key: skuIndex,
        attrs: {
          "group-name": groupItem.name,
          "sku-name": skuItem.sku,
          "sku-data-item": skuItem,
          step: _vm.batchNumber
        },
        on: {
          skuStepperOnChange: _vm.skuStepperOnChange
        }
      })], 1);
    }), 0)]);
  }), 1)], 1) : _vm.group.length === 1 ? _c("div", [_c("van-tabs", {
    attrs: {
      animated: "",
      swipeable: true,
      ellipsis: false
    },
    model: {
      value: _vm.active,
      callback: function callback($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, _vm._l(_vm.groupSku, function (groupItem, index) {
    return _c("van-tab", {
      key: index,
      attrs: {
        title: groupItem.name,
        badge: _vm.getGroupCount("", _vm.group[0].items)
      }
    }, [_c("div", {
      staticClass: "tab-view padding-row"
    }, _vm._l(groupItem.items, function (skuItem, skuIndex) {
      return _c("div", {
        key: skuIndex
      }, [_c("TheSkuItem", {
        key: skuIndex,
        attrs: {
          "sku-name": skuItem.sku,
          "sku-data-item": skuItem,
          step: _vm.batchNumber
        },
        on: {
          skuStepperOnChange: _vm.skuStepperOnChange
        }
      })], 1);
    }), 0)]);
  }), 1)], 1) : _vm._e()]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "s-q-style padding-row f-14"
  }, [_c("div", {
    staticClass: "subtotal-text"
  }, [_vm._v("\n      Subtotal: \n      "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.subtotalAmount)))]), _vm._v(" "), _c("div", {
    staticStyle: {
      width: "10px"
    }
  }), _vm._v(" "), _c("router-link", {
    attrs: {
      to: "/currency"
    }
  }, [_c("span", {
    staticClass: "iconfont",
    staticStyle: {
      "font-size": "20px",
      color: "grey"
    }
  }, [_vm._v("")])])], 1), _vm._v(" "), _c("span", [_vm._v("Quantity: " + _vm._s(_vm.totalQuantity))])]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _vm._v(" "), _vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "btn-box"
  }, [_c("van-row", {
    attrs: {
      type: "flex",
      justify: "center"
    }
  }, [_c("van-button", {
    staticStyle: {
      width: "40vw",
      height: "40px"
    },
    attrs: {
      type: "danger",
      round: "",
      size: "normal"
    },
    on: {
      click: _vm.toSubmit
    }
  }, [_vm._v(_vm._s(_vm.buttonType === 1 ? "Add Cart" : "Start Order"))])], 1)], 1)]) : _vm._e();
};

exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("span", {
    staticClass: "padding-row f-14"
  }, [_c("span", {
    staticClass: "iconfont theme-color",
    staticStyle: {
      "font-size": "22px"
    }
  }, [_vm._v("")]), _vm._v("\n    Send to\n    "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v(" GuangZhou Warehouse")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;