"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PayPassword = PayPassword;
exports.editUser = editUser;
exports.forgotPassword = forgotPassword;
exports.freeLogin = freeLogin;
exports.getCode = getCode;
exports.getInfo = getInfo;
exports.getWalletDetail = getWalletDetail;
exports.login = login;
exports.logout = logout;
exports.refreshToken = refreshToken;
exports.register = register;

var _request = _interopRequireDefault(require("@/utils/request"));

var _qs = _interopRequireDefault(require("qs"));

function login(data) {
  return (0, _request.default)({
    url: 'auth-service/login?grant_type=mall',
    method: 'post',
    // data,
    data: _qs.default.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

function freeLogin(data) {
  return (0, _request.default)({
    url: 'auth-service/loginV2?grant_type=mall',
    method: 'post',
    data: _qs.default.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

function getInfo() {
  return (0, _request.default)({
    url: 'base_service/user',
    method: 'get'
  });
}

function logout(data) {
  return (0, _request.default)({
    url: 'auth-service/logout',
    method: 'get',
    params: data
  });
}

function register(data) {
  return (0, _request.default)({
    url: 'base_service/register',
    method: 'post',
    data: data
  });
}

function editUser(data) {
  return (0, _request.default)({
    url: 'common-service/user',
    method: 'put',
    data: data
  });
}

function getCode(data) {
  return (0, _request.default)({
    url: 'base_service/send_verify_code/' + data,
    method: 'get'
  });
}

function forgotPassword(data) {
  return (0, _request.default)({
    url: 'common-service/setPassword',
    method: 'post',
    data: data
  });
}

function PayPassword(data) {
  return (0, _request.default)({
    url: 'common-service/setPayPassword',
    method: 'post',
    data: data
  });
} // 用户钱包详情


function getWalletDetail(data) {
  return (0, _request.default)({
    url: 'base_service/wallet_detail',
    method: 'get',
    params: data
  });
} // 刷新token


function refreshToken(reToken) {
  var data = {
    grant_type: 'refresh_token',
    client_id: 'mall',
    client_secret: 'secret',
    refresh_token: reToken
  };
  return (0, _request.default)({
    url: 'auth-service/oauth/token',
    method: 'post',
    data: _qs.default.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}