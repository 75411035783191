"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/divider/style");

var _divider = _interopRequireDefault(require("vant/es/divider"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("core-js/modules/es6.function.name");

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

var _vuex = require("vuex");

var _coupon = require("@/api/coupon.js");

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default2 = {
  name: "Coupon",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _divider.default.name, _divider.default), _components),
  props: {
    // 列表数据
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 是否展示选择框
    checkedIconShow: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    defaultCurrency: function defaultCurrency(state) {
      return state.exchangeRate['defaultCurrency'];
    }
  })),
  created: function created() {},
  methods: {
    // 选择优惠券并返回index
    selectCoupon: function selectCoupon(index) {
      this.$emit('selectCoupon', index);
    },
    // 去使用
    nextGo: function nextGo(coupon) {
      if (coupon.goodsUsableType === 1) {
        // 全部可用
        switch (coupon.goodsRangeType) {
          case 2:
            this.$router.push('/selfSupport');
            break;

          default:
            this.$router.push('/');
            break;
        }
      } else {
        // 部分可用
        switch (coupon.goodsRangeType) {
          case 2:
            this.$router.push({
              path: '/designatCouponPage',
              query: {
                id: coupon.id
              }
            });
            break;

          default:
            this.$router.push('/');
            break;
        }
      }
    },
    // 领取券码
    obtainCoupon: function obtainCoupon(id) {
      var _this = this;

      (0, _coupon.obtainCoupon)({
        couponId: id
      }).then(function (res) {
        _toast.default.success("Received successfully");

        _this.$emit("receivedCouponSuccess", id);
      });
    }
  }
};
exports.default = _default2;