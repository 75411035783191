"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _vm.type ? _c("div", {
    staticClass: "r-start"
  }, [_c("div", {
    staticClass: "f-12",
    staticStyle: {
      "margin-right": "7px"
    },
    domProps: {
      textContent: _vm._s(_vm.switchType(_vm.type))
    }
  }), _vm._v(" "), _c("van-count-down", {
    attrs: {
      time: _vm.time
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(timeData) {
        return [_c("span", {
          staticClass: "block f-12",
          style: _vm.myStyle
        }, [_vm._v(_vm._s(_vm._f("addZero")(timeData.hours + timeData.days * 24)))]), _vm._v(" "), _c("span", {
          staticClass: "colon"
        }), _vm._v(" "), _c("span", {
          staticClass: "block f-12",
          style: _vm.myStyle
        }, [_vm._v(_vm._s(_vm._f("addZero")(timeData.minutes)))]), _vm._v(" "), _c("span", {
          staticClass: "colon"
        }), _vm._v(" "), _c("span", {
          staticClass: "block f-12",
          style: _vm.myStyle
        }, [_vm._v(_vm._s(_vm._f("addZero")(timeData.seconds)))])];
      }
    }], null, false, 3619546258)
  })], 1) : _vm._e();
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;