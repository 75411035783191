"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMyBrowsed = getMyBrowsed;
exports.getMyLikeList = getMyLikeList;
exports.removeMyLike = removeMyLike;

var _request = _interopRequireDefault(require("@/utils/request"));

// 我的收藏夹
function getMyLikeList(data) {
  return (0, _request.default)({
    url: 'spu-service/spu/user/like/list',
    method: 'post',
    data: data
  });
} // 用户取消收藏商品


function removeMyLike(data) {
  return (0, _request.default)({
    url: 'spu-service/spu/user/unlike',
    method: 'post',
    data: data
  });
} // 我的足迹


function getMyBrowsed(params) {
  return (0, _request.default)({
    url: 'spu-service/spu/user/browse_records',
    method: 'get',
    params: params
  });
}