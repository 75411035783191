"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// 相关文档 https://vuex.vuejs.org/zh/api/#subscribeaction
var NAMESPACE = '@@loading';

var createLoadingPlugin = function createLoadingPlugin() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$namespace = _ref.namespace,
      namespace = _ref$namespace === void 0 ? NAMESPACE : _ref$namespace,
      _ref$includes = _ref.includes,
      includes = _ref$includes === void 0 ? [] : _ref$includes,
      _ref$excludes = _ref.excludes,
      excludes = _ref$excludes === void 0 ? [] : _ref$excludes;

  return function (store) {
    if (store.state[namespace]) {
      throw new Error("createLoadingPlugin: ".concat(namespace, " exited in current store"));
    }

    store.registerModule(namespace, {
      namespaced: true,
      state: {
        global: false,
        effects: {}
      },
      mutations: {
        SHOW: function SHOW(state, _ref2) {
          var payload = _ref2.payload;
          state.global = true;
          state.effects = _objectSpread(_objectSpread({}, state.effects), {}, (0, _defineProperty2.default)({}, payload, true));
        },
        HIDE: function HIDE(state, _ref3) {
          var payload = _ref3.payload;
          state.global = false;
          state.effects = _objectSpread(_objectSpread({}, state.effects), {}, (0, _defineProperty2.default)({}, payload, false));
        }
      }
    });
    store.subscribeAction({
      before: function before(action) {
        console.log("before action ".concat(action.type));

        if (shouldEffect(action, includes, excludes)) {
          store.commit({
            type: namespace + '/SHOW',
            payload: action.type
          });
        }
      },
      after: function after(action) {
        console.log("after action ".concat(action.type));

        if (shouldEffect(action, includes, excludes)) {
          store.commit({
            type: namespace + '/HIDE',
            payload: action.type
          });
        }
      }
    });
  };
};

function shouldEffect(_ref4, includes, excludes) {
  var type = _ref4.type;

  if (includes.length === 0 && excludes.length === 0) {
    return true;
  }

  if (includes.length > 0) {
    return includes.indexOf(type) > -1;
  }

  return excludes.length > 0 && excludes.indexOf(type) === -1;
}

var _default = createLoadingPlugin; // 需要在vuex中引入并注册成插件：

/**
import createLoadingPlugin from 'utils/vuex-loading'
export default new Vuex.Store({
  plugins: [createLoadingPlugin()],
  state: {
    // loading: false,
    direction: 'forward'
  }
})
**/

exports.default = _default;