"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

var _vue = _interopRequireDefault(require("vue"));

var _vuex = _interopRequireDefault(require("vuex"));

var _vuexLoading = _interopRequireDefault(require("utils/vuex-loading"));

_vue.default.use(_vuex.default);

var files = require.context('./modules', false, /\.js$/);

var modules = {};
files.keys().forEach(function (key) {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default;
});

var _default = new _vuex.default.Store({
  plugins: [(0, _vuexLoading.default)()],
  state: {
    direction: 'forward' // 页面切换方向

  },
  getters: {
    userData: function userData(state, getters) {
      return state.user.user; // return getters['user/user']
    } // vuex 全局getters引入局部
    // token () {
    //   return store.getters['user/token']
    // }

  },
  mutations: {
    // 更新页面切换方向
    updateDirection: function updateDirection(state, direction) {
      state.direction = direction;
    }
  },
  actions: {},
  modules: modules
});

exports.default = _default;