"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    class: !_vm.position.latitude || !_vm.position.longitude ? "isNotice view" : "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Set your pickup address",
      "safe-area-inset-top": "",
      "left-arrow": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), !_vm.position.latitude || !_vm.position.longitude ? _c("van-notice-bar", {
    attrs: {
      wrapable: "",
      "left-icon": "info-o",
      background: "#FFF3E9",
      color: "#FF0037"
    }
  }, [_vm._v("Open positioning to view the nearest pickup address.")]) : _vm._e(), _vm._v(" "), _vm.packageStoreList.length ? _c("van-tabs", {
    class: !_vm.position.latitude || !_vm.position.longitude ? "isTabTop" : "",
    attrs: {
      animated: "",
      swipeable: ""
    },
    on: {
      change: _vm.changeTab
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, _vm._l(_vm.packageStoreList, function (item, index) {
    return _c("van-tab", {
      key: item.id,
      attrs: {
        title: "Local".concat(index + 1),
        name: item.id
      }
    }, [_c("div", {
      staticClass: "main"
    }, [_vm._l(_vm.postStationList, function (ele, i) {
      return [i === 1 ? _c("div", {
        key: i + "tip",
        staticClass: "post-station-tip"
      }, [_c("span", {
        staticStyle: {
          color: "#8888FF"
        }
      }, [_vm._v("▼ ▼ ▼")]), _vm._v(" You can pay some fees for the shipping station so that they can send the goods to the nearest pickup address from you：\n          ")]) : _vm._e(), _vm._v(" "), _c("div", {
        key: i,
        staticClass: "card",
        class: {
          "selected-border": String(ele.id) === _vm.$route.query.selectPackageStoreId
        },
        on: {
          click: function click($event) {
            return _vm.selectPickup(ele);
          }
        }
      }, [_c("div", {
        staticClass: "card-title"
      }, [_c("span", {
        staticClass: "title-name van-ellipsis"
      }, [_vm._v(_vm._s(ele.nameEn))]), [0, 1].includes(i) ? _c("span", {
        staticClass: "tip"
      }, [_vm._v(_vm._s(i === 0 ? "Free" : i === 1 ? "Nearest" : ""))]) : _vm._e()]), _vm._v(" "), _c("div", {
        staticClass: "content"
      }, [_c("div", {
        staticClass: "content-left"
      }, [_c("div", {
        staticClass: "cell"
      }, [_c("div", [_vm._v("Distance: "), _c("span", {
        staticStyle: {
          color: "#FF0037"
        }
      }, [_vm._v(_vm._s(Number(ele.distance) < 0 ? "-" : ele.distance))]), _vm._v("km")]), _vm._v(" "), _vm.$route.query.showPrice && ele.price ? _c("b", {
        staticStyle: {
          color: "#FF0037"
        }
      }, [_vm._v("₦" + _vm._s(Number(ele.price).toFixed(2)))]) : _vm._e()]), _vm._v(" "), _c("div", {
        staticStyle: {
          height: "6px"
        }
      }), _vm._v(" "), _c("div", {
        staticClass: "cell"
      }, [_c("div", [_vm._v("Estimated pickup time: ")]), _vm._v(" "), _c("b", {
        staticStyle: {
          color: "#FF0037"
        }
      }, [_vm._v(_vm._s(_vm.dayjs(ele.estimatedPickupTime).format("MMM DD")))])])]), _vm._v(" "), _c("div", {
        staticClass: "content-right"
      }, [_c("van-icon", {
        attrs: {
          size: "60",
          name: require("@/assets/images/goods_detail/map.png")
        },
        on: {
          click: function click($event) {
            $event.stopPropagation();
            return _vm.openMap(ele);
          }
        }
      })], 1)]), _vm._v(" "), _c("div", {
        staticClass: "address"
      }, [_c("div", [_vm._v(_vm._s(ele.region))]), _vm._v(" "), _c("div", {
        staticStyle: {
          "text-decoration": "underline"
        },
        on: {
          click: function click($event) {
            $event.stopPropagation();
            return _vm.openMap(ele);
          }
        }
      }, [_vm._v(_vm._s(ele.address))])])])];
    })], 2)]);
  }), 1) : _vm._e()], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;