"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.function.name");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Refund Details",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("header", [_c("div", {
    staticClass: "photo"
  }, [_vm._l(_vm.titleArr, function (item, index) {
    return [_c("van-icon", {
      key: "icon".concat(index),
      class: {
        opacity: !item.active
      },
      attrs: {
        name: item.icon,
        size: "24"
      }
    }), _vm._v(" "), _vm.titleArr[index + 1] ? _c("van-divider", {
      key: "divider".concat(index),
      class: {
        opacity: !item.active
      },
      style: {
        background: "#fff",
        flex: "1",
        margin: "0 16px"
      }
    }) : _vm._e()];
  })], 2), _vm._v(" "), _c("div", {
    staticClass: "explain"
  }, [_vm._l(_vm.titleArr, function (item, index) {
    return [_c("div", {
      key: index,
      class: {
        opacity: !item.active
      }
    }, [_vm._v(_vm._s(item.name))])];
  })], 2)]), _vm._v(" "), _c("div", {
    staticClass: "card",
    staticStyle: {
      fontSize: "10px"
    }
  }, [_c("div", {
    staticClass: "grey"
  }, [_vm._v("Creat Time: " + _vm._s(_vm._f("parseTime")(_vm.dataObj.createTime)))]), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/after_service/refund_id.png"),
      size: "12"
    }
  }), _c("span", {
    staticStyle: {
      margin: "0 4px"
    }
  }, [_vm._v("Refund Id:")]), _c("span", {
    staticClass: "grey"
  }, [_vm._v(_vm._s(_vm.dataObj.id))])], 1), _vm._v(" "), _vm.dataObj.innerStatus === 2 ? _c("div", {
    staticClass: "theme-color"
  }, [_vm._v("Need to return the goods")]) : _vm._e()]), _vm._v(" "), _c("van-divider"), _vm._v(" "), _vm._l(_vm.dataObj.order.orderDetails, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "wrap",
      on: {
        click: function click($event) {
          return _vm.$router.push({
            path: "/orderDetails",
            query: {
              id: _vm.dataObj.orderId
            }
          });
        }
      }
    }, [_c("van-image", {
      attrs: {
        width: "70",
        height: "70",
        src: item.mainImage
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "content"
    }, [_c("div", {
      staticClass: "good-name"
    }, [_vm._v(_vm._s(item.goodsNameEn))]), _vm._v(" "), _c("div", {
      staticClass: "size"
    }, [_vm._v(_vm._s(_vm._f("getSkuInfosEn")(item.skuInfosEn && JSON.parse(item.skuInfosEn))))]), _vm._v(" "), _c("div", {
      staticClass: "info"
    }, [_c("div", {
      staticClass: "piece"
    }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(item.unitPrice, JSON.parse(_vm.dataObj.order.exchangeRate || null)))), _c("span", {
      staticClass: "piece"
    }, [_vm._v(" /Piece")])]), _vm._v(" "), _c("div", [_vm._v("x" + _vm._s(item.quantity))])]), _vm._v(" "), _c("div", {
      staticClass: "size"
    }, [_vm._v("Number of applications：" + _vm._s(item.quantity2 || 0))])])], 1);
  }), _vm._v(" "), [1, 2].includes(_vm.dataObj.afterType) ? _c("div", {
    staticClass: "amount"
  }, [_vm._v(_vm._s([1, 2, 4].includes(_vm.dataObj.status) ? "Application" : "Refunded") + " Amount: "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.dataObj.refundAmount).toFixed(2))))])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "amount"
  }, [_vm._v("Total Amount: "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.dataObj.order.totalPrice, JSON.parse(_vm.dataObj.order.exchangeRate || null))))])])], 2), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("h3", [_vm._v("Refund Information")]), _vm._v(" "), _c("van-divider"), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Type:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.dataObj.afterType === 1 ? "Return&Refund" : _vm.dataObj.afterType === 2 ? "Refund" : "Exchange"))])]), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Order Id:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.dataObj.orderId))])]), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Create time:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("parseTime")(_vm.dataObj.createTime)))])]), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Reason for " + _vm._s(_vm.dataObj.afterType === 1 ? "return&refund" : _vm.dataObj.afterType === 2 ? "refund" : "exchange") + ":")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(JSON.parse(_vm.dataObj.reason).refundReasonEn))])]), _vm._v(" "), _vm.dataObj.isPay === 2 && Number(_vm.dataObj.refundStationFee) ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Logistics cost:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.dataObj.refundStationFee).toFixed(2))) + " "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v("NGN")])])]) : _vm._e(), _vm._v(" "), JSON.parse(_vm.dataObj.voucher).length ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Voucher:")]), _vm._v(" "), _c("van-image", {
    attrs: {
      width: "36",
      height: "44",
      src: JSON.parse(_vm.dataObj.voucher)[0]
    },
    on: {
      click: _vm.openImage
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _vm.dataObj.innerStatus === 2 ? _c("div", {
    staticClass: "card"
  }, [_c("h3", [_vm._v("Return Information")]), _vm._v(" "), _c("van-divider"), _vm._v(" "), _c("div", {
    staticStyle: {
      "align-items": "normal"
    }
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/order/warehouse.png"),
      size: "12"
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "0 4px"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.returnOrderAddress.nameEn))]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.returnOrderAddress.region))]), _vm._v(" "), _c("div", [_c("span", {
    staticStyle: {
      "text-decoration": "underline"
    },
    on: {
      click: _vm.openMap
    }
  }, [_vm._v(_vm._s(_vm.returnOrderAddress.address))])]), _vm._v(" "), _vm.returnOrderAddress.startTime && _vm.returnOrderAddress.endTime ? _c("div", [_vm._v("Business hours: " + _vm._s(_vm.returnOrderAddress.startTime) + "-" + _vm._s(_vm.returnOrderAddress.endTime))]) : _vm._e()])], 1)], 1) : _vm._e()], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;