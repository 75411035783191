"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("core-js/modules/es6.function.name");

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

var _CountDown = _interopRequireDefault(require("@/components/CountDown"));

var _components;

var _default = {
  name: 'FlashSaleCard',
  components: (_components = {}, (0, _defineProperty2.default)(_components, _image.default.name, _image.default), (0, _defineProperty2.default)(_components, "CountDown", _CountDown.default), _components),
  props: {
    // 闪购商品
    flashSaleList: {
      type: Array,
      required: true,
      default: null
    },
    // 开始时间
    beginTime: {
      type: String,
      required: true,
      default: ''
    },
    // 结束时间
    endTime: {
      type: String,
      required: true,
      default: ''
    },
    // 活动id
    activityId: {
      type: String,
      required: true,
      default: ''
    }
  },
  data: function data() {
    return {
      upRowGoodList: [],
      // 上一行
      downRowGoodList: [] // 下一行

    };
  },
  watch: {
    flashSaleList: function flashSaleList(newQuestion, oldQuestion) {
      this.getRowList();
    }
  },
  mounted: function mounted() {
    this.getRowList();
  },
  methods: {
    getRowList: function getRowList() {
      var _length = this.flashSaleList.length;

      var _count = _length >= 8 ? Math.ceil(_length / 2) : _length;

      this.upRowGoodList = this.flashSaleList.slice(0, _count);
      this.downRowGoodList = this.flashSaleList.slice(_count, _length);
    },
    toGoodDetail: function toGoodDetail(item) {
      var _arguments = {
        "url": item['spuId'],
        "module": 1,
        "activityId": this.activityId
      };
      this.$router.push({
        path: "/GoodsContent",
        query: _arguments
      });
    }
  }
};
exports.default = _default;