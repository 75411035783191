"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("van-share-sheet", {
    ref: "share-sheet",
    attrs: {
      title: "About commission",
      options: _vm.shareOptions
    },
    on: {
      select: _vm.onSelectShare
    },
    scopedSlots: _vm._u([{
      key: "description",
      fn: function fn() {
        return [_c("div", [_vm._v("\n        In order to attract more "), _c("span", {
          staticClass: "theme-color"
        }, [_vm._v("new customers")]), _vm._v(" to use Womata App, Womata \n        decided to "), _c("span", {
          staticClass: "theme-color"
        }, [_vm._v("give a sum of money as a commission reward to users")]), _vm._v(" \n        who share womata and make others buy.\n      ")]), _vm._v(" "), _c("div", [_c("div", [_vm._v("The more you share, the more commission!")]), _vm._v(" "), _c("div", {
          staticClass: "theme-color",
          on: {
            click: function click($event) {
              return _vm.$router.push("/ruleDescription");
            }
          }
        }, [_vm._v("view more>>>")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm._showShare,
      callback: function callback($$v) {
        _vm._showShare = $$v;
      },
      expression: "_showShare"
    }
  }), _vm._v(" "), _vm.showShare ? _c("div", {
    ref: "share-photo",
    staticClass: "share-photo",
    style: _vm.myStyleZIndex
  }, [_vm.canvasImg ? [_c("van-image", {
    attrs: {
      src: _vm.canvasImg
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "canvas-img"
  }, [_vm._v("Long press to save the picture")])] : _c("div", {
    staticClass: "wrap"
  }, [_c("van-image", {
    attrs: {
      src: require("@/assets/images/goods_detail/womata_share.png"),
      width: "50px",
      height: "16px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "title"
  }, [_vm._v("Hey friends, there's a great product for you!")]), _vm._v(" "), _c("img", {
    attrs: {
      src: _vm._f("filterGoodImg")("".concat(_vm.headImg, "?time=").concat(new Date().valueOf()), _vm.goodsSource),
      width: "100%",
      height: "160px",
      crossorigin: "anonymous"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "bottom"
  }, [_vm.goodsSource === 1 ? _c("div", {
    staticClass: "goods-info"
  }, [_c("div", [_c("span", {
    staticClass: "theme-color",
    staticStyle: {
      "font-size": "11px"
    }
  }, [_vm._v("\n              " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm._f("filtersPmsLeftPrice")(_vm.productInfo))) + " \n              "), _vm._v("\n              " + _vm._s(_vm._f("filtersPmsLeftLine")(_vm.productInfo)) + " \n              "), _vm._v("\n              " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm._f("filtersPmsRightPrice")(_vm.productInfo))) + "\n            ")])]), _vm._v(" "), _c("div", {
    staticClass: "van-multi-ellipsis--l3"
  }, [_vm._v(_vm._s(_vm.subjectEn || _vm.subjectZh))])]) : _c("div", {
    staticClass: "goods-info"
  }, [_c("div", [_vm.aliPriceRangeList.length > 1 ? _c("span", {
    staticClass: "theme-color",
    staticStyle: {
      "font-size": "11px"
    }
  }, [_vm._v("\n              " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm.aliPriceRangeList[0])) + " - " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm.aliPriceRangeList[_vm.aliPriceRangeList.length - 1])) + "\n            ")]) : _c("span", [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.aliPriceRangeList[0])))])])]), _vm._v(" "), _c("div", {
    ref: "qrcodeContainer",
    staticClass: "qrcode"
  })])], 1)], 2) : _vm._e()], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;