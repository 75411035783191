"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("vant/es/dialog/style");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

require("core-js/modules/es6.function.name");

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

var _order = require("api/order");

var _filter = require("@/filter");

var _components;

var _default2 = {
  components: (_components = {}, (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _image.default.name, _image.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), _components),
  props: {
    // 跳去页面Name
    clickToPageName: {
      type: String,
      default: null
    },
    order: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  methods: {
    handleGoodCard: function handleGoodCard() {
      if (this.clickToPageName === "OrderDetails") {
        this.$router.push({
          path: '/orderDetails',
          query: {
            order: this.order
          }
        });
      }
    },
    paymentOrder: function paymentOrder(id) {
      var _this = this;

      _dialog.default.confirm({
        message: 'This fee is only for warehouse storage, does not include the international freight fee.',
        theme: 'round-button'
      }).then(function () {
        _this.$EventBus.$emit(_this.$route.name === 'OrderDetails' ? 'openPayPassword2' : 'openPayPassword', {
          totalPriceNGN: (0, _filter.fromCurrencyByToCurrency)(_this.order.subtotal, "CNY", "NGN"),
          selectOrderIdList: [id]
        });
      }).catch(function () {// on cancel
      });
    },
    cancelOrder: function cancelOrder(id) {
      var _this2 = this;

      _dialog.default.confirm({
        title: 'Cancel this order?',
        theme: 'round-button'
      }).then(function () {
        (0, _order.cancelOrder)({
          orderId: id
        }).then(function (res) {
          (0, _toast.default)("OK");

          _this2.$EventBus.$emit('orderListRefresh');

          _this2.$router.push({
            name: 'Order',
            params: {
              status: 1
            }
          });
        });
      }).catch(function () {// on cancel
      });
    }
  }
};
exports.default = _default2;