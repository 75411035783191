"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("van-nav-bar", {
    attrs: {
      title: "Logistics track",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _vm.logisticsTrackList.length ? _c("div", {
    staticClass: "card"
  }, [_c("van-collapse", {
    model: {
      value: _vm.activeNames,
      callback: function callback($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, _vm._l(_vm.logisticsTrackList, function (item, index) {
    return _c("van-collapse-item", {
      key: index,
      attrs: {
        title: item.orderId,
        name: index
      }
    }, [_c("van-collapse", {
      model: {
        value: _vm.activeNames2,
        callback: function callback($$v) {
          _vm.activeNames2 = $$v;
        },
        expression: "activeNames2"
      }
    }, _vm._l(item.orders, function (element, i) {
      return _c("van-collapse-item", {
        key: i,
        attrs: {
          title: element.orderDetailsId,
          name: "".concat(index, "-").concat(i)
        }
      }, [_c("van-steps", {
        attrs: {
          direction: "vertical",
          active: 0
        }
      }, _vm._l(element.logisticsTracks, function (track) {
        return _c("van-step", {
          key: track.bid
        }, [_c("h4", [_vm._v(_vm._s(track.nameEn))]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm._f("parseTime")(track.createTime)))])]);
      }), 1)], 1);
    }), 1)], 1);
  }), 1)], 1) : _c("van-empty", {
    attrs: {
      description: "No more data!"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/no_data.png")
          }
        })];
      },
      proxy: true
    }])
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;