"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/dialog/style");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/number-keyboard/style");

var _numberKeyboard = _interopRequireDefault(require("vant/es/number-keyboard"));

require("vant/es/password-input/style");

var _passwordInput = _interopRequireDefault(require("vant/es/password-input"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("core-js/modules/es6.function.name");

require("vant/es/popup/style");

var _popup = _interopRequireDefault(require("vant/es/popup"));

var _components;

var _default = {
  name: "PayPassword",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _popup.default.name, _popup.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _passwordInput.default.name, _passwordInput.default), (0, _defineProperty2.default)(_components, _numberKeyboard.default.name, _numberKeyboard.default), _components),
  props: {
    // 商品总价
    totalAmount: {
      type: [String, Number],
      default: 0
    },
    // 展示密码输入框
    showPayPassword: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: function data() {
    return {
      payPassword: "",
      errorInfo: ''
    };
  },
  computed: {
    showPayPassword_: {
      get: function get() {
        return this.showPayPassword;
      },
      set: function set(newVal) {
        return newVal;
      }
    }
  },
  watch: {
    payPassword: function payPassword(value) {
      if (value.length === 6) {
        this.$emit('payPasswordDown', this.payPassword);
      }
    }
  },
  created: function created() {
    var _this = this;

    this.$EventBus.$on('clearPayPassword', function (_) {
      _this.payPassword = "";
    });
  },
  methods: {
    // 关闭支付密码
    clickClose: function clickClose() {
      var _this2 = this;

      _dialog.default.confirm({
        message: 'Are you sure you want to waive payment?',
        theme: 'round-button'
      }).then(function () {
        _this2.$emit('showPayPassword', false);

        _this2.payPassword = "";

        _this2.$emit('cancelPayment');
      }).catch(function () {// on cancel
      });
    }
  }
};
exports.default = _default;