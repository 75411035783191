"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.function.name");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "address-edit-view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "123",
      "left-text": "Back",
      "left-arrow": ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "address-box"
  }, [_c("van-form", {
    staticClass: "from-box",
    on: {
      submit: _vm.onSubmit
    }
  }, [_c("van-field", {
    attrs: {
      name: "name",
      label: "Name",
      placeholder: "Receiver Name",
      rules: [{
        required: true,
        message: "Please fill in the consignee's name"
      }]
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      type: "number",
      name: "phone",
      label: "phone",
      placeholder: "Receiver's mobile number",
      rules: [{
        required: true,
        message: "Please fill"
      }]
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      name: "detailedAddress",
      label: "Detailed address",
      placeholder: "Street sect, floor room and other information",
      type: "textarea",
      rows: "2",
      rules: [{
        required: true,
        message: "Please fill"
      }]
    },
    model: {
      value: _vm.detailedAddress,
      callback: function callback($$v) {
        _vm.detailedAddress = $$v;
      },
      expression: "detailedAddress"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      name: "zipCode",
      label: "zip code",
      placeholder: "zip code"
    },
    model: {
      value: _vm.zipCode,
      callback: function callback($$v) {
        _vm.zipCode = $$v;
      },
      expression: "zipCode"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      name: "defaultAddress",
      label: "isDefault"
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c("van-switch", {
          model: {
            value: _vm.defaultAddress,
            callback: function callback($$v) {
              _vm.defaultAddress = $$v;
            },
            expression: "defaultAddress"
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "16px"
    }
  }, [_c("van-button", {
    attrs: {
      round: "",
      block: "",
      type: "info",
      "native-type": "submit"
    }
  }, [_vm._v("\n          Submit\n        ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "16px"
    }
  }), _vm._v(" "), _vm.id ? _c("van-button", {
    attrs: {
      round: "",
      block: "",
      plain: "",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.onDelete();
      }
    }
  }, [_vm._v("\n          Delete\n        ")]) : _vm._e()], 1)], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;