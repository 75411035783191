"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-search", {
          attrs: {
            shape: "round",
            disabled: "",
            "clear-trigger": "always",
            placeholder: "Please search"
          },
          on: {
            click: function click($event) {
              return _vm.$router.push({
                path: "/search",
                query: {
                  fromPage: "MyLike"
                }
              });
            },
            clear: _vm.changeTab
          },
          model: {
            value: _vm.keyword,
            callback: function callback($$v) {
              _vm.keyword = $$v;
            },
            expression: "keyword"
          }
        })];
      },
      proxy: true
    }, {
      key: "right",
      fn: function fn() {
        return [_c("div", {
          staticClass: "remove",
          on: {
            click: function click($event) {
              _vm.removeShow = !_vm.removeShow;
            }
          }
        }, [_vm._v(_vm._s(_vm.removeShow ? "Done" : "Remove"))])];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("van-tabs", {
    attrs: {
      swipeable: "",
      animated: ""
    },
    on: {
      change: _vm.changeTab
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, _vm._l(_vm.tabList, function (item) {
    return _c("van-tab", {
      key: item.id,
      attrs: {
        title: _vm.activeTab === item.id ? "".concat(item.title, "(").concat(_vm.totalCount, ")") : item.title,
        name: item.id
      }
    }, [_c("van-pull-refresh", {
      attrs: {
        "pulling-text": "Pull down refresh",
        "loosing-text": "Release to refresh",
        "loading-text": "Refreshing",
        "success-text": "Refresh completed"
      },
      on: {
        refresh: _vm.onRefresh
      },
      model: {
        value: _vm.refreshing,
        callback: function callback($$v) {
          _vm.refreshing = $$v;
        },
        expression: "refreshing"
      }
    }, [_vm.list.length ? _c("van-list", {
      attrs: {
        finished: _vm.finished,
        "finished-text": "No More ...",
        "immediate-check": false
      },
      on: {
        load: _vm.onLoad
      },
      model: {
        value: _vm.loading,
        callback: function callback($$v) {
          _vm.loading = $$v;
        },
        expression: "loading"
      }
    }, [_vm._l(_vm.list, function (ele, i) {
      return [_c("div", {
        key: i,
        staticClass: "good-card"
      }, [_vm.removeShow ? _c("van-checkbox", {
        attrs: {
          "icon-size": "16"
        },
        model: {
          value: ele.checked,
          callback: function callback($$v) {
            _vm.$set(ele, "checked", $$v);
          },
          expression: "ele.checked"
        }
      }) : _vm._e(), _vm._v(" "), _c("GoodCardItem", {
        attrs: {
          ele: ele,
          "remove-show": _vm.removeShow
        },
        nativeOn: {
          click: function click($event) {
            return _vm.handleChecked(ele);
          }
        }
      })], 1)];
    })], 2) : _c("van-empty", {
      attrs: {
        description: "No more data!"
      },
      scopedSlots: _vm._u([{
        key: "image",
        fn: function fn() {
          return [_c("van-icon", {
            attrs: {
              name: require("@/assets/images/no_data.png")
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1)], 1);
  }), 1), _vm._v(" "), _vm.removeShow ? _c("div", {
    staticClass: "remove-bar"
  }, [_c("div", {
    staticClass: "remove-bar-total"
  }, [_vm._v("Select: " + _vm._s(_vm.getTotalChecked) + "/" + _vm._s(_vm.totalCount))]), _vm._v(" "), _c("div", {
    staticClass: "remove-bar-remove",
    on: {
      click: _vm.handleRemove
    }
  }, [_vm._v("Remove")])]) : _vm._e()], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;