"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/notify/style");

var _notify = _interopRequireDefault(require("vant/es/notify"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/list/style");

var _list = _interopRequireDefault(require("vant/es/list"));

require("vant/es/notice-bar/style");

var _noticeBar = _interopRequireDefault(require("vant/es/notice-bar"));

require("vant/es/tabs/style");

var _tabs = _interopRequireDefault(require("vant/es/tabs"));

require("vant/es/tab/style");

var _tab = _interopRequireDefault(require("vant/es/tab"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

require("vant/es/empty/style");

var _empty = _interopRequireDefault(require("vant/es/empty"));

require("core-js/modules/es6.function.name");

require("vant/es/pull-refresh/style");

var _pullRefresh = _interopRequireDefault(require("vant/es/pull-refresh"));

var _coupon = require("@/api/coupon.js");

var _CouponItem = _interopRequireDefault(require("components/CouponItem"));

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "CouponCenter",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _pullRefresh.default.name, _pullRefresh.default), (0, _defineProperty2.default)(_components, _empty.default.name, _empty.default), (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _tab.default.name, _tab.default), (0, _defineProperty2.default)(_components, _tabs.default.name, _tabs.default), (0, _defineProperty2.default)(_components, _noticeBar.default.name, _noticeBar.default), (0, _defineProperty2.default)(_components, _list.default.name, _list.default), (0, _defineProperty2.default)(_components, "CouponItem", _CouponItem.default), _components),
  data: function data() {
    return {
      notice: true,
      activeTab: 1,
      refreshing: false,
      loading: false,
      finished: false,
      tabList: [{
        id: 1,
        title: "Available"
      }, {
        id: 2,
        title: "Received"
      }],
      list: [],
      pageNum: 1
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      if (this.activeTab === 1) {
        (0, _coupon.getCouponsCenter)({
          queryCouponType: this.activeTab,
          pageNum: this.pageNum,
          pageSize: 10
        }).then(function (res) {
          var _res$data;

          _this.loading = false;
          _this.refreshing = false;

          if (((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.length) > 0) {
            if (_this.pageNum === 1) {
              _this.list = res.data.map(function (item) {
                return {
                  coupon: _objectSpread({}, item)
                };
              });
            } else {
              _this.list = _this.list.concat(res.data.map(function (item) {
                return {
                  coupon: _objectSpread({}, item)
                };
              }));
            }
          } else {
            if (_this.pageNum === 1) _this.list = [];
            _this.finished = true;
          }
        });
      } else {
        (0, _coupon.getCouponCode)({
          status: 1,
          pageNum: this.pageNum,
          pageSize: 10
        }).then(function (res) {
          var _res$data2;

          _this.loading = false;
          _this.refreshing = false;

          if (((_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.length) > 0) {
            if (_this.pageNum === 1) {
              _this.list = res.data;
            } else {
              _this.list = _this.list.concat(res.data);
            }
          } else {
            if (_this.pageNum === 1) _this.list = [];
            _this.finished = true;
          }
        });
      }
    },
    // 领取券码
    obtainCoupon: function obtainCoupon(id) {
      var _this2 = this;

      (0, _coupon.obtainCoupon)({
        couponId: id
      }).then(function (res) {
        (0, _notify.default)({
          type: 'success',
          message: 'Received successfully'
        });

        _this2.onRefresh();
      });
    },
    // 去使用
    useNow: function useNow(coupon) {
      if (coupon.goodsUsableType === 1) {
        // 全部可用
        switch (coupon.goodsRangeType) {
          case 2:
            this.$router.push('/selfSupport');
            break;

          default:
            this.$router.push('/');
            break;
        }
      } else {
        // 部分可用
        switch (coupon.goodsRangeType) {
          case 2:
            this.$router.push({
              path: '/designatCouponPage',
              query: {
                id: coupon.id
              }
            });
            break;

          default:
            this.$router.push('/');
            break;
        }
      }
    },
    changeTab: function changeTab() {
      this.list = [];
      this.finished = false;
      this.pageNum = 1;
      this.fetchData();
    },
    onLoad: function onLoad() {
      this.pageNum++;
      this.fetchData();
    },
    // 下拉刷新
    onRefresh: function onRefresh() {
      this.finished = false;
      this.pageNum = 1;
      this.fetchData();
    }
  }
};
exports.default = _default;