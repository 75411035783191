"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "good-card"
  }, [_c("div", {
    staticClass: "good-box"
  }, [_c("div", {
    staticClass: "good-img"
  }, [_c("van-image", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "lazy-load": "",
      src: _vm.imageUrl
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function fn() {
        return [_c("img", {
          staticStyle: {
            width: "100%",
            height: "240px"
          },
          attrs: {
            src: require("@/assets/images/wologo_opacity.png")
          }
        })];
      },
      proxy: true
    }, {
      key: "error",
      fn: function fn() {
        return [_c("img", {
          staticStyle: {
            width: "100%",
            height: "240px"
          },
          attrs: {
            src: require("@/assets/images/wologo_opacity.png")
          }
        })];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "9px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "p-r spu-name van-multi-ellipsis--l3"
  }, [_vm.supplyType != null && _vm.supplyType === 2 ? _c("span", {
    staticClass: "my-tag"
  }, [_vm._v("Goods in Nigeria")]) : _vm._e(), _vm._v(_vm._s(_vm.spuName))]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "7px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "p-r r-start-center"
  }, [_c("div", {
    staticClass: "f-12 theme-color van-ellipsis"
  }, [_vm._v("\n        " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm.offerPrice)) + "\n      ")]), _vm._v(" "), _vm.showPrice ? _c("div", {
    staticClass: "show-price van-ellipsis"
  }, [_vm._v("\n        " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm.showPrice)) + "\n      ")]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "13px"
    }
  })]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showWomall,
      expression: "showWomall"
    }],
    staticClass: "good-tag"
  }, [_vm._v("Womall")])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;