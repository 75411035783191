"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "orderDetails-view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Order Detail",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "status-name"
  }, [_c("van-icon", {
    attrs: {
      name: _vm._f("getStatusIconUrl")(_vm.order.status),
      size: "30",
      color: "#fff"
    }
  }), _vm._v(_vm._s(_vm._f("getStatusIconUrl")(_vm.order.status, 2)))], 1), _vm._v(" "), _c("div", {
    staticClass: "container"
  }, [_vm.order.supplyType === 2 ? _c("div", {
    staticClass: "card"
  }, [_c("h3", [_vm._v("Receiving Information")]), _vm._v(" "), _c("van-divider"), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/order/consignee.png"),
      size: "13"
    }
  }), _vm._v(_vm._s(_vm.order.receiver))], 1), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.order.receiverCountryCode) + _vm._s(_vm.order.receiverPhone))])]), _vm._v(" "), [0, 3, 9].includes(_vm.order.status) && _vm.order.reservePickupTime && _vm.order.pickUpType === 2 ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_c("van-icon", {
    attrs: {
      name: "clock-o",
      size: "13"
    }
  }), _vm._v("Pickup time: "), _c("span", {
    class: {
      isExpires: _vm.order.reservePickupTimeIsExpires
    }
  }, [_vm._v(_vm._s(_vm._f("parseTime")(_vm.order.reservePickupTime, "{y}-{m}-{d}")))])], 1), _vm._v(" "), _c("div", {
    staticClass: "theme-color"
  }, [_vm.order.reservePickupTimeIsExpires ? [_c("span", [_vm._v("Timed out")])] : _vm.order.pickingStatus === 1 ? [_c("span", {
    on: {
      click: function click($event) {
        _vm.showPickupTime = true;
      }
    }
  }, [_vm._v("Change")])] : _vm._e()], 2)]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "cell",
    staticStyle: {
      "align-items": "flex-start"
    }
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/order/warehouse.png"),
      size: "13"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "address"
  }, [_c("div", [_vm._v(_vm._s(_vm.order.pickUpType === 2 ? _vm.order.packageStore && JSON.parse(_vm.order.packageStore)["nameEn"] : _vm.order.stationInfo && _vm.order.stationInfo.nameEn))]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.order.pickUpType === 2 ? _vm.order.packageStore && JSON.parse(_vm.order.packageStore)["region"] : _vm.order.stationInfo && _vm.order.stationInfo.region))]), _vm._v(" "), _c("div", {
    staticStyle: {
      "text-decoration": "underline"
    }
  }, [_vm._v(_vm._s(_vm.order.pickUpType === 2 ? _vm.order.packageStore && JSON.parse(_vm.order.packageStore)["address"] : _vm.order.stationInfo && _vm.order.stationInfo.address))]), _vm._v(" "), _c("div", [_vm._v("Business hours:" + _vm._s(_vm.order.pickUpType === 2 ? _vm.order.packageStore && JSON.parse(_vm.order.packageStore)["setting"] && "".concat(JSON.parse(JSON.parse(_vm.order.packageStore)["setting"])["businessHours"]["begin"], "-").concat(JSON.parse(JSON.parse(_vm.order.packageStore)["setting"])["businessHours"]["end"]) : _vm.order.stationInfo && "".concat(_vm.order.stationInfo.startTime, "-").concat(_vm.order.stationInfo.endTime)))])])])], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_vm.order.type === 3 ? _c("ForwardingItem", {
    attrs: {
      order: _vm.order
    }
  }) : [0, 1].includes(_vm.order.type) ? _c("PurchaseItem", {
    attrs: {
      "click-to-page-name": "GoodsContent",
      "cancel-btn-show": true,
      "service-btn-show": true,
      order: _vm.order
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("h3", [_vm._v("Order Information")]), _vm._v(" "), _c("van-divider"), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Order Id:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.order.id)), _c("span", {
    staticClass: "btn",
    on: {
      click: function click($event) {
        return _vm.copy(_vm.order.id);
      }
    }
  }, [_vm._v("copy")])])]), _vm._v(" "), _vm.order.type === 3 ? [_c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Category:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.order.agentGoodName))])]), _vm._v(" "), _vm.isShowKey.includes(_vm.order.status) ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Remark:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.order.remark))])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Express No:")]), _vm._v(" "), _c("div", {
    staticClass: "van-ellipsis"
  }, [_vm._v(_vm._s(_vm.order.agentExpress)), _c("span", {
    staticClass: "btn",
    on: {
      click: function click($event) {
        return _vm.copy(_vm.order.agentExpress);
      }
    }
  }, [_vm._v("copy")])])]), _vm._v(" "), [2, 3, 4, 6].includes(_vm.order.status) ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Specs:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.order.specs))])]) : _vm._e(), _vm._v(" "), [2, 3, 4, 6].includes(_vm.order.status) ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Estimated weight:")]), _vm._v(" "), _c("div", {
    staticClass: "theme-color"
  }, [_vm._v(_vm._s(Number(_vm.order.estimateWeight).toFixed(1)) + "KG")])]) : _vm._e(), _vm._v(" "), _vm.isShowKey.includes(_vm.order.status) ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Warehouse:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.order.storeAddress))])]) : _vm._e(), _vm._v(" "), _vm.isShowKey.includes(_vm.order.status) ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Consignee:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.order.receiver))])]) : _vm._e(), _vm._v(" "), _vm.isShowKey.includes(_vm.order.status) ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Phone:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.order.receiverPhone))])]) : _vm._e()] : [0, 1].includes(_vm.order.type) ? [_c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Total of goods:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.order.goodsPrice, JSON.parse(_vm.order.exchangeRate))))])]), _vm._v(" "), Number(_vm.order.discountedPrice) ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Discount of coupon:")]), _vm._v(" "), _c("div", {
    staticClass: "theme-color"
  }, [_vm._v("-" + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm.order.discountedPrice, JSON.parse(_vm.order.exchangeRate))))])]) : _vm._e(), _vm._v(" "), Number(_vm.order.commissionOffsetPrice) ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Amount of commission:")]), _vm._v(" "), _c("div", {
    staticClass: "theme-color"
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.order.commissionOffsetPrice).toFixed(2))))])]) : _vm._e(), _vm._v(" "), Number(_vm.order.changeOffsetPrice) ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Subtotal:")]), _vm._v(" "), _c("div", {
    staticClass: "theme-color"
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.order.changeOffsetPrice).toFixed(2))))])]) : _vm._e(), _vm._v(" "), Number(_vm.order.billAmount) ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Station fee:")]), _vm._v(" "), _c("div", {
    staticClass: "theme-color"
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.order.billAmount).toFixed(2))))])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Total Amount:")]), _vm._v(" "), _c("div", {
    staticClass: "theme-color"
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(_vm.getTotalPrice(_vm.order.totalPrice, _vm.order.billAmount, JSON.parse(_vm.order.exchangeRate)))))])]), _vm._v(" "), _vm.order.status === 3 && _vm.order.remark.trim() ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Remark:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.order.remark))])]) : _vm._e(), _vm._v(" "), _vm.order.supplyType === 1 ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Estimated weight:")]), _vm._v(" "), _c("div", {
    staticClass: "theme-color"
  }, [_vm._v(_vm._s(Number(_vm.order.estimateWeight).toFixed(1)) + "KG")])]) : _vm._e()] : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Create time:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("parseTime")(_vm.order.createTime)))])]), _vm._v(" "), [_vm.order.type === 3 && [1, 2, 3].includes(_vm.order.status) && _vm.order.payTime ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Pay time:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("parseTime")(_vm.order.payTime)))])]) : [0, 1].includes(_vm.order.type) && ![0, 5, 100].includes(_vm.order.status) && _vm.order.payTime ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Pay time:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("parseTime")(_vm.order.payTime)))])]) : _vm._e()], _vm._v(" "), _vm.order.type === 3 && _vm.order.status === 3 ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Delivery time:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("parseTime")(_vm.order.deliveryTime)))])]) : _vm._e(), _vm._v(" "), _vm.order.status === 5 ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Cancel time:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("parseTime")(_vm.order.cancelTime)))])]) : _vm._e(), _vm._v(" "), _vm.order.status === 8 && _vm.order.orderPremium && _vm.order.orderPremium.remark.trim() ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Remark:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.order.orderPremium.remark))])]) : _vm._e(), _vm._v(" "), _vm.isShowKey.includes(_vm.order.status) && _vm.order.finishTime ? _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Finish time:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("parseTime")(_vm.order.finishTime)))])]) : _vm._e()], 2), _vm._v(" "), Number(_vm.order.premiumPrice) ? _c("div", {
    staticClass: "card"
  }, [_c("h3", [_vm._v("Additional Payment")]), _vm._v(" "), _c("van-divider"), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Additional Amount:")]), _vm._v(" "), _c("div", {
    staticClass: "theme-color"
  }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.order.premiumPrice)))])]), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("pay time:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("parseTime")(_vm.order.premiumTime)))])])], 1) : _vm._e(), _vm._v(" "), _vm.order.type !== 3 && _vm.order.myRefunds && _vm.order.myRefunds.length ? _c("div", {
    staticClass: "card"
  }, [_c("h3", [_vm._v("Refund Information")]), _vm._v(" "), _vm._l(_vm.order.myRefunds, function (item) {
    return _c("div", {
      key: item.id
    }, [_c("van-divider"), _vm._v(" "), _c("div", {
      staticClass: "cell"
    }, [_c("div", {
      staticClass: "cell-title"
    }, [_vm._v("Refund Id:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(item.id))])]), _vm._v(" "), _c("div", {
      staticClass: "cell"
    }, [_c("div", {
      staticClass: "cell-title"
    }, [_vm._v("Create time:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("parseTime")(item.createTime)))])]), _vm._v(" "), _vm._l(item.myRefundDetails, function (ele, i) {
      return _c("div", {
        key: i,
        staticClass: "goods-card"
      }, [_c("van-image", {
        attrs: {
          width: "40",
          height: "40",
          src: ele.mainImage
        }
      }), _vm._v(" "), _c("div", {
        staticClass: "content"
      }, [_c("div", {
        staticClass: "good-name"
      }, [_vm._v(_vm._s(ele.goodsNameEn))]), _vm._v(" "), _c("div", {
        staticClass: "size"
      }, [_vm._v(_vm._s(_vm._f("getSkuInfosEn")(ele.skuInfosEn && JSON.parse(ele.skuInfosEn))))]), _vm._v(" "), _c("div", {
        staticClass: "info"
      }, [_c("div", {
        staticClass: "piece"
      }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(_vm.getUnitPrice(ele), JSON.parse(_vm.order.exchangeRate)))), _c("span", {
        staticClass: "piece"
      }, [_vm._v(" /Piece")])]), _vm._v(" "), _c("div", {
        staticStyle: {
          color: "#4EAF52",
          fontSize: "10px"
        }
      }, [_vm._v("out of stock: " + _vm._s(ele.quantity2))])])])], 1);
    }), _vm._v(" "), Number(item.refundAmount) ? _c("div", {
      staticClass: "cell"
    }, [_c("div", {
      staticClass: "cell-title"
    }, [_vm._v("Refund Amount(wallet):")]), _vm._v(" "), _c("div", [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(item.refundAmount).toFixed(2))))])]) : _vm._e(), _vm._v(" "), Number(item.stationFee) ? _c("div", {
      staticClass: "cell"
    }, [_c("div", {
      staticClass: "cell-title"
    }, [_vm._v("Refund station fee:")]), _vm._v(" "), _c("div", [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(item.stationFee).toFixed(2))))])]) : _vm._e(), _vm._v(" "), _c("div", {
      staticClass: "cell"
    }, [_c("div", {
      staticClass: "cell-title"
    }, [_vm._v("Refund Status:")]), _vm._v(" "), _c("div", {
      staticClass: "theme-color"
    }, [_vm._v(_vm._s(_vm._f("getOrderRefundStatus")(item.status)))])])], 2);
  })], 2) : _vm._e()]), _vm._v(" "), _c("PayPassword", {
    attrs: {
      "show-pay-password": _vm.showKeyboard,
      "total-amount": _vm.payOrder.totalPriceNGN || null
    },
    on: {
      showPayPassword: _vm.showPayPassword,
      payPasswordDown: _vm.payPasswordDown
    }
  }), _vm._v(" "), _vm.showPickupTime ? _c("PickupTime", {
    attrs: {
      show: _vm.showPickupTime,
      "order-create-time": _vm.order.createTime,
      "select-pickup-time": _vm.order.reservePickupTime,
      "select-package-store": _vm.order.packageStore && JSON.parse(_vm.order.packageStore)
    },
    on: {
      handlePickupTime: _vm.handlePickupTime,
      closePickupTime: _vm.closePickupTime
    }
  }) : _vm._e()], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;