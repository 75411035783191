"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getByWhIdPackageStoreList = getByWhIdPackageStoreList;
exports.getLogisticsLine = getLogisticsLine;
exports.getPackageStoreBusiness = getPackageStoreBusiness;
exports.getPackageStoreList = getPackageStoreList;
exports.getPostStationList = getPostStationList;
exports.getPostStationPrice = getPostStationPrice;
exports.getQuicklyPickupTime = getQuicklyPickupTime;
exports.getWarehouseList = getWarehouseList;
exports.setDefaultPackageStore = setDefaultPackageStore;

var _request = _interopRequireDefault(require("@/utils/request"));

function getWarehouseList(data) {
  return (0, _request.default)({
    url: 'tms-service/warehouse/list',
    method: 'post',
    data: data
  });
} // 国际物流线路


function getLogisticsLine(data) {
  return (0, _request.default)({
    url: 'wtms-service/tms/line/list',
    method: 'post',
    data: data
  });
} // 提货点


function getPackageStoreList(params) {
  var method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "post";
  return (0, _request.default)({
    url: 'spu-service/package/store',
    method: method,
    data: params
  });
} // 根据提货点获取驿站


function getPostStationList(params) {
  return (0, _request.default)({
    url: 'spu-service/package/store/listPostStationByWhId',
    method: 'get',
    params: params
  });
} // 根据驿站获取提货点


function getByWhIdPackageStoreList(data) {
  return (0, _request.default)({
    url: 'spu-service/package/store/getByWhId',
    method: 'post',
    data: data
  });
} // 计算驿站费用


function getPostStationPrice(data) {
  return (0, _request.default)({
    url: 'spu-service/spu/calculateFreight',
    method: 'post',
    data: data
  });
} // 仓库营业时间


function getPackageStoreBusiness(params) {
  return (0, _request.default)({
    url: 'spu-service/package/store/business',
    method: 'get',
    params: params
  });
} // 海外仓商品详情显示的拣货时间


function getQuicklyPickupTime(params) {
  return (0, _request.default)({
    url: 'spu-service/sys/config/wol/pickup/quickly',
    method: 'get',
    params: params
  });
} // 设置默认提货点


function setDefaultPackageStore(data) {
  return (0, _request.default)({
    url: 'spu-service/package/store/set/default',
    method: 'post',
    data: data
  });
}