"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "deliveryDetailsItem-view"
  }, [_c("div", {
    staticClass: "info"
  }, [_c("h3", [_vm._v("Logistics information")]), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Consignee:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.deliveryItem.receiverName))])]), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Phone:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.deliveryItem.receiverPhone))])]), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Pick-up point:")]), _vm._v(" "), _c("div", {
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/houseAddress",
          query: {
            warehouseList: [_vm.deliveryItem.logisticAddressInfo],
            clickReturn: 1
          }
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.deliveryItem.pickUpName)), _c("van-icon", {
    attrs: {
      name: "arrow",
      color: "#969799",
      size: "10"
    }
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "delivery-order"
  }, [_c("h3", [_vm._v("Delivery Order Information")]), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Delivery Id:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.logisticsId)), _c("span", {
    staticClass: "btn",
    on: {
      click: function click($event) {
        return _vm.copy(_vm.logisticsId);
      }
    }
  }, [_vm._v("copy")])])]), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Create time:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("parseTime")(_vm.deliveryItem.creatTime)))])]), _vm._v(" "), _vm.deliveryItem.payStatus === 4 ? [_c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Advance amount:")]), _vm._v(" "), _c("div", {
    staticClass: "theme-color"
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.deliveryItem.amount).toFixed(2))))])]), _vm._v(" "), _c("div", {
    staticClass: "cell"
  }, [_c("div", {
    staticClass: "cell-title"
  }, [_vm._v("Pay time:")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm._f("parseTime")(_vm.deliveryItem.payTime)))])])] : _vm._e()], 2), _vm._v(" "), _c("footer", [_c("div", {
    staticClass: "open"
  }, [_c("span", {
    on: {
      click: function click($event) {
        _vm.orderDetailShow = !_vm.orderDetailShow;
      }
    }
  }, [_vm._v(_vm._s(_vm.orderDetailShow ? "Fold orders" : "Open orders")), _c("van-icon", {
    attrs: {
      name: _vm.orderDetailShow ? "arrow-down" : "arrow-up",
      color: "#EA3340",
      size: "10"
    }
  })], 1)]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "van-fade"
    }
  }, [_vm.orderDetailShow ? _c("div", {
    staticClass: "wrap"
  }, [_c("TabList", {
    attrs: {
      list: _vm.deliveryItem.orderList || _vm.deliveryItem.logisticsDetail,
      "is-coding": _vm.deliveryItem.orderList ? false : true,
      "bg-color": "#FCFCFF"
    }
  })], 1) : _vm._e()])], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;