"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/tabbar-item/style");

var _tabbarItem = _interopRequireDefault(require("vant/es/tabbar-item"));

require("core-js/modules/es6.function.name");

require("vant/es/tabbar/style");

var _tabbar = _interopRequireDefault(require("vant/es/tabbar"));

var _components;

var _default = {
  name: 'FooterTabbar',
  components: (_components = {}, (0, _defineProperty2.default)(_components, _tabbar.default.name, _tabbar.default), (0, _defineProperty2.default)(_components, _tabbarItem.default.name, _tabbarItem.default), _components),
  data: function data() {
    return {
      active: 0,
      tabbars: [{
        id: 0,
        name: "/",
        title: "Home",
        normal: require("@/assets/images/tabbar/home.png"),
        active: require("@/assets/images/tabbar/home2.png")
      }, {
        id: 1,
        name: "forwarding",
        title: "Forwading",
        normal: require("@/assets/images/tabbar/forwading.png"),
        active: require("@/assets/images/tabbar/forwading2.png")
      }, {
        id: 2,
        name: "cart",
        title: "Cart",
        normal: require("@/assets/images/tabbar/cart.png"),
        active: require("@/assets/images/tabbar/cart2.png")
      }, {
        id: 3,
        name: "account",
        title: "Account",
        normal: require("@/assets/images/tabbar/account.png"),
        active: require("@/assets/images/tabbar/account2.png")
      }]
    };
  },
  // props: ['infoLength'],
  watch: {
    $route: {
      handler: function handler(to, from) {
        switch (to.name) {
          case "Home":
            this.active = 0;
            break;

          case "Forwarding":
            this.active = 1;
            break;

          case "Cart":
            this.active = 2;
            break;

          case "Account":
            this.active = 3;
            break;
        }
      },
      immediate: true
    }
  }
};
exports.default = _default;