"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view",
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/deliveryDetails",
          query: {
            logisticsId: _vm.order.logisticsId
          }
        });
      }
    }
  }, [_c("div", [_vm._v("Delivery ID: " + _vm._s(_vm.order.logisticsId))]), _vm._v(" "), _c("div", [_vm._v("Logistics mode: " + _vm._s(_vm.order.buyTypeNameEn))]), _vm._v(" "), _vm.$attrs["delivered-status"] === 2 ? [_c("div", {
    staticClass: "flex"
  }, [_c("div", [_vm._v("Estimated weight:")]), _vm._v(" "), _c("div", [_vm._v(">" + _vm._s(Number(_vm.order.estimateWeight).toFixed(1)) + "KG")])]), _vm._v(" "), _c("div", {
    staticClass: "flex"
  }, [_c("div", [_vm._v("Advance payment:")]), _vm._v(" "), _vm.order.approvalStatus === 1 ? _c("div", {
    staticClass: "theme-color"
  }, [_vm._v("Processing")]) : _c("div", [_c("span", {
    staticClass: "theme-color"
  }, [_vm._v(_vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.order.customsClearanceFee).toFixed(2))))]), _vm._v("NGN "), _c("span", {
    staticClass: "theme-color"
  }, [_vm._v(_vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.order.logisticsFee).toFixed(2))))]), _vm._v("USD \n        ")])])] : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "flex"
  }, [_c("div", {
    staticClass: "time flex"
  }, [_c("van-icon", {
    attrs: {
      size: "20",
      name: _vm.order.expressType === 2 ? require("@/assets/images/order/express_type_air.png") : require("@/assets/images/order/express_type_ship.png")
    }
  }), _vm._v("\n      " + _vm._s(_vm.$attrs["delivered-status"] === 2 ? "Waiting for advance payment." : _vm.$attrs["delivered-status"] === 0 ? "Order is picking." : "Your order is being sent to you.") + "\n    ")], 1), _vm._v(" "), _c("div", {
    staticClass: "flex"
  }, [_vm.order.isAnyOffTheShelf === 2 && [0, 2].includes(_vm.$attrs["delivered-status"]) ? _c("van-button", {
    attrs: {
      plain: "",
      hairline: "",
      round: "",
      size: "mini",
      color: "#EA3340"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.returnDelivered(_vm.order.logisticsId);
      }
    }
  }, [_vm._v("Return")]) : _vm._e(), _vm._v(" "), _vm.order.logisticWaybillList.length ? _c("van-button", {
    attrs: {
      plain: "",
      hairline: "",
      round: "",
      size: "mini",
      color: "#F58019"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.logisticsTrack(_vm.order.logisticWaybillList);
      }
    }
  }, [_vm._v("Logistics track")]) : _vm._e()], 1)])], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;