"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "categories-page"
  }, [_c("div", [_c("van-nav-bar", {
    attrs: {
      title: "Womall",
      "left-arrow": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "46px"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "categories-view"
  }, [_c("van-sidebar", {
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.activeKey,
      callback: function callback($$v) {
        _vm.activeKey = $$v;
      },
      expression: "activeKey"
    }
  }, _vm._l(_vm.categoryTitleList, function (categoryTitle, index) {
    return _c("van-sidebar-item", {
      key: index,
      attrs: {
        title: categoryTitle.englishTitle
      }
    });
  }), 1), _vm._v(" "), _c("div", {
    staticClass: "categories-item-detail"
  }, [_c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _vm.groupItemMap != null ? _c("div", {
    staticClass: "group-image",
    on: {
      click: function click($event) {
        return _vm.onGroupToPage(_vm.groupItemMap);
      }
    }
  }, [_c("van-image", {
    attrs: {
      width: "100%",
      height: "100%",
      "lazy-load": "",
      fit: "cover",
      src: _vm.groupItemMap.otherImage
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.categoryDetail.length > 0 ? _c("div", {
    staticClass: "padding-row-13 page-background-color"
  }, [_c("span", {
    staticStyle: {
      "font-size": "15px",
      "font-weight": "bold"
    }
  }, [_vm._v("Recommedation")]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "15px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "recommedation-box"
  }, _vm._l(_vm.categoryDetail, function (categoryDetailItem, index) {
    return _c("div", {
      key: index,
      staticClass: "recommedation-item",
      on: {
        click: function click($event) {
          return _vm.onCategoryToPage(categoryDetailItem);
        }
      }
    }, [_c("van-image", {
      attrs: {
        width: "12vw",
        height: "12vw",
        "lazy-load": "",
        fit: "cover",
        src: categoryDetailItem.categoryImage
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "title"
    }, [_vm._v("\n              " + _vm._s(categoryDetailItem.englishTitle) + "\n            ")])], 1);
  }), 0)]) : _vm._e()])], 1), _vm._v(" "), _c("div", [_c("div", {
    staticStyle: {
      height: "50px"
    }
  }), _vm._v(" "), _c("TheFooterTabbar")], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;