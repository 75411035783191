"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.function.name");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Checkout",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _vm.dataObj.localOrders && _vm.dataObj.localOrders.length ? [_c("div", {
    staticClass: "order-card"
  }, [_c("div", {
    staticClass: "group-title"
  }, [_c("div", {
    staticClass: "theme-color"
  }, [_c("van-icon", {
    attrs: {
      size: "24",
      name: require("@/assets/images/cart/local_orders.png")
    }
  }), _vm._v("Local orders")], 1)]), _vm._v(" "), _c("div", {
    staticClass: "group-info"
  }, [_vm._v("Choose receiving information")]), _vm._v(" "), _c("div", {
    staticClass: "select",
    on: {
      click: function click($event) {
        return _vm.$router.push("/consignee");
      }
    }
  }, [_c("van-icon", {
    attrs: {
      size: "16",
      name: require("@/assets/images/order/consignee.png")
    }
  }), _vm._v(" "), _vm.selectedConsigneeItem ? _c("div", {
    staticClass: "receiving"
  }, [_c("span", [_vm._v(_vm._s(_vm.selectedConsigneeItem.name))]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.selectedConsigneeItem.tel))])]) : _c("div", [_vm._v("Please select your consignee")]), _vm._v(" "), _c("van-icon", {
    attrs: {
      size: "12",
      name: "arrow",
      color: "#696969"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "select",
    on: {
      click: _vm.handlePackageStore
    }
  }, [_c("van-icon", {
    attrs: {
      size: "16",
      name: require("@/assets/images/home/home_mall.png")
    }
  }), _vm._v(" "), _vm.selectPackageStore ? _c("div", {
    staticClass: "receiving"
  }, [_c("span", [_vm._v(_vm._s(_vm.selectPackageStore.nameEn))]), _vm._v(" "), _c("span", {
    staticStyle: {
      color: "#FF0037"
    }
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(_vm.selectPackageStore.warehouseType === 1 ? "0.00" : _vm.stationBill && Number(_vm.stationBill.totalAmount).toFixed(2))))])]) : _c("div", [_vm._v("View the nearest pickup address from you")]), _vm._v(" "), _c("van-icon", {
    attrs: {
      size: "12",
      name: "arrow",
      color: "#696969"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "tip"
  }, [_vm._v("change")])], 1), _vm._v(" "), _vm.selectPackageStore && _vm.selectPackageStore.warehouseType === 1 ? _c("div", {
    staticClass: "select",
    on: {
      click: _vm.openPickupTime
    }
  }, [_c("van-icon", {
    attrs: {
      size: "16",
      name: require("@/assets/images/home/home_clock.png")
    }
  }), _vm._v(" "), _vm.selectPickupTime ? _c("div", {
    staticClass: "receiving"
  }, [_c("span", [_vm._v("Pickup time")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.selectPickupTime))])]) : _c("div", [_vm._v("Please select your pickup time")]), _vm._v(" "), _c("van-icon", {
    attrs: {
      size: "12",
      name: "arrow",
      color: "#696969"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("CheckoutGoodItem", {
    attrs: {
      "order-list": _vm.dataObj.localOrders
    }
  })], 1)] : _vm._e(), _vm._v(" "), _vm.dataObj.globalSales && _vm.dataObj.globalSales.length ? [_c("div", {
    staticClass: "order-card"
  }, [_c("div", {
    staticClass: "group-title"
  }, [_c("div", {
    staticClass: "theme-color"
  }, [_c("van-icon", {
    attrs: {
      size: "24",
      name: require("@/assets/images/cart/global_sales.png")
    }
  }), _vm._v("Global sales")], 1)]), _vm._v(" "), _c("CheckoutGoodItem", {
    attrs: {
      "order-list": _vm.dataObj.globalSales
    }
  })], 1)] : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "wrap"
  }, [_c("van-cell", {
    attrs: {
      title: "Coupon:",
      "is-link": ""
    },
    on: {
      click: _vm.openCoupon
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [String(_vm.selectCouponIndex) ? _c("span", [_vm._v("-" + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm.dataObj.couponCodes[_vm.selectCouponIndex].discountedPrice)))]) : _c("span", [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")("0")))])];
      },
      proxy: true
    }])
  }), _vm._v(" "), _vm.stationBill && Number(_vm.stationBill.totalAmount) ? _c("van-cell", {
    attrs: {
      title: "Station fee for local orders:"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_vm._v("\n        ₦" + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.stationBill.totalAmount).toFixed(2))) + "\n      ")];
      },
      proxy: true
    }], null, false, 3177374073)
  }) : _vm._e(), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Subtotal:",
      value: _vm._f("CNYToCurrencyUnit")(_vm.dataObj.totalPrice)
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "submit-bar"
  }, [_vm.dataObj.globalSales && _vm.dataObj.globalSales.length ? _c("div", {
    staticClass: "submit-bar__tip"
  }, [_c("div", {
    staticClass: "excluding-tip"
  }, [_c("van-icon", {
    attrs: {
      name: "info-o",
      size: "18"
    }
  }), _c("div", [_vm._v("Global orders excluding international freight")])], 1)]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "submit-bar__bar"
  }, [_c("div", {
    staticClass: "submit-bar__text"
  }, [_c("div", [_c("span", [_vm._v("Total Amount：")]), _vm._v(" "), _c("span", {
    staticClass: "submit-bar__price"
  }, [_vm._v("₦" + _vm._s(_vm._f("numberToCurrencyNo")(_vm.getTotalAmout)))])]), _vm._v(" "), _c("div", [_vm._v("Just only Commodity price")])]), _vm._v(" "), _c("van-button", {
    attrs: {
      color: "linear-gradient(to right, #ff6034, #ee0a24)",
      round: ""
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v("Buy now")])], 1)]), _vm._v(" "), _c("van-popup", {
    staticStyle: {
      height: "80%",
      padding: "16px 16px 0"
    },
    attrs: {
      round: "",
      position: "bottom",
      closeable: "",
      "close-on-popstate": "",
      "safe-area-inset-bottom": ""
    },
    model: {
      value: _vm.showCouponPop,
      callback: function callback($$v) {
        _vm.showCouponPop = $$v;
      },
      expression: "showCouponPop"
    }
  }, [_c("div", {
    staticClass: "coupon-title"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("Coupon")]), _vm._v(" "), _c("div", [_vm.dataObj.couponCodes.length && _vm.dataObj.couponCodes[_vm.selectCouponIndex] ? [_vm._v("\n          You save: " + _vm._s(_vm._f("CNYToCurrencyUnit")(_vm.dataObj.couponCodes[_vm.selectCouponIndex].discountedPrice)) + "\n        ")] : [_vm._v(" ")]], 2)]), _vm._v(" "), _c("van-divider", {
    style: {
      margin: "6px 0"
    }
  }), _vm._v(" "), _vm.dataObj.couponCodes.length ? _c("CouponList", {
    staticClass: "coupon-content",
    attrs: {
      list: _vm.dataObj.couponCodes
    },
    on: {
      selectCoupon: _vm.selectCoupon
    }
  }) : _c("div", {
    staticClass: "noData"
  }, [_vm._v("Sorry, no discounts are currently available!")])], 1), _vm._v(" "), _vm.showKeyboard ? _c("PayPassword", {
    attrs: {
      "show-pay-password": _vm.showKeyboard,
      "total-amount": Number(_vm.getTotalAmout)
    },
    on: {
      showPayPassword: _vm.showPayPassword,
      cancelPayment: _vm.cancelPayment,
      payPasswordDown: _vm.payPasswordDown
    }
  }) : _vm._e(), _vm._v(" "), _vm.showPickupTime ? _c("PickupTime", {
    attrs: {
      show: _vm.showPickupTime,
      "select-pickup-time": _vm.selectPickupTime,
      "select-package-store": _vm.selectPackageStore
    },
    on: {
      handlePickupTime: _vm.handlePickupTime,
      closePickupTime: _vm.closePickupTime
    }
  }) : _vm._e()], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;