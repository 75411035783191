"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("van-nav-bar", {
    attrs: {
      title: "Payment Password",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("van-form", {
    ref: "form",
    staticClass: "form",
    attrs: {
      "validate-first": ""
    },
    on: {
      failed: _vm.onFailed,
      submit: _vm.submit
    }
  }, [_c("van-field", {
    attrs: {
      name: "account",
      label: "You account：",
      placeholder: "Please enter your account",
      disabled: ""
    },
    model: {
      value: _vm.account2,
      callback: function callback($$v) {
        _vm.account2 = $$v;
      },
      expression: "account2"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      label: "Get code：",
      autocomplete: "off",
      rules: [{
        required: true,
        message: "Email Code can not be empty"
      }, {
        pattern: _vm.pattern,
        message: "Email Code must be 6 digits"
      }]
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [!_vm.counter ? _c("van-icon", {
          attrs: {
            name: "envelop-o",
            color: "red"
          },
          on: {
            click: _vm.getCode
          }
        }) : _c("span", [_vm._v(_vm._s(_vm.counter) + " s")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.verifyCode,
      callback: function callback($$v) {
        _vm.verifyCode = $$v;
      },
      expression: "verifyCode"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      type: "password",
      label: "New password：",
      autocomplete: "new-password",
      rules: [{
        required: true,
        message: "NewPassword can not be empty"
      }]
    },
    on: {
      focus: _vm.noBomBox
    },
    nativeOn: {
      touchstart: function touchstart($event) {
        $event.stopPropagation();
        _vm.keyboardShow = true;
        _vm.keyboardShow2 = false;
      }
    },
    model: {
      value: _vm.tmpPassword,
      callback: function callback($$v) {
        _vm.tmpPassword = $$v;
      },
      expression: "tmpPassword"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      type: "password",
      label: "Repeat new password：",
      autocomplete: "new-password",
      rules: [{
        required: true,
        message: "ConfirmPassword can not be empty"
      }, {
        validator: _vm.comparePassword,
        message: "Inconsistent passwords"
      }]
    },
    on: {
      focus: _vm.noBomBox
    },
    nativeOn: {
      touchstart: function touchstart($event) {
        $event.stopPropagation();
        _vm.keyboardShow = false;
        _vm.keyboardShow2 = true;
      }
    },
    model: {
      value: _vm.payPassword,
      callback: function callback($$v) {
        _vm.payPassword = $$v;
      },
      expression: "payPassword"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "submit"
  }, [_c("van-button", {
    staticStyle: {
      width: "36%"
    },
    attrs: {
      round: "",
      color: "#EA3340",
      "native-type": "submit"
    }
  }, [_vm._v("OK")])], 1)], 1), _vm._v(" "), _c("van-number-keyboard", {
    attrs: {
      "safe-area-inset-bottom": "",
      maxlength: "6",
      show: _vm.keyboardShow
    },
    on: {
      blur: function blur($event) {
        _vm.keyboardShow = false;
      }
    },
    model: {
      value: _vm.tmpPassword,
      callback: function callback($$v) {
        _vm.tmpPassword = $$v;
      },
      expression: "tmpPassword"
    }
  }), _vm._v(" "), _c("van-number-keyboard", {
    attrs: {
      "safe-area-inset-bottom": "",
      maxlength: "6",
      show: _vm.keyboardShow2
    },
    on: {
      blur: function blur($event) {
        _vm.keyboardShow2 = false;
      }
    },
    model: {
      value: _vm.payPassword,
      callback: function callback($$v) {
        _vm.payPassword = $$v;
      },
      expression: "payPassword"
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;