"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "flash-sale-card"
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "r-space-between padding-row"
  }, [_c("div", {
    staticClass: "r-start"
  }, [_c("div", {
    staticClass: "f-15",
    staticStyle: {
      "margin-right": "18px",
      fontSize: "14px",
      fontWeight: "bold"
    }
  }, [_vm._v("Flash Sale")]), _vm._v(" "), _c("CountDown", {
    attrs: {
      "begin-time": _vm.beginTime,
      "end-time": _vm.endTime
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "theme-color",
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/flashSale",
          query: {
            activityId: _vm.activityId
          }
        });
      }
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("view more")]), _vm._v(" "), _c("span", {
    staticClass: "iconfont",
    staticStyle: {
      "font-size": "10px"
    }
  }, [_vm._v("")])])]), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "20px"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "r-start list-box padding-left"
  }, _vm._l(_vm.upRowGoodList, function (goodItem, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "good-item",
      on: {
        click: function click($event) {
          return _vm.toGoodDetail(goodItem);
        }
      }
    }, [_c("van-image", {
      staticStyle: {
        width: "26vw",
        height: "26vw"
      },
      attrs: {
        fit: "cover",
        "lazy-load": "",
        src: goodItem["images"]
      }
    }), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "4px"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "r-start price"
    }, [_c("div", {
      staticClass: "offer-price theme-color van-ellipsis"
    }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(goodItem["discountPrice"])))]), _vm._v(" "), _c("div", {
      staticClass: "discount-price van-ellipsis"
    }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(goodItem["offerPrice"])))])]), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "4px"
      }
    })], 1)]);
  }), 0), _vm._v(" "), _vm.downRowGoodList.length > 0 ? _c("div", {
    staticStyle: {
      height: "20px"
    }
  }) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "r-start list-box padding-left"
  }, _vm._l(_vm.downRowGoodList, function (goodItem, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "good-item",
      on: {
        click: function click($event) {
          return _vm.toGoodDetail(goodItem);
        }
      }
    }, [_c("van-image", {
      staticStyle: {
        width: "26vw",
        height: "26vw"
      },
      attrs: {
        fit: "cover",
        "lazy-load": "",
        src: goodItem["images"]
      }
    }), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "4px"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "r-start price"
    }, [_c("div", {
      staticClass: "offer-price theme-color van-ellipsis"
    }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(goodItem["discountPrice"])))]), _vm._v(" "), _c("div", {
      staticClass: "discount-price van-ellipsis"
    }, [_vm._v(_vm._s(_vm._f("CNYToCurrencyUnit")(goodItem["offerPrice"])))])]), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "4px"
      }
    })], 1)]);
  }), 0), _vm._v(" "), _c("div", {
    staticStyle: {
      height: "11px"
    }
  })])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;