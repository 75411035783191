"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "tab-view"
  }, [_vm.isCoding ? _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "wrap"
    }, [_c("div", {
      staticClass: "time"
    }, [_vm._v("Create time: " + _vm._s(_vm._f("parseTime")(item.createAt)))]), _vm._v(" "), _c("div", {
      staticClass: "coding"
    }, [_vm._v("NO.: " + _vm._s(item.coding))]), _vm._v(" "), _c("div", {
      staticClass: "card",
      style: "background: ".concat(_vm.bgColor, ";")
    }, [_c("div", {
      staticClass: "time"
    }, [_vm._v("Create time: " + _vm._s(_vm._f("parseTime")(item.order.createTime)))]), _vm._v(" "), item.order.type === 3 ? _c("ForwardingItem", {
      attrs: {
        "click-to-page-name": "OrderDetails",
        order: item.order
      }
    }) : [0, 1].includes(item.order.type) ? _c("PurchaseItem", {
      attrs: {
        "click-to-page-name": "OrderDetails",
        order: item.order
      }
    }) : _vm._e()], 1)]);
  }) : _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "card",
      class: {
        "time_out-bg": _vm.$attrs["is-show-time-out-bg"] && item.status === 9 && item.reservePickupTimeIsExpires
      }
    }, [_c("div", {
      staticClass: "time"
    }, [_vm._v("Create time: " + _vm._s(_vm._f("parseTime")(item.createTime)))]), _vm._v(" "), _vm.activeTab === 3 && _vm.invoiceStatus === 2 ? _c("DeliveredItem", _vm._b({
      attrs: {
        order: item
      }
    }, "DeliveredItem", _vm.$attrs, false)) : item.type === 3 ? _c("ForwardingItem", {
      attrs: {
        "click-to-page-name": "OrderDetails",
        order: item
      }
    }, [_vm.activeTab === 2 ? _c("van-checkbox", {
      attrs: {
        slot: "checkbox",
        "icon-size": "18",
        "checked-color": "#EA3340"
      },
      slot: "checkbox",
      model: {
        value: item.checked,
        callback: function callback($$v) {
          _vm.$set(item, "checked", $$v);
        },
        expression: "item.checked"
      }
    }) : _vm._e()], 1) : [0, 1].includes(item.type) ? _c("PurchaseItem", {
      attrs: {
        "active-tab": _vm.activeTab,
        "click-to-page-name": "OrderDetails",
        order: item
      }
    }, [[0, 2].includes(_vm.activeTab) ? _c("van-checkbox", {
      attrs: {
        slot: "checkbox",
        "icon-size": "18",
        "checked-color": "#EA3340"
      },
      slot: "checkbox",
      model: {
        value: item.checked,
        callback: function callback($$v) {
          _vm.$set(item, "checked", $$v);
        },
        expression: "item.checked"
      }
    }) : _vm._e()], 1) : _vm._e()], 1);
  })], 2);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;