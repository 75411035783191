"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    class: _vm.notice ? "isNotice view" : "view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Coupon Center",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" "), _c("van-notice-bar", {
    attrs: {
      wrapable: "",
      mode: "closeable",
      "left-icon": "info-o",
      background: "#FFE2E2",
      color: "#EA3340"
    },
    on: {
      close: function close($event) {
        _vm.notice = false;
      }
    }
  }, [_vm._v("The discount fluctuates according to the exchange rate.")]), _vm._v(" "), _c("van-tabs", {
    attrs: {
      swipeable: "",
      animated: ""
    },
    on: {
      change: _vm.changeTab
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, _vm._l(_vm.tabList, function (item) {
    return _c("van-tab", {
      key: item.id,
      attrs: {
        title: item.title,
        name: item.id
      }
    }, [_c("van-pull-refresh", {
      attrs: {
        "pulling-text": "Pull down refresh",
        "loosing-text": "Release to refresh",
        "loading-text": "Refreshing",
        "success-text": "Refresh completed"
      },
      on: {
        refresh: _vm.onRefresh
      },
      model: {
        value: _vm.refreshing,
        callback: function callback($$v) {
          _vm.refreshing = $$v;
        },
        expression: "refreshing"
      }
    }, [_vm.list.length ? _c("van-list", {
      attrs: {
        finished: _vm.finished,
        "finished-text": "No More ...",
        "immediate-check": false
      },
      on: {
        load: _vm.onLoad
      },
      model: {
        value: _vm.loading,
        callback: function callback($$v) {
          _vm.loading = $$v;
        },
        expression: "loading"
      }
    }, [_c("CouponItem", {
      attrs: {
        list: _vm.list,
        "time-show": _vm.activeTab === 1 ? false : true
      },
      scopedSlots: _vm._u([{
        key: "btn",
        fn: function fn(props) {
          return [_vm.activeTab === 1 ? _c("div", {
            on: {
              click: function click($event) {
                return _vm.obtainCoupon(props.coupon.id);
              }
            }
          }, [_vm._v("get now")]) : _c("div", {
            on: {
              click: function click($event) {
                return _vm.useNow(props.coupon);
              }
            }
          }, [_vm._v("use now")])];
        }
      }], null, true)
    })], 1) : _c("van-empty", {
      attrs: {
        description: "No more data!"
      },
      scopedSlots: _vm._u([{
        key: "image",
        fn: function fn() {
          return [_c("van-icon", {
            attrs: {
              name: require("@/assets/images/no_data.png")
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1)], 1);
  }), 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;