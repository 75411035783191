"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("van-nav-bar", {
    attrs: {
      title: "Login password",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("van-form", {
    ref: "form",
    staticClass: "form",
    attrs: {
      "validate-first": ""
    },
    on: {
      failed: _vm.onFailed,
      submit: _vm.submit
    }
  }, [_c("van-field", {
    attrs: {
      name: "account",
      label: "User account：",
      placeholder: "Please enter your account",
      autocomplete: "off",
      rules: [{
        required: true,
        message: "UserAccount can not be empty"
      }]
    },
    on: {
      focus: function focus($event) {
        _vm.emailShow = !_vm.emailShow;
      },
      blur: _vm.handleBlur
    },
    model: {
      value: _vm.account,
      callback: function callback($$v) {
        _vm.account = $$v;
      },
      expression: "account"
    }
  }), _vm._v(" "), _vm.emailShow ? _c("div", {
    staticClass: "hintBox"
  }, _vm._l(_vm.emails, function (mail) {
    return _c("div", {
      key: mail,
      staticClass: "hintItem",
      on: {
        click: function click($event) {
          return _vm.setInput(mail);
        }
      }
    }, [_vm._v(_vm._s(mail))]);
  }), 0) : _vm._e(), _vm._v(" "), _c("van-field", {
    attrs: {
      type: "password",
      label: "New password：",
      autocomplete: "new-password",
      rules: [{
        required: true,
        message: "NewPassword can not be empty"
      }, {
        validator: _vm.validPassword,
        message: "Must be a combination of 8-20 English letters, numbers or symbols"
      }]
    },
    model: {
      value: _vm.tmpPassword,
      callback: function callback($$v) {
        _vm.tmpPassword = $$v;
      },
      expression: "tmpPassword"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      type: "password",
      label: "Confirm password：",
      autocomplete: "new-password",
      rules: [{
        required: true,
        message: "ConfirmPassword can not be empty"
      }, {
        validator: _vm.comparePassword,
        message: "Inconsistent passwords"
      }]
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "email"
  }, [_c("van-field", {
    attrs: {
      label: "Get code：",
      rules: [{
        required: true,
        message: "Email Code can not be empty"
      }, {
        pattern: _vm.pattern,
        message: "Email Code must be 6 digits"
      }]
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [!_vm.counter ? _c("van-icon", {
          attrs: {
            name: "envelop-o",
            color: "red"
          },
          on: {
            click: _vm.getCode
          }
        }) : _c("span", [_vm._v(_vm._s(_vm.counter) + " s")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.verifyCode,
      callback: function callback($$v) {
        _vm.verifyCode = $$v;
      },
      expression: "verifyCode"
    }
  }), _vm._v(" "), !_vm.counter ? _c("span", {
    staticClass: "mail"
  }, [_vm._v("Click on the mail icon to get the Code")]) : _c("span", {
    staticClass: "mail"
  }, [_vm._v("Re-acquisition of the Code after countdown")])], 1), _vm._v(" "), _c("div", {
    staticClass: "submit"
  }, [_c("van-button", {
    staticStyle: {
      width: "36%"
    },
    attrs: {
      round: "",
      color: "#EA3340",
      "native-type": "submit"
    }
  }, [_vm._v("OK")])], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;