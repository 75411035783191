"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.number.constructor");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "account-view"
  }, [_c("img", {
    staticClass: "edit",
    attrs: {
      src: require("@/assets/images/account/edit.png"),
      alt: "edit"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/userInfo");
      }
    }
  }), _vm._v(" "), _c("img", {
    staticClass: "gift",
    attrs: {
      src: require("@/assets/images/account/gift.gif"),
      alt: "gift"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/myGain");
      }
    }
  }), _vm._v(" "), _c("van-pull-refresh", {
    attrs: {
      "pulling-text": "Pull down refresh",
      "loosing-text": "Release to refresh",
      "loading-text": "Refreshing",
      "success-text": "Refresh completed"
    },
    on: {
      refresh: _vm.onRefresh
    },
    model: {
      value: _vm.refreshing,
      callback: function callback($$v) {
        _vm.refreshing = $$v;
      },
      expression: "refreshing"
    }
  }, [_c("header", {
    on: {
      click: function click($event) {
        return _vm.$router.push("/userInfo");
      }
    }
  }, [_vm.user.headImage ? _c("van-image", {
    attrs: {
      round: "",
      width: "48",
      height: "48",
      src: _vm.user.headImage
    }
  }) : _c("van-icon", {
    attrs: {
      name: "manager",
      size: "48",
      color: "#F8F8F8"
    }
  }), _vm._v(" "), _c("div", [_c("div", [_vm._v(_vm._s(_vm.user.username))]), _vm._v(" "), _c("div", [_c("span", {
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.copyUserCode(_vm.user.userCode);
      }
    }
  }, [_vm._v("ID: " + _vm._s(_vm.user.userCode))])])])], 1), _vm._v(" "), _c("div", {
    staticClass: "info"
  }, [_c("div", {
    staticClass: "info-left"
  }, [_c("div", {
    staticClass: "balance"
  }, [_c("div", [_vm._v("Current balance")]), _vm._v(" "), _c("div", {
    staticClass: "van-ellipsis"
  }, [_vm._v("₦ " + _vm._s(_vm._f("numberToCurrencyNo")(Number(_vm.user.balance).toFixed(2))))])]), _vm._v(" "), _c("div", {
    staticClass: "recharge",
    on: {
      click: function click($event) {
        return _vm.$router.push("/recharge");
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/account/recharge.png")
    }
  }), _vm._v(" "), _c("div", [_vm._v("recharge")])])]), _vm._v(" "), _c("div", {
    staticClass: "info-right",
    on: {
      click: function click($event) {
        return _vm.$router.push("/customerCare");
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/account/customer.png")
    }
  }), _vm._v(" "), _c("div", [_vm._v("contact")])])]), _vm._v(" "), _c("div", {
    staticClass: "tool"
  }, [_c("div", {
    staticClass: "tool-raw"
  }, [_c("div", {
    on: {
      click: function click($event) {
        return _vm.$router.push("/myCoupon");
      }
    }
  }, [_c("van-badge", {
    attrs: {
      content: _vm.couponCodeCount || null,
      max: "99"
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/account/coupon.png")
    }
  })]), _vm._v(" "), _c("div", [_vm._v("Coupon")])], 1), _vm._v(" "), _c("div", {
    on: {
      click: function click($event) {
        return _vm.$router.push("/refund");
      }
    }
  }, [_c("div", [_c("img", {
    attrs: {
      src: require("@/assets/images/account/refund.png")
    }
  })]), _vm._v(" "), _c("div", [_vm._v("Refund")])]), _vm._v(" "), _c("div", {
    on: {
      click: function click($event) {
        return _vm.$router.push("/transactions");
      }
    }
  }, [_c("div", [_c("van-badge", [_c("img", {
    attrs: {
      src: require("@/assets/images/account/transactions.png")
    }
  })])], 1), _vm._v(" "), _c("div", [_vm._v("Transactions")])]), _vm._v(" "), _c("div", {
    on: {
      click: function click($event) {
        return _vm.$router.push("/myLike");
      }
    }
  }, [_c("div", [_c("img", {
    attrs: {
      src: require("@/assets/images/account/my_likes.png")
    }
  })]), _vm._v(" "), _c("div", [_vm._v("My likes")])])])]), _vm._v(" "), _c("div", {
    staticClass: "order"
  }, [_c("van-cell", {
    staticClass: "my-odrer",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("span", {
          staticClass: "order-title fontWeight"
        }, [_vm._v("My orders")])];
      },
      proxy: true
    }, {
      key: "right-icon",
      fn: function fn() {
        return [_c("div", {
          staticClass: "history",
          on: {
            click: function click($event) {
              return _vm.$router.push({
                name: "Order",
                params: {
                  status: -1
                }
              });
            }
          }
        }, [_vm._v("Purchase History"), _c("van-icon", {
          staticClass: "van-cell__right-icon",
          attrs: {
            name: "arrow"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("div", {
    staticClass: "banner"
  }, [_c("van-swipe", {
    staticClass: "my-swipe",
    attrs: {
      autoplay: 3000,
      "indicator-color": "#FF0037"
    }
  }, _vm._l(_vm.swipeList, function (item, index) {
    return _c("van-swipe-item", {
      key: index
    }, [[1, 3].includes(item.type) ? _c("van-image", {
      attrs: {
        width: "32",
        height: "32",
        src: item.mainImage
      }
    }) : item.type === 2 ? _c("van-icon", {
      attrs: {
        size: "32",
        name: item.expressType === 2 ? require("@/assets/images/order/express_type_air.png") : require("@/assets/images/order/express_type_ship.png")
      }
    }) : _vm._e(), _vm._v(" "), _c("div", [_c("div", {
      staticClass: "type"
    }, [_vm._v(_vm._s(item.type === 1 ? "Additional Payment" : item.type === 2 ? "Need to pay" : "Pending pick up"))]), _vm._v(" "), _c("div", {
      staticClass: "name van-multi-ellipsis--l2"
    }, [_vm._v(_vm._s(item.type === 1 ? item.goodsNameEn : item.type === 1 ? item.buyTypeNameEn : item.goodsNameEn))])]), _vm._v(" "), _c("div", [_c("div", {
      on: {
        click: function click($event) {
          return _vm.$router.push({
            name: "Order",
            params: {
              status: item.type === 1 ? 8 : item.type === 2 ? 3 : 9,
              activeChildren: item.type === 2 ? 2 : null
            }
          });
        }
      }
    }, [_vm._v(_vm._s([1, 2].includes(item.type) ? "pay" : "pickup"))])])], 1);
  }), 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "group"
  }, [_c("div", {
    staticClass: "group-box no-border"
  }, [_c("van-cell", {
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "Order",
          params: {
            status: 0
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/account/payment_pending.png")
          }
        }), _vm._v(" "), _c("span", {
          staticClass: "order-title-s"
        }, [_vm._v("Payment Pending")])];
      },
      proxy: true
    }, {
      key: "right-icon",
      fn: function fn() {
        return [_c("div", {
          staticClass: "history"
        }, [_c("van-badge", {
          attrs: {
            content: _vm.paymentPending || null,
            max: "99"
          }
        }), _c("van-icon", {
          staticClass: "van-cell__right-icon",
          attrs: {
            name: "arrow"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "group"
  }, [_c("div", {
    staticClass: "group-title"
  }, [_vm._v("In global delivery")]), _vm._v(" "), _c("div", {
    staticClass: "group-box"
  }, [_c("van-cell", {
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "Order",
          params: {
            status: 1
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/account/warehouse_pending.png")
          }
        }), _vm._v(" "), _c("span", {
          staticClass: "order-title-s"
        }, [_vm._v("Warehouse Pending")])];
      },
      proxy: true
    }, {
      key: "right-icon",
      fn: function fn() {
        return [_c("div", {
          staticClass: "history"
        }, [_c("van-badge", {
          attrs: {
            content: _vm.warehousePending || null,
            max: "99"
          }
        }), _c("van-icon", {
          staticClass: "van-cell__right-icon",
          attrs: {
            name: "arrow"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("van-cell", {
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "Order",
          params: {
            status: 2
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/account/my_warehouse.png")
          }
        }), _vm._v(" "), _c("span", {
          staticClass: "order-title-s"
        }, [_vm._v("Global Warehouse")])];
      },
      proxy: true
    }, {
      key: "right-icon",
      fn: function fn() {
        return [_c("div", {
          staticClass: "history"
        }, [_c("van-badge", {
          attrs: {
            content: _vm.globalWarehouse || null,
            max: "99"
          }
        }), _c("van-icon", {
          staticClass: "van-cell__right-icon",
          attrs: {
            name: "arrow"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "group"
  }, [_c("div", {
    staticClass: "group-title"
  }, [_vm._v("In local delivery or to be")]), _vm._v(" "), _c("div", {
    staticClass: "group-box"
  }, [_c("van-cell", {
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "Order",
          params: {
            status: 3
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/account/delivered_reviews.png")
          }
        }), _vm._v(" "), _c("span", {
          staticClass: "order-title-s"
        }, [_vm._v("Delivered Reviews")])];
      },
      proxy: true
    }, {
      key: "right-icon",
      fn: function fn() {
        return [_c("div", {
          staticClass: "history"
        }, [_c("van-badge", {
          attrs: {
            content: _vm.deliveredReviews || null,
            max: "99"
          }
        }), _c("van-icon", {
          staticClass: "van-cell__right-icon",
          attrs: {
            name: "arrow"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("van-cell", {
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "Order",
          params: {
            status: 9
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/account/pending_pick_up.png")
          }
        }), _vm._v(" "), _c("span", {
          staticClass: "order-title-s"
        }, [_vm._v("Pending pick up")])];
      },
      proxy: true
    }, {
      key: "right-icon",
      fn: function fn() {
        return [_c("div", {
          staticClass: "history"
        }, [_c("van-badge", {
          attrs: {
            content: _vm.pendingPickUp || null,
            max: "99"
          }
        }), _c("van-icon", {
          staticClass: "van-cell__right-icon",
          attrs: {
            name: "arrow"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "group"
  }, [_c("div", {
    staticClass: "group-title"
  }, [_vm._v("Completed")]), _vm._v(" "), _c("div", {
    staticClass: "group-box no-border"
  }, [_c("van-cell", {
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "Order",
          params: {
            status: 4
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/account/picked_up.png")
          }
        }), _vm._v(" "), _c("span", {
          staticClass: "order-title-s"
        }, [_vm._v("Picked up")])];
      },
      proxy: true
    }, {
      key: "right-icon",
      fn: function fn() {
        return [_c("div", {
          staticClass: "history"
        }, [_c("van-badge", {
          attrs: {
            content: _vm.pickedUp || null,
            max: "99"
          }
        }), _c("van-icon", {
          staticClass: "van-cell__right-icon",
          attrs: {
            name: "arrow"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)])], 1), _vm._v(" "), _c("div", {
    staticClass: "tool"
  }, [_c("div", {
    staticClass: "tool-raw"
  }, [_c("div", {
    on: {
      click: function click($event) {
        return _vm.$router.push("/consignee");
      }
    }
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/account/consignee.png")
    }
  }), _vm._v(" "), _c("div", [_vm._v("Consignee")])], 1), _vm._v(" "), _c("div", {
    on: {
      click: function click($event) {
        return _vm.$router.push("/houseAddress");
      }
    }
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/account/address.png")
    }
  }), _vm._v(" "), _c("div", [_vm._v("Address")])], 1), _vm._v(" "), _c("div", {
    on: {
      click: function click($event) {
        return _vm.$router.push("/setting");
      }
    }
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/account/setting.png")
    }
  }), _vm._v(" "), _c("div", [_vm._v("Setting")])], 1), _vm._v(" "), _c("div", {
    on: {
      click: _vm.handleLoginOut
    }
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/account/logout.png")
    }
  }), _vm._v(" "), _c("div", [_vm._v("Logout")])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "tool-raw"
  }, [_c("div", {
    on: {
      click: function click($event) {
        return _vm.$router.push("/browsed");
      }
    }
  }, [_c("van-icon", {
    attrs: {
      name: require("@/assets/images/account/browsed.png")
    }
  }), _vm._v(" "), _c("div", [_vm._v("Browsed")])], 1)])])]), _vm._v(" "), _c("FooterTabbar")], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;