"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/divider/style");

var _divider = _interopRequireDefault(require("vant/es/divider"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _cmmission = require("api/cmmission");

var _components;

var _default = {
  name: "MyTeam",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _divider.default.name, _divider.default), _components),
  data: function data() {
    return {
      fansCount: 0,
      fansList: []
    };
  },
  created: function created() {
    var _this = this;

    (0, _cmmission.getFansCommission)().then(function (res) {
      _this.fansCount = res.data.fansCount;
      _this.fansList = res.data.detail;
    });
  },
  methods: {}
};
exports.default = _default;