"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.resetRouter = resetRouter;

var _typeof2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.weak-map");

require("core-js/modules/es6.regexp.replace");

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || (0, _typeof2.default)(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// 首页相关
var Home = function Home() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/home"));
  });
}; // 首页


var GoodsContent = function GoodsContent() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/home/GoodsContent.vue"));
  });
}; // 商品详情页


var ProductListPage = function ProductListPage() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/home/ProductListPage.vue"));
  });
}; // 商品列表页


var DesignatCouponPage = function DesignatCouponPage() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/home/DesignatCouponPage.vue"));
  });
}; // 指定优惠券可用商品页


var ShippingFee = function ShippingFee() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/home/ShippingFee.vue"));
  });
}; // 发货列表页


var ProductListFromImg = function ProductListFromImg() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/home/ProductListFromImg.vue"));
  });
}; // 以图找货商品列表


var Alibaba = function Alibaba() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/home/Alibaba.vue"));
  });
}; // 1688


var TopSale = function TopSale() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/home/TopSale.vue"));
  });
}; // 热销


var FlashSale = function FlashSale() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/home/FlashSale.vue"));
  });
}; // 闪购


var NewArrival = function NewArrival() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/home/NewArrival.vue"));
  });
}; // 新品


var PageErr = function PageErr() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/404.vue"));
  });
};

var Cart = function Cart() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/cart"));
  });
}; // 购物车


var Checkout = function Checkout() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/cart/Checkout.vue"));
  });
}; // 提交订单


var Search = function Search() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/common/Search.vue"));
  });
}; // 搜索框


var PaySuccess = function PaySuccess() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/common/PaySuccess.vue"));
  });
};

var Account = function Account() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/account"));
  });
}; // 我的


var Transactions = function Transactions() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/account/components/Transactions.vue"));
  });
}; // 交易记录


var TransactionsDetails = function TransactionsDetails() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/account/components/TransactionsDetails.vue"));
  });
}; // 交易记录详情


var CustomerCare = function CustomerCare() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/account/components/CustomerCare.vue"));
  });
}; // 顾客服务


var Consignee = function Consignee() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/account/components/Consignee.vue"));
  });
}; // 联系人


var EditConsignee = function EditConsignee() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/account/components/EditConsignee.vue"));
  });
}; // 编辑联系人


var Wallet = function Wallet() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/account/components/Wallet.vue"));
  });
}; // 钱包


var Setting = function Setting() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/account/components/Setting.vue"));
  });
}; // 设置


var Browsed = function Browsed() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/account/components/Browsed.vue"));
  });
}; // 足迹


var Terms = function Terms() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/account/components/Terms.vue"));
  });
}; // 条款


var MyLike = function MyLike() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/myLike"));
  });
}; // 我的收藏


var Order = function Order() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/order"));
  });
}; // 订单列表


var LogisticsTrack = function LogisticsTrack() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/order/components/LogisticsTrack.vue"));
  });
}; // 发货单物流轨迹


var LogisticsDetail = function LogisticsDetail() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/order/components/LogisticsDetail.vue"));
  });
}; // 1688国际物流轨迹


var PreviewDeliveryDetails = function PreviewDeliveryDetails() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/order/components/PreviewDeliveryDetails.vue"));
  });
}; // 预览发货详情


var RemoveCartons = function RemoveCartons() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/order/components/RemoveCartons.vue"));
  });
}; // 是否去纸皮


var DeliveryDetails = function DeliveryDetails() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/order/components/DeliveryDetails.vue"));
  });
}; // 发货单详情


var OrderDetails = function OrderDetails() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/order/components/OrderDetails.vue"));
  });
}; // 订单详情


var QueryOrder = function QueryOrder() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/order/components/QueryOrder.vue"));
  });
}; // 订单搜索结果


var Recharge = function Recharge() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/recharge"));
  });
}; // 充值


var SelectRecharge = function SelectRecharge() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/recharge/components/SelectRecharge.vue"));
  });
}; // 选择充值方式


var BankRechargeRecord = function BankRechargeRecord() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/recharge/components/BankRechargeRecord.vue"));
  });
}; // 银行充值记录


var BankRechargeOne = function BankRechargeOne() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/recharge/components/bankRecharge/BankRechargeOne.vue"));
  });
}; // 银行充值


var BankRechargeTwo = function BankRechargeTwo() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/recharge/components/bankRecharge/BankRechargeTwo.vue"));
  });
}; // 银行充值


var BankRechargeThree = function BankRechargeThree() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/recharge/components/bankRecharge/BankRechargeThree.vue"));
  });
}; // 银行充值


var CommissionRecharge = function CommissionRecharge() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/recharge/components/CommissionRecharge.vue"));
  });
}; // 佣金充值


var UserInfo = function UserInfo() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/userInfo"));
  });
}; // 我的信息


var ChangeUserName = function ChangeUserName() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/userInfo/components/ChangeUserName.vue"));
  });
}; // 编辑用户名


var ChangePhone = function ChangePhone() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/userInfo/components/ChangePhone.vue"));
  });
}; // 编辑电话


var PaymentPassword = function PaymentPassword() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/userInfo/components/PaymentPassword.vue"));
  });
}; // 支付密码


var Currency = function Currency() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/userInfo/components/Currency.vue"));
  });
}; // 默认币种


var Forwarding = function Forwarding() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/Forwarding.vue"));
  });
}; // 入库


var Login = function Login() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/login/Login.vue"));
  });
}; // 登录


var Register = function Register() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/login/Register.vue"));
  });
}; // 注册


var Forgot = function Forgot() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/login/Forgot.vue"));
  });
}; // 忘记密码


var AddressList = function AddressList() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/address/AddressList.vue"));
  });
}; // 地址列表


var AddressEdit = function AddressEdit() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/address/AddressEdit.vue"));
  });
}; // 编辑地址


var HouseAddress = function HouseAddress() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/address/HouseAddress.vue"));
  });
}; // 网点


var PickupAddress = function PickupAddress() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/address/PickupAddress.vue"));
  });
}; // 提货点(驿站)
// 帮助


var Help = function Help() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/help"));
  });
}; // 帮助


var ParcelForwardRules = function ParcelForwardRules() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/help/ParcelForwardRules.vue"));
  });
}; // 包裹转发检查规则


var UserHelpForwarding = function UserHelpForwarding() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/help/UserHelpForwarding.vue"));
  });
}; // 帮助文档


var UserHelpPurchase = function UserHelpPurchase() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/help/UserHelpPurchase.vue"));
  });
}; // 帮助文档


var UserHelpPage = function UserHelpPage() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/help/UserHelpPage1.vue"));
  });
}; // 转运帮助文档


var PurchaseNotice = function PurchaseNotice() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/help/PurchaseNotice.vue"));
  });
}; // 代购提示
// 售后


var AfterService = function AfterService() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/afterService"));
  });
}; // 售后服务


var Refund = function Refund() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/afterService/Refund.vue"));
  });
}; // 退款列表


var AboutAfterService = function AboutAfterService() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/afterService/components/AboutAfterService.vue"));
  });
}; // 售后服务条款


var Exchange = function Exchange() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/afterService/components/Exchange.vue"));
  });
}; // 换货


var RefundDetails = function RefundDetails() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/afterService/components/RefundDetails.vue"));
  });
}; // 换货详情
// 优惠券


var CouponCenter = function CouponCenter() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/coupon/CouponCenter.vue"));
  });
}; // 优惠券中心


var MyCoupon = function MyCoupon() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/coupon/MyCoupon.vue"));
  });
}; // 我的优惠券


var CouponHistory = function CouponHistory() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/coupon/MyCoupon.vue"));
  });
}; // 优惠券记录
// 分销


var MyGain = function MyGain() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/myGain"));
  });
}; // 佣金


var Withdrawal = function Withdrawal() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/myGain/components/Withdrawal.vue"));
  });
}; // 佣金提现


var WithdrawalRecord = function WithdrawalRecord() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/myGain/components/WithdrawalRecord.vue"));
  });
}; // 佣金提现记录


var WithdrawalRules = function WithdrawalRules() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/myGain/components/WithdrawalRules.vue"));
  });
}; // 佣金提现规则


var WithdrawalSuccess = function WithdrawalSuccess() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/myGain/components/WithdrawalSuccess.vue"));
  });
}; // 佣金提现成功


var SelectAccount = function SelectAccount() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/myGain/components/SelectAccount.vue"));
  });
}; // 提现账户


var EditAccount = function EditAccount() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/myGain/components/EditAccount.vue"));
  });
}; // 编辑提现账户


var AllCommissioinOrders = function AllCommissioinOrders() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/myGain/components/AllCommissioinOrders.vue"));
  });
}; // 佣金订单


var MyTeam = function MyTeam() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/myGain/components/MyTeam.vue"));
  });
}; // 佣金队伍


var RuleDescription = function RuleDescription() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/myGain/components/RuleDescription.vue"));
  });
}; // 佣金手册
// 自营


var SelfSupport = function SelfSupport() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/selfSupport"));
  });
}; // 商品列表页


var Categories = function Categories() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/selfSupport/Categories.vue"));
  });
}; // 商品分类
// 海外仓


var Local = function Local() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/local"));
  });
}; // 商品列表页


_vue.default.use(_vueRouter.default);

var routes = [// {
//   path: '/',
//   name: 'Home',
//   component: Home,
//   meta: {
//     title: 'Home', // 
//     keepAlive: true
//   }
// },
// {
//   path: '/forwarding',
//   name: 'Forwarding',
//   component: Forwarding,
//   meta: {
//     title: 'Forwarding', // 待入库，已提交',
//     keepAlive: false
//   }
// },
// {
//   path: '/couponCenter',
//   name: 'CouponCenter',
//   component: CouponCenter,
//   meta: {
//     title: 'CouponCenter', // 优惠券中心,
//     keepAlive: false
//   }
// },
// {
//   path: '/myGain',
//   name: 'MyGain',
//   component: MyGain,
//   meta: {
//     title: 'MyGain', // 佣金,
//     keepAlive: false
//   }
// },
// {
//   path: '/withdrawal',
//   name: 'Withdrawal',
//   component: Withdrawal,
//   meta: {
//     title: 'Withdrawal', // 佣金提现,
//     keepAlive: true
//   }
// },
// {
//   path: '/withdrawalRecord',
//   name: 'WithdrawalRecord',
//   component: WithdrawalRecord,
//   meta: {
//     title: 'WithdrawalRecord', // 佣金提现记录,
//     keepAlive: false
//   }
// },
// {
//   path: '/withdrawalRules',
//   name: 'WithdrawalRules',
//   component: WithdrawalRules,
//   meta: {
//     title: 'WithdrawalRules', // 佣金提现规则,
//     keepAlive: false
//   }
// },
// {
//   path: '/withdrawalSuccess',
//   name: 'WithdrawalSuccess',
//   component: WithdrawalSuccess,
//   meta: {
//     title: 'WithdrawalSuccess', // 佣金提现成功,
//     keepAlive: false
//   }
// },
// {
//   path: '/editAccount',
//   name: 'EditAccount',
//   component: EditAccount,
//   meta: {
//     title: 'EditAccount', // 编辑提现账户,
//     keepAlive: false
//   }
// },
// {
//   path: '/selectAccount',
//   name: 'SelectAccount',
//   component: SelectAccount,
//   meta: {
//     title: 'SelectAccount', // 提现账户,
//     keepAlive: true
//   }
// },
{
  path: '/allCommissioinOrders',
  name: 'AllCommissioinOrders',
  component: AllCommissioinOrders,
  meta: {
    title: 'AllCommissioinOrders',
    // 佣金订单,
    keepAlive: false
  }
}, {
  path: '/myTeam',
  name: 'MyTeam',
  component: MyTeam,
  meta: {
    title: 'MyTeam',
    // 佣金队伍,
    keepAlive: false
  }
}, // {
//   path: '/ruleDescription',
//   name: 'RuleDescription',
//   component: RuleDescription,
//   meta: {
//     title: 'RuleDescription', // 佣金手册,
//     keepAlive: false
//   }
// },
// {
//   path: '/account',
//   name: 'Account',
//   component: Account,
//   meta: {
//     title: 'Account', // 我的,
//     keepAlive: true
//   }
// },
// {
//   path: '/myLike',
//   name: 'MyLike',
//   component: MyLike,
//   meta: {
//     title: 'MyLike', // 我的收藏,
//     keepAlive: true
//   }
// },
{
  path: '/customerCare',
  name: 'CustomerCare',
  component: CustomerCare,
  meta: {
    title: 'CustomerCare',
    // 顾客服务,
    keepAlive: false
  }
}, {
  path: '/logisticsTrack',
  name: 'LogisticsTrack',
  component: LogisticsTrack,
  meta: {
    title: 'LogisticsTrack',
    // 发货单物流轨迹,
    keepAlive: false
  }
}, {
  path: '/previewDeliveryDetails',
  name: 'PreviewDeliveryDetails',
  component: PreviewDeliveryDetails,
  meta: {
    title: 'PreviewDeliveryDetails',
    // 预览发货详情,
    keepAlive: true
  }
}, {
  path: '/removeCartons',
  name: 'RemoveCartons',
  component: RemoveCartons,
  meta: {
    title: 'RemoveCartons',
    // 是否去纸皮,
    keepAlive: false
  }
}, {
  path: '/deliveryDetails',
  name: 'DeliveryDetails',
  component: DeliveryDetails,
  meta: {
    title: 'DeliveryDetails',
    // 发货单详情,
    keepAlive: false
  }
}, {
  path: '/orderDetails',
  name: 'OrderDetails',
  component: OrderDetails,
  meta: {
    title: 'OrderDetails',
    // 订单详情,
    keepAlive: true
  }
}, {
  path: '/order',
  name: 'Order',
  component: Order,
  meta: {
    title: 'Order',
    // 订单,
    keepAlive: true
  }
}, {
  path: '/logisticsDetail',
  name: 'LogisticsDetail',
  component: LogisticsDetail,
  meta: {
    title: 'LogisticsDetail',
    // 1688国际物流信息,
    keepAlive: false
  }
}, // {
//   path: '/search',
//   name: 'Search',
//   component: Search,
//   meta: {
//     title: 'Search', // 搜索框,
//     keepAlive: false
//   }
// },
{
  path: '/queryOrder',
  name: 'QueryOrder',
  component: QueryOrder,
  meta: {
    title: 'QueryOrder',
    // 搜索订单结果,
    keepAlive: false
  }
}, {
  path: '/refund',
  name: 'Refund',
  component: Refund,
  meta: {
    title: 'Refund',
    // 退款列表,
    keepAlive: false
  }
}, // {
//   path: '/afterService',
//   name: 'AfterService',
//   component: AfterService,
//   meta: {
//     title: 'AfterService', // 售后服务,
//     keepAlive: false
//   }
// },
{
  path: '/aboutAfterService',
  name: 'AboutAfterService',
  component: AboutAfterService,
  meta: {
    title: 'AboutAfterService',
    // 售后服务条款,
    keepAlive: false
  }
}, // {
//   path: '/exchange',
//   name: 'Exchange',
//   component: Exchange,
//   meta: {
//     title: 'Exchange', // 换货,
//     keepAlive: true
//   }
// },
{
  path: '/refundDetails',
  name: 'RefundDetails',
  component: RefundDetails,
  meta: {
    title: 'RefundDetails',
    // 换货详情,
    keepAlive: false
  }
}, // {
//   path: '/userInfo',
//   name: 'UserInfo',
//   component: UserInfo,
//   meta: {
//     title: 'UserInfo', // 我的信息,
//     keepAlive: false
//   }
// },
// {
//   path: '/recharge',
//   name: 'Recharge',
//   component: Recharge,
//   meta: {
//     title: 'Recharge', // 充值,
//     keepAlive: false
//   }
// },
// {
//   path: '/selectRecharge',
//   name: 'SelectRecharge',
//   component: SelectRecharge,
//   meta: {
//     title: 'SelectRecharge', // 选择充值方式,
//     keepAlive: false
//   }
// },
// {
//   path: '/commissionRecharge',
//   name: 'CommissionRecharge',
//   component: CommissionRecharge,
//   meta: {
//     title: 'CommissionRecharge', // 佣金充值,
//     keepAlive: false
//   }
// },
// {
//   path: '/bankRechargeOne',
//   name: 'BankRechargeOne',
//   component: BankRechargeOne,
//   meta: {
//     title: 'BankRecharge', // 银行充值,
//     keepAlive: false
//   }
// },
// {
//   path: '/bankRechargeTwo',
//   name: 'BankRechargeTwo',
//   component: BankRechargeTwo,
//   meta: {
//     title: 'BankRecharge', // 银行充值,
//     keepAlive: false
//   }
// },
// {
//   path: '/bankRechargeThree',
//   name: 'BankRechargeThree',
//   component: BankRechargeThree,
//   meta: {
//     title: 'BankRecharge', // 银行充值,
//     keepAlive: false
//   }
// },
{
  path: '/bankRechargeRecord',
  name: 'BankRechargeRecord',
  component: BankRechargeRecord,
  meta: {
    title: 'BankRechargeRecord',
    // 银行充值记录,
    keepAlive: false
  }
}, // {
//   path: '/changeUserName',
//   name: 'ChangeUserName',
//   component: ChangeUserName,
//   meta: {
//     title: 'ChangeUserName', // 编辑用户名,
//     keepAlive: false
//   }
// },
// {
//   path: '/changePhone',
//   name: 'ChangePhone',
//   component: ChangePhone,
//   meta: {
//     title: 'ChangePhone', // 编辑电话,
//     keepAlive: false
//   }
// },
// {
//   path: '/myCoupon',
//   name: 'MyCoupon',
//   component: MyCoupon,
//   meta: {
//     title: 'MyCoupon', // 我的优惠券,
//     keepAlive: false
//   }
// },
// {
//   path: '/couponHistory',
//   name: 'CouponHistory',
//   component: CouponHistory,
//   meta: {
//     title: 'CouponHistory', // 优惠券记录,
//     keepAlive: false
//   }
// },
{
  path: '/consignee',
  name: 'Consignee',
  component: Consignee,
  meta: {
    title: 'Consignee',
    // 联系人,
    keepAlive: false
  }
}, // {
//   path: '/wallet',
//   name: 'Wallet',
//   component: Wallet,
//   meta: {
//     title: 'Wallet', // 钱包,
//     keepAlive: false
//   }
// },
// {
//   path: '/currency',
//   name: 'Currency',
//   component: Currency,
//   meta: {
//     title: 'Currency', // 默认币种,
//     keepAlive: false
//   }
// },
{
  path: '/paymentPassword',
  name: 'PaymentPassword',
  component: PaymentPassword,
  meta: {
    title: 'PaymentPassword',
    // 交易密码,
    keepAlive: false
  }
}, {
  path: '/transactions',
  name: 'Transactions',
  component: Transactions,
  meta: {
    title: 'Transactions',
    // 交易记录,
    keepAlive: true
  }
}, {
  path: '/transactionsDetails',
  name: 'TransactionsDetails',
  component: TransactionsDetails,
  meta: {
    title: 'TransactionsDetails',
    // 交易记录详情,
    keepAlive: false
  }
}, {
  path: '/editConsignee',
  name: 'EditConsignee',
  component: EditConsignee,
  meta: {
    title: 'EditConsignee',
    // 新增联系人,
    keepAlive: false
  }
}, // {
//   path: '/setting',
//   name: 'Setting',
//   component: Setting,
//   meta: {
//     title: 'Setting', // 设置,
//     keepAlive: true
//   }
// },
// {
//   path: '/browsed',
//   name: 'Browsed',
//   component: Browsed,
//   meta: {
//     title: 'Browsed', // 足迹,
//     keepAlive: true
//   }
// },
{
  path: '/terms',
  name: 'Terms',
  component: Terms,
  meta: {
    title: 'Terms',
    // 服务条款,
    keepAlive: true
  }
}, // {
//   path: '/cart',
//   name: 'Cart',
//   component: Cart,
//   meta: {
//     title: 'Cart', // 购物车',
//     keepAlive: true
//   }
// },
// {
//   path: '/checkout',
//   name: 'Checkout',
//   component: Checkout,
//   meta: {
//     title: 'Checkout', // 创建订单预览',
//     keepAlive: true
//   }
// },
// {
//   path: '/addressList',
//   name: 'AddressList',
//   component: AddressList,
//   meta: {
//     title: 'Address list', // 地址列表',
//     keepAlive: false
//   }
// },
// {
//   path: '/addressEdit',
//   name: 'AddressEdit',
//   component: AddressEdit,
//   meta: {
//     title: 'Edit address', // 编辑地址',
//     keepAlive: false
//   }
// },
{
  path: '/houseAddress',
  name: 'HouseAddress',
  component: HouseAddress,
  meta: {
    title: 'House address',
    // 网点
    keepAlive: false
  }
}, // {
//   path: '/pickupAddress',
//   name: 'PickupAddress',
//   component: PickupAddress,
//   meta: {
//     title: 'Pickup address', // 提货点(驿站)
//     keepAlive: false
//   }
// },
{
  path: '/404',
  name: '404',
  component: PageErr,
  meta: {
    title: '404',
    keepAlive: true
  }
}, {
  path: '/paySuccess',
  name: 'PaySuccess',
  component: PaySuccess,
  meta: {
    title: 'PaySuccess',
    keepAlive: false
  }
}, // {
//   path: '/login',
//   name: 'login',
//   component: Login,
//   meta: {
//     title: 'Login' // 登录
//     // auth: true,
//     // keepAlive: true
//   }
// },
// {
//   path: '/forgot',
//   name: 'forgot',
//   component: Forgot,
//   meta: {
//     title: 'Forgot' // 注册
//     // auth: true,
//     // keepAlive: true
//   }
// },
// {
//   path: '/register',
//   name: 'register',
//   component: Register,
//   meta: {
//     title: 'Register' // 注册
//     // auth: true,
//     // keepAlive: true
//   }
// },
{
  path: '/parcel_forward_rules',
  name: 'parcel_forward_rules',
  component: ParcelForwardRules,
  meta: {
    title: 'parcel_forward_rules',
    // 包裹转发检查规则
    // auth: true,
    keepAlive: false
  }
}, // --------------------------------------------
{
  path: '/user_help_forwarding',
  name: 'user_help_forwarding',
  component: UserHelpForwarding,
  meta: {
    title: 'user_help_forwarding',
    // 转运帮助文档
    // auth: true,
    keepAlive: false
  }
}, {
  path: '/user_help_purchase',
  name: 'user_help_purchase',
  component: UserHelpPurchase,
  meta: {
    title: 'user_help_purchase',
    // 代购帮助文档
    // auth: true,
    keepAlive: true
  }
}, {
  path: '/user_help_page_1',
  name: 'user_help_page_1',
  component: UserHelpPage,
  meta: {
    title: 'user_help_page_1',
    // 代购帮助文档
    // auth: true,
    keepAlive: false
  }
}, {
  path: '/purchaseNotice',
  name: 'PurchaseNotice',
  component: PurchaseNotice,
  meta: {
    title: 'PurchaseNotice',
    // 代购帮助文档
    // auth: true,
    keepAlive: false
  }
}, {
  path: '/GoodsContent',
  name: 'GoodsContent',
  component: GoodsContent,
  meta: {
    title: 'GoodsContent',
    // 商品详情页
    // auth: true,
    keepAlive: false
  }
} // {
//   path: '/ProductListPage',
//   name: 'ProductListPage',
//   component: ProductListPage,
//   meta: {
//     title: 'ProductListPage', // 商品列表
//     // auth: true,
//     keepAlive: false
//   }
// },
// {
//   path: '/alibaba',
//   name: 'Alibaba',
//   component: Alibaba,
//   meta: {
//     title: 'Alibaba', // 1688
//     keepAlive: true
//   }
// },
// {
//   path: '/topSale',
//   name: 'TopSale',
//   component: TopSale,
//   meta: {
//     title: 'TopSale', // 热销
//     keepAlive: true
//   }
// },
// {
//   path: '/flashSale',
//   name: 'FlashSale',
//   component: FlashSale,
//   meta: {
//     title: 'FlashSale', // 闪购
//     keepAlive: true
//   }
// },
// {
//   path: '/newArrival',
//   name: 'NewArrival',
//   component: NewArrival,
//   meta: {
//     title: 'NewArrival', // 新品
//     keepAlive: true
//   }
// },
// {
//   path: '/designatCouponPage',
//   name: 'DesignatCouponPage',
//   component: DesignatCouponPage,
//   meta: {
//     title: 'DesignatCouponPage', // 指定优惠券可用商品页
//     keepAlive: true
//   }
// },
// {
//   path: '/shippingFee',
//   name: 'ShippingFee',
//   component: ShippingFee,
//   meta: {
//     title: 'ShippingFee', // 发货列表
//     // auth: true,
//     keepAlive: false
//   }
// },
// {
//   path: '/selfSupport',
//   name: 'SelfSupport',
//   component: SelfSupport,
//   meta: {
//     title: 'SelfSupport', // 自营首页
//     // auth: true,
//     keepAlive: true
//   }
// },
// {
//   path: '/local',
//   name: 'Local',
//   component: Local,
//   meta: {
//     title: 'Local', // 海外仓首页
//     keepAlive: true
//   }
// },
// {
//   path: '/categories',
//   name: 'Categories',
//   component: Categories,
//   meta: {
//     title: 'Categories', // 自营分类
//     // auth: true,
//     keepAlive: true
//   }
// },
// {
//   path: '/help',
//   name: 'Help',
//   component: Help,
//   meta: {
//     title: 'Help', // 自营分类
//     // auth: true,
//     keepAlive: false
//   }
// },
// {
//   path: '/productListFromImg',
//   name: 'ProductListFromImg',
//   component: ProductListFromImg,
//   meta: {
//     title: 'ProductListFromImg', // 商品列表
//     // auth: true,
//     keepAlive: false
//   }
// }
]; // const routerContext = require.context('./', true, /\.js$/)
// routerContext.keys().forEach(route => {
//   // 如果是根目录的 index.js 、不处理
//   if (route.startsWith('./index')) {
//     return
//   }
//   const routerModule = routerContext(route)
//   /**
//    * 兼容 import export 和 require module.export 两种规范
//    */
//   routes = routes.concat(routerModule.default || routerModule)
// })
// routes = routes.concat({
//   path: '*',
//   redirect: '/404'
// })

var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    base: process.env.BASE_URL,
    savedPosition: true,
    scrollBehavior: function scrollBehavior(to, from, savedPosition) {
      console.log('savedPosition', savedPosition);

      if (savedPosition) {
        return savedPosition;
      } else {
        console.log("from.meta", from.meta);

        if (from.meta.keepAlive) {
          from.meta.savedPosition = document.body.scrollTop;
        }

        return {
          x: 0,
          y: to.meta.savedPosition || 0
        };
      }
    },
    routes: routes
  });
};

var myRouter = createRouter(); // const history = window.sessionStorage
// history.clear()
// let historyCount = history.getItem('count') * 1 || 0
// history.setItem('/', 0)

function resetRouter() {
  myRouter.replace('/login');
}

var _default = myRouter;
exports.default = _default;