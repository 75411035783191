"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/dialog/style");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

require("vant/es/divider/style");

var _divider = _interopRequireDefault(require("vant/es/divider"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _vuex = require("vuex");

var _utils = require("utils");

var _order = require("api/order");

var _filter = require("@/filter");

var _ForwardingItem = _interopRequireDefault(require("./ForwardingItem"));

var _PurchaseItem = _interopRequireDefault(require("./PurchaseItem"));

var _PayPassword = _interopRequireDefault(require("components/PayPassword"));

var _PickupTime = _interopRequireDefault(require("components/PickupTime"));

var _components;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: "OrderDetails",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _divider.default.name, _divider.default), (0, _defineProperty2.default)(_components, _image.default.name, _image.default), (0, _defineProperty2.default)(_components, "ForwardingItem", _ForwardingItem.default), (0, _defineProperty2.default)(_components, "PurchaseItem", _PurchaseItem.default), (0, _defineProperty2.default)(_components, "PayPassword", _PayPassword.default), (0, _defineProperty2.default)(_components, "PickupTime", _PickupTime.default), _components),
  filters: {
    // 标题文字和图片
    getStatusIconUrl: function getStatusIconUrl(status) {
      var ele = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      var iconUrl = "";
      var title = "";

      switch (status) {
        case 0:
          iconUrl = require('@/assets/images/order/wallet.png');
          title = "Payment Pending";
          break;

        case 1:
          iconUrl = require('@/assets/images/order/sendgood.png');
          title = "Warehouse Pending";
          break;

        case 2:
          iconUrl = require('@/assets/images/order/warehouse_icon.png');
          title = "My Warehouse";
          break;

        case 3:
          iconUrl = require('@/assets/images/order/pass.png');
          title = "Delivered Recviews";
          break;

        case 4:
          iconUrl = require('@/assets/images/order/picked_up.png');
          title = "Picked up";
          break;

        case 5:
          iconUrl = require('@/assets/images/order/delete.png');
          title = "Order Canceled";
          break;

        case 6:
          iconUrl = require('@/assets/images/order/inspection_fill.png');
          title = "Dispatched";
          break;

        case 8:
          iconUrl = require('@/assets/images/order/re_pay.png');
          title = "Additional Payment";
          break;

        case 9:
          iconUrl = require('@/assets/images/order/pending_pickup.png');
          title = "Pending Pickup";
          break;

        case 100:
          iconUrl = require('@/assets/images/order/delete.png');
          title = "Order Deleted";
          break;

        default:
          break;
      }

      if (ele === 1) {
        return iconUrl;
      } else {
        return title;
      }
    },
    // 退款状态
    getOrderRefundStatus: function getOrderRefundStatus(status) {
      var title = "";

      switch (status) {
        case 0:
          title = "Pending";
          break;

        case 1:
          title = "Refunding";
          break;

        case 2:
          title = "Finished";
          break;

        case 3:
          title = "Refused";
          break;

        case 4:
          title = "";
          break;

        default:
          break;
      }

      return title;
    }
  },
  data: function data() {
    return {
      isShowKey: [3, 4, 6],
      order: {},
      payOrder: {
        orderIds: "",
        commissionOffsetPrice: "",
        totalPriceNGN: "",
        selectOrderIdList: []
      },
      showKeyboard: false,
      showPickupTime: false,
      selectPickupTime: null,
      changeTimeStatus: false // 解决日历确认时自动弹框关闭

    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)({
    user: function user(state) {
      return state.user['user'];
    }
  })), {}, {
    getTotalPrice: function getTotalPrice() {
      return function (totalPrice, stationAmount, exchangeRateList) {
        var total = 0;
        if (stationAmount) total += Number(stationAmount);
        total += +(0, _filter.fromCurrencyByToCurrency)(totalPrice, "CNY", "NGN", exchangeRateList);
        return total.toFixed(2);
      };
    }
  }),
  watch: {
    $route: {
      handler: function handler(val, oldVal) {
        // 因缓存刷新页面
        if (["TransactionsDetails", "Order", "Refund", "RefundDetails", "DeliveryDetails", "PreviewDeliveryDetails", "QueryOrder"].includes(oldVal === null || oldVal === void 0 ? void 0 : oldVal.name) && val.name === "OrderDetails") {
          this.fetchData();
        }
      },
      immediate: true
    }
  },
  created: function created() {
    var _this = this;

    this.$EventBus.$on('openPayPassword2', function (order) {
      console.log(order);
      _this.showKeyboard = true;
      _this.payOrder = order;
    });
    this.fetchData();
  },
  destroyed: function destroyed() {
    this.$EventBus.$off('openPayPassword2');
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)({
    getInfo: 'user/getInfo'
  })), {}, {
    fetchData: function fetchData() {
      var _this$$route$query,
          _this$$route$query$or,
          _this$$route$query3,
          _this2 = this;

      if ((_this$$route$query = this.$route.query) !== null && _this$$route$query !== void 0 && (_this$$route$query$or = _this$$route$query.order) !== null && _this$$route$query$or !== void 0 && _this$$route$query$or.id) {
        var _this$$route$query2;

        this.order = (_this$$route$query2 = this.$route.query) === null || _this$$route$query2 === void 0 ? void 0 : _this$$route$query2.order;
      } else if ((_this$$route$query3 = this.$route.query) !== null && _this$$route$query3 !== void 0 && _this$$route$query3.id) {
        var _this$$route$query4;

        (0, _order.getOrderList)({
          id: (_this$$route$query4 = this.$route.query) === null || _this$$route$query4 === void 0 ? void 0 : _this$$route$query4.id,
          queryPage: 2,
          queryPremium: 2,
          queryRefund: 2,
          queryRefundOrder: 2
        }).then(function (res) {
          _this2.order = res.data.data[0];

          _this2.deepObj();
        });
      } else {
        this.$router.go(-1);
      }
    },
    copy: function copy(logisticsId) {
      (0, _utils.copyTextToClipboard)("".concat(logisticsId));

      _toast.default.success('Copy success');
    },
    // 回调选中的时间
    handlePickupTime: function handlePickupTime(date) {
      var _this3 = this;

      this.changeTimeStatus = true;

      _dialog.default.confirm({
        title: "Notice",
        messageAlign: "left",
        message: "Whether to submit ".concat(date),
        theme: 'round-button'
      }).then(function () {
        (0, _order.updateOrderPickupTime)({
          id: _this3.order.id,
          reservePickupTime: date
        }).then(function (res) {
          _this3.changeTimeStatus = false;
          _this3.showPickupTime = false;

          _this3.$set(_this3.order, "reservePickupTime", res.data);

          _this3.$EventBus.$emit('orderListRefresh');

          _toast.default.success("successfully");
        });
      }).catch(function () {
        _this3.changeTimeStatus = false;
        _this3.showPickupTime = true;
      });
    },
    // 关闭提货时间弹框
    closePickupTime: function closePickupTime(status) {
      // 修改提货时间提示确认后再执行
      if (!this.changeTimeStatus) this.showPickupTime = status;
    },
    // 支付密码pop
    showPayPassword: function showPayPassword(show) {
      this.showKeyboard = show;
    },
    // 密码六位数回调
    payPasswordDown: function payPasswordDown(payPassword) {
      var _this$payOrder,
          _this$payOrder$orderI,
          _this4 = this,
          _this$payOrder2,
          _this$payOrder2$selec,
          _this$payOrder3;

      console.log(payPassword);
      console.log(this.payOrder);

      if ((_this$payOrder = this.payOrder) !== null && _this$payOrder !== void 0 && (_this$payOrder$orderI = _this$payOrder.orderIds) !== null && _this$payOrder$orderI !== void 0 && _this$payOrder$orderI.length) {
        // 待支付订单
        (0, _order.payOrder)({
          orderIds: this.payOrder.orderIds,
          commissionOffsetPrice: this.payOrder.commissionOffsetPrice ? Number(this.payOrder.commissionOffsetPrice) < Number(this.user.commissionBalance) ? this.payOrder.commissionOffsetPrice : this.user.commissionBalance : null,
          payPassword: payPassword
        }).then(function (res) {
          _this4.$EventBus.$emit('clearPayPassword');

          _this4.showKeyboard = false;

          _this4.$router.push({
            path: "/PaySuccess",
            query: {
              status: 1
            }
          });
        }).catch(function (e) {
          _this4.$EventBus.$emit('clearPayPassword');
        });
      } else if ((_this$payOrder2 = this.payOrder) !== null && _this$payOrder2 !== void 0 && (_this$payOrder2$selec = _this$payOrder2.selectOrderIdList) !== null && _this$payOrder2$selec !== void 0 && _this$payOrder2$selec.length) {
        // 转运费
        (0, _order.payTransportOrder)({
          selectOrderIdList: this.payOrder.selectOrderIdList,
          payPassword: payPassword
        }).then(function (res) {
          _this4.getInfo();

          _this4.$EventBus.$emit('clearPayPassword');

          _this4.showKeyboard = false;

          _this4.$router.push({
            path: "/PaySuccess",
            query: {
              status: 2
            }
          });
        }).catch(function (e) {
          _this4.$EventBus.$emit('clearPayPassword');
        });
      } else if ((_this$payOrder3 = this.payOrder) !== null && _this$payOrder3 !== void 0 && _this$payOrder3.orderPremiumId) {
        console.log("补款");
        (0, _order.payAdditionalOrder)({
          orderPremiumId: this.payOrder.orderPremiumId,
          payPassword: payPassword
        }).then(function (res) {
          _this4.getInfo();

          _this4.$EventBus.$emit('clearPayPassword');

          _this4.showKeyboard = false;

          _this4.$router.push({
            path: "/PaySuccess",
            query: {
              status: 8
            }
          });
        }).catch(function (e) {
          _this4.$EventBus.$emit('clearPayPassword');
        });
      }
    },
    // 组装为一个退款数组
    deepObj: function deepObj() {
      var _this$order,
          _this$order$refunds,
          _this$order2,
          _this$order2$orderDet,
          _this5 = this,
          _this$order3,
          _this$order3$localGoo,
          _this$order4,
          _this$order4$orderDet;

      if ((_this$order = this.order) !== null && _this$order !== void 0 && (_this$order$refunds = _this$order.refunds) !== null && _this$order$refunds !== void 0 && _this$order$refunds.length && (_this$order2 = this.order) !== null && _this$order2 !== void 0 && (_this$order2$orderDet = _this$order2.orderDetails) !== null && _this$order2$orderDet !== void 0 && _this$order2$orderDet.length) {
        // 代购
        this.order.myRefunds = this.order.refunds.map(function (item) {
          _this5.$set(item, "myRefundDetails", item.refundDetails.map(function (item) {
            return _objectSpread(_objectSpread(_objectSpread({}, item), _this5.order.orderDetails.filter(function (ele) {
              return ele.orderDetailId === item.orderDetailId;
            })[0]), {}, {
              quantity2: item.quantity
            });
          }));

          return item;
        });
      } else if ((_this$order3 = this.order) !== null && _this$order3 !== void 0 && (_this$order3$localGoo = _this$order3.localGoodsRefunds) !== null && _this$order3$localGoo !== void 0 && _this$order3$localGoo.length && (_this$order4 = this.order) !== null && _this$order4 !== void 0 && (_this$order4$orderDet = _this$order4.orderDetails) !== null && _this$order4$orderDet !== void 0 && _this$order4$orderDet.length) {
        // 海外仓
        this.order.myRefunds = this.order.localGoodsRefunds.map(function (item) {
          _this5.$set(item, "status", item.status - 1);

          _this5.$set(item, "myRefundDetails", item.localGoodsRefundDetails.map(function (item) {
            return _objectSpread(_objectSpread(_objectSpread({}, item), _this5.order.orderDetails.filter(function (ele) {
              return ele.orderDetailId === item.orderDetailId;
            })[0]), {}, {
              quantity2: item.quantity
            });
          }));

          return item;
        });
      }
    },
    // 获取优惠后的单价
    getUnitPrice: function getUnitPrice(orderItem) {
      // console.log(orderItem);
      var unitPrice = "0";

      try {
        if (this.order.couponCodeId) {
          unitPrice = Number(orderItem.unitPrice) - Number((Number(orderItem.discountedPrice) / orderItem.quantity).toFixed(2));
        } else {
          unitPrice = orderItem.unitPrice;
        }
      } catch (error) {
        console.log(error);
      }

      return unitPrice;
    }
  })
};
exports.default = _default;