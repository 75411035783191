"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticStyle: {
      padding: "0 10px"
    }
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Recharge",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("main", [_c("div", {
    staticClass: "title"
  }, [_vm._v("Top up online")]), _vm._v(" "), _c("van-form", {
    on: {
      submit: _vm.onSubmit
    }
  }, [_c("van-field", {
    attrs: {
      name: "amount",
      type: "number",
      label: "₦",
      placeholder: "0.00",
      rules: [{
        required: true,
        message: "Recharge amount can not be empty"
      }]
    },
    model: {
      value: _vm.amount,
      callback: function callback($$v) {
        _vm.amount = $$v;
      },
      expression: "amount"
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "16px"
    }
  }, [_c("van-button", {
    attrs: {
      block: "",
      type: "info",
      "native-type": "submit",
      color: "#EA3340"
    }
  }, [_vm._v("Recharge")])], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "my-cell"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("Or other recharge")]), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Recharge by commission",
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/commissionRecharge");
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c("van-tag", {
          attrs: {
            color: "#FF0037"
          }
        }, [_vm._v("For free")])];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Bank transfer record",
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/bankRechargeOne");
      }
    }
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Bank transfer recharge record",
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/bankRechargeRecord");
      }
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "my-cell"
  }, [_c("van-cell", {
    attrs: {
      title: "Change payment password",
      "is-link": ""
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/paymentPassword");
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: "lock"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;