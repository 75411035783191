"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/popup/style");

var _popup = _interopRequireDefault(require("vant/es/popup"));

require("vant/es/divider/style");

var _divider = _interopRequireDefault(require("vant/es/divider"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("core-js/modules/es6.function.name");

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

var _utils = require("utils");

var _TabList = _interopRequireDefault(require("./TabList"));

var _components;

var _default2 = {
  name: "DeliveryDetailsItem",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, _divider.default.name, _divider.default), (0, _defineProperty2.default)(_components, _popup.default.name, _popup.default), (0, _defineProperty2.default)(_components, "TabList", _TabList.default), _components),
  props: {
    // 发货单号
    logisticsId: {
      type: String,
      default: ""
    },
    // 数据
    deliveryItem: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      // open订单
      orderDetailShow: false
    };
  },
  watch: {
    deliveryItem: function deliveryItem() {
      this.orderDetailShow = false;
    }
  },
  created: function created() {},
  methods: {
    copy: function copy(logisticsId) {
      (0, _utils.copyTextToClipboard)("".concat(logisticsId));

      _toast.default.success('Copy success');
    }
  }
};
exports.default = _default2;