"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "forwarding-view"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "Womata Forwarding",
      "safe-area-inset-top": "",
      fixed: ""
    }
  }), _vm._v(" "), _c("van-pull-refresh", {
    staticStyle: {
      "min-height": "calc(100vh - 46px)"
    },
    attrs: {
      "pulling-text": "Pull down refresh",
      "loosing-text": "Release to refresh",
      "loading-text": "Refreshing",
      "success-text": "Refresh completed"
    },
    on: {
      refresh: _vm.onRefresh
    },
    model: {
      value: _vm.refreshing,
      callback: function callback($$v) {
        _vm.refreshing = $$v;
      },
      expression: "refreshing"
    }
  }, [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "top"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("span", [_vm._v("1")]), _vm._v("Womata warehouse")]), _vm._v(" "), _c("van-button", {
    attrs: {
      color: "#EA3340",
      round: "",
      plain: "",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.copy("".concat(_vm.businessMan.userCode + _vm.businessMan.businessManRealName, " ").concat(_vm.businessMan.businessManPhone, " ").concat(_vm.warehouse.provinceName + _vm.warehouse.cityName + _vm.warehouse.districtName + _vm.warehouse.address + _vm.businessMan.userCode, "\u5E93"));
      }
    }
  }, [_vm._v("copy all")])], 1), _vm._v(" "), _c("div", {
    staticClass: "bg-border"
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Consignee: ".concat(_vm.businessMan.userCode + _vm.businessMan.businessManRealName || "联非")
    },
    scopedSlots: _vm._u([{
      key: "right-icon",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            "class-prefix": "iconfont icon-iccopy",
            size: "16"
          },
          on: {
            click: function click($event) {
              return _vm.copy("".concat(_vm.businessMan.userCode + _vm.businessMan.businessManRealName));
            }
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Address: ".concat(_vm.warehouse.provinceName + _vm.warehouse.cityName + _vm.warehouse.districtName + _vm.warehouse.address + _vm.businessMan.userCode, "\u5E93")
    },
    scopedSlots: _vm._u([{
      key: "right-icon",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            "class-prefix": "iconfont icon-iccopy",
            size: "16"
          },
          on: {
            click: function click($event) {
              return _vm.copy("".concat(_vm.warehouse.provinceName + _vm.warehouse.cityName + _vm.warehouse.districtName + _vm.warehouse.address + _vm.businessMan.userCode, "\u5E93"));
            }
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Phone: ".concat(_vm.businessMan.businessManPhone)
    },
    scopedSlots: _vm._u([{
      key: "right-icon",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            "class-prefix": "iconfont icon-iccopy",
            size: "16"
          },
          on: {
            click: function click($event) {
              return _vm.copy("".concat(_vm.businessMan.businessManPhone));
            }
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("van-cell", {
    attrs: {
      title: "Postcode: ".concat(_vm.warehouse.postCode)
    },
    scopedSlots: _vm._u([{
      key: "right-icon",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            "class-prefix": "iconfont icon-iccopy",
            size: "16"
          },
          on: {
            click: function click($event) {
              return _vm.copy("".concat(_vm.warehouse.postCode));
            }
          }
        })];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "top"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("span", [_vm._v("2")]), _vm._v("Add logistics information")])]), _vm._v(" "), _c("van-form", {
    on: {
      submit: _vm.onSubmit
    }
  }, [_c("van-field", {
    attrs: {
      name: "expressNo",
      label: "Tracking Number:",
      placeholder: "Please enter the Chinese tracking number",
      rules: [{
        required: true,
        message: "Tracking number can not be empty"
      }]
    },
    model: {
      value: _vm.expressNo,
      callback: function callback($$v) {
        _vm.expressNo = $$v;
      },
      expression: "expressNo"
    }
  }), _vm._v(" "), _c("van-field", {
    attrs: {
      name: "title",
      label: "Goods Category:",
      placeholder: "please choose",
      "is-link": "",
      readonly: "",
      rules: [{
        required: true,
        message: "Category of goods can not be empty"
      }]
    },
    on: {
      click: function click($event) {
        _vm.show = true;
      }
    },
    model: {
      value: _vm.title,
      callback: function callback($$v) {
        _vm.title = $$v;
      },
      expression: "title"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "rules"
  }, [_c("van-checkbox", {
    attrs: {
      shape: "square",
      "checked-color": "#EA3340",
      "icon-size": "18px"
    },
    model: {
      value: _vm.checked,
      callback: function callback($$v) {
        _vm.checked = $$v;
      },
      expression: "checked"
    }
  }), _c("div", [_vm._v("Read & Agreed")]), _c("span", {
    on: {
      click: function click($event) {
        return _vm.$router.push("/parcel_forward_rules");
      }
    }
  }, [_vm._v("<Parcel Forward Inspection Rules>")])], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "16px"
    }
  }, [_c("van-button", {
    attrs: {
      round: "",
      block: "",
      type: "info",
      "native-type": "submit",
      color: "#EA3340",
      disabled: !_vm.checked
    }
  }, [_vm._v("Submit orders")])], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "top"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("span", [_vm._v("3")]), _vm._v("Pay for logistics freight")]), _vm._v(" "), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("span", {
    staticClass: "theme-color",
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "Order",
          params: {
            status: 2
          }
        });
      }
    }
  }, [_vm._v("To pay")]), _vm._v(" "), _c("van-icon", {
    attrs: {
      name: "arrow",
      size: "16",
      color: "#969799"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "info"
  }, [_vm._v("Waiting for the package to be packed,\n        you can pay for international package freight on the \n        pending page. Next, just wait for the package to be\n        expressed.")])])]), _vm._v(" "), _c("van-popup", {
    attrs: {
      round: "",
      position: "bottom",
      "close-on-popstate": "",
      "safe-area-inset-bottom": ""
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c("van-tree-select", {
    attrs: {
      height: "500",
      items: _vm.categoryTreeList,
      "active-id": _vm.activeId,
      "main-active-index": _vm.activeIndex
    },
    on: {
      "update:activeId": function updateActiveId($event) {
        _vm.activeId = $event;
      },
      "update:active-id": function updateActiveId($event) {
        _vm.activeId = $event;
      },
      "update:mainActiveIndex": function updateMainActiveIndex($event) {
        _vm.activeIndex = $event;
      },
      "update:main-active-index": function updateMainActiveIndex($event) {
        _vm.activeIndex = $event;
      },
      "click-item": _vm.selectItem
    }
  })], 1), _vm._v(" "), _c("FooterTabbar")], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;