"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("core-js/modules/es6.function.name");

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

var _default = {
  name: 'TheWaterGoodCard',
  components: (0, _defineProperty2.default)({}, _image.default.name, _image.default),
  props: {
    spuName: {
      type: String,
      default: ''
    },
    offerPrice: {
      type: String,
      default: '0'
    },
    showPrice: {
      type: String,
      default: null
    },
    imageUrl: {
      type: String,
      default: ''
    },
    showWomall: {
      type: Boolean,
      default: false
    },
    supplyType: {
      type: Number,
      default: null
    }
  }
};
exports.default = _default;