"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addConsignee = addConsignee;
exports.deleteConsignee = deleteConsignee;
exports.editConsignee = editConsignee;
exports.getConsigneeList = getConsigneeList;

var _request = _interopRequireDefault(require("@/utils/request"));

function getConsigneeList(params) {
  return (0, _request.default)({
    url: 'common-service/receivingAddress',
    method: 'get',
    params: params
  });
}
/** * 
 *新增联系人
 * {
    "name": "test",
    "phone": "13265117781",
    "countryId": "662084",
    "provinceId": "44",
    "cityId": "4401",
    "districtId": "440103",
    "detailedAddress": "荔湾尸场",
    "zipCode": "510000",
    "defaultAddress": 1,
    "countryCode": "+86"
}
 */


function addConsignee(data) {
  return (0, _request.default)({
    url: 'common-service/receivingAddress',
    method: 'post',
    data: data
  });
} // 编辑联系人


function editConsignee(data) {
  return (0, _request.default)({
    url: 'common-service/receivingAddress',
    method: 'put',
    data: data
  });
} // 删除联系人


function deleteConsignee(data) {
  return (0, _request.default)({
    url: 'common-service/receivingAddress/' + data,
    method: 'delete' // data

  });
}