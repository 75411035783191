"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/empty/style");

var _empty = _interopRequireDefault(require("vant/es/empty"));

require("vant/es/card/style");

var _card = _interopRequireDefault(require("vant/es/card"));

require("vant/es/list/style");

var _list = _interopRequireDefault(require("vant/es/list"));

require("vant/es/pull-refresh/style");

var _pullRefresh = _interopRequireDefault(require("vant/es/pull-refresh"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _goods = require("@/api/goods.js");

var _CountDown = _interopRequireDefault(require("@/components/CountDown"));

var _components;

var _default = {
  name: "FlashSale",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _image.default.name, _image.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), (0, _defineProperty2.default)(_components, _pullRefresh.default.name, _pullRefresh.default), (0, _defineProperty2.default)(_components, _list.default.name, _list.default), (0, _defineProperty2.default)(_components, _card.default.name, _card.default), (0, _defineProperty2.default)(_components, _empty.default.name, _empty.default), (0, _defineProperty2.default)(_components, _button.default.name, _button.default), (0, _defineProperty2.default)(_components, "CountDown", _CountDown.default), _components),
  data: function data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      beginTime: "",
      // 闪购开始时间
      endTime: "",
      // 闪购结束时间
      activityId: null,
      // 闪购活动id
      pageNum: 1
    };
  },
  watch: {
    $route: {
      handler: function handler(val, oldVal) {
        // 因缓存刷新页面
        if (val.name === "FlashSale" && (oldVal === null || oldVal === void 0 ? void 0 : oldVal.name) !== "GoodsContent") {
          var _val$query;

          if ((_val$query = val.query) !== null && _val$query !== void 0 && _val$query.activityId) {
            this.onRefresh();
          }
        }
      },
      immediate: true
    }
  },
  created: function created() {},
  methods: {
    fetchData: function fetchData() {
      var _this$$route$query,
          _this = this;

      (0, _goods.getSpuActivity)({
        activityId: (_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.activityId,
        pageNum: this.pageNum,
        pageSize: 10
      }).then(function (res) {
        try {
          var _res$data$lists$spuLi;

          _this.loading = false;
          _this.refreshing = false;
          _this.beginTime = res['data']['lists']['startTime'];
          _this.endTime = res['data']['lists']['endTime'];
          _this.activityId = res['data']['lists']['activityId'];

          if (((_res$data$lists$spuLi = res['data']['lists']['spuList']) === null || _res$data$lists$spuLi === void 0 ? void 0 : _res$data$lists$spuLi.length) > 0) {
            if (_this.pageNum === 1) {
              _this.list = res['data']['lists']['spuList'];
            } else {
              _this.list = _this.list.concat(res['data']['lists']['spuList']);
            }
          } else {
            if (_this.pageNum === 1) _this.list = [];
            _this.finished = true;
          }
        } catch (e) {
          console.log("🚀 ~ file: index.vue ~ line 387 ~ _initGetSpuActivity ~ e", e);
        }
      });
    },
    toGoodDetailPage: function toGoodDetailPage(item) {
      var _arguments = {
        "url": item['spuId'],
        "module": 1,
        "activityId": this.activityId
      };
      this.$router.push({
        path: "/GoodsContent",
        query: _arguments
      });
    },
    onLoad: function onLoad() {
      this.pageNum++;
      this.fetchData();
    },
    // 下拉刷新
    onRefresh: function onRefresh() {
      this.list = [];
      this.finished = false;
      this.pageNum = 1;
      this.fetchData();
    }
  }
};
exports.default = _default;