"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "coupon-content-box"
    }, [_vm.checkedIconShow ? [item.isAvailable ? _c("van-icon", {
      attrs: {
        name: item.checked ? "checked" : "circle",
        size: "18",
        color: item.checked ? "#EA3340" : "#ccc"
      },
      on: {
        click: function click($event) {
          return _vm.selectCoupon(index);
        }
      }
    }) : _c("van-icon", {
      attrs: {
        name: require("@/assets/images/coupon/round.png"),
        size: "18"
      }
    })] : _vm._e(), _vm._v(" "), _c("div", {
      staticClass: "box-right"
    }, [[item.coupon.type === 1 ? _c("div", {
      staticClass: "discount"
    }, [_c("span", [_vm._v(_vm._s(_vm.defaultCurrency.unit) + " " + _vm._s(_vm._f("CNYToCurrencyComma")(item.coupon.subtract)))]), _vm._v(" off")]) : item.coupon.type === 2 ? _c("div", {
      staticClass: "discount"
    }, [_c("span", [_vm._v(_vm._s(100 - item.coupon.discount) + "%")]), _vm._v(" off")]) : item.coupon.type === 3 ? _c("div", {
      staticClass: "discount"
    }, [_c("span", [_vm._v(_vm._s(_vm.defaultCurrency.unit) + " " + _vm._s(_vm._f("CNYToCurrencyComma")(item.coupon.voucher)))]), _vm._v(" off")]) : _vm._e()], _vm._v(" "), [_c("div", {
      staticClass: "amount"
    }, [item.coupon.type === 1 ? _c("div", [_vm._v("Amount over " + _vm._s(_vm._f("CNYToCurrencyUnit")(item.coupon.reach)))]) : item.coupon.type === 2 ? _c("div", {
      staticClass: "amount"
    }, [_vm._v(_vm._s(100 - item.coupon.discount) + "% off for items")]) : item.coupon.type === 3 ? _c("div", {
      staticClass: "amount"
    }, [_vm._v("Special discount for particular goods")]) : _vm._e(), _vm._v(" "), item.status === 1 && !item.isAvailable ? _c("van-button", {
      staticClass: "submit-go",
      attrs: {
        round: "",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.nextGo(item.coupon);
        }
      }
    }, [_vm._v("GO")]) : _vm._e(), _vm._v(" "), item.status !== 1 && !item.coupon.isReceived ? _c("van-button", {
      staticClass: "submit-go",
      attrs: {
        round: "",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.obtainCoupon(item.coupon.id);
        }
      }
    }, [_vm._v("Get now")]) : _vm._e()], 1)], _vm._v(" "), _c("van-divider", {
      style: {
        margin: "6px 0",
        borderColor: "#ccc"
      },
      attrs: {
        dashed: ""
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "info"
    }, [_c("span", [_vm._v(_vm._s(item.status ? item.isAvailable ? "• Cannot be superimposed with other coupons." : "• The specified amount is not reached." : "• Cannot be superimposed with other coupons."))]), _vm._v(" "), _c("span", [_vm._v(_vm._s(item.coupon.goodsRangeType === 1 ? "" : item.coupon.goodsRangeType === 2 ? "WOMALL" : "1688"))])]), _vm._v(" "), _c("div", {
      staticClass: "tip"
    }, [_vm._v(_vm._s(item.coupon.titleEn || "WOMATA"))])], 2)], 2);
  }), 0);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;