"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/image-preview/style");

var _imagePreview = _interopRequireDefault(require("vant/es/image-preview"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/divider/style");

var _divider = _interopRequireDefault(require("vant/es/divider"));

require("vant/es/image/style");

var _image = _interopRequireDefault(require("vant/es/image"));

require("core-js/modules/es6.function.name");

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

var _order = require("api/order");

var _components;

var _default = {
  name: "RefundDetails",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _navBar.default.name, _navBar.default), (0, _defineProperty2.default)(_components, _image.default.name, _image.default), (0, _defineProperty2.default)(_components, _divider.default.name, _divider.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), _components),
  data: function data() {
    return {
      dataObj: {
        localGoodsRefundDetails: [],
        order: {
          orderDetails: [],
          exchangeRate: "[]"
        },
        reason: "{}",
        voucher: "[]"
      },
      returnOrderAddress: {},
      titleArr: []
    };
  },
  created: function created() {
    var _this$$route$query,
        _this = this;

    if ((_this$$route$query = this.$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.orderId) {
      var _this$$route$query2;

      (0, _order.getLocalGoodsRefundList)({
        orderId: (_this$$route$query2 = this.$route.query) === null || _this$$route$query2 === void 0 ? void 0 : _this$$route$query2.orderId
      }).then(function (res) {
        _this.dataObj = res === null || res === void 0 ? void 0 : res.data[0];

        try {
          if (res !== null && res !== void 0 && res.data[0].whId) {
            (0, _order.getLocalGoodsRetuenAddress)(res === null || res === void 0 ? void 0 : res.data[0].id).then(function (res) {
              _this.returnOrderAddress = res.data.warehouse === null ? res.data.station : res.data.warehouse;
            });
          } else {
            var _res$data$0$order, _res$data$0$order2;

            _this.returnOrderAddress = (res === null || res === void 0 ? void 0 : res.data[0].order.pickUpType) === 2 ? JSON.parse((res === null || res === void 0 ? void 0 : (_res$data$0$order = res.data[0].order) === null || _res$data$0$order === void 0 ? void 0 : _res$data$0$order.packageStore) || "{}") : res === null || res === void 0 ? void 0 : (_res$data$0$order2 = res.data[0].order) === null || _res$data$0$order2 === void 0 ? void 0 : _res$data$0$order2.stationInfo;
          }
        } catch (error) {
          console.log(error);
        }

        if ([1, 2].includes(_this.dataObj.afterType)) {
          switch (_this.dataObj.status) {
            case 1:
              _this.titleArr = [{
                name: "Pending",
                icon: "checked",
                active: true
              }, {
                name: "Refunding",
                icon: "checked",
                active: false
              }, {
                name: "Finished",
                icon: "checked",
                active: false
              }];
              break;

            case 2:
              _this.titleArr = [{
                name: "Pending",
                icon: "checked",
                active: true
              }, {
                name: "Refunding",
                icon: "checked",
                active: true
              }, {
                name: "Finished",
                icon: "checked",
                active: false
              }];
              break;

            case 3:
              _this.titleArr = [{
                name: "Pending",
                icon: "checked",
                active: true
              }, {
                name: "Refunding",
                icon: "checked",
                active: true
              }, {
                name: "Finished",
                icon: "checked",
                active: true
              }];
              break;

            case 4:
              _this.titleArr = [{
                name: "Pending",
                icon: "checked",
                active: true
              }, {
                name: "Refused",
                icon: "clear",
                active: true
              }];
              break;

            default:
              break;
          }
        } else {
          switch (_this.dataObj.status) {
            case 1:
              _this.titleArr = [{
                name: "Pending",
                icon: "checked",
                active: true
              }, {
                name: "Finished",
                icon: "checked",
                active: false
              }];
              break;

            case 3:
              _this.titleArr = [{
                name: "Pending",
                icon: "checked",
                active: true
              }, {
                name: "Finished",
                icon: "checked",
                active: true
              }];
              break;

            case 4:
              _this.titleArr = [{
                name: "Pending",
                icon: "checked",
                active: true
              }, {
                name: "Refused",
                icon: "clear",
                active: true
              }];
              break;

            default:
              break;
          }
        }

        _this.dataObj.localGoodsRefundDetails.forEach(function (item) {
          _this.dataObj.order.orderDetails.forEach(function (ele) {
            if (item.orderDetailId === ele.orderDetailId) {
              _this.$set(ele, "quantity2", item.quantity);
            }
          });
        });
      });
    } else {
      this.$router.go(-1);
    }
  },
  methods: {
    openImage: function openImage() {
      (0, _imagePreview.default)(JSON.parse(this.dataObj.voucher));
    },
    openMap: function openMap() {
      window.open("https://www.google.com/maps/dir/".concat(this.returnOrderAddress.latitude, ",").concat(this.returnOrderAddress.longitude, "/").concat(this.returnOrderAddress.nameEn, "?hl=en"), '_blank');
    }
  }
};
exports.default = _default;