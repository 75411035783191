"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: "UserHelpForwarding",
  data: function data() {
    return {
      isEn: true,
      // 英文？
      forwardingGuide: [// 描述内容
      {
        title: "填写转运订单",
        entitle: "Fill in delivery orders",
        content: "在转运订单页面填写商品名,转运单号,选择收货人信息,添加商品描述(如果为敏感商品请勾选Sensitivity Good),订单填写完成后点击Submit orders进行提交.",
        encontent: "On the forwarding page, fill in the produtor name, tracking number, select the receiver information, add the item description (check the <b>[Sensitivity Good?]</b> if it is a sensitive item), click <b>[Submit orders]</b> to submit the order after completion.",
        image: require("../../static/asset/images/using_help/fstep01.png")
      }, {
        title: "订单提交",
        entitle: "Order submission",
        content: "订单在成功提交后会显示Submitted successfully.",
        encontent: "An order will show 'Submitted successfully' after successful submission.",
        image: require("../../static/asset/images/using_help/fstep02.png")
      }, {
        title: "等待入库",
        entitle: "Warehouse Pending",
        content: "在用户页点击Warehouse Pending进入待入库订单页面.",
        encontent: "Click on the user page <i>Warehouse Pending</i> enter the pending order page.",
        image: require("../../static/asset/images/using_help/fstep03.png")
      }, {
        title: "查看订单",
        entitle: "Checking out orders",
        content: "滚动订单列表,若之前下单成功即可找到该订单并查看订单状况.",
        encontent: "Scroll the order list to find the order and see the status of the order if the order is successful before.",
        image: require("../../static/asset/images/using_help/fstep04.png")
      }, {
        title: "已入库订单",
        entitle: "Warehouse orders",
        content: "一段时间之后,转运订单商品会转移至Womata仓库,此时用户可以点击My Warehouse进入仓库页查看可发货的订单.",
        encontent: "Over time, the forwarding order item is transferred to the Womata warehouse, where the user can click on the <i>My Warehouse</i> to enter the warehouse page to view the shipping order.",
        image: require("../../static/asset/images/using_help/fstep05.png")
      }, {
        title: "订单发货",
        entitle: "Delivery",
        content: "点击Select The Goods For Deliver选择需要发货的商品.",
        encontent: "Click <b>[Select The Goods For Deliver]</b> to select the item that needs to be shipped.",
        image: require("../../static/asset/images/using_help/fstep06.png")
      }, {
        title: "选择发货订单",
        entitle: "Select delivery orders",
        content: "点击需要发货的商品(可选择多个),点击Next进行下一步操作.",
        encontent: "Click on the goods that need to be shipped (multiple options), click on the <b>[Next]</b> for the next step.",
        image: require("../../static/asset/images/using_help/fstep07.png")
      }, {
        title: "填写发货订单",
        entitle: "Fill in delivery orders",
        content: "分别选择收货人地址和Womata仓库地址,若没有预先设置收货人地址可跳转到设置页面进行设置,在订单信息完善后,可以点击PayNow进行订单支付.",
        encontent: "Select receiver address and Womata warehouse address, if there is no pre-set consignee address can jump to the setting page to set up, after the order information is perfect, you can click on the <b>[PayNow]</b> to pay the order.",
        image: require("../../static/asset/images/using_help/fstep08.png")
      }, {
        title: "支付",
        entitle: "Pay",
        content: "输入支付密码进行订单支付,若没有预先设置支付密码则自动跳转到支付密码设置页.",
        encontent: "Enter the payment password for order payment and automatically jump to the payment password settings page if the payment password is not pre-set.",
        image: require("../../static/asset/images/using_help/fstep09.png")
      }, {
        title: "支付完成",
        entitle: "Payment completed",
        content: "在支付成功后自动跳转到Delivered Reviews.",
        encontent: "Automatically jump to <i>Delivered Reviews</i> after payment is successful.",
        image: require("../../static/asset/images/using_help/fstep10.png")
      }, {
        title: "查看订单",
        entitle: "Checking out orders",
        content: "在该页面可查看订单的后续状态.",
        encontent: "On this page you can see the subsequent status of the order.",
        image: require("../../static/asset/images/using_help/fstep11.png")
      }]
    };
  },
  mounted: function mounted() {// 方法1：
    // this.$refs.tempHtml.contentDocument.documentElement.innerHTML = this.ifeData;
    // 方法2：
    // document.getElementById(
    //   "tempHtml"
    // ).contentDocument.documentElement.innerHTML = this.ifeData;
  }
};
exports.default = _default;