"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.function.name");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("van-tabbar", {
    attrs: {
      "safe-area-inset-bottom": "",
      "active-color": "#EA3340",
      "inactive-color": "#666666"
    },
    model: {
      value: _vm.active,
      callback: function callback($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, _vm._l(_vm.tabbars, function (item) {
    return _c("van-tabbar-item", {
      key: item.id,
      attrs: {
        name: item.id,
        to: item.name
      },
      scopedSlots: _vm._u([{
        key: "icon",
        fn: function fn(props) {
          return _c("img", {
            attrs: {
              src: props.active ? item.active : item.normal
            }
          });
        }
      }], null, true)
    }, [_c("span", [_vm._v(_vm._s(item.title))])]);
  }), 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;