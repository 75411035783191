"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPolicy = getPolicy;

var _request = _interopRequireDefault(require("@/utils/request"));

function getPolicy() {
  return (0, _request.default)({
    url: 'oss-service/oss/policy',
    method: 'get'
  });
}