"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("van-nav-bar", {
    attrs: {
      title: "User Name",
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": _vm.onClickLeft
    }
  }), _vm._v(" "), _c("main", [_c("van-form", {
    on: {
      submit: _vm.onSubmit
    }
  }, [_c("van-field", {
    attrs: {
      name: "username",
      required: "",
      label: "User name:",
      rules: [{
        required: true,
        message: "Username can not be empty"
      }]
    },
    model: {
      value: _vm.username,
      callback: function callback($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "40px"
    }
  }, [_c("van-button", {
    attrs: {
      round: "",
      block: "",
      type: "info",
      "native-type": "submit"
    }
  }, [_vm._v("OK")])], 1)], 1)], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;