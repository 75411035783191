"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("van-pull-refresh", {
    attrs: {
      "pulling-text": "Pull down refresh",
      "loosing-text": "Release to refresh",
      "loading-text": "Refreshing",
      "success-text": "Refresh completed"
    },
    on: {
      refresh: _vm.onRefresh
    },
    model: {
      value: _vm.refreshing,
      callback: function callback($$v) {
        _vm.refreshing = $$v;
      },
      expression: "refreshing"
    }
  }, [_vm.data.length ? _c("van-list", {
    attrs: {
      finished: _vm.finished,
      "finished-text": "No More ...",
      "immediate-check": false
    },
    on: {
      load: _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_c("TabList", _vm._b({
    attrs: {
      list: _vm.data,
      "active-tab": _vm.activeTab,
      "invoice-status": _vm.invoiceStatus,
      "delivered-status": _vm.deliveredStatus
    }
  }, "TabList", _vm.$attrs, false))], 1) : _c("van-empty", {
    attrs: {
      description: "No more data!"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/no_data.png")
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;