"use strict";

var _interopRequireDefault = require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/womata-web-app_dYpF/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/card/style");

var _card = _interopRequireDefault(require("vant/es/card"));

require("vant/es/list/style");

var _list = _interopRequireDefault(require("vant/es/list"));

require("core-js/modules/es6.function.name");

require("vant/es/empty/style");

var _empty = _interopRequireDefault(require("vant/es/empty"));

var _cmmission = require("api/cmmission");

var _utils = require("@/utils");

var _components;

var _default = {
  name: "GainList",
  components: (_components = {}, (0, _defineProperty2.default)(_components, _empty.default.name, _empty.default), (0, _defineProperty2.default)(_components, _list.default.name, _list.default), (0, _defineProperty2.default)(_components, _card.default.name, _card.default), (0, _defineProperty2.default)(_components, _icon.default.name, _icon.default), _components),
  data: function data() {
    return {
      list: [],
      loading: false,
      finished: false,
      pageNum: 1
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      (0, _cmmission.geCommissionGroupList)({
        pageNum: this.pageNum,
        pageSize: 10
      }).then(function (res) {
        var _res$data, _res$data$data;

        _this.loading = false;

        if (((_res$data = res.data) === null || _res$data === void 0 ? void 0 : (_res$data$data = _res$data.data) === null || _res$data$data === void 0 ? void 0 : _res$data$data.length) > 0) {
          if (_this.pageNum === 1) {
            _this.list = res.data.data;
          } else {
            _this.list = _this.list.concat(res.data.data);
          }
        } else {
          if (_this.pageNum === 1) _this.list = [];
          _this.finished = true;
        }
      });
    },
    toGoodDetailPage: function toGoodDetailPage(ele) {
      var _arguments = {
        "url": ele.module === 1 ? ele.spuId : (0, _utils.alibabaGoodsLink)(ele.spuId),
        "module": ele.module
      };
      this.$router.push({
        path: "/GoodsContent",
        query: _arguments
      });
    },
    onLoad: function onLoad() {
      this.pageNum++;
      this.fetchData();
    }
  }
};
exports.default = _default;