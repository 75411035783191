"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es6.regexp.replace");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "queryOrder-view"
  }, [_c("van-nav-bar", {
    attrs: {
      "left-arrow": "",
      "safe-area-inset-top": "",
      fixed: ""
    },
    on: {
      "click-left": function clickLeft($event) {
        return _vm.$router.go(-1);
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("van-search", {
          attrs: {
            shape: "round",
            disabled: ""
          },
          on: {
            click: function click($event) {
              return _vm.$router.replace({
                name: "Search",
                query: {
                  fromPage: "Order",
                  toName: "QueryOrder"
                }
              });
            }
          },
          model: {
            value: _vm.value,
            callback: function callback($$v) {
              _vm.value = $$v;
            },
            expression: "value"
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _vm.list.length ? _c("TabList", {
    attrs: {
      list: _vm.list
    }
  }) : _c("van-empty", {
    staticStyle: {
      "min-height": "calc(100vh - 3rem)"
    },
    attrs: {
      description: "No more data!"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function fn() {
        return [_c("van-icon", {
          attrs: {
            name: require("@/assets/images/no_data.png")
          }
        })];
      },
      proxy: true
    }])
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;